
export const reportSettingsData = [
    {
      "Scenario Name": "Scenario 1",
      "Shortlist Parameter":"Vendor",
      "Parameter Value":"3",
      "Vendor Shortlist Metric Type":"Offer Score(Inherited from Scenario)"
    },
    {
      "Scenario Name": "Scenario 2",
      "Shortlist Parameter":"Offer",
      "Parameter Value":"5",
      "Vendor Shortlist Metric Type":"Offer Relevance(Inherited from Scenario)"
    },
    {
      "Scenario Name": "Scenario 3",
      "Shortlist Parameter":"Vendor",
      "Parameter Value":"3",
      "Vendor Shortlist Metric Type":"Offer Score(Inherited from Scenario)"
    }
  ];