import React, { useState, useEffect, useContext } from 'react';
import {
  Snackbar,
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { apiService } from "../utils/utils";
import { UserProfileContext } from "../context/UserContext";
import theme from "../theme/theme.js";

import HeaderNaming from "../components/HeaderNaming/HeaderNaming.jsx";
import DataGridTable from "../components/DataGridTable.jsx";
import DropdownSelect from "../components/InterestedBuyer/DropdownSelect.jsx";
import CustomName from "../components/InterestedBuyer/CustomName.jsx";

const InterestedBuyer = ({
  // interestedGuides,
  moveToSelectBuyer,
}) => {
  const navigate = useNavigate();
  const {
    userProfile,
    currentOrganization,
    interestedBuyerGuides,
    setBuyerGuideName,
    currentOrganizationId,
    notification,
    handleCloseNotification,
  } = useContext(UserProfileContext);

  //
  // Proper DATA

  //  interestedBuyerGuides = [
  //   {
  //     id: 9678,
  //     name: "ZTNA Solution (Test)",
  //     status: "To Do",
  //     action: "Start Analysis",

  //     purchasedPackageClaimed: {
  //       id: 2345678,
  //       selected: "None",
  //       options: [
  //         {
  //           id: 234,
  //           package: "Solo Starter",
  //           quantity: "9",
  //           type: "flexible",
  //         },
  //         {
  //           id: 8988,
  //           package: "Duel Dynamics",
  //           quantity: "3",
  //           type: "flexible",
  //         },
  //         {
  //           id: 1234157,
  //           package: "Focus Five",
  //           quantity: "4",
  //           type: "flexible",
  //         },
  //         {
  //           id: 234578985,
  //           package: "All Available",
  //           quantity: "5",
  //           type: "flexible",
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 108712,
  //     name: "Buyer Guide 2",
  //     status: "To Do",
  //     action: "Start Analysis",
  //     purchasedPackageClaimed: {
  //       id: 68321454,
  //       selected: "None",
  //       options: [
  //         {
  //           id: 8903303,
  //           package: "Duel Dynamics",
  //           quantity: "3",
  //           type: "flexible",
  //         },

  //         {
  //           id: 3784738,
  //           package: "All Available",
  //           quantity: "5",
  //           type: "flexible",
  //         },
  //       ],
  //     },
  //   },

  //   {
  //     id: 5454756,
  //     name: "Buyer Guide 3",
  //     status: "To Do",
  //     action: "Start Analysis",
  //     purchasedPackageClaimed: {
  //       id: 3872347,
  //       selected: "None",
  //       options: null,
  //     },
  //   },
  //   {
  //     id: 12235,
  //     name: "Buyer Guide 4",
  //     status: "To Do",
  //     action: "Start Analysis",
  //     purchasedPackageClaimed: {
  //       id: 837928,
  //       selected: "None",
  //       options: [
  //         {
  //           id: 567890,
  //           package: "Solo Starter",
  //           quantity: 1,
  //           type: "claimed",
  //         },
  //       ],
  //     },
  //   },

  //   {
  //     id: 133456,

  //     name: "Buyer Guide 5",
  //     status: "To Do",
  //     action: "Start Analysis",
  //     purchasedPackageClaimed: {
  //       id: 6454,
  //       selected: "None",
  //       options: [
  //         {
  //           id: 8903303,
  //           package: "Duel Dynamics",
  //           quantity: "3",
  //           type: "fixed",
  //         },
  //       ],
  //     },
  //   },
  // ];

  //
  // NO purchasedPackageClaimed column

  // const interestedBuyerGuides = [
  //   {
  //     id: 5454756,
  //     name: 'Buyer Guide 3',
  //     status: 'To Do',
  //     action: 'Start Analysis',
  //     purchasedPackageClaimed: {
  //       id: 3872347,
  //       selected: 'None',
  //       options: null,
  //     },
  //   },
  //   {
  //     id: 12235,
  //     name: 'Buyer Guide 4',
  //     status: 'To Do',
  //     action: 'Start Analysis',
  //     purchasedPackageClaimed: {
  //       id: 3872347,
  //       selected: 'None',
  //       options: null,
  //     },
  //   },

  //   {
  //     id: 133456,

  //     name: 'Buyer Guide 5',
  //     status: 'To Do',
  //     action: 'Start Analysis',
  //     purchasedPackageClaimed: {
  //       id: 3872347,
  //       selected: 'None',
  //       options: null,
  //     },
  //   },
  // ];

  //
  // NO Checkbox column

  // const interestedBuyerGuides = [
  //   {
  //     id: 5454756,
  //     name: 'Buyer Guide 3',
  //     status: 'To Do',
  //     action: 'Start Analysis',
  //     purchasedPackageClaimed: {
  //       id: 837928,
  //       selected: 'None',
  //       options: [
  //         {
  //           id: 567890,
  //           package: 'Solo Starter',
  //           quantity: 1,
  //           type: 'claimed',
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     id: 12235,
  //     name: 'Buyer Guide 4',
  //     status: 'To Do',
  //     action: 'Start Analysis',
  //     purchasedPackageClaimed: {
  //       id: 837928,
  //       selected: 'None',
  //       options: [
  //         {
  //           id: 567890,
  //           package: 'Solo Starter',
  //           quantity: 1,
  //           type: 'claimed',
  //         },
  //       ],
  //     },
  //   },

  //   {
  //     id: 133456,

  //     name: 'Buyer Guide 5',
  //     status: 'To Do',
  //     action: 'Start Analysis',
  //     purchasedPackageClaimed: {
  //       id: 837928,
  //       selected: 'None',
  //       options: [
  //         {
  //           id: 567890,
  //           package: 'Solo Starter',
  //           quantity: 1,
  //           type: 'claimed',
  //         },
  //       ],
  //     },
  //   },
  // ];

  //

  const [loading, setLoading] = useState(true); // Add loading state

  // All the data
  const [sectionData, setSectionData] = useState(interestedBuyerGuides);

  // Show Error in respected rows
  const [errorToShow, setErrorToShow] = useState([]);

  // Selection of Guides in "Checkbox" and "Purchase Package Claimed" column
  const [guideCheck, setGuideCheck] = useState([]);
  const [allGuidesCheck, setAllGuidesCheck] = useState(false);

  // Enable/Disable Columns
  const [
    purchasedPackageClaimedColumnToShow,
    setPurchasedPackageClaimedColumnToShow,
  ] = useState(false);
  const [checkboxColumnToShow, setCheckboxColumnToShow] = useState(false);

  // Enable/Disable Buttons
  const [claimPurchasedPackage, setClaimPurchasedPackage] = useState(false); // 'Claim Purchased Package' and 'Show Available Buyer Guides'
  const [noLongerInterested, setNoLongerInterested] = useState(false);

  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
  };
  useEffect(() => {
    if (
      Array.isArray(interestedBuyerGuides) &&
      interestedBuyerGuides?.length > 0
    ) {
      setSectionData(
        interestedBuyerGuides?.map((guide) => ({
          id: guide.id,
          name: guide.name,
          status: guide.status,
          action: guide.action ? guide.action : [],
          purchasedPackageClaimed: {
            id: guide.id,
            selected: "None",
            options: [
              { package: "Solo Starter", quantity: "2", type: "flexible" },
              { package: "Duel Dynamics", quantity: "3", type: "flexible" },
              { package: "Focus Five", quantity: "4", type: "flexible" },
              { package: "All Available", quantity: "5", type: "flexible" },
            ],
          },
        }))
      );
      setLoading(false); // Set loading to false once data is loaded
    }
  }, [interestedBuyerGuides]);
  // }, []);

  useEffect(() => {
    //
    setPurchasedPackageClaimedColumnToShow(
      sectionData?.some(
        (guide) => guide.purchasedPackageClaimed?.options !== null
      )
    );

    setCheckboxColumnToShow(
      sectionData?.some(
        (guide) =>
          guide.purchasedPackageClaimed?.options === null ||
          (guide.purchasedPackageClaimed?.options?.length > 0 &&
            guide.purchasedPackageClaimed?.options[0]?.type !== "claimed")
      )
    );
    //

    const allChecked =
      sectionData?.length > 0 &&
      sectionData.every(
        (guide) =>
          guideCheck?.includes(guide.id) ||
          (guide.purchasedPackageClaimed?.options?.length > 0 &&
            guide.purchasedPackageClaimed?.options[0]?.type === "claimed")
      );

    setAllGuidesCheck(allChecked && guideCheck?.length > 0);

    // checks if there are some options to select and checkbox is checked (i.e to show claim button)
    let claimVaid = sectionData?.some((guide) =>
      guideCheck?.some(
        (id) =>
          id === guide.id &&
          guide.purchasedPackageClaimed?.selected !== "None" &&
          guide.purchasedPackageClaimed?.selected?.type !== "claimed" &&
          guide.purchasedPackageClaimed?.options !== null
      )
    );

    setClaimPurchasedPackage(guideCheck?.length > 0 && claimVaid); // to toggle between "Claim Purchase Package" and "Show Available Buyer Guides" buttons

    // checks if the package is "Fixed Allocated" and checkbox is checked
    const isfixed = sectionData?.some((guide) =>
      guideCheck?.some(
        (id) =>
          id === guide.id &&
          guide.purchasedPackageClaimed?.options &&
          guide.purchasedPackageClaimed?.options[0].type === "fixed"
      )
    );
    setNoLongerInterested(guideCheck.length > 0 && !isfixed); // to Hide or Show "No Longer Interested Button"
  }, [sectionData, guideCheck]);

  useEffect(() => {
    // adding {error:true} in "sectionData" when there is an invalid package is tried to be claimed
    if (errorToShow?.length > 0) {
      const errorIds = errorToShow?.map((pack) => pack.id);

      const latestSectionData = sectionData?.map((guide) => {
        if (!errorIds?.includes(guide.id)) {
          return guide;
        } else {
          let newGuide = guide;
          newGuide.purchasedPackageClaimed.error = true;
          return newGuide;
        }
      });
      setSectionData(latestSectionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorToShow, guideCheck]);

  useEffect(() => {
    // Removing the error sign if the box got unchecked or any other options got selected

    if (errorToShow?.length > 0) {
      const errorIds = errorToShow?.map((packag) => packag.id);

      // Checking for checkbox if it got unchecked to remove the error logo
      const errorIdsToBeRemoved = errorIds?.filter((errorId) => {
        if (!guideCheck?.includes(errorId)) return true;

        return sectionData?.some((guide) => {
          return (
            guide.id === errorId &&
            (guide.purchasedPackageClaimed?.options === null ||
              guide.purchasedPackageClaimed?.selected === "None")
          );
        });
      });

      const latestSectionData = sectionData?.map((guide) => {
        if (!errorIdsToBeRemoved?.includes(guide.id)) {
          return guide;
        } else {
          let newGuide = guide;
          delete newGuide.purchasedPackageClaimed.error;
          return newGuide;
        }
      });
      setSectionData(latestSectionData);
      setErrorToShow(
        errorToShow?.filter((pakag) => {
          if (!errorIdsToBeRemoved?.includes(pakag.id)) return true;
          else return false;
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guideCheck]);

  const handleCheckboxChange = (id) => {
    setGuideCheck((prevState) =>
      prevState?.includes(id)
        ? prevState?.filter((guideId) => guideId !== id)
        : [...prevState, id]
    );
  };

  const handleSelectAllChange = () => {
    if (allGuidesCheck) {
      setGuideCheck([]);
    } else {
      const updatedSectionData = sectionData?.filter(
        (guide) =>
          (guide?.purchasedPackageClaimed?.options?.length > 0 &&
            guide?.purchasedPackageClaimed?.options[0]?.type !== "claimed") ||
          guide?.purchasedPackageClaimed?.options === null
      );
      setGuideCheck(updatedSectionData?.map((guide) => guide.id));
    }
    setAllGuidesCheck(!allGuidesCheck);
  };

  const returnSelectedGuides = () => {
    let interested_tab_checkbox_states = {};
    for (let val of interestedBuyerGuides) {
      interested_tab_checkbox_states[val.id] = guideCheck?.includes(val.id);
    }
    return interested_tab_checkbox_states;
  };

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.endUser_companyId
      : currentOrganizationId;

  const handleShowAvailable = () => {
    if (!claimPurchasedPackage)
      navigate("/select-buyer-guide/available-buyer-guides");
    else {
      const guidesNotToBeSelected = sectionData?.filter((guide) => {
        if (!guideCheck?.includes(guide.id)) return false;
        else return guide.purchasedPackageClaimed?.selected === "None";
      });

      if (!(guidesNotToBeSelected?.length === 0))
        setErrorToShow(guidesNotToBeSelected);
    }
  };

  const handleSendBack = async () => {
    await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/notInterestedBuyerGuides?",
      {
        method: "POST",
        data: {
          email: userProfile.email,
          currentOrganisation: currentOrg,
          interested_tab_checkbox_states: returnSelectedGuides(),
        },
      }
    );
    moveToSelectBuyer(guideCheck);
    setSectionData((prevData) =>
      prevData?.filter((guide) => !guideCheck?.includes(guide.id))
    );

    setGuideCheck([]); // Reset selection
    setAllGuidesCheck(false); // Uncheck "Select All"
  };

  const handleRowClick = (params) => {
    setBuyerGuideName(params.row.name);
    localStorage.setItem("buyerGuideName", params.row.name); // NEED THIS
    navigate("/select-scenario");
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 90,
      sortable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",

      flex: 0.5,
      renderHeader: () => (
        <Checkbox
          checked={allGuidesCheck}
          onChange={handleSelectAllChange}
          color="secondary"
          sx={{ color: "white" }}
        />
      ),

      renderCell: (params) => {
        return (
          ((params.row?.purchasedPackageClaimed?.options?.length > 0 &&
            params.row?.purchasedPackageClaimed?.options[0]?.type !==
              "claimed") ||
            params.row?.purchasedPackageClaimed?.options === null) && (
            <Checkbox
              checked={guideCheck?.includes(params.row.id)}
              onChange={() => handleCheckboxChange(params.row.id)}
              color="secondary"
            />
          )
        );
      },
    },
    {
      field: "name",
      headerName: "Buyer Guide Name",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <CustomName 
          // data-test-id="name"
          name={params.value}
          isError={params.row?.purchasedPackageClaimed?.error ? true : false}
        />
      ),
    },

    {
      field: "purchasedPackageClaimed",
      headerName: "Purchased Package Claimed",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <DropdownSelect
          purchasedPackageClaimed={params.value}
          onSet={() => {
            setErrorToShow((prevErrorToShow) =>
              prevErrorToShow?.filter((packag) => {
                return packag.purchasedPackageClaimed.id !== params?.value?.id;
              })
            );

            sectionData.forEach((el) => {
              if (
                el.purchasedPackageClaimed.id === params?.value?.id &&
                params.value.selected !== "None"
              )
                if (!guideCheck?.includes(el.id)) handleCheckboxChange(el.id);
            });

            setSectionData(
              sectionData?.map((el) => {
                if (el.purchasedPackageClaimed.id === params?.value?.id)
                  return { ...el, purchasedPackageClaimed: params.value };
                return el;
              })
            );
          }}
        ></DropdownSelect>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <div data-test-id="start-analysis">
        <Button
          sx={{ width: "250px" }}
          variant="contained"
          color="secondary"
          onClick={() => handleRowClick(params)}
        >
          {params.value}
        </Button>
        </div>
      ),
    },
  ];

  // it decides whether to show dropdown and checkbox column

  let updatedColumns = columns;
  if (!purchasedPackageClaimedColumnToShow && !checkboxColumnToShow)
    updatedColumns = columns?.filter(
      (el) => el.field !== "purchasedPackageClaimed" && el.field !== "checkbox"
    );
  else if (!purchasedPackageClaimedColumnToShow)
    updatedColumns = columns?.filter(
      (el) => el.field !== "purchasedPackageClaimed"
    );
  else if (!checkboxColumnToShow)
    updatedColumns = columns?.filter((el) => el.field !== "checkbox");

  if (loading) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginTop: 4,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <HeaderNaming data={data} />
        <Box
          sx={{
            "& .super-app-theme--header": {
              backgroundColor: theme.palette.primary.main,
              border: "0.5px solid grey",
              borderBottom: "none", // Remove the bottom border
              height: "fit-content",
            },
          }}
        >
          <DataGridTable
            rows={sectionData}
            columns={updatedColumns}
            disableSelectionOnClick
            hideFooter
            autoHeight
            onRowClick={handleRowClick} // Added onRowClick event
            sx={{
              minWidth: "1080px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
                whiteSpace: "normal",
                wordWrap: "break-word",
                borderBottom: "none", // Remove the bottom border
              },
              "& .MuiDataGrid-cell": {
                border: "0.5px solid grey",
                wordWrap: "break-word",
                whiteSpace: "normal",
              },
              "& .MuiDataGrid-cell--editing": {
                backgroundColor: "transparent !important",
                border: "3px solid #000", // Thicker border when in edit mode
              },
              "& .MuiDataGrid-cell:hover": {
                border: "0.5px solid #000",
              },
              "& .MuiDataGrid-cell--editable:hover": {
                border: "2px solid #000", // Thicker border when editable cell is hovered
              },
            }}
          />
        </Box>
        {errorToShow?.length > 0 && (
          <span style={{ color: "red", marginTop: "16px", marginLeft: "8px" }}>
            Please Select Valid Package for the Claim
          </span>
        )}
        {noLongerInterested && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: "16px", width: "250px" }}
            onClick={handleSendBack}
          >
            No Longer Interested
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "16px", width: "250px" }}
          onClick={handleShowAvailable}
        >
          {claimPurchasedPackage
            ? "Claim Purchased Package"
            : "Show Available Buyer Guides"}
        </Button>
      </Box>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
       
            <Alert
              onClose={handleCloseNotification}
              severity={notification.severity}
            >
              {notification.message}
            </Alert>
    </Snackbar>
       
    </>
  );
};

export default InterestedBuyer;
