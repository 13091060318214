import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JsonInput from "./JsonInput";
import TreeComponent from "./TreeComponent";
import "./MainComponent.css";
import { UserProfileContext } from "../context/UserContext";

function MainComponent() {
 const [jsonInput, setJsonInput] = useState("");
 const [parsedData, setParsedData] = useState(null);
 const [loading, setLoading] = useState(false);
 const location = useLocation();
 const { userProfile } = useContext(UserProfileContext);
 const navigate = useNavigate();
 const [elementId, setElementId] = useState(null);
 const [description, setDescription] = useState(""); // New state for description

 useEffect(() => {
   if (location.state?.buyerGuideData) {
     const json = JSON.stringify(location.state.buyerGuideData, null, 2);
     setJsonInput(json);
     setParsedData(location.state.buyerGuideData);
   }
 }, [location.state]);

 const handleFileUpload = (event) => {
   const file = event.target.files[0];
   const reader = new FileReader();
   reader.onload = (e) => {
     setJsonInput(e.target.result);
   };
   reader.readAsText(file);
 };

 const handlePasteData = (event) => {
   setJsonInput(event.target.value);
 };

 const handleRun = () => {
   try {
     setLoading(true);
     const parsed = JSON.parse(jsonInput);
     setTimeout(() => {
       setParsedData(parsed);
       setLoading(false);
     }, 1000);
   } catch (e) {
     alert("Invalid JSON data");
     setLoading(false);
   }
 };

 const handleGenerateUseCases = async () => {
   if (!elementId) {
     alert("Please select a rectangle before generating use cases.");
     return;
   }

   const isMatching = elementId === parsedData?.[0]?.element_id;
// Create the request body
const reqBody = {
 userEmail: userProfile.email,
 buyerGuideElementId: isMatching ? elementId : parsedData?.[0]?.element_id,
 selectedUseCaseId: elementId,
};

// Conditionally add 'userPrompt' if description is not empty
if (description.trim()) {
 reqBody.userPrompt = description;
}
        

   try {
     setLoading(true);
     const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/generateUseCasesWithAI', {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify(reqBody),
     });
     if (!response.ok) throw new Error('Network response was not ok.');
     const data = await response.json();
     navigate('/sub-use-cases', { state: { data } });
   } catch (error) {
     console.error('Error generating use cases:', error);
     alert('Failed to generate use cases: ' + error.message);
   } finally {
     setLoading(false);
   }
 };

 const handleReview = async () => {
       if (!elementId) {
         alert("Please select a rectangle before reviewing use cases.");
         return;
       }
  
       const isMatching = elementId === parsedData?.[0]?.element_id;
       const reviewPayload = {
         userEmail: userProfile.email,
         buyerGuideElementId: isMatching ? elementId : parsedData?.[0]?.element_id,
         selectedUseCaseId: elementId
       };
  
       try {
         setLoading(true);
         const response = await fetch('https://newtestfuncpython.azurewebsites.net/api/reviewSubUseCasesForAnalyst', {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
           },
           body: JSON.stringify(reviewPayload),
         });
         const data = await response.json();
         navigate('/sub-use-cases', { state: { data } });
       } catch (error) {
         console.error('Error reviewing use cases:', error);
         alert('Failed to review use cases: ' + error.message);
       } finally {
         setLoading(false);
       }
     };

 // Using useCallback to ensure function stability
 const onNodeClick = useCallback((elementId) => {
   console.log("Node clicked with ID:", elementId);
   setElementId(elementId); // Store the clicked node's element ID in the state
 }, []);

 return (
   <div className="MainComponent">
     <JsonInput
       onFileUpload={handleFileUpload}
       onPasteData={handlePasteData}
       inputData={jsonInput}
       handleRun={handleRun}
       handleGenerate={handleGenerateUseCases}
       handleReview={handleReview}
       description={description} // Pass the description state
       onDescriptionChange={(e) => setDescription(e.target.value)} // Pass the handler to update description
     />
     {loading && <div className="loading-spinner"></div>}
     {!loading && parsedData && (
       <TreeComponent
         data={parsedData}
         onUpdateJson={setParsedData}
         onNodeClick={onNodeClick}
       />
     )}
   </div>
 );
}

export default MainComponent;
