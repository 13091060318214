import React from 'react'
import { useEffect, useState } from "react";
import DataGridTable from "../../../../components/DataGridTable";
import { Box } from "@mui/material";

function UsageDetailTable({ fetchedData, section }) {
    console.log(fetchedData[section])
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {

        let rowData = [];
        let key =1;
        fetchedData[section].forEach((data) => {
            const users = data.users;
            const packageName = data.package;
            for (var i = 0; i < users.length; i++) {
                if (i === 0) {
                    
                    rowData.push({
                        id: key,
                        package: packageName,
                        buyerGuideName: users[i].buyerGuideName,
                        endUserOrganisationName: users[i].endUserOrganisationName,
                        unlockedVendorNames: users[i].unlockedVendorNames,
                        userName: users[i].userName
                    })
                    
                }
                else{
                    
                    rowData.push({
                        id: key,
                        package: "",
                        buyerGuideName: users[i].buyerGuideName,
                        endUserOrganisationName: users[i].endUserOrganisationName,
                        unlockedVendorNames: users[i].unlockedVendorNames,
                        userName: users[i].userName
                    })
                   
                 }
                key++;
               }
               
            })
        const transformedData = {
            columuns: [

                {
                    field: "package",
                    headerName: "Purchased Package",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    cellClassName:"super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "userName",
                    headerName: "User (Ultimate) Name",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "endUserOrganisationName",
                    headerName: "End-User Organisation Name",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "buyerGuideName",
                    headerName: "Buyer Guide Name",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "unlockedVendorNames",
                    headerName: "Unlocked Vendors Names",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                }
            ],
            rows: rowData

        }
        console.log(rowData)
        setColumns(transformedData.columuns);
        setRows(transformedData.rows)
        setLoading(false);
        setError(null);
    }, [fetchedData, section]);
    return (
        <Box sx={{ width: "100%", marginRight: "30px" }}><DataGridTable
            rows={rows}
            columns={columns}
            loading={loading}
            error={error}
            menu={true}

        /></Box>
    )
}

export default UsageDetailTable