import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, purchasedGuide, theme) {
  return {
    fontWeight:
      purchasedGuide?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DropdownSelect({ purchasedPackageClaimed, onSet }) {
  const [selectedGuide, setSelectedGuide] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const updatedSelect =
      selectedGuide?.length > 0
        ? purchasedPackageClaimed.options?.find(
            (option) => option.package === selectedGuide[0]
          )
        : "None";

    purchasedPackageClaimed.selected = updatedSelect;

    if (
      purchasedPackageClaimed.selected !== "None" &&
      purchasedPackageClaimed?.error
    ) {
      delete purchasedPackageClaimed.error;
    }

    onSet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuide, purchasedPackageClaimed]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedGuide(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Box sx={{ width: "100%" }} data-test-id='dropdown'>
      <FormControl
        sx={{
          m: 1,
          width: "80%",
        }}
      >
        {purchasedPackageClaimed?.options === null ? (
          <span>None</span>
        ) : (
          purchasedPackageClaimed?.options[0]?.type === "claimed" && (
            <span>{purchasedPackageClaimed?.options[0]?.package} </span>
          )
        )}

        {purchasedPackageClaimed?.options?.length > 0 &&
          purchasedPackageClaimed?.options[0]?.type !== "claimed" && (
            <Select
              displayEmpty
              value={selectedGuide}
              onChange={handleChange}
              input={<OutlinedInput />}
              sx={{
                color: "gray",
                height: 40,
              }}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <span>None </span>;
                }
                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem data-test-id='dropdown' disabled value="" sx={{ justifyContent: "center" }}>
                <span>None</span>
              </MenuItem>
              {purchasedPackageClaimed?.options?.map((val) => (
                <MenuItem
                  sx={{ justifyContent: "center" }}
                  key={val.package}
                  value={val.package}
                  // style={getStyles(theme)}
                  style={getStyles(val.package, selectedGuide, theme)}
                >
                  {`${val.package} ${
                    val.type === "flexible" ? `(${val.quantity})` : ""
                  }`}
                </MenuItem>
              ))}
            </Select>
          )}
      </FormControl>
    </Box>
  );
}
