import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Radio, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { reportSettingsData } from './Utils/dummyReportSettings.js';
import DataGridTable from '../components/DataGridTable.jsx';

const ReportSettings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState(
    reportSettingsData.map((item, index) => ({
      id: index,
      scenarioName: item['Scenario Name'],
      shortlistParam: item['Shortlist Parameter'],
      paramValue: item['Parameter Value'],
      vendorMetricType: item['Vendor Shortlist Metric Type'],
    }))
  );

  const handleRadioChange = (id) => {
    setSelectedRow(id);
    setRows((prevRows) =>
      prevRows.map((row) => ({
        ...row,
        select: row.id === id,
      }))
    );
  };

  const columns = [
    {
      field: 'select',
      headerName: '',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',

      renderCell: (params) => (
        <Radio
          checked={params.id === selectedRow}
          onChange={() => handleRadioChange(params.id)}
          value={params.id}
        />
      ),
    },
    {
      field: 'scenarioName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Scenario Name',
      flex: 1,
    },
    {
      field: 'shortlistParam',
      headerClassName: 'super-app-theme--header',
      headerName: 'Shortlist Parameters',
      flex: 1,
    },
    {
      field: 'paramValue',
      headerClassName: 'super-app-theme--header',
      headerName: 'Parameter Value',
      flex: 1,
    },
    {
      field: 'vendorMetricType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Vendor Shortlist Metric Type',
      flex: 1,
    },
  ];

  return (
    <Box
      flexGrow={1}
      sx={{
        '& .super-app-theme--header': {
          backgroundColor: theme.palette.primary.main,
          border: '0.5px solid grey',
          height: 'fit-content',
        },
      }}
    >
      <DataGridTable
        rows={rows}
        columns={columns}
        autoHeight
        hideFooter
        sx={{
          minWidth: '1080px',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
          '& .MuiDataGrid-cell': {
            border: '0.5px solid grey',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          },
          '& .MuiDataGrid-cell--editable:hover': {
            border: '2px solid #000',
          },
        }}
      />
      <Box mt={2} display="flex" flexDirection="column" gap={2}>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '250px' }}
          onClick={() => navigate('/view-reports')}
        >
          Generate Report
        </Button>
        <Button variant="contained" color="secondary" sx={{ width: '250px' }}>
          Edit Scenario
        </Button>
      </Box>
    </Box>
  );
};

export default ReportSettings;
