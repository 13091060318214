import { useNavigate } from 'react-router-dom';
import { UserProfileContext } from "../context/UserContext";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
import { useContext, useState, useEffect } from 'react';
import { Box, Typography, Radio, Button } from '@mui/material';

const PurchaseBulkSingle = () => {
  const navigate = useNavigate();
  const {
    userProfile,
    purchasePackage,
    totalUnits,
    setTotalUnits,
    userUnits,
    endUserUnits,
    buyerUnits,
  } = useContext(UserProfileContext);

  useEffect(() => {
    setTotalUnits(userUnits + endUserUnits + buyerUnits);
  }, [userUnits, endUserUnits, buyerUnits, setTotalUnits]);

  const [value, setValue] = useState("allocate");
  const [nestedValue, setNestedValue] = useState("");
  const [noNestedValue, setNoNestedValue] = useState("resultsPage");

  const data = {
    "Purchased Package": purchasePackage,
    "Total Units": totalUnits,
    "User Units": userUnits,
    "End User Units": endUserUnits,
    "Buyer Guide Units": buyerUnits,
  };

  const handleNavigate = () => {
    if (value === "allocate") {
      if (nestedValue === "listOfUsers") {
        navigate('/list-of-users');
      } else if (nestedValue === "listOfEndUserOrgs") {
        navigate('/list-of-end-user-organizations');
      } else if (nestedValue === "listOfBuyerGuides") {
        navigate('/list-of-buyer-guides');
      }
    } else if (value === "no") {
      if (noNestedValue === "resultsPage") {
        navigate('/results/comparative-analysis');
      } else if (noNestedValue === "subscriptionsPage") {
        navigate('/my-subscriptions');
      }
    }
  };

  return (
    <Box>
      <HeaderNaming data={data} />
      <Typography>Dear {userProfile?.displayName},</Typography>
      <Typography>Your purchase of {purchasePackage} was successful!</Typography>
      <Typography>Congratulations! Let's allocate the units</Typography>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              checked={value === "allocate"}
              onChange={() => setValue("allocate")}
              value="allocate"
            />
            <Typography>Yes, Let's allocate</Typography>
          </Box>
          {value === "allocate" && (
            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 4 }}>
              {userUnits > 1 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio
                    checked={nestedValue === "listOfUsers"}
                    onChange={() => setNestedValue("listOfUsers")}
                    value="listOfUsers"
                  />
                  <Typography>to 'List of Users'</Typography>
                </Box>
              )}
              {endUserUnits > 1 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio
                    checked={nestedValue === "listOfEndUserOrgs"}
                    onChange={() => setNestedValue("listOfEndUserOrgs")}
                    value="listOfEndUserOrgs"
                  />
                  <Typography>to 'List of End-User Organizations'</Typography>
                </Box>
              )}
              {buyerUnits > 1 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio
                    checked={nestedValue === "listOfBuyerGuides"}
                    onChange={() => setNestedValue("listOfBuyerGuides")}
                    value="listOfBuyerGuides"
                  />
                  <Typography>to 'List of Buyer Guides'</Typography>
                </Box>
              )}
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              checked={value === "no"}
              onChange={() => setValue("no")}
              value="no"
            />
            <Typography>No</Typography>
          </Box>
          {value === "no" && (
            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  checked={noNestedValue === "resultsPage"}
                  onChange={() => setNoNestedValue("resultsPage")}
                  value="resultsPage"
                />
                <Typography>(Default) Take me to 'Results' Page</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  checked={noNestedValue === "subscriptionsPage"}
                  onChange={() => setNoNestedValue("subscriptionsPage")}
                  value="subscriptionsPage"
                />
                <Typography>Take me to 'My Subscriptions' page</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button variant="contained" color="secondary" onClick={handleNavigate} sx={{ width: '250px' }}>
          {value === "allocate" ? "Allocate" : "Exit to selected page"}
        </Button>
      </Box>
    </Box>
  );
}

export default PurchaseBulkSingle;
