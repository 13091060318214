import React from "react";
import QuadrantCommentary from "./QuadrantCommentary";
import {
  Box,
  CircularProgress,
} from "@mui/material";
function QuadrantSummary({ commentary, section, setCommentary, data }) {
 
  return (
    <Box>
      {commentary[section]?.quadrantSummary.offer_summaries.length ===
      data.length ?  (
        <QuadrantCommentary
          commentary={commentary}
          section={section}
          setCommentary={setCommentary}
          offer_summaries={commentary[section].quadrantSummary.offer_summaries}
        />
      ):(
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      ) }
    </Box>
  );
}

export default QuadrantSummary;
