import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import { UserProfileContext } from "../../../context/UserContext";

Chart.register(annotationPlugin);

export let XLineValue = 55; // Xline to separate quadrant
export let YLineValue = 45; // Yline to separate quadrant

const ChartComponent = ({
  chartType,
  initialData,
  gridLines,
  onUpdateVisibleData,
}) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const navigate = useNavigate();
  const { setOfferName } = useContext(UserProfileContext);

  if (gridLines) {
    XLineValue = gridLines?.XDivider;
    YLineValue = gridLines?.YDivider;
  }
  useEffect(() => {
    const createChart = () => {
      const ctx = chartRef.current.getContext("2d");
      const chartData = initialData.slice(1).map((row) => {
        const originalR = parseFloat(row[5]);
        return {
          x: parseFloat(row[7]),
          y: parseFloat(row[6]),
          r: originalR < 15 ? originalR / 2 : originalR / 5,
          label: row[3],
          vendor: row[1],
          status: row[2], // Add status to differentiate mandatory and overall
          originalR: originalR, // store the original value for display
          CustomID:`bubble-${row[3]}`
        };
      });

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const datasets = [
        {
          label: "Overall Use Cases",
          data: chartData.filter((d) => d.status === "Unlocked"),
          backgroundColor: "#40BAB4",
          borderWidth: 0,
        },
        {
          label: "Mandatory Use Cases",
          data: chartData.filter((d) => d.status === "Locked"),
          backgroundColor: "#4F40BA",
          borderWidth: 0,
          testId:"mandatory"
        },
      ];

      const config = {
        type: chartType.includes("Bar") ? "bar" : "bubble",
        data: { datasets },
        options: {
          plugins: {
              // afterRender: (chart) => {
              //   chart.data.datasets.forEach((dataset, datasetIndex) => {
              //     const meta = chart.getDatasetMeta(datasetIndex);
              //     meta.data.forEach((bubble, index) => {
              //       bubble._model.element._view.$context[0].$el.setAttribute(
              //         'data-test-id',
              //         `${dataset.testId}`
              //       );
              //     });
              //   });
              // },
            title: {
              display: true,
              color: "#444",
              font: {
                family: "Oswald,sans-serif",
                size: 24,
              },
            },
            legend: {
              display: true,
              position: "top",
              onClick: (e, legendItem) => {
                const index = legendItem.datasetIndex;
                const ci = legendItem.chart;
                const alreadyHidden =
                  ci.getDatasetMeta(index).hidden === null
                    ? false
                    : ci.getDatasetMeta(index).hidden;

                ci.data.datasets.forEach((dataset, i) => {
                  const meta = ci.getDatasetMeta(i);
                  if (i === index) {
                    meta.hidden = !alreadyHidden;
                  }
                });

                ci.update();

                // Call onUpdateVisibleData to update the table
                const visibleData = ci.data.datasets.filter(
                  (dataset) =>
                    !dataset._meta[Object.keys(dataset._meta)[0]].hidden
                );
                onUpdateVisibleData(
                  visibleData.map((dataset) => dataset.label)
                );
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.raw.label;
                  const vendor = context.raw.vendor;
                  const xValue = context.raw.x;
                  const yValue = context.raw.y;
                  const rValue = context.raw.originalR;
                  return `${label} (${vendor}): (Score: ${rValue}%, Relevance: ${yValue}%, Utilization: ${xValue}%)`;
                },
              },
            },
            annotation: {
              annotations: {
                xLine: {
                  type: "line",
                  scaleID: "x",
                  value: XLineValue,
                  borderColor: "gray",
                  borderWidth: 1,
                  borderDash: [6, 6],
                },
                yLine: {
                  type: "line",
                  scaleID: "y",
                  value: YLineValue,
                  borderColor: "gray",
                  borderWidth: 1,
                  borderDash: [6, 6],
                },
                quadrant1: {
                  type: "label",
                  xValue: 80,
                  yValue: 83,
                  content: "Smart",
                  color: "#444",
                  font: {
                    size: 16,
                  },
                  position: {
                    x: "start",
                    y: "end",
                  },
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
                quadrant2: {
                  type: "label",
                  xValue: 10,
                  yValue: 83,
                  content: "Premium",
                  color: "#444",
                  font: {
                    size: 16,
                  },
                  position: {
                    x: "start",
                    y: "end",
                  },
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
                quadrant3: {
                  type: "label",
                  xValue: 10,
                  yValue: 20,
                  content: "Low",
                  color: "#444",
                  font: {
                    size: 16,
                  },
                  position: {
                    x: "start",
                    y: "start",
                  },
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
                quadrant4: {
                  type: "label",
                  xValue: 80,
                  yValue: 20,
                  content: "Budget",
                  color: "#444",
                  font: {
                    size: 16,
                  },
                  position: {
                    x: "start",
                    y: "start",
                  },
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              },
            },
          },
          onClick: (e, elements) => {
            if (elements.length > 0) {
              const datasetIndex = elements[0].datasetIndex;
              const index = elements[0].index;
              const selectedData =
                chartInstanceRef.current.data.datasets[datasetIndex].data[
                  index
                ];
              setOfferName(selectedData.label);
              // const selectedOfferElement = chartRef.current;
              // selectedOfferElement.setAttribute(
              //   "data-test-id",
              //   `${selectedData.CustomID}` // Use customID here
              // );
              localStorage.setItem("currentOffer", selectedData.label);
              navigate("/results/offer-details", {
                state: {
                  vendor: selectedData.vendor,
                  offer: selectedData.label,
                },
              });
            }
          },
          scales: {
            x: {
              min: 0,
              max: 100,
              title: {
                display: true,
                text: "Offer Utilization (%)",
                color: "#444",
                font: {
                  size: 16,
                },
              },
              ticks: {
                color: "#444",
              },
            },
            y: {
              min: 0,
              max: 100,
              title: {
                display: true,
                text: "Offer Relevance (%)",
                color: "#444",
                font: {
                  size: 16,
                },
              },
              ticks: {
                color: "#444",
              },
            },
          },
        },
      };
      chartInstanceRef.current = new Chart(ctx, config);
      
    };

    if (chartRef.current) {
      createChart();
    }
  }, [chartType, initialData, navigate, onUpdateVisibleData, setOfferName]);

  return <canvas data-test-id="chartcomponent" ref={chartRef}  />;
};

export default ChartComponent;
