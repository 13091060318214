import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DataGridTable from "../../../components/DataGridTable";
import {
  Box,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import {
  FileCopyOutlined,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import ChartComponent from "./ChartComponent";
import {
  apiService,
  modifySubscriptionBillingCycle,
} from "../../../utils/utils";
import { UserProfileContext } from "../../../context/UserContext";
import "./ComponentStyle.css";
// Function to convert percentage strings to integers
const parsePercentage = (value) => {
  if (typeof value === "string" && value.endsWith("%")) {
    return parseInt(value.slice(0, -1), 10);
  }
  return value;
};
 
const DataGridComponent = ({
  isTableVisible,
  widgetLabels,
  widgetKey,
  toggleTableVisibility,
  handleCopyWidget,
  handleRemoveWidget,
  setLayout,
}) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [chartType, setChartType] = useState("Bubble Chart");
  const { initialData, setInitialData } = useContext(UserProfileContext);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLink, setDialogLink] = useState("");
  const [gridLines, setGridLines] = useState({});
  const [isNewUser, setIsNewUser] = useState(false);
  const selectedOffer = useRef("N/A");
  const selectedVendor = useRef("N/A");
  const navigate = useNavigate()
  const handleToggleColumn = (columnField) => {
    const updatedColumns = columns.map((col) =>
      col.field === columnField ? { ...col, isVisible: !col.isVisible } : col
    );
    setColumns(updatedColumns);
  };
 
  const handleToggleIcon = async (rowId, iconType) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId && row.column0.type !== "checkbox"
          ? {
              ...row,
              column0:
                row.column0.type === "lock"
                  ? { type: "check" }
                  : { type: "lock" },
            }
          : row
      )
    );
    if (iconType === "lock" && !isNewUser) {
      await modifySubscriptionBillingCycle(userProfile?.email);
      setIsNewUser(true);
      localStorage.setItem("isNewUser", true);
    }
  };
 
  const handleUnlock = () => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.column0.type === "check"
          ? { ...row, column0: { type: "checkbox", checked: false } }
          : row
      )
    );
  };
 
  const handleCheckboxToggle = (rowId) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              column0: { ...row.column0, checked: !row.column0.checked },
            }
          : row
      )
    );
  };
 
  const updateChartType = (visibleColumnNames) => {
    if (
      visibleColumnNames.includes("Offer Score") &&
      visibleColumnNames.includes("Offer Utilization") &&
      !visibleColumnNames.includes("Offer Relevance")
    ) {
      return "Bar Chart(Offer Score & Utilization)";
    } else if (
      visibleColumnNames.includes("Offer Score") &&
      visibleColumnNames.includes("Offer Relevance") &&
      !visibleColumnNames.includes("Offer Utilization")
    ) {
      return "Bar Chart(Offer Score & Relevance)";
    } else if (
      visibleColumnNames.includes("Offer Score") &&
      !visibleColumnNames.includes("Offer Relevance") &&
      !visibleColumnNames.includes("Offer Utilization")
    ) {
      return "Bar Chart(Offer Score)";
    } else if (visibleColumnNames.includes("Offer Score")) {
      return "Bubble Chart";
    } else if (
      visibleColumnNames.includes("Offer Relevance") &&
      visibleColumnNames.includes("Offer Utilization")
    ) {
      return "Scatter Chart";
    } else if (visibleColumnNames.includes("Offer Utilization")) {
      return "Bar Chart(Utilization)";
    } else if (visibleColumnNames.includes("Offer Relevance")) {
      return "Bar Chart(Relevance)";
    } else {
      return "Bubble Chart"; // Default to Bubble Chart if no specific type matches
    }
  };
 
  const { currentOrganization, userProfile, scenarioName, buyerGuideName } =
    useContext(UserProfileContext);
 
  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/valyouMatrixWidgetComparativeAnalysis?", // Replace with your actual API endpoint
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: buyerGuideName,
              currentScenario: scenarioName,
            },
          },
          setLoading,
          null,
          console.error
        );
         
        // Transform the fetched data into the required format
        const headers = [
          "Select",
          "Vendor Name",
          "Status",
          "Offer Name",
          "Mandatory Status",
          "Offer Score",
          "Offer Relevance",
          "Offer Utilization",
        ];
        setLayout((prev) => {
          const newLayout = prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              const newItem = {
                ...item,
                h:
                  8 + (data.OverallOffers.length + data.MandatoryOffers.length),
                minH:
                  8 + (data.OverallOffers.length + data.MandatoryOffers.length),
                maxH:
                  8 + (data.OverallOffers.length + data.MandatoryOffers.length),
              };
              return newItem;
            }
          });
          return newLayout
        });
        const overallData = data.OverallOffers.map((offer) => [
          {
            type: offer.OfferName === "Offer not available" ? "check" : "lock",
          },
          offer.VendorName,
          offer.OfferName === "Offer not available" ? "Unlocked" : "Locked",
          offer.OfferName,
          "Overall Use Cases",
          `${offer.OverallOfferScore}%`,
          `${offer.OverallOfferRelevance}%`,
          `${offer.OverallOfferUtilisation}%`,
        ]);
 
        const mandatoryData = data.MandatoryOffers.map((offer) => [
          {
            type: offer.OfferName === "Offer not available" ? "check" : "lock",
          },
          offer.VendorName,
          offer.OfferName === "Offer not available" ? "Unlocked" : "Locked",
          offer.OfferName,
          "Mandatory Use Cases",
          `${offer.MandatoryOfferScore}%`,
          `${offer.MandatoryOfferRelevance}%`,
          `${offer.MandatoryOfferUtilisation}%`,
        ]);
        setGridLines(data.Gridlines);
 
        const combinedData = [headers, ...overallData, ...mandatoryData];
        setInitialData(combinedData);
 
        const initialColumns = headers
          .filter((header) => header !== "Status")
          .map((header, index) => ({
            field: `column${index}`,
            headerName: header,
            flex: 1,
            isVisible: true, // Initially all columns are visible
          }));
 
        const rows = combinedData.slice(1).map((row, index) => {
          const rowData = { id: index + 1 };
          row.forEach((cell, cellIndex) => {
            if (cellIndex !== 2) {
              rowData[`column${cellIndex < 2 ? cellIndex : cellIndex - 1}`] =
                parsePercentage(cell);
            }
          });
          console.log("rowData", rowData);
          return rowData;
        });
 
        setColumns(initialColumns);
        setRows(rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
 
    fetchData();
  }, [userProfile, currentOrg, scenarioName, buyerGuideName, setInitialData,setLayout,widgetKey]);
 
  useEffect(() => {
    if (columns.length > 0) {
      const visibleColumns = columns
        .filter((col) => col.isVisible)
        .map((col) => col.headerName);
      const newChartType = updateChartType(visibleColumns);
      setChartType(newChartType);
    }
  }, [columns]);
 
  const handleLinkClick = (link) => {
    setDialogLink(link);
    setDialogOpen(true);
  };
 
  const handleDialogClose = (confirmed) => {
    setDialogOpen(false);
    if (confirmed) {
      navigate(dialogLink, {
        state: {
          vendor: selectedVendor.current,
          offer: selectedOffer.current,
        },
      });
     
    }
  };
 
  useEffect(() => {
    setIsNewUser(localStorage.getItem("isNewUser") || false);
  }, []);
 
  if (loading) {
    return <div>Loading...</div>;
  }
 
  return (
    <>
      <Box
        className="widget-header-container"
        style={{
          flexShrink: 0,
          display: "flex",   
          height: 30,
        }}
      >
        {widgetLabels && widgetKey && (
          <Typography
            variant="h6"
            sx={{ marginTop: "15px" ,textAlign:"center"}}
            className="widget-header"
            data-test-id={`${widgetLabels[widgetKey.split("_")[0]]}`}
          >
            {widgetLabels[widgetKey.split("_")[0]]}
          </Typography>
        )}
 
        <span className="widget-icon">
          {widgetKey === "d" && (
            <IconButton
              data-test-id={`eye-${widgetLabels[widgetKey.split("_")[0]]}`}
              onClick={toggleTableVisibility}
              aria-label="toggle table visibility"
            >
              {isTableVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          <FileCopyOutlined
            data-test-id={`copy-${widgetLabels[widgetKey.split("_")[0]]}`}
            onClick={() => handleCopyWidget(widgetKey)}
          />
          <IconButton
            data-test-id={`close-${widgetLabels[widgetKey.split("_")[0]]}`}
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </span>
      </Box>
      <ChartComponent
        data-test-id="chartcomponent"
        chartType={chartType}
        initialData={initialData}
        gridLines={gridLines}
      />
      {isTableVisible && (
        <Box data-test-id="chartTable" sx={{ height: "90%", width: "100%" }}>
          <div>
            {columns
              .filter((column) =>
                [
                  "Offer Score",
                  "Offer Utilization",
                  "Offer Relevance",
                ].includes(column.headerName)
              )
              .map((column) => (
                <Chip
                  key={column.field}
                  label={column.headerName}
                  data-test-id={`chip-${column.headerName}`}
                  onClick={() => handleToggleColumn(column.field)}
                  variant={column.isVisible ? "outlined" : "default"}
                  color={column.isVisible ? "primary" : "default"}
                  style={{ marginRight: "5px" }}
                />
              ))}
          </div>
          <Button
            data-test-id="unlock"
            variant="contained"
            color="primary"
            onClick={handleUnlock}
          >
            Unlock
          </Button>
          <DataGridTable
            data-test-id="table"
            rows={rows}
            columns={columns.map((col) => ({
              ...col,
              renderCell:
                col.headerName === "Select"
                  ? (params) => {
                      const iconType = params.value.type;
                      if (iconType === "checkbox") {
                        return (
                          <span onClick={() => handleCheckboxToggle(params.id)}>
                            {params.value.checked ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )}
                          </span>
                        );
                      }
                      return (
                        <span
                          onClick={() => handleToggleIcon(params.id, iconType)}
                        >
                          {iconType === "lock" ? <LockIcon /> : <CheckIcon />}
                        </span>
                      );
                    }
                  : col.headerName === "Vendor Name"
                  ? (params) => (
                      <span
                        onClick={() =>{
                          selectedOffer.current = params.row.column2;
                          selectedVendor.current = params.row.column1;
                          handleLinkClick("/results/vendor-details")
                          }
                        }
                      >
                        {params.value}
                      </span>
                    )
                  : [
                      "Offer Name",
                      "Offer Score",
                      "Offer Relevance",
                      "Offer Utilization",
                    ].includes(col.headerName)
                  ? (params) => (
                      <span
                        onClick={() =>{
                          selectedOffer.current = params.row.column2;
                          selectedVendor.current = params.row.column1;
                          handleLinkClick("/results/offer-details")
                          }
                        }
                      >
                        {params.value}
                      </span>
                    )
                  : null,
            }))}
            hideFooter
          />
        </Box>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Navigation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will be navigating to a new page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
 
export default DataGridComponent;
 
 
// import React, { useState, useEffect, useContext } from "react";
// import DataGridTable from "../../../components/DataGridTable";
// import {
//   Box,
//   Chip,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   IconButton,
// } from "@mui/material";
// import {
//   FileCopyOutlined,
//   Close,
//   Visibility,
//   VisibilityOff,
// } from "@mui/icons-material";
// import LockIcon from "@mui/icons-material/Lock";
// import CheckIcon from "@mui/icons-material/Check";
// import CheckBox from "@mui/icons-material/CheckBox";
// import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
// import ChartComponent from "./ChartComponent";
// import {
//   apiService,
//   modifySubscriptionBillingCycle,
// } from "../../../utils/utils";
// import { UserProfileContext } from "../../../context/UserContext";
// import "./ComponentStyle.css";
// // Function to convert percentage strings to integers
// const parsePercentage = (value) => {
//   if (typeof value === "string" && value.endsWith("%")) {
//     return parseInt(value.slice(0, -1), 10);
//   }
//   return value;
// };

// const DataGridComponent = ({
//   isTableVisible,
//   widgetLabels,
//   widgetKey,
//   toggleTableVisibility,
//   handleCopyWidget,
//   handleRemoveWidget,
//   setLayout,
// }) => {
//   const [columns, setColumns] = useState([]);
//   const [rows, setRows] = useState([]);
//   const [chartType, setChartType] = useState("Bubble Chart");
//   const { initialData, setInitialData } = useContext(UserProfileContext);
//   const [loading, setLoading] = useState(true);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [dialogLink, setDialogLink] = useState("");
//   const [gridLines, setGridLines] = useState({});
//   const [isNewUser, setIsNewUser] = useState(false);

//   const handleToggleColumn = (columnField) => {
//     const updatedColumns = columns.map((col) =>
//       col.field === columnField ? { ...col, isVisible: !col.isVisible } : col
//     );
//     setColumns(updatedColumns);
//   };

//   const handleToggleIcon = async (rowId, iconType) => {
//     setRows((prevRows) =>
//       prevRows.map((row) =>
//         row.id === rowId && row.column0.type !== "checkbox"
//           ? {
//               ...row,
//               column0:
//                 row.column0.type === "lock"
//                   ? { type: "check" }
//                   : { type: "lock" },
//             }
//           : row
//       )
//     );
//     if (iconType === "lock" && !isNewUser) {
//       await modifySubscriptionBillingCycle(userProfile?.email);
//       setIsNewUser(true);
//       localStorage.setItem("isNewUser", true);
//     }
//   };

//   const handleUnlock = () => {
//     setRows((prevRows) =>
//       prevRows.map((row) =>
//         row.column0.type === "check"
//           ? { ...row, column0: { type: "checkbox", checked: false } }
//           : row
//       )
//     );
//   };

//   const handleCheckboxToggle = (rowId) => {
//     setRows((prevRows) =>
//       prevRows.map((row) =>
//         row.id === rowId
//           ? {
//               ...row,
//               column0: { ...row.column0, checked: !row.column0.checked },
//             }
//           : row
//       )
//     );
//   };

//   const updateChartType = (visibleColumnNames) => {
//     if (
//       visibleColumnNames.includes("Offer Score") &&
//       visibleColumnNames.includes("Offer Utilization") &&
//       !visibleColumnNames.includes("Offer Relevance")
//     ) {
//       return "Bar Chart(Offer Score & Utilization)";
//     } else if (
//       visibleColumnNames.includes("Offer Score") &&
//       visibleColumnNames.includes("Offer Relevance") &&
//       !visibleColumnNames.includes("Offer Utilization")
//     ) {
//       return "Bar Chart(Offer Score & Relevance)";
//     } else if (
//       visibleColumnNames.includes("Offer Score") &&
//       !visibleColumnNames.includes("Offer Relevance") &&
//       !visibleColumnNames.includes("Offer Utilization")
//     ) {
//       return "Bar Chart(Offer Score)";
//     } else if (visibleColumnNames.includes("Offer Score")) {
//       return "Bubble Chart";
//     } else if (
//       visibleColumnNames.includes("Offer Relevance") &&
//       visibleColumnNames.includes("Offer Utilization")
//     ) {
//       return "Scatter Chart";
//     } else if (visibleColumnNames.includes("Offer Utilization")) {
//       return "Bar Chart(Utilization)";
//     } else if (visibleColumnNames.includes("Offer Relevance")) {
//       return "Bar Chart(Relevance)";
//     } else {
//       return "Bubble Chart"; // Default to Bubble Chart if no specific type matches
//     }
//   };

//   const { currentOrganization, userProfile, scenarioName, buyerGuideName } =
//     useContext(UserProfileContext);

//   const currentOrg =
//     userProfile && userProfile.profileType === "endUser"
//       ? userProfile.companyName
//       : currentOrganization;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await apiService(
//           "https://newtestfuncpython.azurewebsites.net/api/valyouMatrixWidgetComparativeAnalysis?", // Replace with your actual API endpoint
//           {
//             method: "POST",
//             data: {
//               email: userProfile?.email,
//               currentOrganisation: currentOrg,
//               currentBuyerGuide: buyerGuideName,
//               currentScenario: scenarioName,
//             },
//           },
//           setLoading,
//           null,
//           console.error
//         );

//         // Transform the fetched data into the required format
//         const headers = [
//           "Select",
//           "Vendor Name",
//           "Status",
//           "Offer Name",
//           "Mandatory Status",
//           "Offer Score",
//           "Offer Relevance",
//           "Offer Utilization",
//         ];
//         setLayout((prev) => {
//           const newLayout = prev.map((item) => {
//             if (item.i !== widgetKey) {
//               return item;
//             } else {
//               const newItem = {
//                 ...item,
//                 h:
//                   8 + (data.OverallOffers.length + data.MandatoryOffers.length),
//                 minH:
//                   8 + (data.OverallOffers.length + data.MandatoryOffers.length),
//                 maxH:
//                   8 + (data.OverallOffers.length + data.MandatoryOffers.length),
//               };
//               return newItem;
//             }
//           });
//           return newLayout
//         });
//         const overallData = data.OverallOffers.map((offer) => [
//           {
//             type: offer.OfferName === "Offer not available" ? "check" : "lock",
//           },
//           offer.VendorName,
//           offer.OfferName === "Offer not available" ? "Unlocked" : "Locked",
//           offer.OfferName,
//           "Overall Use Cases",
//           `${offer.OverallOfferScore}%`,
//           `${offer.OverallOfferRelevance}%`,
//           `${offer.OverallOfferUtilisation}%`,
//         ]);

//         const mandatoryData = data.MandatoryOffers.map((offer) => [
//           {
//             type: offer.OfferName === "Offer not available" ? "check" : "lock",
//           },
//           offer.VendorName,
//           offer.OfferName === "Offer not available" ? "Unlocked" : "Locked",
//           offer.OfferName,
//           "Mandatory Use Cases",
//           `${offer.MandatoryOfferScore}%`,
//           `${offer.MandatoryOfferRelevance}%`,
//           `${offer.MandatoryOfferUtilisation}%`,
//         ]);
//         setGridLines(data.Gridlines);

//         const combinedData = [headers, ...overallData, ...mandatoryData];
//         setInitialData(combinedData);

//         const initialColumns = headers
//           .filter((header) => header !== "Status")
//           .map((header, index) => ({
//             field: `column${index}`,
//             headerName: header,
//             flex: 1,
//             isVisible: true, // Initially all columns are visible
//           }));

//         const rows = combinedData.slice(1).map((row, index) => {
//           const rowData = { id: index + 1 };
//           row.forEach((cell, cellIndex) => {
//             if (cellIndex !== 2) {
//               rowData[`column${cellIndex < 2 ? cellIndex : cellIndex - 1}`] =
//                 parsePercentage(cell);
//             }
//           });
//           console.log("rowData", rowData);
//           return rowData;
//         });

//         setColumns(initialColumns);
//         setRows(rows);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [userProfile, currentOrg, scenarioName, buyerGuideName, setInitialData,setLayout,widgetKey]);

//   useEffect(() => {
//     if (columns.length > 0) {
//       const visibleColumns = columns
//         .filter((col) => col.isVisible)
//         .map((col) => col.headerName);
//       const newChartType = updateChartType(visibleColumns);
//       setChartType(newChartType);
//     }
//   }, [columns]);

//   const handleLinkClick = (link) => {
//     setDialogLink(link);
//     setDialogOpen(true);
//   };

//   const handleDialogClose = (confirmed) => {
//     setDialogOpen(false);
//     if (confirmed) {
//       window.location.href = dialogLink;
//     }
//   };

//   useEffect(() => {
//     setIsNewUser(localStorage.getItem("isNewUser") || false);
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       <div
//         className="widget-header-container"
//         style={{
//           flexShrink: 0,
//           display: "flex",
//           flexDirection: "column",
//           height: 20,
//         }}
//       >
//         {widgetLabels && widgetKey && (
//           <div
//             className="widget-header"
//             data-test-id={`${widgetLabels[widgetKey.split("_")[0]]}`}
//           >
//             <h3>{widgetLabels[widgetKey.split("_")[0]]}</h3>
//           </div>
//         )}

//         <span className="widget-icon">
//           {widgetKey === "d" && (
//             <IconButton
//               data-test-id={`eye-${widgetLabels[widgetKey.split("_")[0]]}`}
//               onClick={toggleTableVisibility}
//               aria-label="toggle table visibility"
//             >
//               {isTableVisible ? <Visibility /> : <VisibilityOff />}
//             </IconButton>
//           )}
//           <FileCopyOutlined
//             data-test-id={`copy-${widgetLabels[widgetKey.split("_")[0]]}`}
//             onClick={() => handleCopyWidget(widgetKey)}
//           />
//           <IconButton
//             data-test-id={`close-${widgetLabels[widgetKey.split("_")[0]]}`}
//             size="small"
//             onClick={() => handleRemoveWidget(widgetKey)}
//           >
//             <Close />
//           </IconButton>
//         </span>
//       </div>
//       <ChartComponent
//         data-test-id="chartcomponent"
//         chartType={chartType}
//         initialData={initialData}
//         gridLines={gridLines}
//       />
//       {isTableVisible && (
//         <Box data-test-id="chartTable" sx={{ height: "100%", width: "100%" }}>
//           <div>
//             {columns
//               .filter((column) =>
//                 [
//                   "Offer Score",
//                   "Offer Utilization",
//                   "Offer Relevance",
//                 ].includes(column.headerName)
//               )
//               .map((column) => (
//                 <Chip
//                   key={column.field}
//                   label={column.headerName}
//                   data-test-id={`chip-${column.headerName}`}
//                   onClick={() => handleToggleColumn(column.field)}
//                   variant={column.isVisible ? "outlined" : "default"}
//                   color={column.isVisible ? "primary" : "default"}
//                   style={{ marginRight: "5px", marginBottom: "5px" }}
//                 />
//               ))}
//           </div>
//           <Button
//             data-test-id="unlock"
//             variant="contained"
//             color="primary"
//             onClick={handleUnlock}
//           >
//             Unlock
//           </Button>
//           <DataGridTable
//             data-test-id="table"
//             rows={rows}
//             columns={columns.map((col) => ({
//               ...col,
//               renderCell:
//                 col.headerName === "Select"
//                   ? (params) => {
//                       const iconType = params.value.type;
//                       if (iconType === "checkbox") {
//                         return (
//                           <span onClick={() => handleCheckboxToggle(params.id)}>
//                             {params.value.checked ? (
//                               <CheckBox />
//                             ) : (
//                               <CheckBoxOutlineBlank />
//                             )}
//                           </span>
//                         );
//                       }
//                       return (
//                         <span
//                           onClick={() => handleToggleIcon(params.id, iconType)}
//                         >
//                           {iconType === "lock" ? <LockIcon /> : <CheckIcon />}
//                         </span>
//                       );
//                     }
//                   : col.headerName === "Vendor Name"
//                   ? (params) => (
//                       <span
//                         onClick={() =>
//                           handleLinkClick("/results/vendor-details")
//                         }
//                       >
//                         {params.value}
//                       </span>
//                     )
//                   : [
//                       "Offer Name",
//                       "Offer Score",
//                       "Offer Relevance",
//                       "Offer Utilization",
//                     ].includes(col.headerName)
//                   ? (params) => (
//                       <span
//                         onClick={() =>
//                           handleLinkClick("/results/offer-details")
//                         }
//                       >
//                         {params.value}
//                       </span>
//                     )
//                   : null,
//             }))}
//             hideFooter
//             autoHeight
//           />
//         </Box>
//       )}
//       <Dialog
//         open={dialogOpen}
//         onClose={() => handleDialogClose(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">
//           {"Confirm Navigation"}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             You will be navigating to a new page.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => handleDialogClose(false)} color="primary">
//             Cancel
//           </Button>
//           <Button
//             onClick={() => handleDialogClose(true)}
//             color="primary"
//             autoFocus
//           >
//             Confirm
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default DataGridComponent;
