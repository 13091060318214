import React, { useContext, useEffect, useState } from "react";

import DataGridTable from "../../../components/DataGridTable";
import { apiService } from "../../../utils/utils"; // Adjust the path as necessary
import { UserProfileContext } from "../../../context/UserContext";
import { IconButton, Box, Typography } from "@mui/material";
import {
  FileCopyOutlined,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import "./ComponentStyle.css";
const BestOfBest = ({
  isTableVisible,
  widgetLabels,
  widgetKey,
  toggleTableVisibility,
  handleCopyWidget,
  handleRemoveWidget,
}) => {
  const { userProfile, currentOrganization, buyerGuideName, scenarioName } =
    useContext(UserProfileContext);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchBestOfBestData = async () => {
      try {
        setLoading(true);
        const data = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getBestofBest?", // Replace with your actual API endpoint
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: buyerGuideName,
              currentScenario: scenarioName,
              displayUseCasesOptions: "Both",
            },
          },
          null, // No need to set loading in the apiService call
          null, // No need to set data in the apiService call
          setError
        );

        // Transform the response data into the format expected by DataGridTable
        const bestOfBestData = [
          ["Metrics", "Mandatory Use Case", "Overall Use Case"],
          [
            "Maximum Offer Score%",
            `${data.MandatoryMetrics.MaxMandatoryOfferScore.toFixed(2)}%`,
            `${data.OverallMetrics.MaxOverallOfferScore.toFixed(2)}%`,
          ],
          [
            "Maximum Offer Relevance%",
            `${data.MandatoryMetrics.MaxMandatoryOfferRelevance.toFixed(2)}%`,
            `${data.OverallMetrics.MaxOverallOfferRelevance.toFixed(2)}%`,
          ],
          [
            "Maximum Offer Utilization%",
            `${data.MandatoryMetrics.MaxMandatoryOfferUtilisation.toFixed(2)}%`,
            `${data.OverallMetrics.MaxOverallOfferUtilisation.toFixed(2)}%`,
          ],
        ];

        // Prepare columns from the first row of data
        const columns = bestOfBestData[0].map((heading, index) => ({
          field: `col${index}`,
          headerName: heading,
          flex: 1,
          headerClassName: "super-app-theme--header",
        }));

        // Prepare rows from the rest of the data
        const rows = bestOfBestData.slice(1).map((row, index) => {
          const rowData = {};
          row.forEach((cell, cellIndex) => {
            rowData[`col${cellIndex}`] = cell;
          });
          return { id: index, ...rowData };
        });

        setColumns(columns);
        setRows(rows);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchBestOfBestData();
  }, [userProfile, currentOrg, buyerGuideName, scenarioName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      <Box
        data-test-id="container-bestofbest"
        className="widget-header-container grab"
        style={{
          flexShrink: 0,
          display: "flex",
          height: 20,
        }}
      >
        {widgetLabels && widgetKey && (
          <Typography
            variant="h6"
            sx={{  marginTop: "15px" ,textAlign:"center"}}
            className="widget-header"
          >
            {widgetLabels[widgetKey.split("_")[0]]}
          </Typography>
        )}

        <Box className="widget-icon">
          {widgetKey === "d" && (
            <IconButton
              data-test-id={`eye-${widgetLabels[widgetKey.split("_")[0]]}`}
              onClick={toggleTableVisibility}
              aria-label="toggle table visibility"
            >
              {isTableVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          <FileCopyOutlined
            data-test-id={`copy-${widgetLabels[widgetKey.split("_")[0]]}`}
            onClick={() => handleCopyWidget(widgetKey)}
          />
          <IconButton
            data-test-id={`close-${widgetLabels[widgetKey.split("_")[0]]}`}
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box data-test-id="chartTable" sx={{ height: "100%", width: "100%"}} style={{marginTop:"22px"}}>
        <DataGridTable name="bestofbest" rows={rows} columns={columns} />
      </Box>
    </>
  );
};

export default BestOfBest;
