import React, { useState, useEffect, useContext } from "react";
import DataGridTable from "../../../components/DataGridTable";
import { Box, IconButton, Typography } from "@mui/material";
import theme from "../../../theme/theme";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { FileCopyOutlined, Close } from "@mui/icons-material";
//import { apiService } from "../../../utils/utils";
import { UserProfileContext } from "../../../context/UserContext";

const transformData = (data) => {
  if (
    data &&
    data["Top 5 Unsupported Use Cases (Overall)"] &&
    Array.isArray(data["Top 5 Unsupported Use Cases (Overall)"].list)
  ) {
    return data["Top 5 Unsupported Use Cases (Overall)"].list.map(
      (item, index) => ({
        id: index + 1,
        subFeatureName: item.subFeatureName,
        featurePath: item.path,
        importanceScore: `${item.relativeImportance.toFixed(2)}%`,
      })
    );
  }
  return [];
};
const ShowPath = (params) => {
  const [showCell, setShowCell] = useState(false);
  const { value } = params;
  return (
    <Box>
      {showCell ? (
        <Box sx={{ display: "flex" }}>
          <Box height={20} width={20} marginRight={4} marginLeft={15}>
            <IconButton
              onClick={() => {
                setShowCell(false);
              }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Box>
          <Box>
            {showCell ? value.join(" > ") : "Sub " + value[value.length - 1]}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box height={20} width={20} marginRight={4} marginLeft={15}>
            <IconButton
              onClick={() => {
                setShowCell(true);
              }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
          <Box>
            {showCell ? value.join(" > ") : "Sub " + value[value.length - 1]}
          </Box>
        </Box>
      )}
    </Box>
  );
};
const VendorDetailUnusedFeature = ({
  menu,
  widgetKey,
  widgetLabels,
  setLayout,
  handleCopyWidget,
  handleRemoveWidget,
}) => {
  const {
    userProfile,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    offerName,
  } = useContext(UserProfileContext);

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    /*const fetchData = async () => {
      try {
        const payload = {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: buyerGuideName,
            currentScenario: scenarioName,
            currentOffer: offerName,
        }
        setLoading(true);
        await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/top5UnSupportedUseCasesOverall",
          {
            method: "POST",
            data: payload,
          },
          setLoading,
          (response) => {
            console.log("API response:", response);
            setData(response); // Assuming response.data is the array you need
          },
          setError
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();*/
    const response = {
      "Top 5 Unsupported Use Cases (Overall)": {
        count: 5,
        list: [
          {
            subFeatureName: "Feature 1.1.1",
            path: ["Feature 1", "Feature 1.1", "Feature 1.1.1"],
            relativeImportance: 5,
            mandatoryFlag: "Mandatory",
          },
          {
            subFeatureName: "Feature 2.1.1",
            path: ["Feature 2", "Feature 2.1", "Feature 2.1.1"],
            relativeImportance: 3,
            mandatoryFlag: "Mandatory",
          },
          {
            subFeatureName: "Feature 3.1.1",
            path: ["Feature 3", "Feature 3.1", "Feature 3.1.1"],
            relativeImportance: 4,
            mandatoryFlag: "Mandatory",
          },
          {
            subFeatureName: "Feature 4.1.1",
            path: ["Feature 4", "Feature 4.1", "Feature 4.1.1"],
            relativeImportance: 4,
            mandatoryFlag: "Mandatory",
          },
          {
            subFeatureName: "Feature 5.1.1",
            path: ["Feature 5", "Feature 5.1", "Feature 5.1.1"],
            relativeImportance: 4,
            mandatoryFlag: "Mandatory",
          },
        ],
      },
    };
    setLoading(false);
    setLayout((prev) => {
      return prev.map((item) => {
        if (item.i !== widgetKey) {
          return item;
        } else {
          return {
            ...item,
            h:
              1 + response["Top 5 Unsupported Use Cases (Overall)"].list.length,
            maxH:
              1 + response["Top 5 Unsupported Use Cases (Overall)"].list.length,
            minH:
              1 + response["Top 5 Unsupported Use Cases (Overall)"].list.length,
          };
        }
      });
    });
    setError(null);
    setData(response);
  }, [
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    setLayout,
    widgetKey,
  ]);

  const transformedData = transformData(data);

  const UnsupportedUseCaseOverallData = {
    columns: [
      {
        field: "featurePath",
        headerName: "Top 5 Unsupported Use Cases (Overall)",
        headerClassName: "super-app-theme--header",
        flex: 1,
        renderCell: ShowPath,
      },
      {
        field: "importanceScore",
        headerName: "Relative Importance Score",
        headerClassName: "super-app-theme--header",
        flex: 1,
      },
    ],
    rows: transformedData,
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Box
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          border: "0.5px solid grey",
          color: "white",
        },
      }}
    >
      <Box
        className="widget-header-container"
        style={{ flexShrink: 0 }}
        sx={{ padding: 2 }}
      >
        <Typography
          variant="h6"
          sx={{  marginTop: "5px", textAlign: "center" }}
          className="widget-header"
        >
          {widgetLabels[widgetKey.split("_")[0]]}
        </Typography>
        <Box className="widget-icon">
          <FileCopyOutlined onClick={() => handleCopyWidget(widgetKey)} />
          <IconButton
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DataGridTable
        rows={UnsupportedUseCaseOverallData.rows}
        columns={UnsupportedUseCaseOverallData.columns}
        menu={menu}
        sx={{
          minWidth: "1080px",
          "& .MuiDataGrid-cell": {
            border: "0.5px solid grey",
            wordWrap: "break-word",
            whiteSpace: "normal",
          },
        }}
      />
    </Box>
  );
};

export default VendorDetailUnusedFeature;
