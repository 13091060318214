import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Tooltip } from "@mui/material";

const NumberInput = ({
  isDisabled,
  max,
  rowData,
  inputsLabel,
  onSet,
  page,
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(rowData[inputsLabel]);
  }, [rowData, inputsLabel]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    const numVlue = Number(value);
    if (numVlue >= 0 && value !== "") {
      if (numVlue !== rowData[inputsLabel]) {
        if (numVlue > max) rowData[inputsLabel] = max;
        else rowData[inputsLabel] = numVlue;
        setValue(rowData[inputsLabel]);
        onSet();
      }
    } else {
      if (value === "" && rowData[inputsLabel] !== 0) {
        rowData[inputsLabel] = 0;
        onSet();
      }
      setValue(0);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {(max === 0 || isDisabled) && (
        <Tooltip
          title={
            max !== 0
              ? "Please fill all the Required Fields"
              : `You don't have any Valid package Unit for Allocation`
          }
        >
          <TextField
            variant="standard"
            color="secondary"
            sx={{
              width: "35px",
              paddingX: "auto",

              "& .css-1w7v89a-MuiInputBase-root-MuiInput-root.Mui-disabled:before ":
                {
                  borderBottom: "none",
                },
            }}
            value={max}
            size="small"
            disabled
            InputProps={{
              inputProps: {
                style: { textAlign: "center" },
              },
            }}
          />
        </Tooltip>
      )}

      {max > 0 && !isDisabled && (
        <TextField
          autoComplete="off"
          variant="standard"
          color="secondary"
          sx={{
            width: "35px",
            paddingX: "auto",

            "& .MuiInputBase-input": {
              // WebkitTextFillColor: 'black',
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiInput-underline:after": {
              borderBottom: "2px solid",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottom: "2px solid",
            },
          }}
          value={value}
          onChange={handleChange}
          onFocus={(e) => {
            e.target.select();
          }}
          onBlur={handleBlur}
          size="small"
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
            },
          }}
        />
      )}
      {page === "avalable_for_allocation" && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              marginX: "4px",
              // fontFamily: theme.typography.h4,
            }}
          >
            /
          </Typography>
          <Typography>{max}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default NumberInput;
