// src/InitialData.js

export const ValYouMatrixData = [
  [
    "Select",
    "Vendor Name",
    "Status",
    "Offer Name",
    "Mandatory Status",
    "Offer Score",
    "Offer Relevance",
    "Offer Utilization",
  ],
  [
    null,
    "Vendor 11",
    "Unlocked",
    "Offer 11",
    "Mandatory Use Cases",
    "40%",
    "50%",
    "30%",
  ],
  [
    null,
    "Vendor 12",
    "Unlocked",
    "Offer 12",
    "Overall Use Cases",
    "60%",
    "90%",
    "80%",
  ],
  [
    null,
    "Vendor 10",
    "Locked",
    "Offer 10",
    "Mandatory Use Cases",
    "40%",
    "50%",
    "90%",
  ],
  [
    null,
    "Vendor 9",
    "Locked",
    "Offer 9",
    "Overall Use Cases",
    "45%",
    "58%",
    "67%",
  ],
  [
    null,
    "Vendor 3",
    "Locked",
    "Offer 3",
    "Mandatory Use Cases",
    "20%",
    "40%",
    "60%",
  ],
  [
    null,
    "Vendor 4",
    "Unlocked",
    "Offer 4",
    "Overall Use Cases",
    "50%",
    "20%",
    "40%",
  ],
  [
    null,
    "Vendor 5",
    "Locked",
    "Offer 5",
    "Mandatory Use Cases",
    "10%",
    "80%",
    "20%",
  ],
  [
    null,
    "Vendor 6",
    "Locked",
    "Offer 6",
    "Overall Use Cases",
    "40%",
    "10%",
    "20%",
  ],
  [
    null,
    "Vendor 7",
    "Locked",
    "Offer 7",
    "Mandatory Use Cases",
    "70%",
    "30%",
    "60%",
  ],
  [
    null,
    "Vendor 8",
    "Locked",
    "Offer 8",
    "Overall Use Cases",
    "80%",
    "40%",
    "10%",
  ],
];

export const CoverageHeatmapData = [
  {
    useCase: "Agentless options",
    importanceScore: 9.74,
    mandatory: "No",
    offers: { count: 1, details: ["Offer A", "Offer B", "Offer C"] },
    vendors: { count: 1, details: ["Vendor X", "Vendor Y"] },
  },
  {
    useCase: "Integration with SAML-compliant IdP",
    importanceScore: 6.5,
    mandatory: "No",
    offers: { count: 2, details: ["Offer D", "Offer E"] },
    vendors: {
      count: 2,
      details: ["Vendor A", "Vendor B"],
    },
  },
  {
    useCase: "Onboarding users from common IAM solutions",
    importanceScore: 6.37,
    mandatory: "No",
    offers: { count: 1, details: ["Offer A", "Offer B", "Offer C"] },
    vendors: { count: 1, details: ["Vendor X", "Vendor Y"] },
  },
  {
    useCase: "Enforcing DLP policies",
    importanceScore: 5.34,
    mandatory: "No",
    offers: { count: 1, details: ["Offer A", "Offer B", "Offer C"] },
    vendors: { count: 1, details: ["Vendor X", "Vendor Y"] },
  },
  {
    useCase: "Contextual access control for all apps",
    importanceScore: 5.12,
    mandatory: "No",
    offers: { count: 1, details: ["Offer A", "Offer B", "Offer C"] },
    vendors: { count: 1, details: ["Vendor X", "Vendor Y"] },
  },
];

export const BestOfBestData = [
  ["Metrics", "Mandatory Use Case", "Overall Use Case"],
  ["Maximum Offer Score%", "12.59%", "47.59%"],
  ["Maximum Offer Relevance%", "14.07%", "39.63%"],
  ["Maximum Offer Utilization%", "11.11%", "55.55%"],
];

export const UnmetUseCasesData = [
  { id: 1, useCase: "ZTNA Solution (Test)>Inclusion Within an SSE Offering", importanceScore: 21.09, mandatory: "Mandatory" },
  { id: 2, useCase: "ZTNA Solution (Test)>Data Loss Prevention (DLP)>Advanced DLP detection mechanisms", importanceScore: 5.34, mandatory: "Non-Mandatory" },
  { id: 3, useCase: "ZTNA Solution (Test)>Malware Protection>Protection for personal devices", importanceScore: 5.11, mandatory: "Non-Mandatory" },
  { id: 4, useCase: "ZTNA Solution (Test)>Deployment Options for BYOD>Agent-based options>Malware protection for all TCP-based protocols", importanceScore: 3.92, mandatory: "Non-Mandatory" },
  { id: 5, useCase: "ZTNA Solution (Test)>Malware Protection>Detection of zero-day threats>Behavior-based techniques", importanceScore: 2.30, mandatory: "Non-Mandatory" },
  // Add more data as needed
];

export const RelevantOfferData = {
  vendors: [
    "Zscaler Private Access",
    "Check Point Harmony Connect Remote Access",
  ],
  get offersCount() {
    return this.vendors.length;
  }
};

export const RelevantVendorData = {
  vendors: ["Zscaler", "Check Point",],
  get offersCount() {
    return this.vendors.length;
  }
};

export const MandatoryUseCaseData = {
  columns: [
    {
      field: "metrics",
      headerName: "Metrics",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "metric",
      headerName: "Metric",
      flex: 1,
    headerClassName: "super-app-theme--header",
    },
    {
      field: "vendorMax",
      headerName: "Vendor Max (amongst relevant offers)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "scenarioMax",
      headerName: "Scenario Max (amongst all relevant vendors)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "offerRank",
      headerName: "Offer Rank (among all vendors)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      metrics: "Offer Score %",
      metric: "12.59%",
      vendorMax: "12.59%",
      scenarioMax: "75%",
      offerRank: 1,
    },
    {
      id: 2,
      metrics: "Offer Relevance %",
      metric: "14.07%",
      vendorMax: "14.07%",
      scenarioMax: "65%",
      offerRank: 1,
    },
    {
      id: 3,
      metrics: "Offer Utilization %",
      metric: "11.11%",
      vendorMax: "11.11%",
      scenarioMax: "90%",
      offerRank: 1,
    },
  ],
};

export const OverallUseCasesData = {
  columns: [
    {
      field: "metrics",
      headerName: "Metrics",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "metric",
      headerName: "Metric",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vendorMax",
      headerName: "Vendor Max (amongst relevant offers)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "scenarioMax",
      headerName: "Scenario Max (amongst all relevant vendors)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "offerRank",
      headerName: "Offer Rank (among all vendors)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      metrics: "Offer Score %",
      metric: "47.59%",
      vendorMax: "47.59%",
      scenarioMax: "47.59%",
      offerRank: 1,
    },
    {
      id: 2,
      metrics: "Offer Relevance %",
      metric: "39.63%",
      vendorMax: "39.63%",
      scenarioMax: "39.63%",
      offerRank: 1,
    },
    {
      id: 3,
      metrics: "Offer Utilization %",
      metric: "55.55%",
      vendorMax: "55.55%",
      scenarioMax: "55.55%",
      offerRank: 2,
    },
  ],
};

export const SupportedUseCaseDetailData = {
  columns: [
    {
      field: "useCase",
      headerName: "Top 5 Supported Use Cases (Mandatory)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "importanceScore",
      headerName: "Relative Importance Score",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      useCase: "Use Case 1 > Use Case 1.1 > Use Case 1.1.1",
      importanceScore: "5%",
    },
    {
      id: 2,
      useCase: "Use Case 2 > Use Case 2.1 > Use Case 2.1.1",
      importanceScore: "3%",
    },
  ],
};

export const SupportedUseCaseOverallData = {
  columns: [
    {
      field: "useCase",
      headerName: "Top 5 Supported Use Cases (Overall)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "importanceScore",
      headerName: "Relative Importance Score",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      useCase: "Use Case 1 > Use Case 1.1 > Use Case 1.1.1",
      importanceScore: "5%",
    },
    {
      id: 2,
      useCase: "Use Case 2 > Use Case 2.1 > Use Case 2.1.1",
      importanceScore: "3%",
    },
  ],
};

export const UnsupportedUseCaseMandatoryData = {
  columns: [
    {
      field: "useCase",
      headerName: "Top 5 Unsupported Use Cases (Mandatory)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lineage",
      headerName: "Use Case Lineage",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "importanceScore",
      headerName: "Relative Importance Score",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      useCase: "Sub Use Case 1",
      lineage: "Use Case 1 > Use Case 1.1 > Use Case 1.1.1",
      importanceScore: "5%",
    },
    {
      id: 2,
      useCase: "Sub Use Case 2",
      lineage: "Use Case 2 > Use Case 2.1 > Use Case 2.1.1",
      importanceScore: "3%",
    },
  ],
};

export const UnsupportedUseCaseOverallData = {
  columns: [
    {
      field: "useCase",
      headerName: "Top 5 Unsupported Use Cases (Overall)",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lineage",
      headerName: "Use Case Lineage",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "importanceScore",
      headerName: "Relative Importance Score",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      useCase: "Sub Use Case 1",
      lineage: "Use Case 1 > Use Case 1.1 > Use Case 1.1.1",
      importanceScore: "5%",
    },
    {
      id: 2,
      useCase: "Sub Use Case 2",
      lineage: "Use Case 2 > Use Case 2.1 > Use Case 2.1.1",
      importanceScore: "3%",
    },
  ],
};

export const UnusedGenericData = {
  columns: [
    {
      field: "feature",
      headerName: "Top 5 Unused Features",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "countScore",
      headerName: "Relative Count Score",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      feature: "Feature 1 > Feature 1.1 > Feature 1.1.1",
      countScore: "5%",
    },
    {
      id: 2,
      feature: "Feature 2 > Feature 2.1 > Feature 2.1.1",
      countScore: "3%",
    },
  ],
};

export const UnusedNotReqData = {
  columns: [
    {
      field: "feature",
      headerName: "Top 5 Unused Features",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "countScore",
      headerName: "Relative Count Score",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ],
  rows: [
    {
      id: 1,
      feature: "Feature 1 > Feature 1.1 > Feature 1.1.1",
      countScore: "5%",
    },
    {
      id: 2,
      feature: "Feature 2 > Feature 2.1 > Feature 2.1.1",
      countScore: "3%",
    },
  ],
};

const data = {
  ValYouMatrixData,
  CoverageHeatmapData,
  BestOfBestData,
  UnmetUseCasesData,
  RelevantOfferData,
  RelevantVendorData,
  MandatoryUseCaseData,
  OverallUseCasesData,
  SupportedUseCaseDetailData,
  SupportedUseCaseOverallData,
  UnsupportedUseCaseMandatoryData,
  UnsupportedUseCaseOverallData,
  UnusedGenericData,
  UnusedNotReqData,
};

export default data;
