// SampleQurryData.js
export const UnsupportedUseCaseOverallRow = [
  {
    "uc.UnmetUseCases": "Inclusion Within an SSE Offering",
    "uc.Importance": 21.09704641350211,
    "uc.MandatoryFlag": "Mandatory",
    "uc.PathNames": [
      "ZTNA Solution (Test)",
      "Inclusion Within an SSE Offering",
    ],
  },
  {
    "uc.UnmetUseCases": "Agent-based options",
    "uc.Importance": 10.511136035891685,
    "uc.MandatoryFlag": "Non-Mandatory",
    "uc.PathNames": [
      "ZTNA Solution (Test)",
      "Deployment Options for BYOD",
      "Agent-based options",
    ],
  },
  {
    "uc.UnmetUseCases": "Advanced DLP detection mechanisms",
    "uc.Importance": 5.3445850914205355,
    "uc.MandatoryFlag": "Non-Mandatory",
    "uc.PathNames": [
      "ZTNA Solution (Test)",
      "Data Loss Prevention (DLP)",
      "Advanced DLP detection mechanisms",
    ],
  },
  {
    "uc.UnmetUseCases": "Protection for personal devices",
    "uc.Importance": 5.117600367631699,
    "uc.MandatoryFlag": "Non-Mandatory",
    "uc.PathNames": [
      "ZTNA Solution (Test)",
      "Malware Protection",
      "Protection for personal devices",
    ],
  },
  {
    "uc.UnmetUseCases": "Behavior-based techniques",
    "uc.Importance": 2.303482539101038,
    "uc.MandatoryFlag": "Non-Mandatory",
    "uc.PathNames": [
      "ZTNA Solution (Test)",
      "Malware Protection",
      "Detection of zero-day threats",
      "Behavior-based techniques",
    ],
  },
];

export const UnsupportedUseCaseMandatoryRow=[
  {
    "uc.UnmetUseCases": "Inclusion Within an SSE Offering",
    "uc.Importance": 21.09704641350211,
    "uc.MandatoryFlag": "Mandatory",
    "uc.PathNames": [
      "ZTNA Solution (Test)",
      "Inclusion Within an SSE Offering"
    ]
  },
  {
    "uc.UnmetUseCases": "MIME types",
    "uc.Importance": 1.3634145641378919,
    "uc.MandatoryFlag": "Mandatory",
    "uc.PathNames": [
      "ZTNA Solution (Test)",
      "Data Loss Prevention (DLP)",
      "Advanced DLP detection mechanisms",
      "MIME types"
    ]
  }
]

export const SupportedUseCaseOverallRow = [
  {
    "UseCase": "Agentless options",
    "Importance": 9.742028521070342,
    "MandatoryFlag": "Non-Mandatory",
    "PathNames": [
      "ZTNA Solution (Test)",
      "Deployment Options for BYOD",
      "Agentless options"
    ]
  },
  {
    "UseCase": "Integration with SAML-compliant IdP",
    "Importance": 6.50994575045208,
    "MandatoryFlag": "Non-Mandatory",
    "PathNames": [
      "ZTNA Solution (Test)",
      "Identity and Access Management (IAM)",
      "Integration with SAML-compliant IdP"
   ]
  },
  {
    "UseCase": "Onboarding users from common IAM solutions",
    "Importance": 6.374321880650996,
    "MandatoryFlag": "Non-Mandatory",
    "PathNames": [
      "ZTNA Solution (Test)",
      "Identity and Access Management (IAM)",
      "Onboarding users from common IAM solutions"
    ]
  },
  {
    "UseCase": "Enforcing DLP policies",
    "Importance": 5.3445850914205355,
    "MandatoryFlag": "Non-Mandatory",
    "PathNames": [
      "ZTNA Solution (Test)",
      "Data Loss Prevention (DLP)",
      "Enforcing DLP policies"
    ]
  },
  {
    "UseCase": "Real-time malware prevention",
    "Importance": 4.9713832142707925,
    "MandatoryFlag": "Non-Mandatory",
    "PathNames": [
      "ZTNA Solution (Test)",
      "Malware Protection",
      "Real-time malware prevention"
    ]
  }
]

export const SupportedUseCaseMandatoryRow = [
  {
    "UseCase": "Digital rights management (DRM)",
    "Importance": 2.2456239879918214,
    "MandatoryFlag": "Mandatory",
    "PathNames": [
      "ZTNA Solution (Test)",
      "Data Loss Prevention (DLP)",
      "DLP enforcement actions",
      "Digital rights management (DRM)"
    ]
  },
  {
    "UseCase": "Sensitive data match patterns",
    "Importance": 0.07451860978081593,
    "MandatoryFlag": "Mandatory",
    "PathNames": [
      "ZTNA Solution (Test)",
      "Visibility and Reporting",
      "Dashboards",
      "Sensitive data match patterns"
    ]
  }
]

export const OverallUseCasesRow = [
  {
    "OfferName": "Zscaler Private Access",
    "OverallOfferScore": 47.593810916179336,
    "OverallOfferRelevance": 39.63206627680311,
    "OverallOfferUtilisation": 55.55555555555556,
    "VendorName": "Zscaler",
    "MaxVendorOfferScore": 47.593810916179336,
    "MaxVendorOfferRelevance": 39.63206627680311,
    "MaxVendorOfferUtilisation": 55.55555555555556,
    "MaxGlobalOfferScore": 47.593810916179336,
    "MaxGlobalOfferRelevance": 39.63206627680311,
    "MaxGlobalOfferUtilisation": 55.55555555555556,
    "RankOfferScore": 1,
    "RankRelevance": 1,
    "RankUtilisation": 2
  }
]

const GridLine =  [

  {
    "XDivider": 43.148148148148145,
    "YDivider": 34.63206627680311
  }

]
export const XDivider = GridLine[0].XDivider;
export const YDivider = GridLine[0].YDivider;

export const ValYouMatrixData = [
  [
    "Select",
    "Vendor Name",
    "Status",
    "Offer Name",
    "Mandatory Status",
    "Offer Score",
    "Offer Relevance",
    "Offer Utilization",
  ],
  [
    null,
    "Zscaler",
    "Unlocked",
    "Zscaler Private Access",
    "Overall Use Cases",
    "47.59%",
    "39.63%",
    "55.55%",
  ],
  [
    null,
    "Check Point ",
    "Unlocked",
    "Check Point Harmony Connect Remote Access",
    "Overall Use Cases",
    "40.86%",
    "26.16%",
    "55.55%",
  ],
  [
    null,
    "Zscaler",
    "Unlocked",
    "Zscaler Private Access",
    "Mandatory Use Cases",
    "12.59%",
    "14.07%",
    "11.11%",
  ],
  [
    null,
    "Check Point ",
    "Unlocked",
    "Check Point Harmony Connect Remote Access",
    "Mandatory Use Cases",
    "9.17%",
    "12.79%",
    "5.55%",
  ],
];

