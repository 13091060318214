import React, { useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import { UserProfileContext } from "../context/UserContext";

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  let breadcrumbPath = "";

  const specialCases = {
    "select-buyer-guide": "select-buyer-guide/available-buyer-guides",
    "edit-scenario": "edit-scenario/vendor-options",
    "scenario-settings": "edit-scenario/scenario-settings/bundle-option",
    results: "results/comparative-analysis",
  };

  const formatBreadcrumb = (breadcrumb) => {
    return breadcrumb
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const { userProfile } = useContext(UserProfileContext); // Accessing userProfile from context

  // Determine the home link based on userProfile
  let homeLink =
    userProfile && userProfile.profileType === "consultant"
      ? "/select-end-user-org"
      : "/select-buyer-guide/available-buyer-guides";

  // If location is root (/) or empty path, redirect to homeLink
  if (location.pathname === "/" || pathnames.length === 0) {
    // Render breadcrumbs with only the home link
    return (
      <Box marginBottom={5}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={homeLink}
          >
            <Box display="flex" alignItems="center">
              <HomeIcon fontSize="small" />
            </Box>
          </Link>
          {(breadcrumbPath = "Welcome")}
          <Typography color="text.primary" key={breadcrumbPath}>
            {formatBreadcrumb(decodeURIComponent(breadcrumbPath))}
          </Typography>
        </Breadcrumbs>
      </Box>
    );
  }

  return (
    <Box marginBottom={5} sx={{ position: "sticky", top: "0" }}>
      <Box marginBottom={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={homeLink}
          >
            <Box display="flex" alignItems="center">
              <HomeIcon fontSize="small" />
            </Box>
          </Link>

          {pathnames.map((name, index) => {
            const isLast = index === pathnames.length - 1;

            if (specialCases[name] && pathnames.length > index + 1) {
              breadcrumbPath = `/${specialCases[name]}`;
            } else {
              breadcrumbPath += `/${name}`;
            }

            return isLast ? (
              <Typography color="text.primary" key={breadcrumbPath}>
                {formatBreadcrumb(decodeURIComponent(name))}
              </Typography>
            ) : (
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={breadcrumbPath}
                key={breadcrumbPath}
              >
                {formatBreadcrumb(decodeURIComponent(name))}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Box>
      <Divider />
    </Box>
  );
};

export default BreadcrumbsComponent;
