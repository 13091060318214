import React, {
    useState,
    useEffect,
    // useCallback,
    useContext,
} from "react";
import PropTypes from "prop-types";
import { apiService } from "../../../utils/utils";
import ListToggleWidget from "../VendorRelevantOffer";
import { UserProfileContext } from "../../../context/UserContext";
import { Box, IconButton, Typography } from "@mui/material";
import {
    FileCopyOutlined,
    Close,
    Visibility,
    VisibilityOff,

} from '@mui/icons-material';
const Low = ({ isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {
        userProfile,
        buyerGuideName,
        scenarioName,
        currentOrganization,
        lowData,
        setLowData,
    } = useContext(UserProfileContext);

    const currentOrg =
        userProfile && userProfile.profileType === "endUser"
            ? userProfile.companyName
            : currentOrganization;

    useEffect(() => {
        const fetchData = async () => {
            const payload = {
                email: userProfile?.email,
                currentOrganisation: currentOrg,
                currentBuyerGuide: buyerGuideName,
                currentScenario: scenarioName,
            };

            try {
                const response = await apiService(
                    "https://newtestfuncpython.azurewebsites.net/api/lowValyouOfferCountAndNames?", // replace with your API endpoint
                    {
                        method: "POST",
                        data: payload,
                    },
                    setLoading,
                    setLowData,
                    setError
                );

                // Assuming the response data structure matches:
                // {
                //   "SmartOfferCount": 2,
                //   "SmartOfferNames": [
                //     "Zscaler Private Access",
                //     "Check Point Harmony Connect Remote Access"
                //   ]
                // }
                if (response) {
                    setLayout((prev) => {
                        const newLayout = prev.map((item) => {
                            if (item.i !== widgetKey) {
                                return item;
                            } else {
                                const newItem = {
                                    ...item,
                                    h:
                                        3 + response.LowOfferDetails.length,
                                    minH:
                                        3 + response.LowOfferDetails.length,
                                    maxH:
                                        3 + response.LowOfferDetails.length,
                                };
                                return newItem;
                            }
                        });
                        return newLayout
                    });
                    setLowData({
                        count: response.LowOfferCount,
                        items: response.LowOfferDetails.map((offer) => offer.LowOfferNames),
                    });
                }
            } catch (err) {
                console.error("Error fetching data:", err);
                setError(err);
            }
        };

        fetchData();
    }, [userProfile, currentOrg, buyerGuideName, scenarioName, setLowData, setLayout, widgetKey]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div data-test-id="low">
            {lowData ? (
                <Box>
                    <div
                        className="widget-header-container"
                        style={{ flexShrink: 0, display: "flex", flexDirection: "column", height: "70px" }}
                    >
                        {widgetLabels && widgetKey && <Typography
                            variant="h6"
                            sx={{  marginTop: "15px", textAlign: "center" }}
                            className="widget-header"
                        >
                            {widgetLabels[widgetKey.split("_")[0]]}
                        </Typography>}

                        <span className="widget-icon">
                            {widgetKey === 'd' && (
                                <IconButton
                                    data-test-id={`eye-${widgetLabels[widgetKey.split('_')[0]]}`}
                                    onClick={toggleTableVisibility}
                                    aria-label="toggle table visibility"
                                >
                                    {isTableVisible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            )}
                            <FileCopyOutlined
                                data-test-id={`copy-${widgetLabels[widgetKey.split('_')[0]]}`}
                                onClick={() => handleCopyWidget(widgetKey)}
                            />
                            <IconButton
                                data-test-id={`close-${widgetLabels[widgetKey.split('_')[0]]}`}
                                size="small"
                                onClick={() => handleRemoveWidget(widgetKey)}
                            >
                                <Close />
                            </IconButton>
                        </span>
                    </div>
                    <ListToggleWidget name="low" items={lowData.items} count={lowData.count} />
                </Box>
            ) : (
                <div>No data available</div>
            )}
        </div>
    );
};

Low.propTypes = {
    apiEndpoint: PropTypes.string.isRequired, // Ensure you pass the API endpoint as a prop
};

export default Low;
