import React, { useState, useEffect, useContext } from "react";
import { Link, Box, CircularProgress, Alert, IconButton, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import DataGridTable from "../../../components/DataGridTable";
import theme from "../../../theme/theme";
import { apiService } from "../../../utils/utils"; // Adjust the path as necessary
import { UserProfileContext } from "../../../context/UserContext";
import {
  FileCopyOutlined,
  Close,
  Visibility,
  VisibilityOff,
 
} from '@mui/icons-material';
import "./ComponentStyle.css"
const columns = [
  {
    field: "useCase",
    headerName: "Use Case (path)",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "importanceScore",
    headerName: "Importance Score",
    type: "number",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "mandatory",
    headerName: "Mandatory (Yes/No)",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "offers",
    headerName: "Number of Offers that meet the Use Case",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "vendors",
    headerName: "Number of Vendors that meet the Use Case",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
];

const CoverageHeatmap = ({ showDetails, setShowDetails, isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [isclicked, setClick] = useState(false);
  const { userProfile, currentOrganization, buyerGuideName, scenarioName } =
    useContext(UserProfileContext);

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getCoverageHeatmap?", // Replace with your actual endpoint
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: buyerGuideName,
              currentScenario: scenarioName,
            },
          },
          setLoading,
          setData,
          setError
        );
        setData(response);
        setLayout((prev) => {
          const newLayout = prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              const newItem = {
                ...item,
                h:
                  1 + response.length,
                minH:
                  1 + response.length,
                maxH:
                  1 + response.length,
              };
              return newItem;
            }
          });
          return newLayout
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userProfile, currentOrg, buyerGuideName, scenarioName, setLayout, widgetKey, setClick ]);

  const handleToggleRow = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const renderDetails = (details, isExpanded, index) => {
    const displayedDetails = isExpanded ? details : details.slice(0, 3);
    const moreText =
      details.length > 3 ? (isExpanded ? "View Less" : "View More") : null;

    return (
      <>
        {displayedDetails.join(", ")}
        {moreText && (
          <Link
            component="button"
            variant="body2"
            onClick={(e) => {
              e.preventDefault();
              handleToggleRow(index);
            }}
            style={{ marginLeft: 8 }}
          >
            {moreText}
          </Link>
        )}
      </>
    );
  };

  const enhancedData = data.map((row, index) => ({
    id: index, // Add an id field for the DataGrid
    useCase: row.UseCase,
    importanceScore: row.Importance,
    mandatory: row.MandatoryFlag,
    offers: showDetails
      ? renderDetails(row.PathNames, expandedRows[index], index)
      : row.OfferCount,
    vendors: showDetails
      ? renderDetails(row.PathNames, expandedRows[index], index)
      : row.VendorCount,
  }));

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert severity="error">Error fetching data: {error.message}</Alert>
        </Box>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        className={isclicked ? "widget-header-container grabbing":"widget-header-container grab" }
        style={{
          flexShrink: 0,
          display: "flex",
          height: 20,
        }}
        onClick={()=>{
          
          console.log("clicked")
        }}
      >
        {widgetLabels && widgetKey && (
           <Typography
            variant="h6"
            sx={{ marginTop: "15px" ,textAlign:"center"}}
            className="widget-header"
          >
            {widgetLabels[widgetKey.split("_")[0]]}
          </Typography>
        )}

        <Box className="widget-icon">
          {widgetKey === "d" && (
            <IconButton
              data-test-id={`eye-${widgetLabels[widgetKey.split("_")[0]]}`}
              onClick={toggleTableVisibility}
              aria-label="toggle table visibility"
            >
              {isTableVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          <FileCopyOutlined
            data-test-id={`copy-${widgetLabels[widgetKey.split("_")[0]]}`}
            onClick={() => handleCopyWidget(widgetKey)}
          />
          <IconButton
            data-test-id={`close-${widgetLabels[widgetKey.split("_")[0]]}`}
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box
        data-test-id="coverage"
        style={{ marginTop: "42px" }}
        height={400}
        width="100%"
      >
        <DataGridTable
          name="coverage"
          rows={enhancedData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
    </ThemeProvider>
  );
};

export default CoverageHeatmap;
