import React, { useContext, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Typography,
  Grid,
  Paper,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { UserProfileContext } from "../context/UserContext";
import CustomButton from "../components/CustomButton";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import { handleCheckout } from "../utils/utils";

const TabContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderRadius: "4px",
  boxShadow: theme.shadows[1],
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const InfoField = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  borderRadius: "4px",
}));

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function PurchasePackSection() {
  const location = useLocation();
  const theme = useTheme();
  const { userProfile, packageCount } = useContext(UserProfileContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const monthlyPackages = location && location?.state?.packages?.[0];
  const priceIds = location && location?.state?.priceIds?.[0];
  const priceIdsForSUb = location && location?.state?.priceIdsForSUb;

  const [value, setValue] = useState(0);

  const [tab1State, setTab1State] = useState({
    numUsers: 1,
    numEndUsers: 1,
    numBuyerGuide: 1,
    unusedVolume: 1,
  });

  const [tab2State, setTab2State] = useState({
    numUsers: 1,
    numEndUsers: 1,
    numBuyerGuide: 1,
    unusedVolume: 1,
  });

  const [tab3State, setTab3State] = useState({
    numUsers: 1,
    numEndUsers: 1,
    numBuyerGuide: 1,
    unusedVolume: 1,
  });

  const userType = userProfile?.profileType;

  const rows = [
    { volumeUnit: "0 - 4", discount: "0%" },
    { volumeUnit: "5 - 24", discount: "10%" },
    { volumeUnit: "25 - 99", discount: "20%" },
    { volumeUnit: "100 or more", discount: "30%" },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calculateVolume = (numUsers, numEndUsers, numBuyerGuide) =>
    numUsers * numEndUsers * numBuyerGuide;

  const calculateNetPurchaseVolume = (
    numUsers,
    numEndUsers,
    numBuyerGuide,
    unusedVolume
  ) => {
    if (calculateVolume(numUsers, numEndUsers, numBuyerGuide) < unusedVolume)
      return 0;

    return calculateVolume(numUsers, numEndUsers, numBuyerGuide) - unusedVolume;
  };

  const returnLevel = () => {
    let numUsers;
    let numEndUsers;
    let numBuyerGuide;
    let unusedVolume;
    if (value === 0) {
      numUsers = tab1State.numUsers;
      numEndUsers = tab1State.numEndUsers;
      numBuyerGuide = tab1State.numBuyerGuide;
      unusedVolume = tab1State.unusedVolume;
    }
    if (value === 1) {
      numUsers = tab2State.numUsers;
      numEndUsers = tab2State.numEndUsers;
      numBuyerGuide = tab2State.numBuyerGuide;
      unusedVolume = tab2State.unusedVolume;
    }
    if (value === 2) {
      numUsers = tab3State.numUsers;
      numEndUsers = tab3State.numEndUsers;
      numBuyerGuide = tab3State.numBuyerGuide;
      unusedVolume = tab3State.unusedVolume;
    }
    const netVolumeUnit = calculateNetPurchaseVolume(
      numUsers,
      numEndUsers,
      numBuyerGuide,
      unusedVolume
    );

    if (+netVolumeUnit >= 0 && +netVolumeUnit <= 4) {
      return 0;
    }
    if (+netVolumeUnit >= 5 && +netVolumeUnit <= 24) {
      return 1;
    }
    if (+netVolumeUnit >= 25 && +netVolumeUnit <= 99) {
      return 2;
    }
    if (+netVolumeUnit >= 100) {
      return 3;
    }
  };

  const handleInputChange = (state, setState, key, value) => {
    setState({ ...state, [key]: Number(value) });
  };

  const calculateNetVolumeUnit = (row) => {
    let numUsers;
    let numEndUsers;
    let numBuyerGuide;
    let unusedVolume;

    if (row === 1) {
      numUsers = tab1State.numUsers;
      numEndUsers = tab1State.numEndUsers;
      numBuyerGuide = tab1State.numBuyerGuide;
      unusedVolume = tab1State.unusedVolume;
    }
    if (row === 2) {
      numUsers = tab2State.numUsers;
      numEndUsers = tab2State.numEndUsers;
      numBuyerGuide = tab2State.numBuyerGuide;
      unusedVolume = tab2State.unusedVolume;
    }
    if (row === 3) {
      numUsers = tab3State.numUsers;
      numEndUsers = tab3State.numEndUsers;
      numBuyerGuide = tab3State.numBuyerGuide;
      unusedVolume = tab3State.unusedVolume;
    }

    const netVolumeUnit = calculateNetPurchaseVolume(
      numUsers,
      numEndUsers,
      numBuyerGuide,
      unusedVolume
    );

    return netVolumeUnit;
  };

  const calculateTotalVolumeUnit = () => {
    let total = 0;
    monthlyPackages?.length > 0 &&
      monthlyPackages.forEach((val, ind) => {
        total += calculateNetVolumeUnit(ind + 1);
      });

    return total;
  };

  const getProductsForSub = () => {
    let products = [];
    for (let key in priceIdsForSUb) {
      products.push({
        price: priceIdsForSUb[key],
        quantity: calculateNetVolumeUnit(+key),
      });
    }
    return products;
  };

  const getProducts = () => {
    let products = [];
    for (let key in priceIds) {
      products.push({
        price: priceIds[key],
        quantity: calculateNetVolumeUnit(+key),
      });
    }
    return products;
  };

  const getPackageDetails = () => {
    const packages = {
      duration: location?.state?.packages,
    };
    monthlyPackages?.length > 0 &&
      monthlyPackages?.forEach((val) => {
        if (val === 1) {
          packages.tab1State = tab1State;
        }
        if (val === 2) {
          packages.tab2State = tab2State;
        }
        if (val === 3) {
          packages.tab3State = tab3State;
        }
      });
    return packages;
  };

  return (
    <Container maxWidth="xl">
      <Header>
        <Typography variant="h6" component="div">
          User Name: John Doe
        </Typography>
        <Typography variant="h6" component="div" sx={{ marginBottom: "16px" }}>
          Buyer Guide: Current Buyer Guide
        </Typography>
      </Header>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={isMobile ? "scrollable" : "standard"}
              scrollButtons="auto"
              aria-label="tabs example"
              centered={isMobile ? false : true}
            >
              {monthlyPackages &&
                monthlyPackages.length > 0 &&
                monthlyPackages
                  .sort()
                  .map((val, ind) => (
                    <Tab
                      key={ind}
                      label={
                        val === 1
                          ? "Duel Dynamics"
                          : val === 2
                          ? "Focus Five"
                          : "All Available"
                      }
                    />
                  ))}
            </Tabs>

            <TabPanel value={value} index={0}>
              {renderTabContent(tab1State, setTab1State)}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {renderTabContent(tab2State, setTab2State)}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {renderTabContent(tab3State, setTab3State)}
            </TabPanel>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Alert severity="info">
              Volume Units is calculated as the product of Number of Users,
              Number of End-User Organizations, and Number of Buyer Guide.
            </Alert>
            <Alert severity="info">
              Required Volume Units - Unused Volume Units = Net Purchase Volume
            </Alert>

            <Box display="flex" justifyContent="center" alignItems="center">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Volume Unit</TableCell>
                      <TableCell>Discount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            returnLevel() === index && "primary.main",
                        }}
                      >
                        <TableCell>{row.volumeUnit}</TableCell>
                        <TableCell>{row.discount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Package Name</TableCell>
                      <TableCell>Net Purchase Volume</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthlyPackages &&
                      monthlyPackages.length > 0 &&
                      monthlyPackages.sort().map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {row === 1
                              ? "Duel Dynamics"
                              : row === 2
                              ? "Focus Five"
                              : "All Available"}
                          </TableCell>
                          <TableCell>
                            {calculateNetVolumeUnit(index + 1)}
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell>
                        <Typography variant="h3">Total Volume Units</Typography>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Typography variant="h3">
                          {calculateTotalVolumeUnit()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box>
            <CustomButton
              style={{ width: "250px", marginBottom: "7px" }}
              color="primary"
              variant="contained"
              onClick={() =>
                handleCheckout(
                  userProfile?.email,
                  getProducts,
                  packageCount,
                  getPackageDetails,
                  getProductsForSub
                )
              }
              text="Checkout"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  function renderTabContent(state, setState) {
    const { numUsers, numEndUsers, numBuyerGuide, unusedVolume } = state;

    return (
      <TabContent>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <FormField
              label="Number of Users"
              type="number"
              variant="outlined"
              value={numUsers}
              onChange={(e) =>
                handleInputChange(state, setState, "numUsers", e.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item>
            <HtmlTooltip
              title={
                userType === "endUser" ? (
                  <Alert
                    severity="info"
                    sx={{ border: "1px solid #6b6b6b6b", textAlign: "left" }}
                  >
                    Analysis of multiple 'End-User Organizations' is restricted
                    to consultants. If necessary, switch to a consultant profile
                    to make changes
                  </Alert>
                ) : (
                  ""
                )
              }
              arrow
              placement="top"
            >
              <span>
                <FormField
                  label="Number of End-User Organizations"
                  type="number"
                  variant="outlined"
                  name="numEndUsers"
                  value={numEndUsers}
                  onChange={(e) =>
                    userType === "consultant"
                      ? handleInputChange(
                          state,
                          setState,
                          "numEndUsers",
                          e.target.value
                        )
                      : null
                  }
                  fullWidth
                  disabled={userType === "endUser"}
                />
              </span>
            </HtmlTooltip>
          </Grid>
          <Grid item>
            <FormField
              label="Number of Buyer Guide"
              type="number"
              variant="outlined"
              value={numBuyerGuide}
              onChange={(e) =>
                handleInputChange(
                  state,
                  setState,
                  "numBuyerGuide",
                  e.target.value
                )
              }
              fullWidth
            />
          </Grid>
          <Grid item>
            <InfoField variant="body1">
              Required Volume Units:{" "}
              {calculateVolume(numUsers, numEndUsers, numBuyerGuide)}
            </InfoField>
          </Grid>
          <Grid item>
            <InfoField variant="body1">
              Unused Volume Units: {unusedVolume}
            </InfoField>
          </Grid>
          <Grid item>
            <InfoField variant="body1">
              Net Purchase Volume:{" "}
              {calculateNetPurchaseVolume(
                numUsers,
                numEndUsers,
                numBuyerGuide,
                unusedVolume
              )}
            </InfoField>
          </Grid>
        </Grid>
      </TabContent>
    );
  }
}

export default PurchasePackSection;
