import React from "react";
import { Radio, Typography } from "@mui/material";
import theme from "../../theme/theme.js";

import { Box } from "@mui/material";

const CustomizeRadio = ({
  selectedRadioValue,
  setSelectedRadioValue,
  data,
  index,
}) => {
  return (
    <Box>
      <Typography
        sx={{
          marginTop: "10px",
          marginLeft: "20px",
          fontFamily: theme.typography.h3,
        }}
      >
        {data.heading}
      </Typography>
      <Box sx={{ marginLeft: "30px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={selectedRadioValue?.[index]?.value === data.option1}
            onChange={(e) =>
              setSelectedRadioValue(
                selectedRadioValue?.map((val, idx) => {
                  if (idx === index) return { ...val, value: e.target.value };
                  else return val;
                })
              )
            }
            value={data.option1}
            name="radio-buttons"
            inputProps={{ "aria-label": "user defined" }}
          />
          <Typography sx={{ fontFamily: theme.typography.body2 }}>
            {data.text1}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Radio
            checked={selectedRadioValue?.[index]?.value === data.option2}
            onChange={(e) =>
              setSelectedRadioValue(
                selectedRadioValue?.map((val, idx) => {
                  if (idx === index) return { ...val, value: e.target.value };
                  else return val;
                })
              )
            }
            value={data.option2}
            name="radio-buttons"
            inputProps={{ "aria-label": "Let me prescribe" }}
          />
          <Typography sx={{ fontFamily: theme.typography.body2 }}>
            {data.text2}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomizeRadio;
