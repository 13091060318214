import React, { useState, useEffect } from "react";

import { Box, TextField } from "@mui/material";

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const TextInput = ({ inputValue, onSet, inputsLabel }) => {
  const [value, setValue] = useState("");
  const [prevValue, setPrevValue] = useState("");
  const [isFocused, setIsfocused] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setValue(inputValue);

    if (emailPattern.test(inputValue)) {
      setIsError(false);
    } else setIsError(true && inputsLabel === "email" && inputValue !== "");
  }, [inputValue, inputsLabel]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (newValue !== "") setPrevValue(newValue);

    if (inputsLabel !== "email") {
      onSet(newValue);
    } else {
      const isValid = emailPattern.test(newValue);

      setIsError(!isValid);
      if (isValid) {
        onSet(newValue);
      }
    }
  };

  const handleBlur = () => {
    setIsfocused(false);
    if (value === "") {
      setValue(prevValue);
      if (inputsLabel !== "email") {
        onSet(prevValue);
      } else {
        const isValid = emailPattern.test(prevValue);

        setIsError(!isValid);
        if (isValid) {
          onSet(prevValue);
        }
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <TextField
        spellCheck={false}
        autoComplete="off"
        sx={{
          width: "250px",
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        required
        error={isError}
        type={inputsLabel === "email" ? "email" : "text"}
        // placeholder={inputsLabel === 'email' ? 'Email' : 'Name'}
        label={
          inputsLabel === "organisation_name"
            ? "End-User Organisation Name"
            : inputsLabel === "email"
            ? "Email"
            : "Name"
        }
        value={value}
        onChange={handleChange}
        onFocus={(e) => {
          setIsfocused(true);
          e.target.select();
        }}
        onBlur={handleBlur}
        InputLabelProps={{
          shrink: isFocused || value?.length > 0, // Ensure label shrinks when focused or when there is a value
        }}
        size="small"
      />
    </Box>
  );
};

export default TextInput;
