import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Radio,
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../context/UserContext";
import { apiService } from "../../utils/utils";

const Consultant = ({ theme, setOpen, updateUi, setUpdateUi }) => {
  const navigate = useNavigate();
  const {
    userProfile,
    orgList,
    setOrgList,
    setCurrentOrganization,
    interestedBuyerGuides,
    setCurrentOrganizationId,
  } = useContext(UserProfileContext);

  const [isLoading, setIsLoading] = useState(true);
  const [, setIsDeleting] = useState(false);
  const [, setMessage] = useState("");
  const [selectedOrg, setSelectedOrg] = useState(null);
  const handleDelete = (removeOrganisation) => {
    //https://newtestfuncpython.azurewebsites.net/api/deleteEndUserOrg?
    // {
    // "email":"babluv613@gmail.com",
    // "removeOrganisation":"Jio"
    // }
    apiService(
      "https://newtestfuncpython.azurewebsites.net/api/deleteEndUserOrg?",
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          removeOrganisation,
        },
      },
      setIsDeleting,
      setMessage,
      (error) => {
        // Handle error if needed
        console.error("Failed to delete organisation:", error);
      }
    ).then(() => setUpdateUi(!updateUi));
  };
  const handleRadioChange = (index) => {
    setSelectedOrg(index);
  };

  useEffect(() => {
    apiService(
      "https://newtestfuncpython.azurewebsites.net/api/getEndUserOrganisationList?",
      {
        method: "POST",
        data: {
          email: userProfile?.email,
        },
      },
      setIsLoading,
      setOrgList,
      (error) => {
        // Handle error if needed
        console.error("Failed to fetch organisation list:", error);
      }
    );
  }, [userProfile, updateUi, setOrgList]);

  useEffect(() => {
    if (Array.isArray(orgList) && orgList.length > 0) {
      setIsLoading(false);
    }
  }, [orgList]);

  if (isLoading) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box
      marginTop={5}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap={2}
    >
      {isLoading && (
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {orgList && orgList?.length > 0 && (
        <TableContainer data-test-id="table-head" sx={{ maxWidth: "100%" }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: "0.5px solid grey",
                    color: "white",
                    padding: 2,
                    backgroundColor: theme.palette.primary.main,
                    width: "5px",
                  }}
                ></TableCell>
                <TableCell
                data-test-id="organization-name-header"
                  sx={{
                    border: "0.5px solid grey",
                    color: "white",
                    padding: 2,
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  Organization Name
                </TableCell>
                <TableCell
                data-test-id="industry-name-header"
                  sx={{
                    border: "0.5px solid grey",
                    color: "white",
                    padding: 2,
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  Industry
                </TableCell>
                <TableCell
                 data-test-id="actions-header"
                  sx={{
                    border: "0.5px solid grey",
                    color: "white",
                    padding: 2,
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
              {orgList &&
                orgList?.length > 0 &&
                orgList?.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        border: "0.5px solid grey",
                        color: "black",
                        padding: 2,
                      }}
                    >
                      <Radio
                        checked={selectedOrg === index}
                        onClick={() => {
                          handleRadioChange(index);
                          setCurrentOrganization(data?.orgname);
                          localStorage.setItem(
                            "currentOrganization",
                            data?.orgname
                          );
                          setCurrentOrganizationId(data?.id);
                          localStorage.setItem(
                            "currentOrganizationId",
                            data?.id
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "0.5px solid grey",
                        color: "black",
                        padding: 2,
                      }}
                    >
                      {data.orgname}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "0.5px solid grey",
                        color: "black",
                        padding: 2,
                      }}
                    >
                      {data.industryName}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "0.5px solid grey",
                        color: "black",
                        padding: 2,
                      }}
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(data?.id)}
                      >
                        <DeleteIcon data-test-id="delete-icon"/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {orgList && orgList?.length > 0 && (
        <Button
          sx={{ width: "250px" }}
          color="primary"
          variant="contained"
          disableRipple
          onClick={() => {
            if (interestedBuyerGuides && interestedBuyerGuides.length > 0) {
              navigate("/select-buyer-guide/buyer-guide-of-interest");
            } else {
              navigate("/select-buyer-guide/available-buyer-guides");
            }
          }}
          disabled={selectedOrg === null}
        >
          <Typography color="white"> Save & Continue </Typography>
        </Button>
      )}
      <Button
        sx={{ width: "250px" }}
        color="secondary"
        variant="contained"
        disableRipple
        onClick={() => setOpen(true)}
      >
        <Typography color="white"> New End-User Organization</Typography>
      </Button>
    </Box>
  );
};

export default Consultant;
