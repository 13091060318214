import React, { useContext, useEffect, useState } from "react";
import DataGridTable from "../../../components/DataGridTable";
import { FileCopyOutlined, Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
//import { apiService } from "../../../utils/utils";
import theme from "../../../theme/theme";
import { UserProfileContext } from "../../../context/UserContext";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
const transformData = (data) => {
  if (
    data &&
    data["Use Cases with MEETS_USE_CASE (Overall)"] &&
    Array.isArray(data["Use Cases with MEETS_USE_CASE (Overall)"].list)
  ) {
    return data["Use Cases with MEETS_USE_CASE (Overall)"].list.map(
      (item, index) => ({
        id: index + 1,
        useCaseName: item.useCaseName,
        useCasePath: item.path,
        importanceScore: `${item.relativeImportance.toFixed(2)}%`,
      })
    );
  }
  return [];
};
const ShowPath = (params) => {
  const [showCell, setShowCell] = useState(false);
  const { value } = params;
  return (
    <Box>
      {showCell ? (
        <Box sx={{ display: "flex" }}>
          <Box height={20} width={20} marginRight={4} marginLeft={15}>
            <IconButton
              onClick={() => {
                setShowCell(false);
              }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Box>
          <Box>
            {showCell ? value.join(" > ") : "Sub " + value[value.length - 1]}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box height={20} width={20} marginRight={4} marginLeft={15}>
            <IconButton
              onClick={() => {
                setShowCell(true);
              }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
          <Box>
            {showCell ? value.join(" > ") : "Sub " + value[value.length - 1]}
          </Box>
        </Box>
      )}
    </Box>
  );
};
const VendorDetailSupportedUseCaseOverallTable = ({
  menu,
  widgetKey,
  widgetLabels,
  setLayout,
  handleCopyWidget,
  handleRemoveWidget,
}) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    userProfile,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    offerName,
  } = useContext(UserProfileContext);

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchData = async () => {
      try {
        /*const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/top5SupportedUseCasesOverall",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(payload),
          },
          setLoading,
          null,
          setError
        );*/
        setLoading(false);
        setError(null);
        const response = {
          "Use Cases with MEETS_USE_CASE (Overall)": {
            count: 3,
            list: [
              {
                mandatoryFlag: "Mandatory",
                useCaseName: "Use Case 1",
                path: ["Use Case 1", "Use Case 1.1", "Use Case 1.1.1"],
                relativeImportance: 3,
              },
              {
                mandatoryFlag: "Mandatory",
                useCaseName: "Use Case 2",
                path: ["Use Case 2", "Use Case 2.1", "Use Case 2.1.1"],
                relativeImportance: 5,
              },
              {
                mandatoryFlag: "Mandatory",
                useCaseName: "Use Case 3",
                path: ["Use Case 3", "Use Case 3.1", "Use Case 3.1.1"],
                relativeImportance: 6,
              },
              {
                mandatoryFlag: "Mandatory",
                useCaseName: "Use Case 4",
                path: ["Use Case 4", "Use Case 4.1", "Use Case 4.1.1"],
                relativeImportance: 5,
              },
              {
                mandatoryFlag: "Mandatory",
                useCaseName: "Use Case 5",
                path: ["Use Case 5", "Use Case 5.1", "Use Case 5.1.1"],
                relativeImportance: 6,
              },
            ],
          },
        };
        const transformedData = transformData(response);
        setLayout((prev) => {
          return prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              return {
                ...item,
                h:
                  1 +
                  response["Use Cases with MEETS_USE_CASE (Overall)"].list
                    .length,
                maxH:
                  1 +
                  response["Use Cases with MEETS_USE_CASE (Overall)"].list
                    .length,
                minH:
                  1 +
                  response["Use Cases with MEETS_USE_CASE (Overall)"].list
                    .length,
              };
            }
          });
        });
        setRows(transformedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error);
      }
    };

    fetchData();
  }, [
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    setLayout,
    widgetKey,
  ]);

  const columns = [
    {
      field: "useCasePath",
      headerName: "Top 5 Supported Use Cases (Overall)",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: ShowPath,
    },
    {
      field: "importanceScore",
      headerName: "Relative Importance Score",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  return (
    <Box
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          border: "0.5px solid grey",
          color: "white",
        },
      }}
    >
      <Box
        className="widget-header-container"
        style={{ flexShrink: 0 }}
        sx={{ padding: 2 }}
      >
        <Typography
          variant="h6"
          sx={{  marginTop: "5px", textAlign: "center" }}
          className="widget-header"
        >
          {widgetLabels[widgetKey.split("_")[0]]}
        </Typography>
        <Box className="widget-icon">
          <FileCopyOutlined onClick={() => handleCopyWidget(widgetKey)} />
          <IconButton
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DataGridTable
        rows={rows}
        columns={columns}
        menu={menu}
        sx={{
          minWidth: "1080px",
          "& .MuiDataGrid-cell": {
            border: "0.5px solid grey",
            wordWrap: "break-word",
            whiteSpace: "normal",
          },
        }}
      />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
    </Box>
  );
};

export default VendorDetailSupportedUseCaseOverallTable;
