import React, { useState, useEffect, useContext } from "react";
import DataGridTable from "../../../components/DataGridTable";
import { Box, IconButton, Typography } from "@mui/material";
import theme from "../../../theme/theme";
import { FileCopyOutlined, Close } from "@mui/icons-material";
//import { apiService } from "../../../utils/utils";
import { UserProfileContext } from "../../../context/UserContext";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
const transformData = (data) => {
  if (
    data &&
    data["Top 5 Unsupported Use Cases (Mandatory)"] &&
    Array.isArray(data["Top 5 Unsupported Use Cases (Mandatory)"].list)
  ) {
    return data["Top 5 Unsupported Use Cases (Mandatory)"].list.map(
      (item, index) => ({
        id: index + 1,
        subUseCaseName: item.subUseCaseName,
        subUseCasePath: item.subUseCasePath,
        importanceScore: `${item.relativeImportance.toFixed(2)}%`,
      })
    );
  }
  return [];
};
const ShowPath = (params) => {
  const [showCell, setShowCell] = useState(false);
  const { value } = params;
  return (
    <Box>
      {showCell ? (
        <Box sx={{ display: "flex" }}>
          <Box height={20} width={20} marginRight={4} marginLeft={15}>
            <IconButton
              onClick={() => {
                setShowCell(false);
              }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
          </Box>
          <Box>
            {showCell ? value.join(" > ") : "Sub " + value[value.length - 1]}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box height={20} width={20} marginRight={4} marginLeft={15}>
            <IconButton
              onClick={() => {
                setShowCell(true);
              }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Box>
          <Box>
            {showCell ? value.join(" > ") : "Sub " + value[value.length - 1]}
          </Box>
        </Box>
      )}
    </Box>
  );
};
const VenderDetailUnsupportedUseCaseMandatoryTable = ({
  menu,
  widgetKey,
  widgetLabels,
  setLayout,
  handleCopyWidget,
  handleRemoveWidget,
}) => {
  const {
    userProfile,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    offerName,
  } = useContext(UserProfileContext);

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    /*const fetchData = async () => {
      try {
        setLoading(true);
        await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/top5UnSupportedUseCasesMandatory",
          {
            method: "POST",
            data: payload,
          },
          setLoading,
          (response) => {
            console.log("API response:", response);
            setData(response);
          },
          setError
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();*/
    const response = {
      "Top 5 Unsupported Use Cases (Mandatory)": {
        count: 5,
        list: [
          {
            subUseCaseName: "Use Case 1.1.1",
            subUseCasePath: ["Use Case1", "Use Case 1.1", "Use Case 1.1.1"],
            relativeImportance: 5,
            mandatoryFlag: "Mandatory",
          },
          {
            subUseCaseName: "Use Case 2.1.1",
            subUseCasePath: ["Use Case2", "Use Case 2.1", "Use Case 2.1.1"],
            relativeImportance: 3,
            mandatoryFlag: "Mandatory",
          },
          {
            subUseCaseName: "Use Case 3.1.1",
            subUseCasePath: ["Use Case3", "Use Case 3.1", "Use Case 3.1.1"],
            relativeImportance: 4,
            mandatoryFlag: "Mandatory",
          },
          {
            subUseCaseName: "Use Case 4.1.1",
            subUseCasePath: ["Use Case 4", "Use Case 4.1", "Use Case 4.1.1"],
            relativeImportance: 3,
            mandatoryFlag: "Mandatory",
          },
          {
            subUseCaseName: "Use Case 5.1.1",
            subUseCasePath: ["Use Case 5", "Use Case 5.1", "Use Case 5.1.1"],
            relativeImportance: 4,
            mandatoryFlag: "Mandatory",
          },
        ],
      },
    };

    setData(response);
    setLayout((prev) => {
      return prev.map((item) => {
        if (item.i !== widgetKey) {
          return item;
        } else {
          return {
            ...item,
            h:
              1 +
              response["Top 5 Unsupported Use Cases (Mandatory)"].list.length,
            maxH:
              1 +
              response["Top 5 Unsupported Use Cases (Mandatory)"].list.length,
            minH:
              1 +
              response["Top 5 Unsupported Use Cases (Mandatory)"].list.length,
          };
        }
      });
    });
    setLoading(false);
    setError(null);
  }, [
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    setLayout,
    widgetKey,
  ]);
  const transformedData = transformData(data);
  const UnsupportedUseCaseMandatoryData = {
    columns: [
      {
        field: "subUseCasePath",
        headerName: "Top 5 Unsupported Use Cases (Mandatory)",
        flex: 1,
        headerClassName: "super-app-theme--header",
        renderCell: ShowPath,
      },

      {
        field: "importanceScore",
        headerName: "Relative Importance Score",
        flex: 1,
        headerClassName: "super-app-theme--header",
      },
    ],
    rows: transformedData,
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Box
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          border: "0.5px solid grey",
          color: "white",
        },
      }}
    >
      <Box
        className="widget-header-container"
        style={{ flexShrink: 0 }}
        sx={{ padding: 2 }}
      >
        <Typography
          variant="h6"
          sx={{  marginTop: "5px", textAlign: "center" }}
          className="widget-header"
        >
          {widgetLabels[widgetKey.split("_")[0]]}
        </Typography>
        <Box className="widget-icon">
          <FileCopyOutlined onClick={() => handleCopyWidget(widgetKey)} />
          <IconButton
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DataGridTable
        rows={UnsupportedUseCaseMandatoryData.rows}
        columns={UnsupportedUseCaseMandatoryData.columns}
        menu={menu}
        sx={{
          minWidth: "1080px",
          "& .MuiDataGrid-cell": {
            border: "0.5px solid grey",
            wordWrap: "break-word",
            whiteSpace: "normal",
          },
        }}
      />
    </Box>
  );
};

export default VenderDetailUnsupportedUseCaseMandatoryTable;
