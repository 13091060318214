import { useEffect, useState, useRef } from "react";
import D3Funnel from "d3-funnel";
import DataGridTable from "../../../../components/DataGridTable";
import { Box } from "@mui/material";
import "./Chart.css";
const rowField = ["purchased", "unallocated", "unclaimed", "unused", "notPreviewedYet", "notFinalisedYet"]
export default function SubscriptionPendingAction({ id, chartData , fetchedData, section , packageName}) {
    const [currentIndex, setCurrentIndex] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState(false); 
    const [transition, setTransition] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false); 
    const tooltipTimeoutRef = useRef(null); 
    const chartRef = useRef(null); 
    useEffect(() => {
        setCurrentIndex(0);
        const data = [
            { label: "Purchased", value: chartData.purchased },
            { label: "Unallocated", value: chartData.unallocated },
            { label: "Unclaimed", value: chartData.unclaimed },
            { label: "Unused", value: chartData.unused },
            { label: "Not Previewed Yet", value: chartData.notPreviewedYet },
            { label: "Not Finalised Yet", value: chartData.notFinalisedYet },  
        ];


        const options = {
            chart: {
                width: 300,
                height: 300,
                bottomWidth: 2 / 5,
                bottomPinch: 0,
                inverted: false,
                horizontal: false,
                animate: 0,
                curve: {
                    enabled: true,
                    height: 20,
                    shade: -0.4
                },
                totalCount: null
            },
            block: {
                dynamicHeight: false,
                dynamicSlope: false,
                barOverlay: false,
                fill: {
                    type: "gradient",
                    scale: [
                        "#23736F",
                        "#368B83",
                        "#4B9D97",
                        "#61AFAA",
                        "#76C1BE",
                        "#8BD3D0"
                    ]
                },
                minHeight: 35,
                highlight: false
                // dynamicHeight: true,
                // minHeight: 15,
            },
            label: {
                enabled: true,
                fontFamily: null,
                fontSize: "13px",
                fill: "#fff",
                //raw: "{v}",
                //formatted: "{l} ${v}"
                format: `{v} \n {l}`,
                //formattedValue: `<text class="AAA">דגמים</text>`
                //format: '{l}'
            },
            tooltip: {
                //  enabled: true
                // format: "{l}\n{f}"
            },
            events: {
                click: {
                    block: (blockData) => {
                        setCurrentIndex(blockData.index);
                        setCurrentRow(data[blockData.index].label)
                        if (!selected) {
                            setSelected(true);
                            setTransition(true); // Trigger transition
                        }
                    }
                }
            }
        };
        const chart = new D3Funnel(chartRef.current);
        chart.draw(data, options);
        setCurrentRow(data[0].label)
        setLoading(false);
        setError(null);
    }, [id, chartData.notFinalisedYet, chartData.notPreviewedYet, chartData.purchased, chartData.unallocated, chartData.unclaimed, chartData.unused, selected]);
    useEffect(() => {
        if (selected) {
        const transformedData = {
            columuns: [

                {
                    field: "name",
                    headerName: "User",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                },
                {
                    field: `${rowField[currentIndex]}`,
                    headerName: `${currentRow}`,
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                }


            ],
            rows: fetchedData[section].filter((item, idx) => { return item.package === packageName })[0].users.map((item, idx) => {
                return {
                    id: idx + 1,
                    name: item.name,
                    [rowField[currentIndex]]: item[rowField[currentIndex]]
                }
            })

        }
        setRows(transformedData.rows);
        setColumns(transformedData.columuns);
        }
    }, [currentRow, currentIndex, fetchedData, packageName, section, selected]);
    const handleMouseEnter = () => {
        setTooltipVisible(true);
        tooltipTimeoutRef.current = setTimeout(() => {
            setTooltipVisible(false);
        }, 3000); 
    };

    return (
        <Box className={`container ${transition ? "moved" : ""}`} sx={{ display: "flex", flexDirection: "row", width: "100%", minWidth: "850px" }}>
            <Box className="funnel-chart">
            {tooltipVisible && (
                    <Box className="tooltip">
                        Click on a section to see more details
                    </Box>
                )}
            <Box
                ref={chartRef}
                
                onMouseEnter={handleMouseEnter} // Show tooltip on hover
               
            >
                
            </Box>
            </Box>
            {selected && (
                <Box className="datagrid-box">
                    <DataGridTable
                        rows={rows}
                        columns={columns}
                        loading={loading}
                        error={error}
                        menu={true}
                    />
                </Box>
            )}
        </Box>
    );
}
