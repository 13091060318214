import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import TextInput from "../TextInput.jsx";
import { useMemo } from "react";

const InputRenderCellWithPlusButton = ({
  params,
  inputsLabel,
  sectionData,
  setSectionData,
}) => {
  const sizeOfSectionData = sectionData?.length;
  const { row: rowData } = params;

  const penultimateRow = sectionData[sizeOfSectionData - 2];
  const thirdLastRow = sectionData[sizeOfSectionData - 3];

  const showButton = useMemo(() => {
    return (
      rowData[inputsLabel] !== "User Defined" &&
      rowData[inputsLabel] !== "Total" &&
      thirdLastRow.id === rowData.id &&
      (penultimateRow.solo_starter_total > 0 ||
        penultimateRow.duel_dynamics_total > 0 ||
        penultimateRow.focus_five_total > 0 ||
        penultimateRow.all_available_total > 0) &&
      (thirdLastRow.solo_starter_entered > 0 ||
        thirdLastRow.duel_dynamics_entered > 0 ||
        thirdLastRow.focus_five_entered > 0 ||
        thirdLastRow.all_available_entered > 0)
    );
  }, [rowData, inputsLabel, penultimateRow, thirdLastRow]);

  const handleRowAddition = () => {
    const userDefinedRow = sectionData[sizeOfSectionData - 2];

    const currentIdNumber = parseInt(thirdLastRow.id.replace("eu", ""), 10);
    const newId = `eu${currentIdNumber + 1}`;

    const newRowWithAllFieldsZero = {
      id: newId,
      [inputsLabel]: "",
      solo_starter_entered: 0,
      duel_dynamics_entered: 0,
      focus_five_entered: 0,
      all_available_entered: 0,
      solo_starter_total: userDefinedRow.solo_starter_total > 0 ? 1 : 0,
      duel_dynamics_total: userDefinedRow.duel_dynamics_total > 0 ? 1 : 0,
      focus_five_total: userDefinedRow.focus_five_total > 0 ? 1 : 0,
      all_available_total: userDefinedRow.all_available_total > 0 ? 1 : 0,
    };

    setSectionData(
      sectionData.toSpliced(sizeOfSectionData - 2, 0, newRowWithAllFieldsZero)
    );
  };

  return (
    <Box
      sx={{
        width: "300px",
        height: "100%",
        display: "flex",
        position: "relative",
        alignItems: "center",
      }}
    >
      <InputRenderCell
        params={params}
        inputsLabel={inputsLabel}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
      {showButton && (
        <Button
          disableRipple
          variant="text"
          sx={{
            width: 30,
            height: 30,
            minWidth: 0,
            cursor: "pointer",
          }}
          onClick={handleRowAddition}
        >
          <AddIcon color="primary" />
        </Button>
      )}
    </Box>
  );
};

export default InputRenderCellWithPlusButton;

const InputRenderCell = ({
  params,
  inputsLabel,
  sectionData,
  setSectionData,
}) => {
  const { row: rowData } = params;

  const handleOnSet = (value) => {
    setSectionData(
      sectionData?.map((packag) => {
        if (packag.id === rowData?.id)
          return { ...packag, [inputsLabel]: value };
        return packag;
      })
    );
  };

  if (
    rowData[inputsLabel] === "User Defined" ||
    rowData[inputsLabel] === "Total"
  )
    return <Typography>{rowData[inputsLabel]}</Typography>;

  return (
    <TextInput
      inputValue={params.value}
      inputsLabel={inputsLabel}
      onSet={handleOnSet}
    />
  );
};
