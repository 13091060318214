import React, { useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert, Box, CircularProgress } from "@mui/material";
//import EndUser from "../components/SelectEndUserComponents/EndUser";
import Consultant from "../components/SelectEndUserComponents/Consultant";
import NewOrgDialog from "../components/SelectEndUserComponents/NewOrgDialog";
import { UserProfileContext } from "../context/UserContext";
import { apiService } from "../utils/utils";

const userData = {
  Name: "username",
  profileType: "endUser", // default to 'End User'
  EndUserOrganizationName: "CompanyName",
  industryName: "indus1",
};

const userSection = {
  data: userData,
  columns: ["Name", "profileType", "End-User Organization"],
};

const SelectEndUser = ({ section }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  //const { state } = useLocation();
  const {
    userProfile,
    orgList,
    interestedBuyerGuides,
    isProfileLoading,
    notification,
    handleCloseNotification,
  } = useContext(UserProfileContext);
  const [, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [updateUi, setUpdateUi] = useState(false);
  const user = userProfile || {};

  const handleIndustryInputChange = (event) => {
    setIndustryName(event.target.value);
  };

  const handleOrgInputChange = (event) => {
    setOrgName(event.target.value);
  };

  const handleOrgSubmit = async () => {
    let know = false;
    console.log(userProfile);
    orgList.forEach((value) => {
      if (value?.orgname === orgName) {
        know = true;
      }
    });

    if (!know) {
      await AddNewEndUserOrganization();
      setOrgName("");
      setIndustryName("");
      setOpen(false);
    } else {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangeProfile = () => {
    navigate("/users");
  };

  const AddNewEndUserOrganization = async () => {
    apiService(
      `https://newtestfuncpython.azurewebsites.net/api/AddEndUserOrganisation?`,
      {
        method: "POST",
        data: {
          email: user?.email,
          new_organisation: orgName,
          new_industry: industryName,
        },
      },
      setLoading,
      setMessage,
      (err) => {
        console.log("Error in adding new EndUserOrganization", err);
      }
    ).then(() => setUpdateUi(!updateUi));
  };

  const profileType = user?.profileType;
  if (profileType === "endUser") {
    if (!isProfileLoading) {
      if (interestedBuyerGuides && interestedBuyerGuides.length > 0) {
        navigate("/select-buyer-guide/buyer-guide-of-interest");
      } else {
        navigate("/select-buyer-guide/available-buyer-guides");
      }
    } else {
      return (
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }
  }

  if (profileType !== "endUser" && !isProfileLoading) {
    return (
      <>
        <Consultant
          theme={theme}
          handleChangeProfile={handleChangeProfile}
          setOpen={setOpen}
          orgName={orgName}
          industryName={industryName}
          updateUi={updateUi}
          setUpdateUi={setUpdateUi}
        />

        <NewOrgDialog
          open={open}
          onClose={() => setOpen(false)}
          orgName={orgName}
          industryName={industryName}
          onOrgInputChange={handleOrgInputChange}
          onIndustryInputChange={handleIndustryInputChange}
          onOrgSubmit={handleOrgSubmit}
          AddNewEndUserOrganization={AddNewEndUserOrganization}
          isLoading={isLoading}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Organization name already exists or is empty.
          </Alert>
        </Snackbar>
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </>
    );
  } else {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
};

const App = () => <SelectEndUser section={userSection} />;

export default App;
