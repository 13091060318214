import React, { useContext } from 'react';
import './styles/MainDashBoard.css';
import GridComponent from '../../GridLayout/GridComponent';
import HeaderNaming from '../../../components/HeaderNaming/HeaderNaming.jsx';
import { UserProfileContext } from '../../../context/UserContext.jsx';

const ComparativeAnalysis = () => {
  const { userProfile, currentOrganization, buyerGuideName, scenarioName } =
    useContext(UserProfileContext);
  const data = {
    'End-user Organisation Name':
      userProfile?.profileType === 'endUser'
        ? userProfile?.companyName
        : currentOrganization,
    'Buyer Guide Name': buyerGuideName,
    'Scenario Name': scenarioName,
    'Purchased Package': '',
  };
  return (
    <>
      <HeaderNaming data={data} />
      <GridComponent />
    </>
  );
};

export default ComparativeAnalysis;
