import React, { useEffect, useState, useContext } from "react";
import DataGridTable from "../../../components/DataGridTable";
import { Box, IconButton,Typography } from "@mui/material";

//import { apiService } from "../../../utils/utils";
import { FileCopyOutlined, Close } from "@mui/icons-material";
import theme from "../../../theme/theme";

import { UserProfileContext } from "../../../context/UserContext";

function VendorDetailMendatoryUseCaseTable({
  menu,
  widgetKey,
  widgetLabels,
  setLayout,
  handleCopyWidget,
  handleRemoveWidget,
}) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {
    userProfile,
    currentOrganization,
    scenarioName,
    buyerGuideName,
    offerName,
  } = useContext(UserProfileContext);

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchData = async () => {
      try {
        /*const payload = {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: buyerGuideName,
          currentScenario: scenarioName,
          offerName: offerName,
        };

        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getMandatoryMetricsAndRank?",
          {
            method: "POST",
            data: payload,
          },
          setLoading,
          setRows,
          setError
        );*/
        const response = {
          VendorName: "Zscaler",
          MaxVendorRelevance: 14.071637426900582,
          MaxVendorUtilisation: 32.098765432098766,
          MaxVendorScore: 23.085201429499673,
          MaxGlobalVendorScore: 23.085201429499673,
          MaxGlobalVendorRelevance: 14.071637426900582,
          MaxGlobalVendorUtilisation: 32.098765432098766,
          RankVendorScore: 2,
          RankRelevance: 3,
          RankUtilisation: 2,
        };
        const transformedData = {
          columns: [
            {
              field: "metrics",
              headerName: "Metrics ",
              flex: 1,
              headerClassName: "super-app-theme--header",
            },
            {
              field: "vendorMax",
              headerName: "Mendatory Use Cases ( Vendor Max )",
              flex: 1,
              headerClassName: "super-app-theme--header",
            },
            {
              field: "scenarioMax",
              headerName: "Mendatory Use Cases ( Scenario Max )",
              flex: 1,
              headerClassName: "super-app-theme--header",
            },
            {
              field: "vendorRanking",
              headerName: "Vendor Ranking #",
              flex: 1,
              headerClassName: "super-app-theme--header",
            },
          ],
          rows: [
            {
              id: 1,
              metrics: "Offer Score %",
              vendorMax: `${response.MaxVendorScore.toFixed(2)}%`,
              scenarioMax: `${response.MaxGlobalVendorScore.toFixed(2)}%`,
              vendorRanking: response.RankVendorScore,
            },
            {
              id: 2,
              metrics: "Offer Relevance %",
              vendorMax: `${response.MaxVendorRelevance.toFixed(2)}%`,
              scenarioMax: `${response.MaxGlobalVendorRelevance.toFixed(2)}%`,
              vendorRanking: response.RankRelevance,
            },
            {
              id: 3,
              metrics: "Offer Utilization %",
              vendorMax: `${response.MaxVendorUtilisation.toFixed(2)}%`,
              scenarioMax: `${response.MaxGlobalVendorUtilisation.toFixed(2)}%`,
              vendorRanking: response.RankUtilisation,
            },
          ],
        };

        setColumns(transformedData.columns);
        setRows(transformedData.rows);
        setLayout((prev) => {
          return prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              return {
                ...item,
                h: 1 + transformedData.rows.length,
                maxH: 1 + transformedData.rows.length,
                minH: 1 + transformedData.rows.length,
              };
            }
          });
        });
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    offerName,
    setLayout,
    widgetKey,
  ]);

  return (
    <Box
      sx={{
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          border: "0.5px solid grey",
          color: "white",
        },
      }}
    >
      <Box
        className="widget-header-container"
        style={{ flexShrink: 0 }}
        sx={{ padding: 1 }}
      >
        <Typography
          variant="h6"
          sx={{   textAlign: "center" }}
          className="widget-header"
        >
          {widgetLabels[widgetKey.split("_")[0]]}
        </Typography>
        <Box className="widget-icon">
          <FileCopyOutlined onClick={() => handleCopyWidget(widgetKey)} />
          <IconButton
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DataGridTable
        rows={rows}
        columns={columns}
        loading={loading}
        error={error}
        menu={menu}
        sx={{
          minWidth: "1080px",
          "& .MuiDataGrid-cell": {
            border: "0.5px solid grey",
            wordWrap: "break-word",
            whiteSpace: "normal",
          },
        }}
      />
    </Box>
  );
}

export default VendorDetailMendatoryUseCaseTable;
