import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import {
 AddCircleOutline,
 RemoveCircleOutline,
 Edit,
 DeleteOutline,
 DeleteSweep,
} from "@mui/icons-material";
import "./TreeComponent.css";

const TreeComponent = ({ data, onUpdateJson,onNodeClick }) => {
 const treeRef = useRef();
  useEffect(() => {
   if (!data) return;
   let selectedNode = null;

   // const buyerGuideElementID = data[0].element_id;   
   localStorage.setItem('buyerGuideElementId',data[0].element_id);
   


   const findChildren = (node) => {
     if (!node || typeof node !== 'object') return null;
     const children = node["Use Case"];
     return {
       name: node.name,
       element_id: node.element_id,
       description: node.description || '',
       source: node.source || '',
       accepted_by: node.accepted_by || [],
       children: Array.isArray(children) ? children.map(findChildren) : []
     };
   };
  


  
   const rootData = data.map(findChildren);

   const root = d3.hierarchy(rootData[0]);



   if (root.children) {
     root.children.forEach(collapse);
   }

   const margin = { top: 80, right: 0, bottom: 0, left: 120 },
     width = treeRef.current.clientWidth - margin.left - margin.right,
     height = treeRef.current.clientHeight - margin.top - margin.bottom;

   const treeLayout = d3.tree().size([height, width]);
   treeLayout(root);

   const rectWidth = 490;
   // const rectHeight = 80;
   const verticalSpacing = 120;
   const iconSize = 25; // Size of the icons
   const iconPadding = 8; // Padding between icons and text
   const iconSpacing = iconSize + iconPadding; // Total space taken by one icon and padding
   const numberOfIcons = 6;
   const textVerticalPadding = 25;

   d3.select(treeRef.current).selectAll("*").remove();

   //Change it
   const svg = d3
     .select(treeRef.current)
     .append("svg")
     .attr("width", width + margin.right + margin.left)
     .attr("height", height + margin.top + margin.bottom)
     .call(
       d3.zoom().on("zoom", function (event) {
         svg.attr("transform", event.transform);
       })
     )
     .append("g")
     .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

   const node = svg
     .selectAll(".node")
     .data(root.descendants())
     .enter()
     .append("g")
     .attr("class", "node")
     .attr("transform", (d) => `translate(${d.y},${d.x})`);

   node
     .append("rect")
     .attr("x", 0)
     .attr("y", 0)
     .attr("width", rectWidth)
     .attr("height", (d) => {
       d.height = calculateHeight(
         d.data.name,
         textVerticalPadding,
         rectWidth,
         iconSpacing,
         numberOfIcons,
         iconPadding
       );
       return d.height;
     })
     .attr("x", 0) // Left align rect with more padding
     .attr("y", 0)
     .attr("rx", 0)
     .attr("ry", 10)
     .attr("fill", "#fff");

   appendStyledText(
     node,
     rectWidth,
     textVerticalPadding,
     iconSpacing,
     numberOfIcons,
     iconPadding
   );

   // Append the edit icon for text editing
   node
     .append("foreignObject")
     .attr("x", rectWidth - iconSpacing * 5.5)
     .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
     .attr("width", iconSize)
     .attr("height", iconSize)
     .append("xhtml:div")
     .attr("class", "edit-icon")
     .style("cursor", "pointer")
     .each(function (d) {
       ReactDOM.render(
         <Edit onClick={(event) => handleEdit(event, d)} />,
         this
       );
     });

   // Append the delete icon for node deletion
   node
     .append("foreignObject")
     .attr("x", rectWidth - iconSpacing * 4.5)
     .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
     .attr("width", iconSize)
     .attr("height", iconSize)
     .append("xhtml:div")
     .attr("class", "delete-icon")
     .style("cursor", "pointer")
     .each(function (d) {
       ReactDOM.render(
         <DeleteOutline onClick={(event) => handleDelete(event, d)} />,
         this
       );
     });

   // Append the delete all icon for node and child node deletion
   node
     .append("foreignObject")
     .attr("x", rectWidth - iconSpacing * 3.5)
     .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
     .attr("width", iconSize)
     .attr("height", iconSize)
     .append("xhtml:div")
     .attr("class", "delete-all-icon")
     .style("cursor", "pointer")
     .each(function (d) {
       ReactDOM.render(
         <DeleteSweep onClick={(event) => handleDeleteAll(event, d)} />,
         this
       );
     });

   // Append the + and - icons for expanding and collapsing nodes
   node
     .append("foreignObject")
     .attr("x", rectWidth - iconSpacing * 2.5)
     .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
     .attr("width", iconSize)
     .attr("height", iconSize)
     .append("xhtml:div")
     .attr("class", "toggle-icon")
     .style("cursor", "pointer")
     .each(function (d) {
       ReactDOM.render(
         d.children || d._children ? (
           d.children ? (
             <RemoveCircleOutline
               onClick={(event) => {
                 event.stopPropagation();
                 toggle(d);
                 updateTree(d);
               }}
             />
           ) : (
             <AddCircleOutline
               onClick={(event) => {
                 event.stopPropagation();
                 toggle(d);
                 updateTree(d);
               }}
             />
           )
         ) : null,
         this
       );
     });

   // Conditionally append the "++" icon for expanding all child nodes
   node.each(function (d) {
     if (d.children || d._children) {
       d3.select(this)
         .append("foreignObject")
         .attr("x", rectWidth - iconSpacing * 1.5)
         .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
         .attr("width", iconSize * 2)
         .attr("height", iconSize)
         .append("xhtml:div")
         .attr("class", "expand-all-icon")
         .style("cursor", "pointer")
         .each(function (d) {
           ReactDOM.render(
             <span
               onClick={(event) => {
                 event.stopPropagation();
                 expandAll(d);
                 updateTree(d);
               }}
             >
               ++
             </span>,
             this
           );
         });
     }
   });



   let index = 0;
   let previousHeight = 0;

   function setNodeX(d) {
     if (d.children) {
       d.children.forEach(setNodeX);
     }


     d.x = previousHeight + index * verticalSpacing;
     previousHeight += d.height;
     index++;
   }

   setNodeX(root);

   function centerNode(d) {
     if (d.children) {
       const firstChild = d.children[0];
       const lastChild = d.children[d.children.length - 1];
       d.x = (firstChild.x + lastChild.x) / 2;
     }
   }

   function centerAllNodes(d) {
     if (d.children) {
       d.children.forEach(centerAllNodes);
       centerNode(d);
     }
   }

   centerAllNodes(root);


   function toggle(d) {
     if (d.children) {
       d._children = d.children;
       d.children = null;
     } else {
       if (d._children) {
         d.children = d._children;
         d._children = null;
       }
     }
   }

   // Function to expand all child nodes recursively
   function expandAll(d) {
     if (d._children) {
       d.children = d._children;
       d._children = null;
     }
     if (d.children) {
       d.children.forEach(expandAll);
     }
   }

   function handleEdit(event, d) {
     event.stopPropagation();
     const node = d3.select(event.target.closest("g.node"));
     node.select("text").remove();

     const editableDiv = node
       .append("foreignObject")
       .attr("x", iconPadding)
       .attr("y", (d) => d.height / 2 - textVerticalPadding)
       .attr("width", rectWidth - iconSpacing * numberOfIcons - iconPadding)
       .attr("height", (d) => d.height / 2)
       .append("xhtml:div")
       .attr("class", "editable-div")
       .attr("contentEditable", true)
       .style("outline", "none")
       .style("border-bottom", "2px solid black")
       .style("font-size", "16px")
       .style("font-family", "Roboto, sans-serif")
       .text(d.data.name)
       .on("keydown", function (e) {
         if (e.key === "Enter") {
           e.preventDefault();
           updateNodeText(e.target.textContent, d);
         }
       })
       .on("blur", function (e) {
         updateNodeText(e.target.textContent, d);
       })
       .node();

     editableDiv.focus();

     const range = document.createRange();
     const sel = window.getSelection();
     range.selectNodeContents(editableDiv);
     range.collapse(false);
     sel.removeAllRanges();
     sel.addRange(range);
     editableDiv.addEventListener("mousedown", function (e) {
       e.stopPropagation();
     });
     editableDiv.addEventListener("click", function (e) {
       e.stopPropagation();
     });
   }

   function handleDelete(event, d) {
     event.stopPropagation();

     const parent = d.parent;
     const children = d.children ? d.children : [];

     if (parent) {
       // Remove the node to be deleted from the parent's children array
       parent.children = parent.children.filter((child) => child !== d);

       // Reattach the children of the deleted node to the parent
       if (children.length > 0) {
         parent.children = parent.children.concat(children);
       }

       // Update the JSON structure
       let target = data;
       const path = d.ancestors().reverse().slice(1); // Get the path from the root to the target node

       try {
         for (let i = 0; i < path.length - 1; i++) {
           const key = path[i].data.name;

           if (Array.isArray(target)) {
             target =
               target.find(
                 (item) => typeof item === "object" && item.hasOwnProperty(key)
               ) || target.find((item) => item === key);
           } else {
             target = target[key];
           }

           if (!target) {
             throw new Error(`Unable to find target for key: ${key}`);
           }
         }

         const lastKey = path[path.length - 1].data.name;

         if (typeof target === "object" && !Array.isArray(target)) {
           // Remove the node itself (Y in this case)
           const removedNode = target[lastKey];
           delete target[lastKey];

           // Add the children of the removed node (Z and R) to the parent node (X)
           if (removedNode && typeof removedNode === "object") {
             Object.keys(removedNode).forEach((childKey) => {
               target[childKey] = removedNode[childKey];
             });
           }
         }

         // Update the tree with the modified data
         onUpdateJson(data);
       } catch (error) {
         console.error("Error deleting node:", error.message);
       }
     }

     // Re-render the tree
     updateTree(parent || d.parent);
   }

   function handleDeleteAll(event, d) {
     event.stopPropagation();

     const parent = d.parent;

     if (parent) {
       // Remove the node and its children from the parent's children array
       parent.children = parent.children.filter((child) => child !== d);

       // Update the JSON structure
       let target = data;
       const path = d.ancestors().reverse().slice(1); // Get the path from the root to the target node

       try {
         for (let i = 0; i < path.length - 1; i++) {
           const key = path[i].data.name;

           if (Array.isArray(target)) {
             target =
               target.find(
                 (item) => typeof item === "object" && item.hasOwnProperty(key)
               ) || target.find((item) => item === key);
           } else {
             target = target[key];
           }

           if (!target) {
             throw new Error(`Unable to find target for key: ${key}`);
           }
         }

         const lastKey = path[path.length - 1].data.name;

         if (typeof target === "object" && !Array.isArray(target)) {
           // Handle deletion within an object
           if (Array.isArray(target[lastKey])) {
             // If the lastKey refers to an array, remove the entire array
             target[lastKey] = [];
           } else if (typeof target[lastKey] === "object") {
             // If the lastKey refers to an object, delete the key from the object
             delete target[lastKey];
           } else {
             // If it's a simple value, just delete the key
             delete target[lastKey];
           }
         } else if (Array.isArray(target)) {
           // If the target itself is an array, remove the element by index or key
           const index = target.findIndex(
             (item) =>
               item === d.data.name ||
               (typeof item === "object" && item.hasOwnProperty(d.data.name))
           );
           if (index > -1) {
             target.splice(index, 1);
           }
         }

         // Update the tree with the modified data
         onUpdateJson(data);

         // Re-render the tree only if parent is defined to avoid rendering deleted nodes
         if (parent) {
           updateTree(parent);
         }
       } catch (error) {
         console.error("Error deleting node:", error.message);
       }
     }
   }

   function updateNodeText(newText, d) {
     const path = d.ancestors().reverse().slice(1); // Get the path from the root to the target node
     let target = data;

     try {
       for (let i = 0; i < path.length - 1; i++) {
         const key = path[i].data.name;

         if (Array.isArray(target)) {
           target =
             target.find(
               (item) => typeof item === "object" && item.hasOwnProperty(key)
             ) || target.find((item) => item === key);
         } else {
           target = target[key];
         }

         if (!target) {
           throw new Error(`Unable to find target for key: ${key}`);
         }
       }

       const lastKey = path[path.length - 1].data.name;

       if (Array.isArray(target)) {
         const index = target.findIndex(
           (item) => typeof item === "object" && item.hasOwnProperty(lastKey)
         );

         if (index >= 0) {
           const lastValue = target[index][lastKey];
           if (typeof lastValue === "object") {
             target[index] = { [newText]: lastValue };
           } else {
             target[index] = newText;
           }
         } else {
           const valueIndex = target.indexOf(lastKey);
           if (valueIndex >= 0) {
             target[valueIndex] = newText;
           } else {
             throw new Error(
               `Unable to find item in array for key: ${lastKey}`
             );
           }
         }
       } else {
         const lastValue = target[lastKey];
         const newObject = {};

         Object.keys(target).forEach((key) => {
           if (key === lastKey) {
             newObject[newText] = lastValue;
           } else {
             newObject[key] = target[key];
           }
         });

         Object.keys(target).forEach((key) => delete target[key]);
         Object.assign(target, newObject);
       }

       onUpdateJson(data);
     } catch (error) {
       console.error("Error updating JSON:", error.message);
     }
   }

   function updateTree(source) {
     const nodes = root.descendants();
     const links = root.descendants().slice(1);

     treeLayout(root);



     nodes.forEach((d) => {
       const rootSpacing = 800;  // Adjust this for root distance
       const childSpacing = 800; // Adjust this for child distance
      
       d.y = d.depth === 1
         ? d.depth * rootSpacing // Larger space for the root's immediate children
         : d.depth * childSpacing; // Standard space for other nodes
     });
     let i = 0;

     const node = svg
       .selectAll("g.node")
       .data(nodes, (d) => d.id || (d.id = ++i));


     const nodeEnter = node.enter()
     .append("g")
     .attr("class", "node")
     .attr("transform", d => `translate(${d.y},${d.x})`);
  



nodeEnter.append("rect")
.style("stroke-width", "2px")
 .attr("width", rectWidth)
 .attr("height", d => {
   d.height = calculateHeight(d.data.name, verticalSpacing, rectWidth, iconSpacing, numberOfIcons, iconPadding);
   return d.height;
 })





 // After depth 3 you can design here ....
nodeEnter.append("text")
.attr("dx", 10)
.attr("dy", 20)
.style("stroke-width", "2px")
.text(d => d.data.name);
  
  


     nodeEnter
       .append("rect")
       .attr("width", rectWidth)
       .attr("height", (d) => {
         d.height = calculateHeight(
           d.data.name,
           verticalSpacing,
           rectWidth,
           iconSpacing,
           numberOfIcons,
           iconPadding
         )-70;
         // console.log("Height is  : ",d.height);
         return d.height-80;
         // return 129;
        
       })
       .attr("x", 0) // Left align rect with more padding
       .attr("y", 0)
       .attr("rx", 0)
       .attr("ry", 10)
       .attr("fill", "white");



     appendStyledText(
       nodeEnter,
       rectWidth,
       textVerticalPadding,
       iconSpacing,
       numberOfIcons,
       iconPadding
     );

    

     nodeEnter
       .append("foreignObject")
       .attr("x", rectWidth - iconSpacing * 5.5)
       .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
       .attr("width", iconSize)
       .attr("height", iconSize)
       .append("xhtml:div")
       .attr("class", "edit-icon")
       .style("cursor", "pointer")
       .each(function (d) {
         ReactDOM.render(
           <Edit onClick={(event) => handleEdit(event, d)} />,
           this
         );
       });

     nodeEnter
       .append("foreignObject")
       .attr("x", rectWidth - iconSpacing * 4.5)
       .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
       .attr("width", iconSize)
       .attr("height", iconSize)
       .append("xhtml:div")
       .attr("class", "delete-icon")
       .style("cursor", "pointer")
       .each(function (d) {
         ReactDOM.render(
           <DeleteOutline onClick={(event) => handleDelete(event, d)} />,
           this
         );
       });

     nodeEnter
       .append("foreignObject")
       .attr("x", rectWidth - iconSpacing * 3.5)
       .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
       .attr("width", iconSize)
       .attr("height", iconSize)
       .append("xhtml:div")
       .attr("class", "delete-all-icon")
       .style("cursor", "pointer")
       .each(function (d) {
         ReactDOM.render(
           <DeleteSweep onClick={(event) => handleDeleteAll(event, d)} />,
           this
         );
       });

     nodeEnter
       .append("foreignObject")
       .attr("x", rectWidth - iconSpacing * 2.5)
       .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
       .attr("width", iconSize)
       .attr("height", iconSize)
       .append("xhtml:div")
       .attr("class", "toggle-icon")
       .style("cursor", "pointer")
       .each(function (d) {
         ReactDOM.render(
           d.children || d._children ? (
             d.children ? (
               <RemoveCircleOutline
                 onClick={(event) => {
                   event.stopPropagation();
                   toggle(d);
                   updateTree(d);
                 }}
               />
             ) : (
               <AddCircleOutline
                 onClick={(event) => {
                   event.stopPropagation();
                   toggle(d);
                   updateTree(d);
                 }}
               />
             )
           ) : null,
           this
         );
       });

     // Conditionally adding the "++" icon for expanding all child nodes
     nodeEnter.each(function (d) {
       if (d.children || d._children) {
         d3.select(this)
           .append("foreignObject")
           .attr("x", rectWidth - iconSpacing * 1.5)
           .attr("y", (d) => d.height / 2 - iconSize / 2) // Vertically center the icon
           .attr("width", iconSize * 2)
           .attr("height", iconSize)
           .append("xhtml:div")
           .attr("class", "expand-all-icon")
           .style("cursor", "pointer")
           .each(function (d) {
             ReactDOM.render(
               <span
                 onClick={(event) => {
                   event.stopPropagation();
                   expandAll(d);
                   updateTree(d);
                 }}
               >
                 ++
               </span>,
               this
             );
           });
       }
     });

  

     previousHeight = 0;
     index = 0;
     setNodeX(root);

     centerAllNodes(root);





     // Design is for depth 0 and depth 1

const nodeUpdate = nodeEnter.merge(node);
// Set default styles for new and existing nodes
nodeUpdate.select("rect")
 .style("fill", "white")
 .style("stroke", "steelblue")  
 .style("stroke-width", "1px"); 

// Update click event on merged nodes
nodeUpdate.on("click", function(event, d) {
 // If there is a previously selected node, reset its style
 if (selectedNode) {
   selectedNode.style("stroke", "steelblue") // Reset previous node's border color
               .style("stroke-width", "2px");  // Reset previous node's border width
 }

 // Select the current node's rectangle and change its style
 const currentRect = d3.select(this).select("rect");
 currentRect.style("stroke", "#4F40BA") // Set the border color to yellow
            .style("stroke-width", "2px"); // Increase the border width to make it clearly visible

 // Update the reference to the currently selected node
 selectedNode = currentRect;

//  console.log("Updated node clicked:", d);

 if (onNodeClick) {
   onNodeClick(d.data.element_id);
 }
 event.stopPropagation(); 
});

d3.select("svg").on("click", function(event) {
 if (selectedNode) {
   selectedNode.style("stroke", "steelblue") // Reset to default border color
               .style("stroke-width", "1px");  // Reset to default border width
   selectedNode = null;  // Clear the reference to the selected node
 }
 // event.stopPropagation();  // Prevent the event from bubbling up
});

     nodeUpdate
       .transition()
       .duration(200)
       .attr("transform", (d) => `translate(${d.y},${d.x})`);



     nodeUpdate.select(".toggle-icon").each(function (d) {
       ReactDOM.render(
         d.children || d._children ? (
           d.children ? (
             <RemoveCircleOutline
               onClick={(event) => {
                 event.stopPropagation();
                 toggle(d);
                 updateTree(d);
               }}
             />
           ) : (
             <AddCircleOutline
               onClick={(event) => {
                 event.stopPropagation();
                 toggle(d);
                 updateTree(d);
               }}
             />
           )
         ) : null,
         this
       );
     });

     nodeUpdate.select(".expand-all-icon").each(function (d) {
       if (d.children || d._children) {
         ReactDOM.render(
           <span
             onClick={(event) => {
               event.stopPropagation();
               expandAll(d);
               updateTree(d);
             }}
           >
             ++
           </span>,
           this
         );
       }
     });


     node
       .exit()
       .transition()
       .duration(200)
       .attr("transform", (d) => `translate(${source.y},${source.x})`)
       .remove();



     const link = svg.selectAll("path.link").data(links, (d) => d.id);

     const linkEnter = link
       .enter()
       .insert("path", "g")
       .attr("class", "link")
       .attr("d", (d) => {
         const o = { x: source.x0, y: source.y0 };
         return diagonal(o, o);
       });

     const linkUpdate = linkEnter.merge(link);

     linkUpdate
       .transition()
       .duration(100)
       .attr("d", (d) => diagonal(d, d.parent));

     link
       .exit()
       .transition()
       .duration(100)
       .attr("d", (d) => {
         const o = { x: source.x, y: source.y };
         return diagonal(o, o);
       })
       .remove();

     nodes.forEach((d) => {
       d.x0 = d.x;
       d.y0 = d.y;
     });



     function diagonal(s, d) {
       const offsetY = rectWidth;

       const path = `M${s.y + offsetY},${s.x + s.height / 2}
                          H${s.y - rectWidth / 5}
                          V${d.x + d.height / 2}
                          H${d.y}`;
       return path;
     }
   }



   function collapse(d) {
     if (d.children) {
       d.children.forEach(collapse);
       d._children = d.children;
       d.children = null;
     }
   }

   updateTree(root);
 }, [data, onUpdateJson,onNodeClick]);

 return <div ref={treeRef} className="tree-container"></div>;
};

export default TreeComponent;







function appendStyledText(
 node,
 rectWidth,
 textVerticalPadding,
 iconSpacing,
 numberOfIcons,
 iconPadding
) {
 const lineHeight = 18; // Line height for the text
 node.each(function (d) {
   const nodeGroup = d3.select(this);
   const data = d.data; // Access the node's data
   const x = iconPadding; // X position for the text
   let y = textVerticalPadding; // Initial Y position for the text

   // Function to add and wrap text
   function addWrappedText(text, x, startY, maxWidth) {
     const words = text.split(/\s+/); // Split text into words
     let line = [];
     let lineNumber = 0;

     let textElement = nodeGroup.append("text")
       .attr("x", x)
       .attr("y", startY)
       .style("text-anchor", "start")
       .style("font-family", "Roboto, sans-serif")
       .style("font-size", "14px");

     words.forEach((word) => {
       line.push(word);
       textElement.text(line.join(" "));
       if (textElement.node().getComputedTextLength() > maxWidth) {
         line.pop(); // Remove word that overflowed
         textElement.text(line.join(" ")); // Set text without overflowed word
         line = [word]; // Start new line with overflowed word
         lineNumber++;
         textElement = nodeGroup.append("text") // Create new text element for the next line
           .attr("x", x)
           .attr("y", startY + lineNumber * lineHeight)
           .style("text-anchor", "start")
           .style("font-family", "Roboto, sans-serif")
           .style("font-size", "14px")
           .text(word);
       }
     });

     return lineHeight * (lineNumber + 1); // Return total height used by the text
   }

  
  
   // Wrap and append each piece of text
   y += addWrappedText("Name: " + data.name, x, y, rectWidth - iconSpacing * numberOfIcons - iconPadding);
   if (data.description) {
     y += addWrappedText("Description: " + data.description, x, y, rectWidth - iconSpacing * numberOfIcons - iconPadding);
   }
   if (data.source) {
     y += addWrappedText("Source: " + data.source, x, y, rectWidth - iconSpacing * numberOfIcons - iconPadding);
   }
   if (data.accepted_by && data.accepted_by.length > 0) {
     y += addWrappedText("Accepted By: " + data.accepted_by.join(", "), x, y, rectWidth - iconSpacing * numberOfIcons - iconPadding);
   }

   // Adjust the rectangle height based on the text height
   nodeGroup.select("rect").attr("height", y + textVerticalPadding-29); // Add padding below the last line
 });
}





function calculateHeight(
 text,
 textVerticalPadding,
 rectWidth,
 iconSpacing,
 numberOfIcons,
 iconPadding
) {
 //
 // Create a canvas context to measure the text width
 const context = document.createElement("canvas").getContext("2d");

 // Set the font properties
 context.font = "16px Roboto, sans-serif";

 // Function to measure the width of a text
 function measureTextWidth(text) {
   return context.measureText(text).width;
 }

 //

 const words = text.split(/\s+/);
 const textWidth = rectWidth - iconSpacing * numberOfIcons - iconPadding;
 let lineNumber = 1;
 let currentLine = "";

 words.forEach((word) => {
   const testLine = currentLine ? currentLine + " " + word : word;
   const testWidth = measureTextWidth(testLine);

   if (testWidth > textWidth) {
     lineNumber++;
     currentLine = word; // Start new line with current word
   } else {
     currentLine = testLine;
   }
 });

 return (textVerticalPadding * 2 + 12 * lineNumber);
}
