
export const pastReportsData = [
    {
        "Report Name":"NAME 1",
        "Scenario Name":"SCENARIO 1",
        "Buyer Guide Name":'BUYER GUIDE 1',
        "End-user Organisation":'ORGANISATION 1',
        "Report Date (Last updated on)":'LAST UPDATED DATE 1',
        "Report Date (Created)":'CREATION DATE 1',
        "Prepared by":'USER 1',
    },
    {
        "Report Name":"Name 2",
        "Scenario Name":"Scenario 2",
        "Buyer Guide Name":'BUYER GUIDE 2',
        "End-user Organisation":'ORGANISATION 2',
        "Report Date (Last updated on)":'LAST UPDATED DATE 2',
        "Report Date (Created)":'CREATION DATE 2',
        "Prepared by":'USER 2',
    },
    {
        "Report Name":"Name 3",
        "Scenario Name":"Scenario 3",
        "Buyer Guide Name":'BUYER GUIDE 3',
        "End-user Organisation":'ORGANISATION 3',
        "Report Date (Last updated on)":'LAST UPDATED DATE 3',
        "Report Date (Created)":'CREATION DATE 3',
        "Prepared by":'USER 3',
    }
  ];