import React, { useState, useEffect, useContext } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../../context/UserContext";
import { apiService } from "../../utils/utils.js";
import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";


// const data = {
//   'End-user Organisation Name': 'dummyEndOrg1',
//   'Buyer Guide Name': 'DummyBuyerGuide',
//   'Scenario Name': 'dummyScenarioName'
// };

const CustomRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "#4f40ba",
  },
});

const ReportOption = () => {
  const [thresholdType, setThresholdType] = useState("maximumNumberOfVendors");
  const [numVendors, setNumVendors] = useState(1);
  const [numOffersPerVendor, setNumOffersPerVendor] = useState(1);
  const [numOffers, setNumOffers] = useState(1);
  const [primaryMetric, setPrimaryMetric] = useState("offerScore");
  const isMobile = useMediaQuery("(max-width:600px)");
  const { currentOrganization, buyerGuideName, scenarioName, userProfile } =
    useContext(UserProfileContext);

  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getScenarioProperties?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: buyerGuideName,
              currentScenario: scenarioName,
              selectedOption: "Maximum Number of Vendors", // This can be dynamic if needed
            },
          }
        );
        if (response) {
          setThresholdType(
            response.selectedOption === "Maximum Number of Vendors"
              ? "maximumNumberOfVendors"
              : "maximumNumberOfOffers"
          );
          setNumVendors(response.maxNoOfVendors || 1);
          setNumOffers(response.maxNoOfOffers || 1);
          setNumOffersPerVendor(response.maxOffersPerVendor || 1);
          setPrimaryMetric(
            response.primaryMetric === "Offer Score"
              ? "offerScore"
              : "offerRelevance"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentOrg, buyerGuideName, scenarioName, userProfile]);

  const handleThresholdTypeChange = (e) => {
    setThresholdType(e.target.value);
  };

  const handlePrimaryMetricChange = (e) => {
    setPrimaryMetric(e.target.value);
  };

  const navigate = useNavigate();

  const handleSaveAndExit = async () => {
    const formData = {
      thresholdType,
      numVendors,
      numOffersPerVendor,
      numOffers,
      primaryMetric,
    };
    
    // try {
    //   await apiService(
    //     "https://newtestfuncpython.azurewebsites.net/api/updateScenarioProperties?",
    //     {
    //       method: "POST",
    //       data: {
    //         email: userProfile?.email,
    //         currentOrganisation: currentOrganization,
    //         currentBuyerGuide: buyerGuideName,
    //         currentScenario: scenarioName,
    //         primaryMetric: formData.primaryMetric === "offerScore" ? "Offer Score" : "Offer Relevance",
    //         selectedOption: formData.thresholdType === "maximumNumberOfVendors" ? "Maximum Number of Vendors" : "Maximum Number of Offers",
    //         maxNoOfVendors: formData.numVendors,
    //         maxNoOfOffers: formData.numOffers,
    //         maxOffersPerVendor: formData.numOffersPerVendor,
    //       },
    //     }
    //   );
    // } catch (error) {
    //   console.error("Error updating data:", error);
    // }

    navigate("/results/comparative-analysis", {
      state: {
        formData,
      },
    });
  };

  return (
    <>
      <HeaderNaming data={data} />
      <Box p={0}>
        <Grid container spacing={3} direction={isMobile ? "column" : "row"}>
          <Grid item xs={12} sm={5}>
            <FormControl component="fieldset">
              <Typography data-test-id="name-Shortlist Threshold Type" variant="h3">Shortlist Threshold Type</Typography>
              <RadioGroup
                value={thresholdType}
                onChange={handleThresholdTypeChange}
              >
                <FormControlLabel
                data-test-id="name-Maximum Number of Vendors"
                  value="maximumNumberOfVendors"
                  control={<CustomRadio 
                data-test-id="radio-Maximum Number of Vendors"

                     />}
                  label="Maximum Number of Vendors"
                />
                {thresholdType === "maximumNumberOfVendors" && (
                  <Box mt={2} ml={3}>
                    <TextField
                data-test-id="text-Number of vendors to be shortlisted"
                      label="Number of vendors to be shortlisted"
                      type="number"
                      value={numVendors}
                      onChange={(e) => setNumVendors(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                data-test-id="text-Maximum Number of offers per vendor to be shortlisted"
                      label="Maximum Number of offers per vendor to be shortlisted"
                      type="number"
                      value={numOffersPerVendor}
                      onChange={(e) => setNumOffersPerVendor(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                )}
                <FormControlLabel
                data-test-id="name-Maximum Number of Offers"
                  value="maximumNumberOfOffers"
                  control={<CustomRadio 
                    data-test-id="radio-Maximum Number of Offers"
                  />}
                  label="Maximum Number of Offers"
                />
                {thresholdType === "maximumNumberOfOffers" && (
                  <Box mt={2} ml={3}>
                    <TextField
                data-test-id="text-Number of offers to be shortlisted"
                      label="Number of offers to be shortlisted"
                      type="number"
                      value={numOffers}
                      onChange={(e) => setNumOffers(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Divider
            orientation={isMobile ? "horizontal" : "vertical"}
            flexItem
            variant="middle"
            sx={{ my: 2, mx: isMobile ? 0 : 2 }}
          />
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset">
              <Typography data-test-id="name-Primary Metric for Shortlisting" variant="h3">
                Primary Metric for Shortlisting
              </Typography>
              <RadioGroup
                value={primaryMetric}
                onChange={handlePrimaryMetricChange}
              >
                <FormControlLabel
                data-test-id="name-Offer Score"
                  value="offerScore"
                  control={<CustomRadio data-test-id="radio-Offer Score" />}
                  label="Offer Score"
                />
                <FormControlLabel
                data-test-id="name-Offer Relevance"
                  value="offerRelevance"
                  control={<CustomRadio data-test-id="radio-Offer Relevance"/>}
                  label="Offer Relevance"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 4,
          ml: 2,
        }}
      >
        <Button
          style={{ width: "250px" }}
          color="secondary"
          variant="contained"
          disableRipple
          onClick={handleSaveAndExit}
        >
          <Typography data-test-id="dashboard" color="white">Save & Exit to Dashboard</Typography>
        </Button>
      </Box>
    </>
  );
};

export default ReportOption;
