import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { Paper, TableContainer } from '@mui/material';
import FilterMenu from '../components/FilterMenu.jsx';
import { Snackbar, Alert } from '@mui/material';
import EnhancedTable from '../components/EnhancedNormalTable.jsx';
import {
  handleSort,
  handleFilterChange,
  getColumnUniqueValues,
  applyFilters,
  apiService,
} from '../utils/utils';
import dummyScenarios from '../pages/Utils/dummyScenarios.js';
import HeaderNaming from '../components/HeaderNaming/HeaderNaming.jsx';
import { UserProfileContext } from '../context/UserContext.jsx';
import NewScenarioModal from '../components/ScenarioModal.jsx';
import { Add } from '@mui/icons-material';

const SelectScenario = () => {
  const { purchasePackage, currentOrganization, buyerGuideName, userProfile } =
    useContext(UserProfileContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Scenario Name');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({});
  const [columns] = useState(['Scenario Name', 'status', 'Action']);
  const theme = useTheme();
  const [scenarios, setScenarios] = useState([]);
  const [isScenarioLoading, setIsScenarioLoading] = useState(false);
  const errorMessage = useRef('');
  const [sectionData, setSectionData] = useState(
    dummyScenarios.map((scenario) => ({
      'Scenario Name': scenario.scenario,
      Status: scenario.status,
      action: scenario.action,
    }))
  );
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [newScenarioName, setNewScenarioName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [, setMessage] = useState('');

  const data = {
    'End-user Organisation Name':
      userProfile?.profileType === 'endUser'
        ? userProfile?.companyName
        : currentOrganization,
    'Buyer Guide Name': buyerGuideName,
    'Purchased Package': '',
  };

  const section = {
    title: 'Scenarios',
    data: scenarios && scenarios?.length > 0 ? scenarios : [],
    columns: columns,
  };

  const currentOrg =
    userProfile && userProfile.profileType === 'endUser'
      ? userProfile.companyName
      : currentOrganization;

  const getScenariosList = useCallback(() => {
    apiService(
      `https://newtestfuncpython.azurewebsites.net/api/getAllScenarios?`,
      {
        method: 'POST',
        data: {
          email: userProfile?.email,
          currentBuyerGuide: buyerGuideName,
          currentOrganisation: currentOrg,
        },
      }, // Options, if needed
      setIsScenarioLoading,
      setScenarios,
      (error) => {
        // Handle error if needed
        console.error('Failed to load base use cases:', error);
      }
    );
  }, [userProfile?.email, currentOrg, buyerGuideName]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedData = handleSort(
      scenarios && scenarios?.length > 0 ? scenarios : sectionData,
      property,
      sortOrder,
      setSortOrder
    );
    setSectionData(sortedData);
  };

  const handleFilterIconClick = (event, column) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterColumn(column);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterColumn('');
  };

  useEffect(() => {
    getScenariosList();
  }, [getScenariosList, triggerRefresh]);

  useEffect(() => {
    const filteredData = applyFilters(
      scenarios && scenarios?.length > 0
        ? scenarios
        : dummyScenarios.map((scenario) => ({
            'Scenario Name': scenario.scenario,
            Status: scenario.status,
            action: scenario.action,
          })),
      columns,
      filters
    );
    setSectionData(filteredData);
  }, [filters, columns, scenarios]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const addNewScenario = async () => {
    try {
      await apiService(
        'https://newtestfuncpython.azurewebsites.net/api/createNewScenario?',
        {
          method: 'POST',
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: buyerGuideName,
            newScenarioName: newScenarioName,
          },
        },
        setIsAdding,
        setMessage,
        (error) => {
          // Handle error if needed
          console.error('Failed to add new scenario', error);
        }
      );
      setOpenModal(false);
      setNewScenarioName('');
      setTriggerRefresh(!triggerRefresh);
    } catch (error) {
      console.log(error.response);
      errorMessage.current = error.response.data.message;
      setSnackbarOpen(true);
    }
  };
  return (
    <div>
      <NewScenarioModal
        data-test-id="create-scenerio-box"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={addNewScenario}
        value={newScenarioName}
        setValue={setNewScenarioName}
        title="Create New scenario"
        label="New Scenario Name"
        isAdding={isAdding}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: '100%' }}
        >
          {errorMessage.current}
        </Alert>
      </Snackbar>
      <HeaderNaming data-cy="header-name" data={data} />

      <TableContainer
        component={Paper}
        sx={{ backgroundColor: 'transparent', boxShadow: 3, padding: '10px' }}
      >
        {section?.data?.length > 0 && (
          <Button
            data-cy="Create-Button"
            variant="contained"
            color="primary"
            sx={{
              padding: '5px 40px',
              marginBottom: '10px',
            }}
            onClick={() => setOpenModal(true)}
            startIcon={<Add />}
          >
            Create
          </Button>
        )}
        {!isScenarioLoading ? (
          section?.data?.length > 0 ? (
            <EnhancedTable
              section={section}
              theme={theme}
              onSort={handleRequestSort}
              sortOrder={sortOrder}
              filters={filters}
              onFilterIconClick={handleFilterIconClick}
              ischeck={false} // No checkboxes
              showIcons={true} // Show action icons
              selectedGuides={[]} // Placeholder
              handleCheckboxChange={() => {}} // Placeholder
              handleSelectAllChange={() => {}} // Placeholder
              selectAllChecked={false} // Placeholder
              purchasePackage={purchasePackage} // Pass the purchasePackage value
              triggerRefresh={() => setTriggerRefresh(!triggerRefresh)} // Pass the refresh function
            />
          ) : (
            <Box
              display="flex"
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              gap={3}
            >
              <Typography data-test-id="no-scanerio" variant="body1">
                {' '}
                You do not have any scenarios. Please create one to get started.
              </Typography>
              <Button
              data-cy="Create-Button"
                variant="contained"
                color="primary"
                sx={{
                  padding: '5px 40px',
                  marginBottom: '10px',
                }}
                onClick={() => setOpenModal(true)}
                startIcon={<Add />}
              >
                Create
              </Button>
            </Box>
          )
        ) : (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
          >
            <CircularProgress />
          </Box>
        )}
      </TableContainer>

      <FilterMenu
        filterAnchorEl={filterAnchorEl}
        filterColumn={filterColumn}
        filters={filters}
        onFilterChange={(column, value) =>
          handleFilterChange(column, value, setFilters)
        }
        onClose={handleFilterClose}
        getColumnUniqueValues={(column) =>
          getColumnUniqueValues(column, section)
        }
      />
    </div>
  );
};

export default SelectScenario;
