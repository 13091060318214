import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import DropdownSelect from "../DropdownSelect.jsx";
import { useMemo } from "react";

const DropdownRenderCellWithRowAdditionButton = ({
  params,
  inputsLabel,
  sectionData,
  setSectionData,
}) => {
  const sizeOfSectionData = sectionData?.length;

  const { row: rowData } = params;

  const penultimateRow = sectionData[sizeOfSectionData - 2];
  const thirdLastRow = sectionData[sizeOfSectionData - 3];
  const listOfVendors = sectionData[sizeOfSectionData - 1]?.listOfVendors;

  const showButton = useMemo(() => {
    const penultimateRowHasValues =
      penultimateRow.solo_starter_total > 0 ||
      penultimateRow.duel_dynamics_total > 0 ||
      penultimateRow.focus_five_total > 0 ||
      penultimateRow.all_available_total > 0;

    const thirdLastRowHasValues =
      thirdLastRow.solo_starter_entered > 0 ||
      thirdLastRow.duel_dynamics_entered > 0 ||
      thirdLastRow.focus_five_entered > 0 ||
      thirdLastRow.all_available_entered > 0;

    return (
      rowData[inputsLabel] !== "User Defined" &&
      rowData[inputsLabel] !== "Total" &&
      thirdLastRow.id === rowData.id &&
      penultimateRowHasValues &&
      thirdLastRowHasValues
    );
  }, [rowData, inputsLabel, penultimateRow, thirdLastRow]);

  const handleRowAddition = () => {
    const currentIdNumber = parseInt(thirdLastRow.id.replace(/^\D+/g, ""), 10);
    const newIdPrefix = inputsLabel === "buyer_guide_name" ? "bu" : "vu";
    const newId = `${newIdPrefix}${currentIdNumber + 1}`;

    let dropdownList = { ...rowData[inputsLabel], selected: "None" };

    if (inputsLabel === "vendor_name") {
      const alreadySelectedVendors = sectionData
        ?.filter((el) => el.id?.length && el[inputsLabel].selected?.name)
        ?.map((el) => el[inputsLabel].selected.name);

      const latestOptions = listOfVendors?.filter(
        (vendor) => !alreadySelectedVendors?.includes(vendor.name)
      );
      dropdownList.options = latestOptions;
    }

    const newRowWithAllFieldsZero = {
      id: newId,
      [inputsLabel]: dropdownList,
      solo_starter_entered: 0,
      duel_dynamics_entered: 0,
      focus_five_entered: 0,
      all_available_entered: 0,
      solo_starter_total: penultimateRow.solo_starter_total > 0 ? 1 : 0,
      duel_dynamics_total: penultimateRow.duel_dynamics_total > 0 ? 1 : 0,
      focus_five_total: penultimateRow.focus_five_total > 0 ? 1 : 0,
      all_available_total: penultimateRow.all_available_total > 0 ? 1 : 0,
    };
    setSectionData(
      sectionData.toSpliced(sizeOfSectionData - 2, 0, newRowWithAllFieldsZero)
    );
  };

  return (
    <Box
      sx={{
        width: "350px",
        height: "100%",
        display: "flex",
        position: "relative",
        alignItems: "center",
      }}
    >
      <DropDownRenderCell
        params={params}
        sectionData={sectionData}
        setSectionData={setSectionData}
        inputsLabel={inputsLabel}
      />
      {showButton &&
        // For Vendor Unlock page if the dropdown Options are exhalistOfVendorsusted then don't show add row button
        (inputsLabel === "vendor_name"
          ? sectionData?.length - 2 !==
            sectionData[sectionData?.length - 1]?.listOfVendors?.length
          : true) && (
          <Button
            disableRipple
            variant="text"
            sx={{
              width: 30,
              height: 30,
              minWidth: 0,
              cursor: "pointer",
            }}
            onClick={handleRowAddition}
          >
            <AddIcon color="primary" />
          </Button>
        )}
    </Box>
  );
};

export default DropdownRenderCellWithRowAdditionButton;

const DropDownRenderCell = ({
  params,
  sectionData,
  setSectionData,
  inputsLabel,
}) => {
  const handleSelectChange = () => {
    //
    const listOfVendors =
      sectionData[sectionData?.length - 1]?.listOfVendors || [];

    // Collect all already selected vendors, including the current one
    const alreadySelectedVendors = [
      ...(params.value?.selected?.name ? [params.value.selected.name] : []),
      ...sectionData
        ?.filter(
          (el) =>
            el.id !== params.row.id &&
            el.id?.length &&
            el[inputsLabel]?.selected?.name
        )
        ?.map((el) => el[inputsLabel].selected.name),
    ];
    //

    // Memoize the filtered list of available vendors
    const filteredOptions = listOfVendors?.filter(
      (vendor) => !alreadySelectedVendors?.includes(vendor.name)
    );
    //

    setSectionData(
      sectionData?.map((el) => {
        if (el.id === params.row.id) {
          return { ...el, [inputsLabel]: params.value };
        }

        //For all the other Rows
        else if (
          el.id?.length &&
          el.id !== params.row.id &&
          inputsLabel === "vendor_name"
        ) {
          //We Need to add the selected vendor to the list so that it remains selected
          const selectedVendor = el[inputsLabel]?.selected;

          const updatedOptions =
            selectedVendor !== "None"
              ? [...filteredOptions, selectedVendor].sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
              : filteredOptions;

          return {
            ...el,
            [inputsLabel]: {
              ...el[inputsLabel],
              options: updatedOptions,
            },
          };
        }
        return el;
      })
    );
  };

  if (params.value === "User Defined" || params.value === "Total")
    return <Typography>{params.value}</Typography>;

  return <DropdownSelect unitsData={params.value} onSet={handleSelectChange} />;
};
