import React from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box } from '@mui/material';

const CustomName = ({ name, isError }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {isError && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WarningAmberRoundedIcon
            color="warning"
            style={{ marginRight: '20px' }}
          />
          <span>{name}</span>
        </Box>
      )}
      {!isError && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>{name}</span>
        </Box>
      )}
    </Box>
  );
};

export default CustomName;
