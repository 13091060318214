import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';

const ListToggleWidget = ({name,items = [], count }) => {
  const [viewMode, setViewMode] = useState("both"); // Default is "both"
  const [dynamicHeight,setDynamicHeight] = useState(140)
  useEffect(()=>{
     if(items.length>0)
     { 
        setDynamicHeight(()=>{
           return (140+(items.length)*70);
        })
      }
  },[items])
  const handleToggleView = (mode) => {
    setViewMode(mode);
  };

  return (
    <Box style={{paddingTop:"35px", paddingBottom:"35px" , height: `${dynamicHeight}px`}} sx={{ p: 1, border: "1px solid #ccc", borderRadius: 2 , marginLeft:0.5, marginRight:0.5}}>
      <Box sx={{ display: "flex", justifyContent: "space-between" , height:50}}>
        <IconButton
         data-test-id={`viewmodule-${name}`}
          color={viewMode === "count" ? "primary" : "default"}
          onClick={() => handleToggleView("count")}
        >
          <ViewModuleIcon />
        </IconButton>
        <IconButton
         data-test-id={`viewlist-${name}`}
          color={viewMode === "list" ? "primary" : "default"}
          onClick={() => handleToggleView("list")}
        >
          <ViewListIcon />
        </IconButton>
        <IconButton
                  data-test-id={`viewcomfy-${name}`}

          color={viewMode === "both" ? "primary" : "default"}
          onClick={() => handleToggleView("both")}
        >
          <ViewComfyIcon />
        </IconButton>
      </Box>

      {viewMode === "count" && (
        <Typography data-test-id={`count-${name}`} variant="h6">Count: {count}</Typography>
      )}
      {viewMode === "list" && (
        <List data-test-id={`list-${name}`}>
          {items.map((item, index) => (
            <ListItem key={index}>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      )}
      {viewMode === "both" && (
        <>
          <Box data-test-id={`count-${name}`} style={{fontSize:"20px", height:"20px" ,lineHeight:"20px", fontWeight:"600"}}>
          Count: {count}
          </Box>
          <List style={{ padding:"0px"}} data-test-id={`list-${name}`}>
            {items.map((item, index) => (
              <ListItem style={{height:"70px" , padding:"0px"}} key={index}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

ListToggleWidget.propTypes = {
  items: PropTypes.array,
  count: PropTypes.number.isRequired,
};

export default ListToggleWidget;
