import React, { useEffect, useState } from "react";

import { Box, Checkbox, Tooltip } from "@mui/material";

const CustomCheckbox = ({ isDisabled, max, rowData, inputsLabel, onSet }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (rowData[inputsLabel] > 0) setChecked(true);
    else setChecked(false);
  }, [rowData, inputsLabel]);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    rowData[inputsLabel] = isChecked ? 1 : 0;
    onSet();
  };

  if (max === 0 || isDisabled)
    return (
      <Tooltip
        title={
          max !== 0
            ? "Please fill all the Required Fields"
            : `You don't have any Valid package Unit for Allocation`
        }
      >
        <Box>
          <Checkbox
            checked={false}
            onChange={handleChange}
            disabled
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
      </Tooltip>
    );

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      // onBlur={handleBlur}
      inputProps={{ "aria-label": "controlled" }}
      color="secondary"
    />
  );
};

export default CustomCheckbox;
