import React, { useEffect, useState, useCallback, useMemo, Suspense } from "react";
import {
    Container,

    Typography,
    Box,
    CircularProgress,
    IconButton,
    Collapse,
    ThemeProvider,
    CssBaseline
} from "@mui/material";

import { ExpandMore, ChevronRight } from "@mui/icons-material";
import SubscriptionOverviewUnits from "./Widget/MySubscription/FunnelCharts/SubscriptionOverviewUnits";
import SubscriptionPendingAction from "./Widget/MySubscription/FunnelCharts/SubscriptionPendingAction";
import SubscriptionTimeLine from "./Widget/MySubscription/Calander/SubscriptionTimeLine";
import OverviewUsageTable from "./Widget/MySubscription/MySubscriptionTables/OverviewUsageTable";
import UsageDetailTable from "./Widget/MySubscription/MySubscriptionTables/UsageDetailTable";
import CostAndRenewal from "./Widget/MySubscription/MySubscriptionTables/CostAndRenewal";
import theme from "../theme/theme";
const sections = [
    "Subscription Units Overview",
    "Subscription Units, Pending Actions",
    "Subscription Usage Overview",
    "Subscription Usage Details",
    "Subscription Timelines & Costs",
    "Subscription Cost & Renewal"
];
const ChartSection = [
    "Subscription Units Overview",
    "Subscription Units, Pending Actions",
]
const packageNames = [
    "Solo Starter",
    "Duel Dynamics",
    "Focus Five",
    "All Available",
]
const SubscriptionDetails = () => {
    const [loading, setLoading] = useState(false);
    //const [subscriptions, setSubscriptions] = useState([]);
    const [accordionState, setAccordionState] = useState({});
    const [error, setError] = useState(null);
    const [dummySubscriptionData, setDummySubscription] = useState(null);
    const [fetchedData, setFetchedData] = useState(null);
    const dummySubscriptionOverviewUnits = useMemo(() => ([
        {
            "package": "Solo Starter",
            "users": [
                {
                    "name": "Self",
                    "purchased": 100,
                    "allocated": 50,
                    "claimed": 30,
                    "used": 20,
                    "previewed": 10,
                    "finalised": 5
                },
                {
                    "name": "Other 1",
                    "purchased": 90,
                    "allocated": 45,
                    "claimed": 25,
                    "used": 15,
                    "previewed": 8,
                    "finalised": 4
                },
                {
                    "name": "Other 2",
                    "purchased": 80,
                    "allocated": 40,
                    "claimed": 20,
                    "used": 10,
                    "previewed": 5,
                    "finalised": 3
                },
                {
                    "name": "Other n",
                    "purchased": 70,
                    "allocated": 35,
                    "claimed": 15,
                    "used": 8,
                    "previewed": 4,
                    "finalised": 2
                }
            ]
        },
        {
            "package": "Duel Dynamics",
            "users": [
                {
                    "name": "Self",
                    "purchased": 60,
                    "allocated": 30,
                    "claimed": 18,
                    "used": 10,
                    "previewed": 6,
                    "finalised": 3
                },
                {
                    "name": "Other 1",
                    "purchased": 50,
                    "allocated": 25,
                    "claimed": 15,
                    "used": 8,
                    "previewed": 5,
                    "finalised": 2
                },
                {
                    "name": "Other 2",
                    "purchased": 40,
                    "allocated": 20,
                    "claimed": 12,
                    "used": 6,
                    "previewed": 3,
                    "finalised": 1
                },
                {
                    "name": "Other n",
                    "purchased": 30,
                    "allocated": 15,
                    "claimed": 9,
                    "used": 4,
                    "previewed": 2,
                    "finalised": 1
                }
            ]
        },
        {
            "package": "Focus Five",
            "users": [
                {
                    "name": "Self",
                    "purchased": 20,
                    "allocated": 10,
                    "claimed": 6,
                    "used": 3,
                    "previewed": 2,
                    "finalised": 1
                },
                {
                    "name": "Other 1",
                    "purchased": 15,
                    "allocated": 8,
                    "claimed": 5,
                    "used": 2,
                    "previewed": 1,
                    "finalised": 1
                },
                {
                    "name": "Other 2",
                    "purchased": 12,
                    "allocated": 6,
                    "claimed": 4,
                    "used": 1,
                    "previewed": 1,
                    "finalised": 1
                },
                {
                    "name": "Other n",
                    "purchased": 10,
                    "allocated": 5,
                    "claimed": 3,
                    "used": 1,
                    "previewed": 1,
                    "finalised": 0
                }
            ]
        },
        {
            "package": "All Available",
            "users": [
                {
                    "name": "Self",
                    "purchased": 5,
                    "allocated": 3,
                    "claimed": 2,
                    "used": 1,
                    "previewed": 0,
                    "finalised": 0
                },
                {
                    "name": "Other 1",
                    "purchased": 4,
                    "allocated": 2,
                    "claimed": 1,
                    "used": 0,
                    "previewed": 0,
                    "finalised": 0
                },
                {
                    "name": "Other 2",
                    "purchased": 3,
                    "allocated": 1,
                    "claimed": 0,
                    "used": 0,
                    "previewed": 0,
                    "finalised": 0
                },
                {
                    "name": "Other n",
                    "purchased": 2,
                    "allocated": 1,
                    "claimed": 0,
                    "used": 0,
                    "previewed": 0,
                    "finalised": 0
                }
            ]
        }
    ]), [])

    const dummySubscriptionUnitsPendingActions = useMemo(() => ([
        {
            "package": "Solo Starter",
            "users": [
                {
                    "name": "Self",
                    "purchased": 100,
                    "unallocated": 25,
                    "unclaimed": 20,
                    "unused": 20,
                    "notPreviewedYet": 10,
                    "notFinalisedYet": 10
                },
                {
                    "name": "Other 1",
                    "purchased": 90,
                    "unallocated": 25,
                    "unclaimed": 15,
                    "unused": 10,
                    "notPreviewedYet": 5,
                    "notFinalisedYet": 5
                },
                {
                    "name": "Other 2",
                    "purchased": 80,
                    "unallocated": 30,
                    "unclaimed": 25,
                    "unused": 20,
                    "notPreviewedYet": 15,
                    "notFinalisedYet": 10
                },
                {
                    "name": "Other n",
                    "purchased": 70,
                    "unallocated": 20,
                    "unclaimed": 10,
                    "unused": 10,
                    "notPreviewedYet": 5,
                    "notFinalisedYet": 5
                }
            ]
        },
        {
            "package": "Duel Dynamics",
            "users": [
                {
                    "name": "Self",
                    "purchased": 60,
                    "unallocated": 15,
                    "unclaimed": 10,
                    "unused": 10,
                    "notPreviewedYet": 5,
                    "notFinalisedYet": 5
                },
                {
                    "name": "Other 1",
                    "purchased": 50,
                    "unallocated": 10,
                    "unclaimed": 8,
                    "unused": 5,
                    "notPreviewedYet": 3,
                    "notFinalisedYet": 2
                },
                {
                    "name": "Other 2",
                    "purchased": 40,
                    "unallocated": 10,
                    "unclaimed": 5,
                    "unused": 3,
                    "notPreviewedYet": 2,
                    "notFinalisedYet": 1
                },
                {
                    "name": "Other n",
                    "purchased": 30,
                    "unallocated": 5,
                    "unclaimed": 3,
                    "unused": 2,
                    "notPreviewedYet": 1,
                    "notFinalisedYet": 1
                }
            ]
        },
        {
            "package": "Focus Five",
            "users": [
                {
                    "name": "Self",
                    "purchased": 20,
                    "unallocated": 10,
                    "unclaimed": 6,
                    "unused": 3,
                    "notPreviewedYet": 2,
                    "notFinalisedYet": 1
                },
                {
                    "name": "Other 1",
                    "purchased": 15,
                    "unallocated": 8,
                    "unclaimed": 5,
                    "unused": 2,
                    "notPreviewedYet": 1,
                    "notFinalisedYet": 1
                },
                {
                    "name": "Other 2",
                    "purchased": 12,
                    "unallocated": 6,
                    "unclaimed": 4,
                    "unused": 1,
                    "notPreviewedYet": 1,
                    "notFinalisedYet": 1
                },
                {
                    "name": "Other n",
                    "purchased": 10,
                    "unallocated": 5,
                    "unclaimed": 3,
                    "unused": 1,
                    "notPreviewedYet": 1,
                    "notFinalisedYet": 0
                }
            ]
        },
        {
            "package": "All Available",
            "users": [
                {
                    "name": "Self",
                    "purchased": 5,
                    "unallocated": 3,
                    "unclaimed": 2,
                    "unused": 1,
                    "notPreviewedYet": 0,
                    "notFinalisedYet": 0
                },
                {
                    "name": "Other 1",
                    "purchased": 4,
                    "unallocated": 2,
                    "unclaimed": 1,
                    "unused": 0,
                    "notPreviewedYet": 0,
                    "notFinalisedYet": 0
                },
                {
                    "name": "Other 2",
                    "purchased": 3,
                    "unallocated": 1,
                    "unclaimed": 0,
                    "unused": 0,
                    "notPreviewedYet": 0,
                    "notFinalisedYet": 0
                },
                {
                    "name": "Other n",
                    "purchased": 2,
                    "unallocated": 1,
                    "unclaimed": 0,
                    "unused": 0,
                    "notPreviewedYet": 0,
                    "notFinalisedYet": 0
                }
            ]
        }
    ]), [])
    const dummySubscriptionUsageOverview = useMemo(() => [
        {
            "package": "Solo Starter",
            "users": [
                {
                    "user": 10,
                    "endUserOrganisation": 5,
                    "buyerGuide": 3,
                    "totalUnits": 150,
                    "unlockedVendors": 2
                }
            ]
        },
        {
            "package": "Duel Dynamics",
            "users": [
                {
                    "user": 15,
                    "endUserOrganisation": 7,
                    "buyerGuide": 4,
                    "totalUnits": 420,
                    "unlockedVendors": 3
                }
            ]
        },
        {
            "package": "Focus Five",
            "users": [
                {
                    "user": 20,
                    "endUserOrganisation": 10,
                    "buyerGuide": 5,
                    "totalUnits": 1000,
                    "unlockedVendors": 4
                }
            ]
        },
        {
            "package": "All Available",
            "users": [
                {
                    "user": 25,
                    "endUserOrganisation": 12,
                    "buyerGuide": 6,
                    "totalUnits": 1800,
                    "unlockedVendors": 5
                }
            ]
        }
    ], [])

    const dummySubscriptionUsageDetails = useMemo(() => ([
        {
            "package": "Solo Starter",
            "users": [
                {
                    "userName": "User A",
                    "endUserOrganisationName": "Org A",
                    "buyerGuideName": "Guide 1",
                    "unlockedVendorNames": "Vendor X, Vendor Y"
                },
                {
                    "userName": "User B",
                    "endUserOrganisationName": "Org B",
                    "buyerGuideName": "Guide 2",
                    "unlockedVendorNames": "Vendor Z, Vendor W"
                },
                {
                    "userName": "User C",
                    "endUserOrganisationName": "Org C",
                    "buyerGuideName": "Guide 3",
                    "unlockedVendorNames": "Vendor P, Vendor Q"
                },
                {
                    "userName": "User D",
                    "endUserOrganisationName": "Org D",
                    "buyerGuideName": "Guide 4",
                    "unlockedVendorNames": "Vendor L, Vendor M"
                }
            ]
        },
        {
            "package": "Duel Dynamics",
            "users": [
                {
                    "userName": "User E",
                    "endUserOrganisationName": "Org E",
                    "buyerGuideName": "Guide 5",
                    "unlockedVendorNames": "Vendor N, Vendor O"
                },
                {
                    "userName": "User F",
                    "endUserOrganisationName": "Org F",
                    "buyerGuideName": "Guide 6",
                    "unlockedVendorNames": "Vendor P, Vendor Q"
                },
                {
                    "userName": "User G",
                    "endUserOrganisationName": "Org G",
                    "buyerGuideName": "Guide 7",
                    "unlockedVendorNames": "Vendor R, Vendor S"
                },
                {
                    "userName": "User H",
                    "endUserOrganisationName": "Org H",
                    "buyerGuideName": "Guide 8",
                    "unlockedVendorNames": "Vendor T, Vendor U"
                }
            ]
        },
        {
            "package": "Focus Five",
            "users": [
                {
                    "userName": "User I",
                    "endUserOrganisationName": "Org I",
                    "buyerGuideName": "Guide 9",
                    "unlockedVendorNames": "Vendor V, Vendor W"
                },
                {
                    "userName": "User J",
                    "endUserOrganisationName": "Org J",
                    "buyerGuideName": "Guide 10",
                    "unlockedVendorNames": "Vendor X, Vendor Y"
                },
                {
                    "userName": "User K",
                    "endUserOrganisationName": "Org K",
                    "buyerGuideName": "Guide 11",
                    "unlockedVendorNames": "Vendor Z, Vendor A"
                },
                {
                    "userName": "User L",
                    "endUserOrganisationName": "Org L",
                    "buyerGuideName": "Guide 12",
                    "unlockedVendorNames": "Vendor B, Vendor C"
                }
            ]
        },
        {
            "package": "All Available",
            "users": [
                {
                    "userName": "User M",
                    "endUserOrganisationName": "Org M",
                    "buyerGuideName": "Guide 13",
                    "unlockedVendorNames": "Vendor D, Vendor E"
                },
                {
                    "userName": "User N",
                    "endUserOrganisationName": "Org N",
                    "buyerGuideName": "Guide 14",
                    "unlockedVendorNames": "Vendor F, Vendor G"
                },
                {
                    "userName": "User O",
                    "endUserOrganisationName": "Org O",
                    "buyerGuideName": "Guide 15",
                    "unlockedVendorNames": "Vendor H, Vendor I"
                },
                {
                    "userName": "User P",
                    "endUserOrganisationName": "Org P",
                    "buyerGuideName": "Guide 16",
                    "unlockedVendorNames": "Vendor J, Vendor K"
                }
            ]
        }
    ]), []);

    const dummySubscriptionTimelinesandCost = useMemo(() => ([
        {
            "package": "Solo Starter",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "start_date": "2024-03-01",
                    "end_date": "2024-04-01",
                    "total_units": 1,
                    "cost": 29.99
                },
                {
                    "type": "Monthly",
                    "start_date": "2024-05-01",
                    "end_date": "2024-06-01",
                    "total_units": 345,
                    "cost": 39.99
                },
                {
                    "type": "Yearly",
                    "start_date": "2024-04-01",
                    "end_date": "2025-04-01",
                    "total_units": 876,
                    "cost": 399.99
                }
            ]
        },
        {
            "package": "Dual Dynamics",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "start_date": "2024-04-01",
                    "end_date": "2024-05-01",
                    "total_units": 732,
                    "cost": 59.99
                },
                {
                    "type": "Monthly",
                    "start_date": "2024-06-01",
                    "end_date": "2024-07-01",
                    "total_units": 1,
                    "cost": 49.99
                },
                {
                    "type": "Yearly",
                    "start_date": "2024-03-01",
                    "end_date": "2025-03-01",
                    "total_units": 512,
                    "cost": 549.99
                }
            ]
        },
        {
            "package": "Focus Five",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "start_date": "2024-07-01",
                    "end_date": "2024-08-01",
                    "total_units": 653,
                    "cost": 79.99
                },
                {
                    "type": "Yearly",
                    "start_date": "2024-01-01",
                    "end_date": "2025-01-01",
                    "total_units": 1,
                    "cost": 69.99
                }
            ]
        },
        {
            "package": "All Available",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "start_date": "2024-02-01",
                    "end_date": "2024-03-01",
                    "total_units": 1,
                    "cost": 99.99
                },
                {
                    "type": "Monthly",
                    "start_date": "2024-03-01",
                    "end_date": "2024-04-01",
                    "total_units": 798,
                    "cost": 109.99
                },
                {
                    "type": "Yearly",
                    "start_date": "2024-01-01",
                    "end_date": "2025-01-01",
                    "total_units": 461,
                    "cost": 999.99
                }
            ]
        }
    ]), []);
    const dummySubscriptionCostandRenewal = useMemo(() => ([
        {
            "package": "Solo Starter",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "purchasePrice": 29.99,
                    "renewalDate": "2024-04-01",
                    "daysToRenewal": 30,
                    "renewalPrice": 29.99,
                    "units": 1,
                    "action": "None (Automatic Renewals)"
                },
                {
                    "type": "Monthly",
                    "purchasePrice": 39.99,
                    "renewalDate": "2024-06-01",
                    "daysToRenewal": 30,
                    "renewalPrice": 39.99,
                    "units": 345,
                    "action": "None (Automatic Renewals)"
                },
                {
                    "type": "Yearly",
                    "purchasePrice": 399.99,
                    "renewalDate": "2025-04-01",
                    "daysToRenewal": 365,
                    "renewalPrice": 399.99,
                    "units": 876,
                    "action": "Renew Now / Change to Automatic Renewals"
                }
            ]
        },
        {
            "package": "Dual Dynamics",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "purchasePrice": 59.99,
                    "renewalDate": "2024-05-01",
                    "daysToRenewal": 30,
                    "renewalPrice": 59.99,
                    "units": 732,
                    "action": "None (Automatic Renewals)"
                },
                {
                    "type": "Monthly",
                    "purchasePrice": 49.99,
                    "renewalDate": "2024-07-01",
                    "daysToRenewal": 30,
                    "renewalPrice": 49.99,
                    "units": 1,
                    "action": "None (Automatic Renewals)"
                },
                {
                    "type": "Yearly",
                    "purchasePrice": 549.99,
                    "renewalDate": "2025-03-01",
                    "daysToRenewal": 365,
                    "renewalPrice": 549.99,
                    "units": 512,
                    "action": "Renew Now / Change to Automatic Renewals"
                }
            ]
        },
        {
            "package": "Focus Five",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "purchasePrice": 79.99,
                    "renewalDate": "2024-08-01",
                    "daysToRenewal": 30,
                    "renewalPrice": 79.99,
                    "units": 653,
                    "action": "None (Automatic Renewals)"
                },
                {
                    "type": "Yearly",
                    "purchasePrice": 69.99,
                    "renewalDate": "2025-01-01",
                    "daysToRenewal": 365,
                    "renewalPrice": 69.99,
                    "units": 1,
                    "action": "Renew Now / Change to Automatic Renewals"
                }
            ]
        },
        {
            "package": "All Available",
            "subscriptions": [
                {
                    "type": "Monthly",
                    "purchasePrice": 99.99,
                    "renewalDate": "2024-03-01",
                    "daysToRenewal": 30,
                    "renewalPrice": 99.99,
                    "units": 1,
                    "action": "None (Automatic Renewals)"
                },
                {
                    "type": "Monthly",
                    "c": 109.99,
                    "renewalDate": "2024-04-01",
                    "daysToRenewal": 30,
                    "renewalPrice": 109.99,
                    "units": 798,
                    "action": "None (Automatic Renewals)"
                },
                {
                    "type": "Yearly",
                    "purchasePrice": 999.99,
                    "renewalDate": "2025-01-01",
                    "daysToRenewal": 365,
                    "renewalPrice": 999.99,
                    "units": 461,
                    "action": "Renew Now / Change to Automatic Renewals"
                }
            ]
        }
    ]), []);;
    useEffect(() => {
        const fetchSubscriptions = async () => {
            setLoading(true);
            setError(null);
            try {
                let initialFetchedData = {
                    "Subscription Units Overview": dummySubscriptionOverviewUnits,
                    "Subscription Units, Pending Actions": dummySubscriptionUnitsPendingActions,
                    "Subscription Usage Overview": dummySubscriptionUsageOverview,
                    "Subscription Usage Details": dummySubscriptionUsageDetails,
                    "Subscription Timelines & Costs": dummySubscriptionTimelinesandCost,
                    "Subscription Cost & Renewal": dummySubscriptionCostandRenewal
                }
                let initialData = {
                    "Subscription Units Overview": dummySubscriptionOverviewUnits.reduce((acc, item) => {
                        return {
                            ...acc, [item.package]: item.users.reduce((acc, item) => {
                                return {
                                    purchased: acc.purchased + item.purchased,
                                    allocated: acc.allocated + item.allocated,
                                    claimed: acc.claimed + item.claimed,
                                    used: acc.used + item.used,
                                    previewed: acc.previewed + item.previewed,
                                    finalised: acc.finalised + item.finalised
                                }
                            }, {
                                purchased: 0,
                                allocated: 0,
                                claimed: 0,
                                used: 0,
                                previewed: 0,
                                finalised: 0
                            })
                        }
                    }, {}),
                    "Subscription Units, Pending Actions": dummySubscriptionUnitsPendingActions.reduce((acc, item) => {
                        return {
                            ...acc, [item.package]: item.users.reduce((acc, item) => {
                                return {
                                    purchased: acc.purchased + item.purchased,
                                    unallocated: acc.unallocated + item.unallocated,
                                    unclaimed: acc.unclaimed + item.unclaimed,
                                    unused: acc.unused + item.unused,
                                    notPreviewedYet: acc.notPreviewedYet + item.notPreviewedYet,
                                    notFinalisedYet: acc.notFinalisedYet + item.notFinalisedYet,
                                }
                            }, {
                                purchased: 0,
                                unallocated: 0,
                                unclaimed: 0,
                                unused: 0,
                                notPreviewedYet: 0,
                                notFinalisedYet: 0
                            })
                        }
                    }, {}),
                }

                setTimeout(() => {
                    setFetchedData(initialFetchedData);
                    setDummySubscription(initialData);
                    setLoading(false);
                }, 1000);
            } catch (error) {
                setError("There was a problem with your fetch operation.");
                console.error("Fetch error:", error);
                setLoading(false);
            }
        };

        fetchSubscriptions();

    }, [dummySubscriptionOverviewUnits, dummySubscriptionUnitsPendingActions, dummySubscriptionUsageOverview, dummySubscriptionUsageDetails, dummySubscriptionTimelinesandCost, dummySubscriptionCostandRenewal]);

    const toggleAccordion = useCallback((section) => {
        setAccordionState((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    }, []);
    const renderTable = useCallback((section) => {

        const tableCollection = {
            "Subscription Usage Overview": <OverviewUsageTable section={section} fetchedData={fetchedData} />,
            "Subscription Usage Details": <UsageDetailTable section={section} fetchedData={fetchedData} />,
            "Subscription Timelines & Costs": <SubscriptionTimeLine section={section} fetchedData={fetchedData} />,
            "Subscription Cost & Renewal": <CostAndRenewal section={section} fetchedData={fetchedData} />
        }
        return (
            <Suspense fallback={<div>Loading...</div>}>
                {tableCollection[section]}
            </Suspense>
        );

    }, [fetchedData])
    const renderChart = useCallback((section, idx, packageName) => {
        const funnelChartCollection = {
            "Subscription Units Overview": <SubscriptionOverviewUnits id={`unitsOverview${idx}`} chartData={dummySubscriptionData[section][packageName]} fetchedData={fetchedData} section={section} packageName={packageName} />,
            "Subscription Units, Pending Actions": <SubscriptionPendingAction id={`pendingActions${idx}`} chartData={dummySubscriptionData[section][packageName]} fetchedData={fetchedData} section={section} packageName={packageName} />,


        }
        return (
            <Suspense fallback={<div>Loading...</div>}>
                {funnelChartCollection[section]}
            </Suspense>
        );
    }, [dummySubscriptionData, fetchedData]);
    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xl">
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                sections.map((section) => (
                    <Box
                        key={section}
                        sx={{
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                            marginBottom: "15px",
                            minWidth: "870px"
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", minWidth: "850px" }}>
                            <Typography variant="h2">
                                {section.split(/(?=[A-Z])/).join(" ")}
                            </Typography>
                            <IconButton
                                size="large"
                                sx={{ cursor: "pointer" }}
                                onClick={() => toggleAccordion(section)}
                            >
                                {accordionState[section] ? <ExpandMore /> : <ChevronRight />}
                            </IconButton>

                        </Box>
                        <Collapse in={accordionState[section]} >

                            <Box>
                                {
                                    ChartSection.find((item) => { return item === section }) ? packageNames.map((packageName, idx) => {
                                        return (
                                            <Box key={`${section}-${packageName}`} sx={{
                                                borderRadius: "5px",
                                                padding: "10px",
                                                marginBottom: "10px",
                                                minWidth: "850px"
                                            }}>
                                                <Typography variant="h4" marginLeft={2} marginTop={3}>{packageName}</Typography>
                                                <Box marginTop={4}   >
                                                    {
                                                        dummySubscriptionData ? renderChart(section, idx, packageName) : <CircularProgress />
                                                    }

                                                </Box>
                                            </Box>
                                        )
                                    }) : <Box marginTop={4}  >{fetchedData ? renderTable(section) : <CircularProgress />}</Box>
                                }

                            </Box>
                        </Collapse>
                    </Box>
                ))
            )}
        </Container>
       </ThemeProvider>
    );
};

export default SubscriptionDetails;

