import React from "react";
import { Box, Typography } from "@mui/material";

import NumberRenderCell from "../../components/AvailableUnitsForAdminAllocation/RenderCells/NumberRenderCell.jsx";
import UserUnitInputRenderCell from "../../components/AvailableUnitsForAdminAllocation/RenderCells/UserUnitInputRenderCell.jsx";
import CheckboxRenderCell from "../../components/AvailableUnitsForAdminAllocation/RenderCells/CheckboxRenderCell.jsx";
import InputRenderCellWithPlusButton from "../../components/AvailableUnitsForAdminAllocation/RenderCells/InputRenderCellWithRowAdditionButton.jsx";
import DropdownRenderCellWithRowAdditionButton from "../../components/AvailableUnitsForAdminAllocation/RenderCells/DropdownRenderCellWithRowAdditionButton.jsx";

const generateColumnData = (
  page,
  sectionData,
  setSectionData,
  setModalData,
  modalData
) => {
  // Reusable function to create column definitions
  const createColumn = (field, headerName, renderCell) => ({
    field,
    headerName,
    headerClassName: "super-app-theme--header",
    display: "flex",
    flex: 1,
    renderCell,
  });

  // Function to determine the correct render cell based on user type
  const createRenderCell = (unitName, inputsLabel, totalLabel) => (params) => {
    const isOther =
      (params.row?.user === "Self" && unitName === "user") ||
      unitName !== "user";

    const RenderComponent = !isOther ? NumberRenderCell : CheckboxRenderCell;

    return (
      <RenderComponent
        params={params}
        unitName={unitName}
        inputsLabel={inputsLabel}
        totalLabel={totalLabel}
        sectionData={sectionData}
        setSectionData={setSectionData}
        setModalData={setModalData}
        modalData={modalData}
      />
    );
  };

  // User Unit's column data
  const user_units = [
    createColumn("user", "User", (params) => {
      const id = params.row.id;
      return (
        <Box style={{ width: "100%", height: "100%", display: "flex" }}>
          {!String(id).startsWith("other") && (
            <Typography
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              {params.value}
            </Typography>
          )}
        </Box>
      );
    }),
    createColumn("name", "Name", (params) => (
      <UserUnitInputRenderCell
        params={params}
        inputsLabel={"name"}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),
    createColumn("email", "Email", (params) => (
      <UserUnitInputRenderCell
        params={params}
        inputsLabel={"email"}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),
    createColumn(
      "solo_starter_total",
      "Solo Starter",
      createRenderCell("user", "solo_starter_entered", "solo_starter_total")
    ),
    createColumn(
      "duel_dynamics_total",
      "Duel Dynamics",
      createRenderCell("user", "duel_dynamics_entered", "duel_dynamics_total")
    ),
    createColumn(
      "focus_five_total",
      "Focus Five",
      createRenderCell("user", "focus_five_entered", "focus_five_total")
    ),
    createColumn(
      "all_available_total",
      "All Available",
      createRenderCell("user", "all_available_entered", "all_available_total")
    ),
  ];

  // End User Organization Unit's column data
  const end_user_organization_units = [
    createColumn("organisation_name", "Organisation Name", (params) => (
      <InputRenderCellWithPlusButton
        params={params}
        inputsLabel={"organisation_name"}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),
    createColumn(
      "solo_starter_total",
      "Solo Starter",
      createRenderCell(
        "organisation_name",
        "solo_starter_entered",
        "solo_starter_total"
      )
    ),
    createColumn(
      "duel_dynamics_total",
      "Duel Dynamics",
      createRenderCell(
        "organisation_name",
        "duel_dynamics_entered",
        "duel_dynamics_total"
      )
    ),
    createColumn(
      "focus_five_total",
      "Focus Five",
      createRenderCell(
        "organisation_name",
        "focus_five_entered",
        "focus_five_total"
      )
    ),
    createColumn(
      "all_available_total",
      "All Available",
      createRenderCell(
        "organisation_name",
        "all_available_entered",
        "all_available_total"
      )
    ),
  ];

  // Buyer Guides Unit's column data
  const buyer_guide_units = [
    createColumn("buyer_guide_name", "Buyer Guide Name", (params) => (
      <DropdownRenderCellWithRowAdditionButton
        inputsLabel={"buyer_guide_name"}
        params={params}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),
    createColumn(
      "solo_starter_total",
      "Solo Starter",
      createRenderCell(
        "buyer_guide_name",
        "solo_starter_entered",
        "solo_starter_total"
      )
    ),
    createColumn(
      "duel_dynamics_total",
      "Duel Dynamics",
      createRenderCell(
        "buyer_guide_name",
        "duel_dynamics_entered",
        "duel_dynamics_total"
      )
    ),
    createColumn(
      "focus_five_total",
      "Focus Five",
      createRenderCell(
        "buyer_guide_name",
        "focus_five_entered",
        "focus_five_total"
      )
    ),
    createColumn(
      "all_available_total",
      "All Available",
      createRenderCell(
        "buyer_guide_name",
        "all_available_entered",
        "all_available_total"
      )
    ),
  ];

  // Unlocked Vendors Unit's column data
  const unlocked_vendors = [
    createColumn("vendor_name", "Vendor Name", (params) => (
      <DropdownRenderCellWithRowAdditionButton
        inputsLabel={"vendor_name"}
        params={params}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),
    createColumn(
      "solo_starter_total",
      "Solo Starter",
      createRenderCell(
        "vendor_name",
        "solo_starter_entered",
        "solo_starter_total"
      )
    ),
    createColumn(
      "duel_dynamics_total",
      "Duel Dynamics",
      createRenderCell(
        "vendor_name",
        "duel_dynamics_entered",
        "duel_dynamics_total"
      )
    ),
    createColumn(
      "focus_five_total",
      "Focus Five",
      createRenderCell("vendor_name", "focus_five_entered", "focus_five_total")
    ),
    createColumn(
      "all_available_total",
      "All Available",
      createRenderCell(
        "vendor_name",
        "all_available_entered",
        "all_available_total"
      )
    ),
  ];

  const dataMapping = {
    user_units,
    end_user_organization_units,
    buyer_guide_units,
    unlocked_vendors,
  };

  return dataMapping[page];
};

export default generateColumnData;
