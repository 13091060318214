const updateColumnsForOtherUnits = (
  sectionData,
  setSectionData,
  rowData,
  totalLabel,
  inputsLabel
) => {
  // Caluclation For User Defined Row
  const totalForRemainingRow = totalForUserDefinedRow(
    sectionData,
    rowData,
    totalLabel,
    inputsLabel
  );

  // Calculation for AvailableOptions for Vendors Unlock page
  const updatedSectionData = sectionData?.map((packag) => {
    if (packag.id === rowData.id) {
      return rowData;
    } else if (packag.id === sectionData[sectionData?.length - 2]?.id) {
      return {
        ...packag,
        [totalLabel]: totalForRemainingRow,
      };
    } else if (packag.id?.length && packag.id !== rowData.id) {
      const newTotal =
        packag[inputsLabel] > 0 || totalForRemainingRow > 0 ? 1 : 0;
      if (packag.vendor_name) {
        const latestOptions = listOfAvailableVendors(sectionData);
        return {
          ...packag,
          [totalLabel]: newTotal,
          vendor_name: {
            ...packag.vendor_name,
            options:
              packag.vendor_name.selected !== "None"
                ? [...latestOptions, packag.vendor_name.selected].sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                : latestOptions,
          },
        };
      }
      return {
        ...packag,
        [totalLabel]: newTotal,
      };
    }
    return packag;
  });

  setSectionData(updatedSectionData);
};

export default updateColumnsForOtherUnits;

const totalForUserDefinedRow = (
  sectionData,
  rowData,
  totalLabel,
  inputsLabel
) => {
  return sectionData
    ?.slice(0, -2)
    ?.reduce(
      (total, el) =>
        total - (el.id === rowData.id ? rowData[inputsLabel] : el[inputsLabel]),
      sectionData[sectionData?.length - 1][totalLabel]
    );
};

const listOfAvailableVendors = (sectionData) => {
  const listOfVendors =
    sectionData[sectionData?.length - 1]?.listOfVendors || [];

  const alreadySelectedVendors = sectionData
    ?.filter((el) => el.id?.length && el.vendor_name?.selected?.name)
    ?.map((el) => el.vendor_name.selected.name);
  //It stores all the options available for every row excluding selected ones

  const latestOptions = listOfVendors?.filter(
    (vendor) => !alreadySelectedVendors?.includes(vendor.name)
  );

  return latestOptions;
};
