import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography, IconButton } from "@mui/material";
import { apiService } from "../../../utils/utils";
import ListToggleWidget from "../VendorRelevantOffer"; // Adjust the path as necessary
import {
  FileCopyOutlined,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
const payload = {
  email: "nitin.acharekar@gmail.com",
  currentOrganisation: "BOI",
  currentBuyerGuide: "ZTNA Solution (Test)",
  currentScenario: "Nitin Acharekar - BOI - Scenario 1",
  displayUseCasesOptions: "Both",
};

const RelevantVendors = ({
  isTableVisible,
  widgetLabels,
  widgetKey,
  toggleTableVisibility,
  handleCopyWidget,
  handleRemoveWidget,
  setLayout,
}) => {
  const [vendors, setVendors] = useState([]);
  const [vendorCount, setVendorCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/relevantVendorsCountAndNames?", // Replace with your actual API endpoint
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: payload,
          }
        );
        if (response) {
          setLayout((prev) => {
            const newLayout = prev.map((item) => {
              if (item.i !== widgetKey) {
                return item;
              } else {
                const newItem = {
                  ...item,
                  h: 3 + response.VendorDetails.length,
                  minH: 3 + response.VendorDetails.length,
                  maxH: 3 + response.VendorDetails.length,
                };
                return newItem;
              }
            });
            return newLayout;
          });
          const item =
            response.VendorDetails.map((item) => {
              return item.VendorName;
            }) || [];
          setVendors(item);
        }
        setVendorCount(response.VendorCount || 0);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch vendors:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchVendors();
  }, [widgetKey, setLayout]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error loading data</Typography>;
  }

  return (
    <Box data-test-id="relevantvendor">
      <Box
        className="widget-header-container"
        style={{
          flexShrink: 0,
          display: "flex",
          height: 70,
        }}
      >
        {widgetLabels && widgetKey && (
          <Typography
            variant="h6"
            sx={{  marginTop: "15px", textAlign: "center" }}
            className="widget-header"
          >
            {widgetLabels[widgetKey.split("_")[0]]}
          </Typography>
        )}

        <Box className="widget-icon">
          {widgetKey === "d" && (
            <IconButton
              data-test-id={`eye-${widgetLabels[widgetKey.split("_")[0]]}`}
              onClick={toggleTableVisibility}
              aria-label="toggle table visibility"
            >
              {isTableVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          <FileCopyOutlined
            data-test-id={`copy-${widgetLabels[widgetKey.split("_")[0]]}`}
            onClick={() => handleCopyWidget(widgetKey)}
          />
          <IconButton
            data-test-id={`close-${widgetLabels[widgetKey.split("_")[0]]}`}
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>
      <ListToggleWidget
        name="relevantvendor"
        items={vendors}
        count={vendorCount}
      />
    </Box>
  );
};

export default RelevantVendors;
