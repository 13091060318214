import React from 'react'
import { useEffect, useState } from "react";
import DataGridTable from "../../../../components/DataGridTable";
import { Box } from "@mui/material";

function CostAndRenewal({ fetchedData, section }) {
    console.log(fetchedData[section])
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {

        let rowData = [];
        let key =1;
        fetchedData[section].forEach((data) => {
            const subscriptions = data.subscriptions;
            const packageName = data.package;
            for (var i = 0; i < subscriptions.length; i++) {
                if (i === 0) {
                    
                    rowData.push({
                        id: key,
                        package: packageName,
                        type: subscriptions[i].type,
                        purchasePrice:subscriptions[i].purchasePrice,
                        renewalDate: subscriptions[i].renewalDate,
                        daysToRenewal: subscriptions[i].daysToRenewal,
                        renewalPrice: subscriptions[i].renewalPrice,
                        units:subscriptions[i].units,
                        action:subscriptions[i].action
                    })
                    
                }
                else{
                    
                    rowData.push({
                        id: key,
                        package: "",
                        type: subscriptions[i].type,
                        purchasePrice:subscriptions[i].purchasePrice,
                        renewalDate: subscriptions[i].renewalDate,
                        daysToRenewal: subscriptions[i].daysToRenewal,
                        renewalPrice: subscriptions[i].renewalPrice,
                        units:subscriptions[i].units,
                        action:subscriptions[i].action
                    })
                   
                 }
                key++;
               }
               
            })
        const transformedData = {
            columuns: [

                {
                    field: "package",
                    headerName: "Subscription",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    cellClassName:"super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "type",
                    headerName: "Type",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "purchasePrice",
                    headerName: "Purchase Price",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "renewalDate",
                    headerName: "Renewal Date",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "daysToRenewal",
                    headerName: "Day to Renewal",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "renewalPrice",
                    headerName: "Renewal Price",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "units",
                    headerName: "Units",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false
                },
                {
                    field: "action",
                    headerName: "Action",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center',
                    sortable: false,
                    minWidth: 310
                }
            ],
            rows: rowData

        }
        console.log(rowData)
        setColumns(transformedData.columuns);
        setRows(transformedData.rows)
        setLoading(false);
        setError(null);
    }, [fetchedData, section]);
    return (
        <Box sx={{ width: "100%", marginRight: "30px" }}><DataGridTable
            rows={rows}
            columns={columns}
            loading={loading}
            error={error}
            menu={true}

        /></Box>
    )
}

export default CostAndRenewal