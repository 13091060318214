import { Box, Tooltip, Typography } from "@mui/material";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import TextInput from "../TextInput.jsx";

const UserUnitInputRenderCell = ({
  params,
  inputsLabel,
  sectionData,
  setSectionData,
}) => {
  const { row: rowData } = params;
  const { user, error } = rowData;

  if (user === "Total" || user === "Other" || user === "Self")
    return (
      <Typography
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {rowData[inputsLabel]}
      </Typography>
    );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {error?.[inputsLabel] && (
        <Tooltip
          title={
            inputsLabel === "email"
              ? "Please Enter Valid Email"
              : "Please Enter Valid Name"
          }
        >
          <WarningAmberRoundedIcon
            color="warning"
            style={{ marginRight: "10px" }}
          />
        </Tooltip>
      )}

      <TextInput
        inputValue={params.value}
        inputsLabel={inputsLabel}
        onSet={(value) => {
          setSectionData(
            sectionData.map((el) => {
              if (el.id === params.row.id) {
                const updatedError = {
                  ...el.error,
                  name: inputsLabel === "name" && value === "" ? true : false,
                  email: inputsLabel === "email" && value === "" ? true : false,
                };

                return { ...el, [inputsLabel]: value, error: updatedError };
              }
              return el;
            })
          );
        }}
      />
    </Box>
  );
};

export default UserUnitInputRenderCell;
