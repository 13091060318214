// FilterMenu.js
import React from 'react';
import { Box, Menu, TextField, Autocomplete, Paper } from '@mui/material';

const FilterMenu = ({ filterAnchorEl, filterColumn, filters, onFilterChange, onClose, getColumnUniqueValues }) => {
  const open = Boolean(filterAnchorEl);

  return (
    <Menu
      anchorEl={filterAnchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        sx: { p: 0 },
      }}
      PaperProps={{
        sx: {
          width: '300px',
          overflow: 'visible',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: '50%',
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid white',
            transform: 'translateX(-50%) translateY(-100%)',
          },
        },
      }}
    >
      <Box sx={{ padding: 1, width: '100%', backgroundColor: 'white' }}>
        <Autocomplete
          freeSolo
          id="filter-autocomplete"
          disableClearable
          sx={{ backgroundColor: 'white' }}
          options={getColumnUniqueValues(filterColumn)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ backgroundColor: 'white' }}
              type='search'
              label={`Filter by ${filterColumn}`}
              value={filters[filterColumn] || ""}
              onChange={e => onFilterChange(filterColumn, e.target.value)}
              fullWidth
            />
          )}
          PaperComponent={(props) => (
            <Paper {...props} style={{ backgroundColor: "white" }} />
          )}
          onInputChange={(event, value) => onFilterChange(filterColumn, value)}
        />
      </Box>
    </Menu>
  );
};

export default FilterMenu;