import React from "react";
import { Box, Typography, CircularProgress, Collapse, Alert } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const RightPane = ({
  rightPaneUseCases,
  setRightPaneUseCases,
  leftPaneUseCases,
  setLeftPaneUseCases,
  setUseCaseHistory,
  loading,
  setLoading,
  noChildren,
  setNoChildren,
  showWarningRightPane,
  setShowWarningRightPane,
  isCollapsed,
  setIsCollapsed,
  renderUseCaseList,
  handleSelectChildUseCase,
}) => {
  return (
    <Box
      width={{ xs: "100%", md: isCollapsed ? "95%" : "50%" }}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      transition="width 0.5s ease"
      padding="10px"
    >
      <Typography
      data-test-id="sub-use-cases"
        variant="h3"
        sx={{
          marginTop: { xs: "81px", md: "135px" },
          marginLeft: { xs: "-15px", md: "20px" },
          textAlign: "center",
          width: "100%",
        }}
      >
        Sub Use Cases
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          marginTop="20px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          sx={{ marginTop: { xs: "20px", md: "90px" } }}
        >
          {noChildren ? (
            <Box display="flex" alignItems="center" mt={2}>
              <WarningAmberIcon color="warning" style={{ marginRight: "10px" }} />
              <Typography data-test-id="no-children" variant="h6">The chosen use case has no sub use cases.</Typography>
            </Box>
          ) : rightPaneUseCases.length > 0 ? (
            <>
              {renderUseCaseList(rightPaneUseCases, handleSelectChildUseCase, setRightPaneUseCases, false)}
              <Collapse in={showWarningRightPane} style={{ width: "100%", marginTop: "10px" }}>
                <Alert severity="warning" onClose={() => setShowWarningRightPane(false)}>
                  Please select sub use case to propagate details
                </Alert>
              </Collapse>
            </>
          ) : (
            <Box display="flex" alignItems="center" mt={2}>
              <WarningAmberIcon color="warning" style={{ marginRight: "10px" }} />
              <Typography data-test-id="no-children-to-display" variant="h6">No children to display</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default RightPane;
