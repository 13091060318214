import React, { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import theme from '../theme/theme';
const DataGridTable = ({ name,columns, rows, menu, page }) => {
  // Modify columns to set center alignment for cell values

  const centeredColumns = useMemo(
    () =>
      columns?.map((column) => ({
        ...column,
        align: 'center',
        
        // Center align cell values
      })),
    [columns]
  );

  // Function to check and round a number to 2 decimal places if needed
  const formatValue = (value) => {
    if (typeof value === 'number') {
      const decimalPlaces = (value.toString().split('.')[1] || '').length;
      if (decimalPlaces === 0 || decimalPlaces === 1) {
        return value;
      } else {
        return value.toFixed(2);
      }
    }
    return value;
  };

  // Apply formatting to each row value before rendering
  const formattedRows =
    rows &&
    rows?.length > 0 &&
    rows.map((row) => {
      const newRow = { ...row };
      Object.keys(newRow).forEach((key) => {
        newRow[key] = formatValue(newRow[key]);
      });
      return newRow;
    });

  return (
    <Box data-test-id={`table-${name}`}

      sx={{
        width: '100%',
        '& .super-app-theme--header': {
          backgroundColor: theme.palette.primary.main,
          border: '0.5px solid grey',
          color: 'white',
        },
      }}
      style={{
        height: formattedRows.length >= 6 ? 480 : 'auto',
        width:
          page === 'allocation'
            ? centeredColumns?.length === 2
              ? '40%'
              : centeredColumns?.length === 3
              ? '60%'
              : centeredColumns?.length === 4
              ? '80%'
              : '100%'
            : '100%',
      }}
    >
      <DataGrid
        rows={formattedRows}
        columns={centeredColumns}
        disableColumnMenu={menu}
        disableRowSelectionOnClick
        hideFooter
        getRowHeight={() => 70}
        scrollbarSize={0}
        rowBufferPx={20}
        sx={{
          width: '100%',
          height: '100%',

          // '& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell':
          //   {
          //     display: 'flex',
          //     alignItems: 'center',
          //     justifyContent: 'center',
          //     textAlign: 'center',
          //     overflow: 'hidden',
          //     textOverflow: 'ellipsis',
          //     overflowY: 'auto',
          //   },

          // '& .MuiDataGrid-viewport': { maxHeight: 'none !important' },
          // '& .MuiDataGrid-virtualScrollerRenderZone': {
          //   maxHeight: 'none !important',
          // },

          // '& .MuiDataGrid-row': {
          //   maxHeight: 'none !important',
          // },
          // '@media (hover: none)': {
          //   '&& .MuiDataGrid-menuIcon': {
          //     width: 0,
          //     visibility: 'hidden',
          //   },
          // },

          // '&& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-menuIcon': {
          //   width: 'auto',
          //   visibility: 'visible',
          //   '& .MuiDataGrid-columnHeaders': {
          //     backgroundColor: theme.palette.primary.main,
          //     color: 'white',
          //     whiteSpace: 'normal',
          //     wordWrap: 'break-word',
          //   },
          //   '& .MuiDataGrid-cell': {
          //     border: '0.5px solid grey',
          //     wordWrap: 'break-word',
          //     whiteSpace: 'normal',
          //   },
          //   '& .MuiDataGrid-cell--editable:hover': { border: '2px solid #000' },
          // },

          '& .MuiDataGrid-cell:hover': {
            cursor: 'default',
          },

          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '.MuiDataGrid-iconButtonContainer': {
            // marginLeft: '10px !important',
            right: 15,
            position: 'absolute',
          },
          '& .MuiDataGrid-sortIcon': {
            color: 'white',
          },
          '&& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
            color: 'white ',
          },
        }}
      />
    </Box>
  );
};

export default DataGridTable;
