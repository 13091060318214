import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  Button,
} from "@mui/material";

import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../context/UserContext.jsx";
import { apiService } from "../../utils/utils.js";

const ScoreAndUtilisation = ({
  limitedScoreChecked,
  addOnScoreChecked,
  handleLimitedScoreChange,
  handleAddOnBundlesChange,
  handleSubmit,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography data-test-id="name-Display Offer Score / Utilisation" variant="h3" gutterBottom>
          Display Offer Score / Utilisation
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel
            data-test-id="name-Display Offer Score"
              control={
                <Checkbox
            data-test-id="checkbox-Display Offer Score"
                  checked={limitedScoreChecked}
                  onChange={handleLimitedScoreChange}
                  color="secondary"
                />
              }
              label="Display Offer Score"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
            data-test-id="name-Display Offer Utilisation"
              control={
                <Checkbox
            data-test-id="checkbox-Display Offer Utilisation"
                  checked={addOnScoreChecked}
                  onChange={handleAddOnBundlesChange}
                  color="secondary"
                />
              }
              label="Display Offer Utilisation"
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const Metrics = ({ selectedOption, handleOptionChange }) => {
  return (
    <>
      <div className="another-component">
        <Typography  data-test-id="name-Display Overall / Mandatory use cases"
        variant="h3" gutterBottom>
          Display Overall / Mandatory use cases
        </Typography>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          <FormControlLabel
          data-test-id="name-Only display metrics for Mandatory use cases"
            value="Mandatory"
            control={< Radio 
          data-test-id="radio-Only display metrics for Mandatory use cases"
                color="secondary" />}
            label="Only display metrics for Mandatory use cases"
          />
          <FormControlLabel
          data-test-id="name-Only display metrics for Overall (combination of Mandatory and Optional) use cases"
            value="Overall"
            control={<Radio
          data-test-id="radio-Only display metrics for Overall (combination of Mandatory and Optional) use cases"
               color="secondary" />}
            label="Only display metrics for Overall (combination of Mandatory and Optional) use cases"
          />
          <FormControlLabel
          data-test-id="name-Display metrics for both Overall and Mandatory use cases"
            value="Both"
            control={<Radio 
          data-test-id="radio-Display metrics for both Overall and Mandatory use cases"
               color="secondary" />}
            label="Display metrics for both Overall and Mandatory use cases"
          />
        </RadioGroup>
      </div>
    </>
  );
};

const MandatoryDisplayOption = () => {
  const { userProfile, currentOrganization, buyerGuideName, scenarioName } =
    useContext(UserProfileContext);
  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  const [limitedScoreChecked, setLimitedScoreChecked] = useState(false);
  const [addOnScoreChecked, setAddOnScoreChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Both");

  const navigate = useNavigate();

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/getDisplayOption?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: buyerGuideName,
              currentScenario: scenarioName,
            },
          }
        );
        if (response.displayOfferOptions) {
          setLimitedScoreChecked(
            response.displayOfferOptions.includes("Display Offer Score")
          );
          setAddOnScoreChecked(
            response.displayOfferOptions.includes("Display Offer Utilisation")
          );
        }
        if (response.displayUseCasesOptions) {
          setSelectedOption(response.displayUseCasesOptions);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userProfile, currentOrg, buyerGuideName, scenarioName]);

  const handleLimitedScoreChange = (event) => {
    setLimitedScoreChecked(event.target.checked);
  };

  const handleAddOnBundlesChange = (event) => {
    setAddOnScoreChecked(event.target.checked);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const saveAndContinue = async () => {
    try {
      await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/updateDisplayOption?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: buyerGuideName,
            currentScenario: scenarioName,
            display_offer_score: limitedScoreChecked,
            display_offer_utilisation: addOnScoreChecked,
            display_use_cases_option: selectedOption,
          },
        }
      );
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const button = event.target.querySelector('button[type="submit"]');
    button.textContent = "Saved";

    await saveAndContinue();

    setTimeout(() => {
      button.textContent = "Save";
    }, 2000);
  };
  return (
    <>
      <Container sx={{ mt: 6, ml: 0 }}>
        <HeaderNaming data={data} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <ScoreAndUtilisation
              limitedScoreChecked={limitedScoreChecked}
              addOnScoreChecked={addOnScoreChecked}
              handleLimitedScoreChange={handleLimitedScoreChange}
              handleAddOnBundlesChange={handleAddOnBundlesChange}
              handleSubmit={handleSubmit}
            />
            <Divider sx={{ display: { xs: "block", sm: "none" }, my: 2 }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Divider
              orientation="vertical"
              sx={{
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0)",
                width: 0,
                }}
                />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Metrics
              selectedOption={selectedOption}
              handleOptionChange={handleOptionChange}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2,
            ml: 2,
            }}
            >
          <Button
            style={{ width: "250px" }}
            color="primary"
            variant="contained"
            disableRipple
            onClick={async () => {
              //await saveAndContinue();
              navigate("/edit-scenario/scenario-settings/report-settings");
            }}
          >
            <Typography  data-test-id="save&continue" color="white">Save & Continue</Typography>
          </Button>

          <Button
            style={{ width: "250px" }}
            color="secondary"
            variant="contained"
            disableRipple
            onClick={async () => {
              //await saveAndContinue();
              navigate("/results/comparative-analysis", {
                state: {
                  limitedScoreChecked,
                  addOnScoreChecked,
                },
              });
            }}
          >
            <Typography data-test-id="dashboard" color="white">Save & Exit to Dashboard</Typography>
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default MandatoryDisplayOption;
