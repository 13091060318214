import React from "react";

const FineTune = () => {
  return (
    <>
      <div>FineTune Page</div>
    </>
  );
};

export default FineTune;
