import React from 'react'
import { useEffect, useState } from "react";
import DataGridTable from "../../../../components/DataGridTable";
import { Box } from "@mui/material";
function OverviewUsageTable({ fetchedData, section }) {
    
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        const transformedData = {
            columuns: [

                {
                    field: "package",
                    headerName: "Purchased Package",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                },
                {
                    field: "user",
                    headerName: "User (Ultimate) #",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                },
                {
                    field: "endUserOrganisation",
                    headerName: "End-User Organisation #",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                },
                {
                    field: "buyerGuide",
                    headerName: "Buyer Guide #",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                },
                {
                    field: "totalUnits",
                    headerName: "Total Units #",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                },
                {
                    field: "unlockedVendors",
                    headerName: "Unlocked Vendors #",
                    flex: 1,
                    headerClassName: "super-app-theme--header",
                    headerAlign: 'center'
                }
            ],
            rows: fetchedData[section].map((item, idx) => {
                return {
                    id: idx + 1,
                    package: item.package,
                    user: item.users[0].user,
                    buyerGuide: item.users[0].buyerGuide,
                    endUserOrganisation:item.users[0].endUserOrganisation,
                    totalUnits: item.users[0].totalUnits,
                    unlockedVendors: item.users[0].unlockedVendors
                }
            })

        }
        console.log(transformedData.rows)
        setColumns(transformedData.columuns);
        setRows(transformedData.rows)
        setLoading(false);
        setError(null);
    }, [fetchedData, section]);
    return (
        <Box sx={{ width: "100%", marginRight: "30px" }}><DataGridTable
            rows={rows}
            columns={columns}
            loading={loading}
            error={error}
            menu={true}

        /></Box>
    )
}

export default OverviewUsageTable