import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const StartNewUseCase = () => {
  // Define your goals with parent tasks and subtasks
  const [goals, setGoals] = useState([
    {
      parent: "After “Start New Use Case” Button",
      subtasks: [
        {
          text: "Query to generate ALL available use cases to display for “Start New Use Case Page",
          achieved: false,
        },
      ],
    },
    {
      parent: "After Selection of Use Case",
      subtasks: [
        { text: "Query to update USER’s available use cases", achieved: false },
      ],
    },
    {
      parent: "After “Select Use Case” Button",
      subtasks: [
        {
          text: "Query to generate USER’s available use cases for “Use Case Selection Page”",
          achieved: false,
        },
      ],
    },
    {
      parent: "After Selection of Use Case",
      subtasks: [
        {
          text: "Query to ‘narrow’ down their database to only account for that particular use case",
          achieved: false,
        },
        {
          text: "Create relationship between User and Use Case",
          achieved: false,
        },
      ],
    },
    {
      parent: "After “Select Scenario” Button",
      subtasks: [
        {
          text: "Query to display available scenarios for “Scenario Selection Page”",
          achieved: false,
        },
        { text: "Default scenario if others are not present", achieved: false },
      ],
    },
    {
      parent: "After Selection of Scenario",
      subtasks: [
        {
          text: "Query to ‘narrow’ down their database to only account for that scenario",
          achieved: false,
        },
        {
          text: "Create a relationship between User and Scenario",
          achieved: false,
        },
      ],
    },
    // Add more parent tasks and subtasks as needed
  ]);

  // Function to toggle the achievement status of a subtask
  const toggleAchievement = (parentIndex, subtaskIndex) => {
    const updatedGoals = [...goals];
    updatedGoals[parentIndex].subtasks[subtaskIndex].achieved =
      !updatedGoals[parentIndex].subtasks[subtaskIndex].achieved;
    setGoals(updatedGoals);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={5}
        height="100%"
      >
        <Box
          border="2px solid #ccc"
          padding="20px"
          borderRadius="5px"
          maxWidth="500px"
        >
          <Typography variant="h2" style={{ textAlign: "center" }}>
            Start New Use Case
          </Typography>
          {/* Map through the goals array and render each parent task with its subtasks */}
          {goals.map((goal, parentIndex) => (
            <div key={parentIndex}>
              <Typography variant="h3" gutterBottom>
                {goal.parent}
              </Typography>
              <List>
                {goal.subtasks.map((subtask, subtaskIndex) => (
                  <ListItem
                    key={subtaskIndex}
                    style={{
                      textDecoration: subtask.achieved
                        ? "line-through"
                        : "none",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={subtask.achieved}
                        onChange={() =>
                          toggleAchievement(parentIndex, subtaskIndex)
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary={subtask.text} />
                  </ListItem>
                ))}
              </List>
            </div>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default StartNewUseCase;
