const generatePayload = (
  userProfile,
  pageData,
  availableUnitsForAllocation
) => {
  const nameMaping = {
    user_units: "adminPurchasedPackageUserUnits",
    end_user_organization_units: "euo_json_data",
    buyer_guide_units: "buyer_guide_json_data",
    unlocked_vendors: "vendor_json_data",
  };

  let allocationData = {};

  pageData?.forEach((page) => {
    if (page === "user_units") {
      const unitData = JSON.parse(sessionStorage.getItem(page));
      let unitArray = [];
      unitData?.forEach((el) => {
        if (el.user === "Self")
          unitArray.push({
            user: el.user,
            name: el.name,
            email: el.email,
            Solo_Starter: el.solo_starter_entered,
            Duel_Dynamics: el.duel_dynamics_entered,
            Focus_Five: el.focus_five_entered,
            All_Available: el.all_available_entered,
          });
        else if (el.user === "Total")
          unitArray.push({
            user: el.user,
            Solo_Starter: el.solo_starter_total,
            Duel_Dynamics: el.duel_dynamics_total,
            Focus_Five: el.focus_five_total,
            All_Available: el.all_available_total,
          });
        else if (el.user !== "Other")
          unitArray.push({
            user: "Other",
            name: el.name,
            email: el.email,
            Solo_Starter: el.solo_starter_entered,
            Duel_Dynamics: el.duel_dynamics_entered,
            Focus_Five: el.focus_five_entered,
            All_Available: el.all_available_entered,
          });
      });

      if (unitArray?.length > 0)
        allocationData = {
          ...allocationData,
          json_data: { [nameMaping[page]]: unitArray },
        };
    } else if (page === "end_user_organization_units") {
      const unitData = JSON.parse(sessionStorage.getItem(page));

      let unitArray = [];
      unitData?.forEach((el) => {
        unitArray.push({
          organisation_name: el.organisation_name,
          Solo_Starter: el.solo_starter_entered,
          Duel_Dynamics: el.duel_dynamics_entered,
          Focus_Five: el.focus_five_entered,
          All_Available: el.all_available_entered,
        });
      });

      if (unitArray?.length > 0)
        allocationData = {
          ...allocationData,
          [nameMaping[page]]: unitArray,
        };
    } else if (page === "buyer_guide_units") {
      const unitData = JSON.parse(sessionStorage.getItem(page));

      let unitArray = [];
      unitData?.forEach((el) => {
        unitArray.push({
          buyer_guide_name:
            el.buyer_guide_name === "User Defined" ||
            el.buyer_guide_name === "Total"
              ? el.buyer_guide_name
              : el.buyer_guide_name.selected.name,
          Solo_Starter: el.solo_starter_entered,
          Duel_Dynamics: el.duel_dynamics_entered,
          Focus_Five: el.focus_five_entered,
          All_Available: el.all_available_entered,
        });
      });

      if (unitArray?.length > 0)
        allocationData = {
          ...allocationData,
          [nameMaping[page]]: unitArray,
        };
    } else if (page === "unlocked_vendors") {
      const unitData = JSON.parse(sessionStorage.getItem(page));

      let unitArray = [];
      unitData?.forEach((el) => {
        unitArray.push({
          vendor_name:
            el.vendor_name === "User Defined" || el.vendor_name === "Total"
              ? el.vendor_name
              : el.vendor_name.selected.name,
          Solo_Starter: el.solo_starter_entered,
          Duel_Dynamics: el.duel_dynamics_entered,
          Focus_Five: el.focus_five_entered,
          All_Available: el.all_available_entered,
        });
      });

      if (unitArray?.length > 0)
        allocationData = {
          ...allocationData,
          [nameMaping[page]]: unitArray,
        };
    }
  });

  // when all are User Defined
  if (pageData?.length === 1) {
    const solo_starter_from_prev_page = availableUnitsForAllocation?.filter(
      (packag) => packag.purchased_package === "Solo Starter"
    );

    const duel_dynamics_from_prev_page = availableUnitsForAllocation?.filter(
      (packag) => packag.purchased_package === "Duel Dynamics"
    );

    const focus_five_from_prev_page = availableUnitsForAllocation?.filter(
      (packag) => packag.purchased_package === "Focus Five"
    );

    const all_available_from_prev_page = availableUnitsForAllocation?.filter(
      (packag) => packag.purchased_package === "All Available"
    );

    //

    let unitArray = userDefinedUserUnits(
      userProfile,
      solo_starter_from_prev_page,
      duel_dynamics_from_prev_page,
      focus_five_from_prev_page,
      all_available_from_prev_page
    );

    allocationData = {
      ...allocationData,
      json_data: { [nameMaping["user_units"]]: unitArray },
    };

    unitArray = EndUserOrganizationUnits(
      solo_starter_from_prev_page,
      duel_dynamics_from_prev_page,
      focus_five_from_prev_page,
      all_available_from_prev_page
    );

    allocationData = {
      ...allocationData,
      [nameMaping["end_user_organization_units"]]: unitArray,
    };

    unitArray = buyerGuideUnits(
      solo_starter_from_prev_page,
      duel_dynamics_from_prev_page,
      focus_five_from_prev_page,
      all_available_from_prev_page
    );

    allocationData = {
      ...allocationData,
      [nameMaping["buyer_guide_units"]]: unitArray,
    };

    unitArray = vendorUnlocked();

    allocationData = {
      ...allocationData,
      [nameMaping["unlocked_vendors"]]: unitArray,
    };

    //
  }

  return allocationData;
};
export default generatePayload;

const userDefinedUserUnits = (
  userProfile,
  solo_starter_from_prev_page,
  duel_dynamics_from_prev_page,
  focus_five_from_prev_page,
  all_available_from_prev_page
) => {
  let unitArray = [];

  unitArray.push({
    user: "Self",
    name: userProfile.displayName + userProfile.surname,
    email: userProfile.email,
    Solo_Starter: solo_starter_from_prev_page[0]?.user_units_entered,
    Duel_Dynamics: duel_dynamics_from_prev_page[0]?.user_units_entered,
    Focus_Five: focus_five_from_prev_page[0]?.user_units_entered,
    All_Available: all_available_from_prev_page[0]?.user_units_entered,
  });
  unitArray.push({
    user: "Total",
    Solo_Starter: solo_starter_from_prev_page[0]?.user_units_entered,
    Duel_Dynamics: duel_dynamics_from_prev_page[0]?.user_units_entered,
    Focus_Five: focus_five_from_prev_page[0]?.user_units_entered,
    All_Available: all_available_from_prev_page[0]?.user_units_entered,
  });

  return unitArray;
};

const EndUserOrganizationUnits = (
  solo_starter_from_prev_page,
  duel_dynamics_from_prev_page,
  focus_five_from_prev_page,
  all_available_from_prev_page
) => {
  let unitArray = [];

  unitArray.push({
    organisation_name: "User Defined",
    Solo_Starter:
      solo_starter_from_prev_page[0]?.end_user_organization_units_entered,
    Duel_Dynamics:
      duel_dynamics_from_prev_page[0]?.end_user_organization_units_entered,
    Focus_Five:
      focus_five_from_prev_page[0]?.end_user_organization_units_entered,
    All_Available:
      all_available_from_prev_page[0]?.end_user_organization_units_entered,
  });

  unitArray.push({
    organisation_name: "Total",
    Solo_Starter:
      solo_starter_from_prev_page[0]?.end_user_organization_units_entered,
    Duel_Dynamics:
      duel_dynamics_from_prev_page[0]?.end_user_organization_units_entered,
    Focus_Five:
      focus_five_from_prev_page[0]?.end_user_organization_units_entered,
    All_Available:
      all_available_from_prev_page[0]?.end_user_organization_units_entered,
  });

  return unitArray;
};

const buyerGuideUnits = (
  solo_starter_from_prev_page,
  duel_dynamics_from_prev_page,
  focus_five_from_prev_page,
  all_available_from_prev_page
) => {
  let unitArray = [];

  unitArray.push({
    organisation_name: "User Defined",
    Solo_Starter: solo_starter_from_prev_page[0]?.buyer_guide_units_entered,
    Duel_Dynamics: duel_dynamics_from_prev_page[0]?.buyer_guide_units_entered,
    Focus_Five: focus_five_from_prev_page[0]?.buyer_guide_units_entered,
    All_Available: all_available_from_prev_page[0]?.buyer_guide_units_entered,
  });

  unitArray.push({
    organisation_name: "Total",
    Solo_Starter: solo_starter_from_prev_page[0]?.buyer_guide_units_entered,
    Duel_Dynamics: duel_dynamics_from_prev_page[0]?.buyer_guide_units_entered,
    Focus_Five: focus_five_from_prev_page[0]?.buyer_guide_units_entered,
    All_Available: all_available_from_prev_page[0]?.buyer_guide_units_entered,
  });

  return unitArray;
};

const vendorUnlocked = () => {
  let unitArray = [];

  unitArray.push({
    organisation_name: "User Defined",
    Solo_Starter: 1,
    Duel_Dynamics: 2,
    Focus_Five: 5,
    All_Available: "Unlimited",
  });

  unitArray.push({
    organisation_name: "Total",
    Solo_Starter: 1,
    Duel_Dynamics: 2,
    Focus_Five: 5,
    All_Available: "Unlimited",
  });

  return unitArray;
};
