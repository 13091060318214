const handleSelfChange = (
  sectionData,
  setSectionData,
  unitName,
  rowData,
  inputsLabel,
  totalLabel,
  setModalData
) => {
  const sizeOfSectionData = sectionData?.length;

  // since entered value is not updated until this point in "sectionData" so needed to check which "inputLabel" are unchanged and non-zero if there ara any then only we will allow row addition

  const isAnyValueNonZero = [
    "solo_starter_entered",
    "duel_dynamics_entered",
    "focus_five_entered",
    "all_available_entered",
  ]
    ?.filter((label) => label !== inputsLabel)
    ?.some((label) => rowData[label] > 0);

  //isValidRowAddition Checks for all the valid cases where row should be added
  const isValidRowAddition =
    rowData[inputsLabel] < rowData[totalLabel] &&
    rowData.id === sectionData[sizeOfSectionData - 2].id &&
    (rowData[inputsLabel] > 0 ? true : isAnyValueNonZero);

  if (rowData[unitName] === "Self") {
    // Handle the case when the row is "Self"
    updateColumnBasedOnSelfChange(
      sectionData,
      setSectionData,
      rowData,
      totalLabel,
      inputsLabel,
      unitName,
      setModalData
    );
  } else if (isValidRowAddition) {
    // Handle row addition

    rowAdditionForUserUnits(
      sectionData,
      setSectionData,
      rowData,
      totalLabel,
      inputsLabel
    );
  } else if (
    rowData.id === sectionData[sizeOfSectionData - 2].id &&
    rowData[inputsLabel] === 0
  ) {
    //  omiting the update when the entered value is 0
    setSectionData(
      sectionData.map((el) => {
        if (el.id === rowData.id) return el;
        return el;
      })
    );
  } else if (
    rowData[inputsLabel] <= rowData[totalLabel] &&
    rowData.id !== sectionData[sizeOfSectionData - 2].id
  ) {
    // Handle the case for updating column data or opening the modal

    if (rowData[inputsLabel] > 0 || isAnyValueNonZero) {
      updateColumnDataForUserUnits(
        sectionData,
        setSectionData,
        rowData,
        totalLabel,
        inputsLabel
      );
    } else {
      setModalData({
        rowData: rowData,
        totalLabel: totalLabel,
        inputsLabel: inputsLabel,
        unitName: unitName,
        isopen: true,
        name: rowData.name,
        email: rowData.email,
      });
    }
  }
};

export default handleSelfChange;

export const updateColumnDataForUserUnits = (
  sectionData,
  setSectionData,
  rowData,
  totalLabel,
  inputsLabel
) => {
  const sizeOfSectionData = sectionData?.length;

  // Calculate the total for the remaining row using reduce
  const totalForRemainingRow = sectionData?.reduce((total, el, idx) => {
    if (el.id === rowData.id) {
      return total - rowData[inputsLabel];
    }
    if (idx > 1 && idx < sizeOfSectionData - 2) {
      return total - el[inputsLabel];
    }
    return total;
  }, sectionData[1][totalLabel]);

  setSectionData(
    sectionData.map((el, index) => {
      if (el.id === rowData.id) return rowData;
      else if (index > 1 && index < sizeOfSectionData - 2) {
        return {
          ...el,
          [inputsLabel]: el[inputsLabel],
          [totalLabel]: el[inputsLabel] + totalForRemainingRow,
        };
      } else if (index === sizeOfSectionData - 2) {
        const adjustedTotal =
          totalForRemainingRow < 0 ? 0 : totalForRemainingRow;

        const removeErrorTriangle =
          adjustedTotal === 0 &&
          ![
            "solo_starter_entered",
            "duel_dynamics_entered",
            "focus_five_entered",
            "all_available_entered",
          ]
            ?.filter((label) => label !== inputsLabel)
            ?.some((label) => el[label] > 0);

        return {
          ...el,
          error: removeErrorTriangle ? { name: false, email: false } : el.error,
          [inputsLabel]: adjustedTotal,
          [totalLabel]: adjustedTotal,
        };
      }
      return el;
    })
  );
};

const rowAdditionForUserUnits = (
  sectionData,
  setSectionData,
  rowData,
  totalLabel,
  inputsLabel
) => {
  const sizeOfSectionData = sectionData?.length;
  const currentId = sectionData[sizeOfSectionData - 2].id.split("r");

  const newId = `other${Number.parseInt(currentId[1], 10) + 1}`;

  // const prevOther = sectionData[sizeOfSectionData - 2];

  const totalForRemainingRow = sectionData?.reduce((total, el, idx) => {
    if (el.id === rowData.id) {
      return total - rowData[inputsLabel];
    }
    if (idx > 1 && idx < sizeOfSectionData - 1) {
      return total - el[inputsLabel];
    }
    return total;
  }, sectionData[1][totalLabel]);

  const newOther = {
    id: newId,
    name: "",
    email: "",
    solo_starter_entered: 0,
    duel_dynamics_entered: 0,
    focus_five_entered: 0,
    all_available_entered: 0,
    solo_starter_total: 0,
    duel_dynamics_total: 0,
    focus_five_total: 0,
    all_available_total: 0,
    [inputsLabel]: totalForRemainingRow,
    [totalLabel]: totalForRemainingRow,
  };

  const updatedSectionData = sectionData.map((el, idx) => {
    if (el.id === rowData.id) return rowData;
    else if (idx > 1 && idx < sizeOfSectionData - 2)
      return {
        ...el,
        [inputsLabel]: el[inputsLabel],
        [totalLabel]: el[inputsLabel] + totalForRemainingRow,
      };
    return el;
  });
  setSectionData(
    updatedSectionData.toSpliced(sizeOfSectionData - 1, 0, newOther)
  );
};

const updateColumnBasedOnSelfChange = (
  sectionData,
  setSectionData,
  rowData,
  totalLabel,
  inputsLabel,
  unitName,
  setModalData
) => {
  const sizeOfSectionData = sectionData?.length;
  const total = sectionData?.find((el) => el[unitName] === "Total");

  const prevSelfValue = sectionData?.find((el) => el.id === rowData.id)[
    inputsLabel
  ];
  const currentSelfValue = rowData[inputsLabel];

  if (prevSelfValue !== currentSelfValue && sizeOfSectionData === 4) {
    const updatedSectionData = sectionData.map((row) => {
      if (row.id === rowData.id) return rowData;
      else if (row[unitName] === "Other") {
        return {
          ...row,
          [totalLabel]: total[totalLabel] - rowData[inputsLabel],
        };
      } else if (row.id === sectionData[sizeOfSectionData - 2].id) {
        const selfIncreased = prevSelfValue < currentSelfValue;

        const newTotal = row[totalLabel] + (selfIncreased ? -1 : 1);
        return {
          ...row,
          [inputsLabel]: newTotal,
          [totalLabel]: newTotal,
        };
      }
      return row;
    });
    setSectionData(updatedSectionData);
  } else if (prevSelfValue !== currentSelfValue) {
    // for number of rows greater than 4
    let rowToBeDeleted = {};

    const updatedSectionData = sectionData.map((packag, idx) => {
      if (packag.id === rowData.id) return rowData;
      else if (packag[unitName] === "Other") {
        return {
          ...packag,
          [totalLabel]: total[totalLabel] - rowData[inputsLabel],
        };
      }

      // apply changes when self is changed

      const selfIncreased = prevSelfValue - currentSelfValue < 0 ? true : false;

      const penultimateRow = sectionData[sizeOfSectionData - 2];

      const lastNonZeroEntry = sectionData?.findLast(
        (el) => el[inputsLabel] > 0
      );

      // when current entered value of self is greater than its previous value
      if (selfIncreased) {
        // No Remaining Row and value of last row should be decreased by 1
        if (
          packag.id === lastNonZeroEntry.id &&
          lastNonZeroEntry[inputsLabel] === lastNonZeroEntry[totalLabel] &&
          lastNonZeroEntry[inputsLabel] !== 0
        ) {
          // For row Deletion when self changes
          if (packag[inputsLabel] === 1) {
            Object.assign(rowToBeDeleted, {
              ...packag,
              [inputsLabel]: packag[inputsLabel] - 1,
              [totalLabel]: packag[totalLabel] - 1,
            });
          }

          return {
            ...packag,
            [inputsLabel]: packag[inputsLabel] - 1,
            [totalLabel]: packag[totalLabel] - 1,
          };
        }
        // Values of total and default are equal of Remaining row and input and total values should be decreased by 1
        else if (
          packag.id === penultimateRow.id &&
          packag[inputsLabel] === packag[totalLabel] &&
          packag[inputsLabel] !== 0
        ) {
          // For row Deletion when self changes
          if (
            packag[inputsLabel] === 1 &&
            packag.name !== "" &&
            packag.email !== ""
          ) {
            Object.assign(rowToBeDeleted, {
              ...packag,
              [inputsLabel]: packag[inputsLabel] - 1,
              [totalLabel]: packag[totalLabel] - 1,
            });
          }

          return {
            ...packag,
            [inputsLabel]: packag[inputsLabel] - 1,
            [totalLabel]: packag[totalLabel] - 1,
          };
        }
        //  Values of total and default are equal of Remaining row and In every rows total should be decresed by 1
        else if (
          idx > 1 &&
          idx < sizeOfSectionData - 2 &&
          packag[totalLabel] !== 0
        ) {
          return {
            ...packag,
            [totalLabel]: packag[totalLabel] - 1,
          };
        }
      }
      // when current Value of self is less than its previous value
      else {
        // increase the value of input and total in remaining row and increase total in every row
        if (packag.id === penultimateRow.id) {
          return {
            ...packag,
            [inputsLabel]: packag[inputsLabel] + 1,
            [totalLabel]: packag[totalLabel] + 1,
          };
        } else if (idx > 1 && idx < sizeOfSectionData - 2) {
          return {
            ...packag,
            [totalLabel]: packag[totalLabel] + 1,
          };
        }
      }
      return packag;
    });

    const validSelfChange = [
      "solo_starter_entered",
      "duel_dynamics_entered",
      "focus_five_entered",
      "all_available_entered",
    ]
      ?.filter((label) => label !== inputsLabel)
      ?.some(
        (label) =>
          Object.keys(rowToBeDeleted)?.length === 0 ||
          rowToBeDeleted[label] > 0 ||
          rowToBeDeleted.name === "" ||
          rowToBeDeleted.email === ""
      );

    if (validSelfChange) {
      const sectionDataWithoutErrorSign = updatedSectionData.map((row) => {
        if (row.id === rowToBeDeleted.id)
          return { ...row, error: { name: false, email: false } };
        return row;
      });
      setSectionData(sectionDataWithoutErrorSign);
    } else {
      setModalData({
        rowData: rowToBeDeleted,
        totalLabel: totalLabel,
        inputsLabel: inputsLabel,
        unitName: unitName,
        isopen: true,
        isSelf: true,
        name: rowToBeDeleted.name,
        email: rowToBeDeleted.email,
      });
    }
  }
};
