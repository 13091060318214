import { Box, Button, Modal, Typography } from "@mui/material";
import FocusTrap from "@mui/material/Unstable_TrapFocus";
import React from "react";

const RowDeletionModal = ({ modalData, setModalData, setDeleteTheRow }) => {
  const handleYesClicked = () => {
    setModalData({
      ...modalData,
      isopen: false,
    });
    setDeleteTheRow(true);
  };

  const handleNoClicked = () => {
    setModalData({
      ...modalData,
      isopen: false,
    });
    setDeleteTheRow(false);
  };

  return (
    <FocusTrap open>
      <Modal
        open={modalData.isopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            borderRadius: "2%",
          }}
        >
          {modalData?.email && (
            <Typography id="modal-title" variant="h6" component="h2">
              {"Are you sure do you want to delete the user with Name " +
                modalData.name +
                " and Email " +
                modalData.email}
            </Typography>
          )}
          {!modalData?.email && (
            <Typography id="modal-title" variant="h6" component="h2">
              {"Are you sure do you want to delete " +
                modalData.name +
                " Allocation"}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button variant="outlined" onClick={handleNoClicked}>
              No
            </Button>
            <Button variant="contained" onClick={handleYesClicked}>
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </FocusTrap>
  );
};

export default RowDeletionModal;
