import React, { useState, useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import {
  TextField,
  Button,
  CircularProgress,
  Box,
  Container,
  Typography,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";

const Users = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const {
    userProfile,
    updateUserProfile,
    azureProfile,
    interestedBuyerGuides,
    isProfileLoading,
    setNotification
  } = useContext(UserProfileContext);
  const [displayName, setDisplayName] = useState("");
  const [mail, setMail] = useState("");
  const [givenName, setGivenName] = useState("");
  const [surname, setSurname] = useState("");
  const [companyName, setCompanyName] = useState(
    userProfile?.companyName || ""
  );
  const [jobTitle, setJobTitle] = useState(userProfile?.jobTitle || "");
  const [profileType, setProfileType] = useState(userProfile?.profileType);
  const [loading, setLoading] = useState(false);
 const [errors, setErrors] = useState({
    displayName: "",
    givenName: "",
    surname: "",
    companyName: "",
    jobTitle: ""
  });
  const handleProfileUpdate = async (updatedProfileData) => {
    try {
      const accessToken = await instance.acquireTokenSilent({
        scopes: ["https://graph.microsoft.com/User.ReadWrite"],
      });
      await fetch("https://graph.microsoft.com/v1.0/me", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken.accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedProfileData),
      });
      setNotification({
        open: true,
        message: "Profile updated successfully",
        severity: "success",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error updating user profile:", error);
      setNotification({
        open: true,
        message: "Error updating profile",
        severity: "error",
      });
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleProfileUpdate({
      displayName,
      mail: azureProfile?.mail,
      givenName,
      surname,
    });
    await updateUserProfile({
      email: mail,
      givenName,
      surname,
      companyName,
      displayName,
      profileType,
      jobTitle,
    });
    if (profileType === "endUser") {
      if(!isProfileLoading){
      if (interestedBuyerGuides && interestedBuyerGuides.length > 0) {
        navigate("/select-buyer-guide/buyer-guide-of-interest");
      } else {
        navigate("/select-buyer-guide/available-buyer-guides");
      }
      }else{
          return (<Box mt={4} textAlign="center">
              <CircularProgress />
          </Box>)
      }
    } else {
      navigate("/select-end-user-org", { state: { profileType, companyName } });
    }
  };
  const validateLength = (value) => {
    return value.length > 30 ? "Input must not exceed 30 characters" : "";
  };
 
  useEffect(() => {
    if (azureProfile) {
      setDisplayName(azureProfile?.displayName || "");
      setGivenName(azureProfile?.givenName || "");
      setSurname(azureProfile?.surname || "");
      setMail(azureProfile?.mail || "");
    }
  }, [azureProfile]);

  useEffect(() => {
    if (userProfile) {
      setCompanyName(userProfile?.companyName || "");
      setJobTitle(userProfile?.jobTitle || "");
      setProfileType(userProfile?.profileType || "");
    }
  }, [userProfile]);
  return (
    <Container maxWidth="sm" sx={{ maxWidth: "400px" }}>
      {azureProfile && !isProfileLoading ? (
        <Paper
          elevation={3}
          sx={{ padding: 3, marginTop: 4, backgroundColor: "#fff" }}
        >
          <Typography variant="h1" align="center" gutterBottom>
            Update Profile
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
              value={mail}
              disabled
              required
            />
            <TextField
              inputProps={{
                'data-test-id': 'displayName',
              }}
              label="Display Name"
              variant="outlined"
              margin="normal"
              fullWidth
              value={displayName}
              onChange={(e) => {
                setDisplayName(e.target.value);
                setErrors({ ...errors, displayName: validateLength(e.target.value) });
              }}
              required
              error={!!errors.displayName}
              helperText={errors.displayName}
            />
            <TextField
              inputProps={{
                'data-test-id': 'givenName',
              }}
              label="Given Name"
              variant="outlined"
              margin="normal"
              fullWidth
              value={givenName}
              onChange={(e) => {
                setGivenName(e.target.value);
                setErrors({ ...errors, givenName: validateLength(e.target.value) });
              }}
              required
              error={!!errors.givenName}
              helperText={errors.givenName}
            />
            <TextField
              inputProps={{
                'data-test-id': 'surname',
              }}
              label="Surname"
              variant="outlined"
              margin="normal"
              fullWidth
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
                setErrors({ ...errors, surname: validateLength(e.target.value) });
              }}
              required
              error={!!errors.surname}
              helperText={errors.surname}
            />
            <TextField
              inputProps={{
                'data-test-id': 'companyName',
              }}
              label="Company Name"
              variant="outlined"
              margin="normal"
              fullWidth
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
              required
            />
            <TextField
              inputProps={{
                'data-test-id': 'jobTitle',
              }}
              label="Job Title"
              variant="outlined"
              margin="normal"
              fullWidth
              value={jobTitle}
              onChange={(e) => {
                setJobTitle(e.target.value);
                setErrors({ ...errors, companyName: validateLength(e.target.value) });
              }}
              required
              error={!!errors.companyName}
              helperText={errors.companyName}
            />

            <FormControl component="fieldset" margin="normal" fullWidth>
              <FormLabel component="legend">Profile Type</FormLabel>
              <RadioGroup
                value={profileType}
                onChange={(e) => {
                  setProfileType(e.target.value);
                }}
              >
                <FormControlLabel
                  value="endUser"
                  control={<Radio inputProps={{ 'data-test-id': 'profileTypeEndUser' }} />}
                  label="End-user"
                />
                <FormControlLabel
                  value="consultant"
                  control={<Radio inputProps={{ 'data-test-id': 'profileTypeConsultant' }} />}
                  label="Consultant"
                />
              </RadioGroup>
            </FormControl>

            <Box textAlign="center" mt={2}>
              <Button
                sx={{ width: "200px" }}
                type="submit"
                disableRipple
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Update Profile"}
              </Button>
            </Box>
          </form>
        </Paper>
      ) : (
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default Users;
