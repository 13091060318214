import React, { useState, useContext, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";

import { createPurchaseNode } from "../utils/utils";
import NestedRadio from "../components/NestedRadio";

const UsePurchasedPackage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { purchasePackage, currentOrganization, buyerGuideName, userProfile } =
    useContext(UserProfileContext);

  const [headerSelected, setHeaderSelected] = useState("Unlock Vendors");
  const [childSelected, setChildSelected] = useState("Based on Offer Metrics");

  const radioData = [
    {
      header: "Unlock Vendors",
      options: ["Based on Offer Metrics", "Based on Vendor Names"],
    },
    {
      header: "Don't Unlock Vendors yet",
      options: [
        "Take me to 'Results' page",
        "Take me to 'My Subscriptions' page",
      ],
    },
  ];

  const headerData = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Purchased Package": purchasePackage,
    "Volume Unit": "1",
  };

  const handleNavigate = () => {
    if (childSelected === "Based on Offer Metrics")
      navigate("/results/comparative-analysis");
    else if (childSelected === "Based on Vendor Names")
      navigate("/results/comparative-analysis");
    else if (childSelected === "Take me to 'Results' page")
      navigate("/results/comparative-analysis");
    else if (childSelected === "Take me to 'My Subscriptions' page")
      navigate("/my-subscriptions");
  };

  const createSinglePurchaseNode = async (packages) => {
    await createPurchaseNode(
      "https://newtestfuncpython.azurewebsites.net/api/createSinglePurchaseNode?",
      userProfile?.email,
      packages
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // const sessionId = queryParams.get("session_id");
    const customerId = queryParams.get("customer_id");
    const packageDetailsStr = queryParams.get("packageDetails");
    let packageDetails;
    if (packageDetailsStr) {
      packageDetails = JSON.parse(decodeURIComponent(packageDetailsStr));
    }

    if (customerId && packageDetails) {
      const packages = packageDetails?.[0]?.map((item) => ({
        customerId,
        packageType:
          item === 1
            ? "Duel Dynamics"
            : item === 2
            ? "Focus Five"
            : "All Available",
      }));
      createSinglePurchaseNode(packages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box>
      <HeaderNaming data={headerData} />
      <Typography>
        Let's use the package to "unlock" vendors and their respective offers to
        see complete analysis.
      </Typography>

      <Box>
        <NestedRadio
          setHeaderSelected={setHeaderSelected}
          headerSelected={headerSelected}
          setChildSelected={setChildSelected}
          childSelected={childSelected}
          radioData={radioData}
        />
      </Box>
      <Box>
        {headerSelected === "Unlock Vendors" && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigate}
            sx={{ marginTop: "16px", width: "250px" }}
          >
            Unlock
          </Button>
        )}
        {headerSelected === "Don't Unlock Vendors yet" && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNavigate}
            sx={{ marginTop: "16px", width: "250px" }}
          >
            Exit to selected page
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UsePurchasedPackage;
