
import React, { useState, useContext } from 'react';
import { Box, Typography, Button, Radio, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Snackbar, ThemeProvider, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../theme/theme';
import { UserProfileContext } from "../context/UserContext";

const BuyerGuideMenu = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const muiTheme = useTheme();
 const { buyerGuides } = location.state;
 const { userProfile } = useContext(UserProfileContext);

 const [selectedRow, setSelectedRow] = useState(null);
 const [collaboratorEmail, setCollaboratorEmail] = useState('');
 const [openInvite, setOpenInvite] = useState(false);
 const [openSnackbar, setOpenSnackbar] = useState(false);

 const columns = [
   {
     field: 'select',
     headerName: '',
     width: 100,
     headerClassName: 'super-app-theme--header',
     renderCell: (params) => (
       <Radio
       data-test-id="radio-button"
         checked={selectedRow === params.id}
         onChange={() => setSelectedRow(params.id)}
         value={params.id}
       />
     ),
   },
   { field: 'name', headerName: 'Buyer Guide Name', flex: 1, headerClassName: 'super-app-theme--header'},
   { field: 'version', headerName: 'Version', flex: 1, headerClassName: 'super-app-theme--header' },
   { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'super-app-theme--header'},
   { field: 'created_by', headerName: 'Created By', flex: 1.5, headerClassName: 'super-app-theme--header' },
 ];

 const rows = buyerGuides.map((guide, index) => ({
   id: index,
   name: guide.name,
   version: guide.version || 'N/A',
   status: guide.status || 'N/A',
   created_by: guide.created_by,
   element_id: guide.element_id  // Ensure you are including the element_id from your data
}));

const fetchBuyerGuideData = async () => {
 const apiUrl = 'https://newtestfuncpython.azurewebsites.net/api/getExistingBuyerGuideForAnalyst';
 const selectedGuide = rows.find(row => row.id === selectedRow);
 const postData = {
   userEmail: userProfile.email,
  //  userEmail: "nitin.acharekar@gmail.com", 
   buyerGuideElementId: selectedGuide?.element_id
 };

 try {
   const response = await fetch(apiUrl, {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(postData)
   });



   if (!response.ok) throw new Error(`Failed to fetch buyer guide data: ${response.statusText} (status ${response.status})`);


   const data = await response.json();

   navigate('/analysis', { state: { buyerGuideData: data } });
 } catch (error) {
  
   console.error('Error fetching buyer guide data:', error);
 }
};


 const handleOpenInvite = () => {
   setOpenInvite(true);
 };

 const handleCloseInvite = () => {
   setOpenInvite(false);
 };

 const handleSaveInvite = async () => {
   const apiUrl = 'https://newtestfuncpython.azurewebsites.net/api/inviteAnalystToCreateBuyerGuide';
   const selectedGuide = rows.find(row => row.id === selectedRow);

   const postData = {
     userEmail: userProfile.email,
    //  userEmail: "nitin.acharekar@gmail.com",
     collaboratorEmail,
     buyerGuideElementId: selectedGuide?.element_id
   };

   try {
     const response = await fetch(apiUrl, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json'
       },
       body: JSON.stringify(postData)
     });

     if (response.ok) {
       setOpenSnackbar(true);
       handleCloseInvite();
     } else {
       throw new Error('Failed to send invitation');
     }
   } catch (error) {
     console.error('Error:', error);
   }
 };

 const handleCloseSnackbar = () => {
   setOpenSnackbar(false);
 };

 const renderButtons = () => {
   if (selectedRow !== null) {
     const selectedGuide = rows.find(row => row.id === selectedRow);

     if (selectedGuide && selectedGuide.status === 'In progress') {
       return (
         <>
           <Button
             data-test-id='button-Edit Buyer Guide'
             variant="contained"
             color="primary"
             sx={{ width: '250px' }}
             onClick={fetchBuyerGuideData}
           >
             Edit Buyer Guide
           </Button>
           <Button
             data-test-id='button-Invite Collaborator'
             variant="contained"
             color="secondary"
             sx={{ width: '250px' }}
             onClick={handleOpenInvite}
           >
             Invite Collaborator
           </Button>
         </>
       );
     } else if (selectedGuide && ['Live', 'End of Sale', 'End of Support'].includes(selectedGuide.status)) {
       return (
         <Button
           data-test-id='button-Create New Version'
           variant="contained"
           color="primary"
           sx={{ width: '250px' }}
           // onClick={() => navigate('/analysis')}
           onClick={fetchBuyerGuideData}
         >
           Create New Version
         </Button>
       );
     }
   }

   return (
     <Button
       data-test-id='button-Start New Buyer Guide'
       variant="contained"
       color="secondary"
       sx={{ width: '250px' }}
       onClick={() => navigate('/create-new-buyer-guide')}
     >
       Start New Buyer Guide
     </Button>
   );
 };

 return (
   <ThemeProvider theme={theme}>
     <Box sx={{ height: 400, width: '100%' }}>
       <Typography variant="h4" sx={{ mb: 2 }} data-test-id='Buyer Guides (Analyst View)'>
         Buyer Guides (Analyst View)
       </Typography>
       <Box>
         <DataGrid
           data-test-id="Table"
           disableRowSelectionOnClick
           rows={rows}
           columns={columns}
           hideFooter
           pageSize={5}
           rowsPerPageOptions={[5]}
           checkboxSelection={false}
           sx={{
             width: '100%',
             height: '100%',
             '& .super-app-theme--header': {
               backgroundColor: muiTheme.palette.primary.main,
               color: 'white',
               fontSize: '14px',
               '.MuiSvgIcon-root': {
                 color: 'white',
               }
             },
             '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
               fontWeight: 'normal',
             },
             '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
               outline: 'none !important',
             },
           }}
         />
       </Box>
       <Box mt={2} display="flex" flexDirection="column" gap={2}>
         {renderButtons()}
       </Box>
     </Box>
     <Dialog
     data-test-id="Dialog-box"
 open={openInvite}
 onClose={handleCloseInvite}
 maxWidth="sm"
 fullWidth
 sx={{
   '& .MuiDialog-paper': {
     width: '400px',
     maxWidth: '400px',
   },
 }}
>
 <DialogTitle data-test-id="Invite Collaborator">Invite Collaborator</DialogTitle> {/* Corrected the closing tag here */}
 <DialogContent>
   <TextField
   data-test-id="text-field"
     autoFocus
     margin="dense"
     id="collaboratorEmail"
     label="Collaborator's Email"
     type="email"
     fullWidth
     variant="standard"
     value={collaboratorEmail}
     onChange={(e) => setCollaboratorEmail(e.target.value)}
   />
 </DialogContent>
 <DialogActions>
   <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
     <Button data-test-id="button-cancel" variant="outlined" onClick={handleCloseInvite} sx={{ mr: 2 }}>Cancel</Button>
     <Button data-test-id="button-Send Invitation" variant="contained" onClick={handleSaveInvite} color="primary">Send Invitation</Button>
   </Box>
 </DialogActions>
</Dialog>

     <Snackbar
      data-test-id="message"
       open={openSnackbar}
       autoHideDuration={6000}
       onClose={handleCloseSnackbar}
       message="Invitation sent successfully!"
     />
   </ThemeProvider>
 );
};

export default BuyerGuideMenu;


