import axios from "axios";

export async function apiService(
  url,
  options = {},
  setLoading,
  setData,
  handleError
) {
  try {
    if (setLoading) {
      setLoading(true);
    }

    const response = await axios({
      url,
      method: options.method || "GET",
      headers: {
        "Content-Type": "application/json",
        ...options.headers,
      },
      ...options,
    });
    const data = response.data;

    if (setData && data) {
      setData(data);
    }
    if (setLoading) {
      setLoading(false);
    }

    return data;
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);

    if (handleError) {
      handleError(error);
    }
    if (setLoading) {
      setLoading(false);
    }

    throw error; // Rethrow the error if you want the caller to handle it as well
  }
}

export const getColumnUniqueValues = (column, section) => {
  const allValues = section?.data?.map((row) => row[column]);
  return Array.from(new Set(allValues))
    .filter((value) => value)
    .sort((a, b) => {
      if (!isNaN(a) && !isNaN(b)) {
        return parseFloat(a) - parseFloat(b);
      }
      return a.localeCompare(b);
    });
};

export const sortData = (data, column, order) => {
  return data.sort((a, b) => {
    const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);
    const valueA = isNumeric(a[column]) ? parseFloat(a[column]) : a[column];
    const valueB = isNumeric(b[column]) ? parseFloat(b[column]) : b[column];

    if (order === "asc") {
      if (isNumeric(valueA) && isNumeric(valueB)) {
        return valueA - valueB;
      }
      return valueA.localeCompare(valueB);
    } else {
      if (isNumeric(valueA) && isNumeric(valueB)) {
        return valueB - valueA;
      }
      return valueB.localeCompare(valueA);
    }
  });
};

export const handleSort = (data, column, sortOrder, setSortOrder) => {
  const order = sortOrder[column] === "asc" ? "desc" : "asc";
  const sortedData = sortData([...data], column, order);
  setSortOrder({ [column]: order });
  return sortedData;
};

export const applyFilters = (data, columns, filters) => {
  return data.filter((row) => {
    return columns.every((column) => {
      const filterValue = filters[column];
      if (!filterValue) return true;

      let condition = ">";
      let value = filterValue;

      if (filterValue.startsWith("<")) {
        condition = "<";
        value = filterValue.substring(1);
      } else if (filterValue.startsWith(">")) {
        condition = ">";
        value = filterValue.substring(1);
      } else if (filterValue.startsWith("=")) {
        condition = "=";
        value = filterValue.substring(1);
      }

      let cellValue = row[column];

      if (typeof cellValue === "string" && cellValue.endsWith("%")) {
        cellValue = cellValue.slice(0, -1);
      }
      if (typeof value === "string" && value.endsWith("%")) {
        value = value.slice(0, -1);
      }

      if (!isNaN(parseFloat(cellValue)) && isFinite(cellValue)) {
        const numericValue = parseFloat(cellValue);
        const numericFilterValue = parseFloat(value);

        if (condition === ">") {
          return numericValue >= numericFilterValue;
        } else if (condition === "<") {
          return numericValue < numericFilterValue;
        } else {
          return numericValue === numericFilterValue;
        }
      } else {
        return cellValue.toLowerCase().includes(value.toLowerCase());
      }
    });
  });
};

export const handleFilterChange = (column, value, setFilters) => {
  setFilters((prev) => ({ ...prev, [column]: value }));
};

export const handleFilterIconClick = (
  event,
  column,
  setFilterAnchorEl,
  setFilterColumn
) => {
  setFilterAnchorEl(event.currentTarget);
  setFilterColumn(column);
};

export const handleFilterClose = (setFilterAnchorEl, setFilterColumn) => {
  setFilterAnchorEl(null);
  setFilterColumn("");
};

export const handleCheckout = async (
  email = "sample@yopmail.com",
  getProducts,
  packageCount,
  getPackageDetails,
  getProductsForSub
) => {
  try {
    const response = await fetch(
      "https://newtestfuncpython.azurewebsites.net/api/createSubscription?",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          products: getProducts(),
          productsForSub: getProductsForSub(),
          package_count: packageCount,
          packageDetails:
            typeof getPackageDetails === "object"
              ? getPackageDetails
              : getPackageDetails(), // Pass the package count to the API
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create checkout session");
    }

    const data = await response.json();
    const { url } = data;
    if (url) {
      window.location.href = url; // Redirect the user to the checkout page
    } else {
      throw new Error("Checkout URL not found in response");
    }
  } catch (error) {
    console.error(error);
    // Handle error
  }
};

export const modifySubscriptionBillingCycle = async (email) => {
  try {
    const response = await fetch(
      "https://newtestfuncpython.azurewebsites.net/api/modifySubscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to modify subscription billing cycle");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    // Handle error
  }
};

export const createPurchaseNode = async (uri, email, packages) => {
  try {
    const response = await fetch(uri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        packages,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to modify subscription billing cycle");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    // Handle error
  }
};
