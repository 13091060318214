import React, { useState, useEffect } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import theme from "../../theme/theme.js";

import DataGridTable from "../../components/DataGridTable.jsx";

import generateColumnData from "../../utils/Allocation/generateColumnData.jsx";
import RowDeletionModal from "../../components/AvailableUnitsForAdminAllocation/RowDeletionModal.jsx";

// For Updation of total value in User Defined row after Deletion of single row
import updateColumnsForOtherUnits from "../../utils/Allocation/updateColumnsForOtherUnits.jsx";
import { updateColumnDataForUserUnits } from "../../utils/Allocation/handleSelfChange.jsx";

const AllocationOfUnits = ({ idx, page, rowData, setIdx, setRadioData }) => {
  // All the data
  const [sectionData, setSectionData] = useState([]);

  //For Row Deletion Modal
  const [modalData, setModalData] = useState({
    isopen: false,
  });
  const [deleteTheRow, setDeleteTheRow] = useState(false);

  useEffect(() => {
    if (page === "user_units") {
      setSectionData(
        rowData?.map((row) => {
          if (row.id === rowData[rowData?.length - 2].id)
            return {
              ...row,
              error: { name: false, email: false },
            };
          return row;
        })
      );
    } else setSectionData(rowData);
  }, [rowData, page]);

  // console.log(" Rendering: rowData sectionData", rowData, sectionData);

  // useEffect(() => {
  // console.log("useEffect 2 triggered: section changed", sectionData);
  // }, [sectionData]);

  useEffect(() => {
    if (deleteTheRow === true) {
      let rowData = modalData.rowData;
      const unitName = modalData.unitName;
      const totalLabel = modalData.totalLabel;
      const inputsLabel = modalData.inputsLabel;
      const isSelf = modalData?.isSelf; // if the row deletion is called due to change in self the only this value will exist

      let updatedSectionData = [];
      //Deleting the whole row if there are other more than one rows then deleted row else resetting the original row
      if (sectionData?.length > 3) {
        updatedSectionData = sectionData?.filter((el) => {
          if (el.id === modalData.rowData.id) return false;
          return true;
        });
      } else {
        updatedSectionData = sectionData?.map((el) => {
          if (
            el.id === modalData?.rowData?.id &&
            unitName === "organisation_name"
          ) {
            rowData = {
              ...el,
              [unitName]: "",
              [inputsLabel]: 0,
            };
            return {
              ...el,
              [unitName]: "",
              [inputsLabel]: 0,
            };
          } else if (
            el.id === modalData?.rowData?.id &&
            unitName !== "organisation_name"
          ) {
            const updatedUnitsObject = {
              ...rowData[unitName],
              selected: "None",
            };
            rowData = {
              ...el,
              [unitName]: updatedUnitsObject,
              [inputsLabel]: 0,
            };
            return {
              ...el,
              [unitName]: updatedUnitsObject,
              [inputsLabel]: 0,
            };
          }
          return el;
        });
      }

      setDeleteTheRow(false);

      if (unitName === "user") {
        // For updating second last row in User Units Page
        if (isSelf)
          updatedSectionData = updatedSectionData?.map((el) => {
            if (el[unitName] === "Self") {
              return {
                ...el,
                [inputsLabel]: 1,
              };
            } else if (el[unitName] === "Other")
              return {
                ...el,
                [inputsLabel]: el[inputsLabel] - 1,
                [totalLabel]: el[totalLabel] - 1,
              };
            return el;
          });

        updateColumnDataForUserUnits(
          updatedSectionData,
          setSectionData,
          rowData,
          totalLabel,
          inputsLabel
        );
      } else {
        // For updation of User Defined rows value
        updateColumnsForOtherUnits(
          updatedSectionData,
          setSectionData,
          rowData,
          totalLabel,
          inputsLabel
        );
      }
    }
  }, [modalData, sectionData, deleteTheRow]);

  const handleAllocate = () => {
    const isinputsEmpty = sectionData?.some((el) => {
      if (page === "user_units")
        return (
          (el?.name === "" || el?.email === "") &&
          (el.solo_starter_entered > 0 ||
            el.duel_dynamics_entered > 0 ||
            el.focus_five_entered > 0 ||
            el.all_available_entered > 0)
        );
      if (page === "end_user_organization_units")
        return (
          el?.organisation_name === "" &&
          (el.solo_starter_entered > 0 ||
            el.duel_dynamics_entered > 0 ||
            el.focus_five_entered > 0 ||
            el.all_available_entered > 0)
        );
      return false;
    });

    if (isinputsEmpty) {
      setSectionData(
        sectionData?.map((el) => {
          const inValidRow =
            (el?.name === "" || el?.email === "") &&
            (el.solo_starter_total > 0 ||
              el.duel_dynamics_total > 0 ||
              el.focus_five_total > 0 ||
              el.all_available_total > 0);

          if (inValidRow) {
            let er = el.error;
            if (el?.name === "") er = { ...er, name: true };
            if (el?.email === "") er = { ...er, email: true };

            return {
              ...el,
              error: er,
            };
          }
          return el;
        })
      );
    } else {
      sessionStorage.setItem("idx", idx + 1);
      sessionStorage.setItem(page, JSON.stringify(sectionData));
      setRadioData((radioData) => {
        const radioDataForState = radioData?.map((el) => {
          if (el.page === page) return { ...el, prescription: true };
          return el;
        });

        const sessionRadioData = JSON.parse(
          sessionStorage.getItem("latestRadioData")
        );
        if (sessionRadioData) {
          const updatedRadioData = sessionRadioData?.map((el) =>
            el.page === page ? { ...el, prescription: true } : el
          );
          sessionStorage.setItem(
            "latestRadioData",
            JSON.stringify(updatedRadioData)
          );
        } else
          sessionStorage.setItem(
            "latestRadioData",
            JSON.stringify(radioDataForState)
          );

        return radioDataForState;
      });

      setIdx((idx) => idx + 1);
    }
  };

  const handleGoBack = () => {
    if (idx) {
      sessionStorage.setItem("idx", idx - 1);
      setIdx((idx) => idx - 1);
    }

    if (sectionData?.length > 0)
      sessionStorage.setItem(page, JSON.stringify(sectionData));
  };

  let columns = generateColumnData(
    page,
    sectionData,
    setSectionData,
    setModalData,
    modalData
  );

  // For Removal of column if total is zero or undefined
  if (columns && sectionData)
    columns = columns?.filter((col) => {
      if (col.field === "solo_starter_total")
        return sectionData[sectionData?.length - 1]?.solo_starter_total > 0;
      else if (col.field === "duel_dynamics_total")
        return sectionData[sectionData?.length - 1]?.duel_dynamics_total > 0;
      else if (col.field === "focus_five_total")
        return sectionData[sectionData?.length - 1]?.focus_five_total > 0;
      else if (col.field === "all_available_total")
        return sectionData[sectionData?.length - 1]?.all_available_total > 0;
      return true;
    });

  if (
    !columns ||
    columns === "undefined" ||
    columns === null ||
    !sectionData ||
    sectionData === "undefined" ||
    sectionData === null
  ) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 4,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {modalData.isopen && (
        <RowDeletionModal
          modalData={modalData}
          setModalData={setModalData}
          setDeleteTheRow={setDeleteTheRow}
        />
      )}
      <Box
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,
            border: "0.5px solid grey",
            borderBottom: "none", // Remove the bottom border
            height: "fit-content",
          },
        }}
      >
        <Typography sx={{ marginLeft: 4, marginBottom: 1 }}>
          For the Allocation of User Units provide the user name and email
        </Typography>
        <DataGridTable
          rows={sectionData}
          columns={columns}
          page={"allocation"}
        />
      </Box>
      <Button
        disableRipple
        variant="contained"
        color="secondary"
        sx={{ marginTop: "16px", width: "250px" }}
        onClick={handleGoBack}
      >
        Go Back
      </Button>
      <Button
        disableRipple
        variant="contained"
        color="primary"
        sx={{ marginTop: "16px", width: "250px" }}
        onClick={handleAllocate}
      >
        {page === "user_units"
          ? "Allocate Users to Packages"
          : page === "end_user_organization_units"
          ? "Allocate End User Organisations to Package"
          : page === "buyer_guide_units"
          ? "Allocate Buyer Guides to Package"
          : "Allocate Vendors Unlocked to Packages"}
      </Button>
    </Box>
  );
};

export default AllocationOfUnits;
