import { Typography } from "@mui/material";

import NumberInput from "../NumberInput.jsx";

import handleSelfChange from "../../../utils/Allocation/handleSelfChange.jsx";
import { useMemo } from "react";

const NumberRenderCell = ({
  page,
  params,
  unitName,
  totalLabel,
  inputsLabel,
  sectionData,
  setSectionData,
  setModalData,
}) => {
  const { row: rowData } = params;

  const isDisabled = useMemo(() => {
    return (
      (rowData[unitName] === "" ||
        rowData.name === "" ||
        rowData.email === "" ||
        rowData[unitName]?.selected === "None") &&
      rowData[inputsLabel] >= 0
    );
  }, [rowData, unitName, inputsLabel]);

  if (rowData[unitName] === "Total" || rowData[unitName] === "Other")
    return <Typography>{rowData[totalLabel]}</Typography>;

  return (
    <NumberInput
      isDisabled={isDisabled}
      max={params.value}
      rowData={rowData}
      inputsLabel={inputsLabel}
      page={page}
      onSet={() => {
        handleSelfChange(
          sectionData,
          setSectionData,
          unitName,
          rowData,
          inputsLabel,
          totalLabel,
          setModalData
        );
      }}
    />
  );
};

export default NumberRenderCell;
