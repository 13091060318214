import React, { useState, useEffect } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./GridComponent.css";
import {
  Box,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import data from "../Widget/InitialData";
import MandatoryUseCaseTable from "../Widget/OfferDetailsWidget/MandatoryUseCaseTable";
import OverallUseCasesTable from "../Widget/OfferDetailsWidget/OverallUseCasesTable";
import SupportedUseCaseMandatoryTable from "../Widget/OfferDetailsWidget/SupportedUseCaseMandatoryTable";
import SupportedUseCaseOverallTable from "../Widget/OfferDetailsWidget/SupportedUseCaseOverallTable";
import UnsupportedUseCaseOverallTable from "../Widget/OfferDetailsWidget/UnsupportedUseCaseOverallTable";
import UnsupportedUseCaseMandatoryTable from "../Widget/OfferDetailsWidget/UnsupportedUseCaseMandatoryTable";
import UnusedGenericTable from "../Widget/OfferDetailsWidget/UnusedGenericTable";
import UnusedNotRequiredTable from "../Widget/OfferDetailsWidget/UnusedNotRequiredTable";

const initialData = data.ValYouMatrixData;

const widgetComponents = {
  widget1: MandatoryUseCaseTable,
  widget2: OverallUseCasesTable,
  widget3: SupportedUseCaseMandatoryTable,
  widget4: SupportedUseCaseOverallTable,
  widget5: UnsupportedUseCaseMandatoryTable,
  widget6: UnsupportedUseCaseOverallTable,
  widget7: UnusedGenericTable,
  widget8: UnusedNotRequiredTable,
};

const widgetLabels = {
  widget1: "Offer Metrics - Mandatory Use Cases",
  widget2: "Offer Metrics - Overall Use Cases",
  widget3: "Top 5 Supported Use Cases (Mandatory)",
  widget4: "Top 5 Supported Use Cases (Overall)",
  widget5: "Top 5 Unupported Use Cases (Mandatory)",
  widget6: "Top 5 Unupported Use Cases (Overall)",
  widget7: "Top 5 Unused Features Widget (Generic)",
  widget8: "Top 5 Unused Features Widget (‘Not Required’ Use Cases)",
};

const widgetOptions = [
  { label: "Offer Metrics - Mandatory Use Cases", key: "widget1" },
  { label: "Offer Metrics - Overall Use Cases", key: "widget2" },
  { label: "Top 5 Supported Use Cases (Mandatory)", key: "widget3" },
  { label: "Top 5 Supported Use Cases (Overall)", key: "widget4" },
  { label: "Top 5 Unupported Use Cases (Mandatory)", key: "widget5" },
  { label: "Top 5 Unupported Use Cases (Overall)", key: "widget6" },
  { label: "Top 5 Unused Features Widget (Generic)", key: "widget7" },
  {
    label: "Top 5 Unused Features Widget (‘Not Required’ Use Cases)",
    key: "widget8",
  },
];

const initialLayout = {
  widget1: {
    i: "widget1",
    x: 0,
    y: 0,
    w: 10,
    h: 7,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
  widget2: {
    i: "widget2",
    x: 0,
    y: 7,
    w: 10,
    h: 7,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
  widget3: {
    i: "widget3",
    x: 0,
    y: 24,
    w: 12,
    h: 10,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
  widget4: {
    i: "widget4",
    x: 0,
    y: 14,
    w: 12,
    h: 10,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
  widget5: {
    i: "widget5",
    x: 0,
    y: 34,
    w: 12,
    h: 10,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
  widget6: {
    i: "widget6",
    x: 0,
    y: 44,
    w: 12,
    h: 10,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
  widget7: {
    i: "widget7",
    x: 0,
    y: 54,
    w: 6,
    h: 6,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
  widget8: {
    i: "widget8",
    x: 6,
    y: 54,
    w: 6,
    h: 6,
    maxW: 12,
    maxH: 35,
    minW: 3,
    minH: 5,
  },
};

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-listbox": {
    backgroundColor: "white",
  },
});

const OfferDetailsGrid = () => {
  const [layout, setLayout] = useState(
      Object.values(initialLayout)
  );
  const [widgets, setWidgets] = useState(
    JSON.parse(localStorage.getItem("offerDetailsWidgets")) ||
      widgetOptions.reduce((acc, option) => {
        acc[option.key] = true;
        return acc;
      }, {})
  );
  const [widgetCounter, setWidgetCounter] = useState(
    JSON.parse(localStorage.getItem("offerDetailsWidgetCounter")) || {
      widget1: 0,
      widget2: 0,
      widget3: 0,
      widget4: 0,
      widget5: 0,
      widget6: 0,
      widget7: 0,
      widget8: 0,
    }
  );

  const [isTableVisible, setIsTableVisible] = useState(
    JSON.parse(localStorage.getItem("offerDetailsIsTableVisible")) || true
  ); // State for table visibility

  useEffect(() => {
    localStorage.setItem("offerDetailsWidgets", JSON.stringify(widgets));
  }, [widgets]);

  useEffect(() => {
    localStorage.setItem(
      "offerDetailsWidgetCounter",
      JSON.stringify(widgetCounter)
    );
  }, [widgetCounter]);

  useEffect(() => {
    localStorage.setItem(
      "offerDetailsIsTableVisible",
      JSON.stringify(isTableVisible)
    );
  }, [isTableVisible]);

  const findNextAvailablePosition = (w, h) => {
    const occupied = new Set();
    layout.forEach((item) => {
      for (let dx = 0; dx < item.w; dx++) {
        for (let dy = 0; dy < item.h; dy++) {
          occupied.add(`${item.x + dx},${item.y + dy}`);
        }
      }
    });

    for (let y = 0; ; y++) {
      for (let x = 0; x <= 12 - w; x++) {
        let fits = true;
        for (let dx = 0; dx < w; dx++) {
          for (let dy = 0; dy < h; dy++) {
            if (occupied.has(`${x + dx},${y + dy}`)) {
              fits = false;
              break;
            }
          }
          if (!fits) break;
        }
        if (fits) {
          return { x, y };
        }
      }
    }
  };

  const toggleWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const updatedWidgets = {
        ...prevWidgets,
        [widgetKey]: !prevWidgets[widgetKey],
      };
      let newLayout;
      if (updatedWidgets[widgetKey]) {
        const widget = initialLayout[widgetKey];
        const position = findNextAvailablePosition(widget.w, widget.h);
        const newWidgetLayout = { ...widget, x: position.x, y: position.y };
        newLayout = [...layout, newWidgetLayout];
      } else {
        newLayout = layout.filter((item) => item.i !== widgetKey);
      }
      setLayout(newLayout);
      return updatedWidgets;
    });
  };

  const handleCopyWidget = (widgetKey) => {
    const widget = layout.find((item) => item.i === widgetKey);
    if (!widget) return;

    const newWidgetKey = `${widgetKey}_Copy_${widgetCounter[widgetKey] + 1}`;
    const position = findNextAvailablePosition(widget.w, widget.h);

    const newWidgetLayout = {
      ...widget,
      i: newWidgetKey,
      x: position.x,
      y: position.y,
    };

    setLayout((prevLayout) => [...prevLayout, newWidgetLayout]);
    setWidgets((prevWidgets) => ({ ...prevWidgets, [newWidgetKey]: true }));
    setWidgetCounter((prevCounter) => ({
      ...prevCounter,
      [widgetKey]: prevCounter[widgetKey] + 1,
    }));
  };

  const handleRemoveWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const updatedWidgets = { ...prevWidgets };
      delete updatedWidgets[widgetKey];
      return updatedWidgets;
    });
    setLayout((prevLayout) =>
      prevLayout.filter((item) => item.i !== widgetKey)
    );
  };

  const handleResizeStop = (newLayout) => {
    setLayout(newLayout);
  };

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const toggleTableVisibility = () => {
    setIsTableVisible((prev) => !prev);
  };

  const renderWidgetContent = ({widgetKey,handleCopyWidget,handleRemoveWidget}) => {
    const WidgetComponent = widgetComponents[widgetKey];

    return WidgetComponent ? (
      <WidgetComponent
        data={initialData}
        isTableVisible={isTableVisible}
        toggleTableVisibility={toggleTableVisibility}
        widgetKey={widgetKey}
        widgetLabels={widgetLabels}
        setLayout={setLayout}
        handleCopyWidget = {handleCopyWidget}
        handleRemoveWidget={handleRemoveWidget}
      />
    ) : null;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flexShrink: 0 }}>
        <StyledAutocomplete
          multiple
          options={widgetOptions}
          getOptionLabel={(option) => option.label}
          value={widgetOptions.filter((option) => widgets[option.key])}
          onChange={(event, newValue) => {
            const newWidgets = {};
            newValue.forEach((option) => {
              newWidgets[option.key] = true;
              if (!widgets[option.key]) toggleWidget(option.key);
            });
            Object.keys(widgets).forEach((key) => {
              if (!newWidgets[key] && widgets[key]) toggleWidget(key);
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Add/Remove Widgets"
              style={{ minWidth: "200px", height: "40px" }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.label}
            </li>
          )}
          sx={{
            marginLeft: "10px",
            flexGrow: 1,
            marginBottom: "50px",
          }}
        />
      </div>

      <div style={{ flexGrow: 1, overflow: "auto" , marginTop:40 }}>
        <GridLayout
          className="layout"
          layout={layout}
          cols={12}
          rowHeight={70}
          width={1400}
          autoSize={true}
          draggableHandle=".widget-header"
          onResizeStop={handleResizeStop}
          onLayoutChange={handleLayoutChange}
        >
          {layout.map((item) =>
            widgets[item.i] ? (
              <Box
                key={item.i}
                className="widget"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                
                <Box className="widget-content" style={{ flexGrow: 1 }}>
                  {renderWidgetContent({widgetKey:item.i,handleCopyWidget,handleRemoveWidget})}
                </Box>
              </Box>
            ) : null
          )}
        </GridLayout>
      </div>
    </Box>
  );
};

export default OfferDetailsGrid;
