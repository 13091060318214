import React, { useContext, useState, useRef } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import ColumnHeaderPopup from "./ColumnHeaderPopup";
import { apiService, applyFilters } from "../utils/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";
import { Snackbar, Alert } from "@mui/material";
import NewScenarioModal from "./ScenarioModal";

const EnhancedTable = ({
  section,
  theme,
  onSort,
  sortOrder,
  filters,
  onFilterIconClick,
  ischeck,
  selectedGuides,
  handleCheckboxChange,
  handleSelectAllChange,
  selectAllChecked,
  handleActionClick,
  showIcons,
  purchasePackage,
  triggerRefresh, // New prop
}) => {
  const {
    setBuyerGuideName,
    setScenarioName,
    userProfile,
    currentOrganization,
    buyerGuideName,
  } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const [, setIsDeleting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [, setDeleteMsg] = useState("");
  const [newScenarioName, setNewScenarioName] = useState("");
  const [isAdding, setAdding] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedScenarioName, setSelectedScenarioName] = useState("");
  const errorMessage = useRef("");
  if (!section || !section?.data || !Array.isArray(section?.data)) {
    return null; // or you can return a fallback UI
  }

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  const deleteScenario = (scenarioName) => {
    apiService(
      "https://newtestfuncpython.azurewebsites.net/api/deleteScenario?",
      {
        method: "POST",
        data: {
          email: userProfile?.email,
          currentOrganisation: currentOrg,
          currentBuyerGuide: buyerGuideName,
          removeScenario: scenarioName,
        },
      },
      setIsDeleting,
      setDeleteMsg,
      (error) => {
        // Handle error if needed
        console.error("Failed to create/update users:", error);
      }
    ).then(() => triggerRefresh()); // Trigger refresh
  };

  const duplicateScenario = async () => {
    try {
      await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/duplicateScenario?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrganization,
            currentBuyerGuide: buyerGuideName,
            copyScenario: selectedScenarioName,
            newScenarioName: newScenarioName,
          },
        },
        setAdding,
        setDeleteMsg,
        (error) => {
          // Handle error if needed
          console.error("Failed to create/update users:", error);
        }
      );
      setOpen(false);
      triggerRefresh();
      // Trigger refresh
    } catch (err) {
      console.log(err);
      errorMessage.current = err.response.data.message;
      setSnackbarOpen(true);
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleAction = (action, scenarioName) => {
    if (action === "Edit") {
      if (purchasePackage === "Solo Starter" || !purchasePackage) {
        navigate("/edit-scenario/update-use-cases");
      }
    }
    if (action === "Delete") {
      deleteScenario(scenarioName);
    }
    if (action === "View") {
      navigate("/results/comparative-analysis");
    }
    if (action === "Duplicate") {
      setOpen(true);
    }
  };

  const getActionIcon = (action) => {
    switch (action) {
      case "View":
        return <VisibilityIcon color="secondary"/>;
      case "Duplicate":
        return <FileCopyIcon color="secondary" />;
      case "Delete":
        return <DeleteIcon color="secondary"/>;
      case "Edit":
        return <EditIcon color="secondary"/>;
      default:
        return null;
    }
  };

  return (
    <>
      <NewScenarioModal
        open={open}
        handleClose={() => setOpen(false)}
        handleSubmit={duplicateScenario}
        value={newScenarioName}
        setValue={setNewScenarioName}
        title="Duplicate scenario"
        label="New Scenario Name"
        isAdding={isAdding}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {errorMessage.current}
        </Alert>
      </Snackbar>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "transparent", boxShadow: 3 }}
      >
        <Table
          sx={{ backgroundColor: "transparent", border: "0.5px solid grey" }}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
              {ischeck && (
                <TableCell
                  sx={{
                    border: "0.5px solid grey",
                    color: "white",
                    padding: 2,
                  }}
                >
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                    color="secondary"
                  />
                </TableCell>
              )}
              {section.columns &&
                section.columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      border: "0.5px solid grey",
                      color: "white",
                      padding: 2,
                      wordWrap: "break-word",
                      cursor: "pointer", // Added to indicate sortable columns
                    }}
                    onClick={() => onSort(column)} // Added onClick to call onSort with the column name
                  >
                    <ColumnHeaderPopup
                      column={column}
                      sortOrder={sortOrder}
                      onSort={onSort}
                      onFilterIconClick={onFilterIconClick}
                    />
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {applyFilters(section.data,section.columns, filters).map(
              (row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? "rgba(0, 0, 0, 0.04)" : "transparent",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.08)" },
                  }}
                >
                  {ischeck && (
                    <TableCell
                      sx={{
                        border: "0.5px solid grey",
                        backgroundColor: "transparent",
                        padding: 2,
                      }}
                    >
                      <Checkbox
                        checked={selectedGuides.includes(row.id)}
                        onChange={() => handleCheckboxChange(row.id)}
                        color="secondary"
                      />
                    </TableCell>
                  )}
                  {section.columns &&
                    section.columns.map((column, idx) => (
                      <TableCell
                        key={idx}
                        sx={{
                          border: "0.5px solid grey",
                          backgroundColor: "transparent",
                          padding: 2,
                        }}
                      >
                        {column === "Action" ? (
                          <div style={{ display: "flex", gap: "8px" }}>
                            {showIcons
                              ? row.action &&
                                Array.isArray(row.action) &&
                                row.action.map((action, actionIndex) => (
                                  <Tooltip title={action} key={actionIndex}>
                                    <IconButton
                                       data-test-id={`${action}`}
                                      sx={{
                                        color: theme.palette.secondary.main,
                                        padding: 0,
                                      }}
                                      onClick={() => {
                                        setScenarioName(row["Scenario Name"]);
                                        localStorage.setItem(
                                          "scenarioName",
                                          row["Scenario Name"]
                                        );
                                        setSelectedScenarioName(
                                          row["Scenario Name"]
                                        );
                                        handleAction(
                                          action,
                                          row["Scenario Name"]
                                        );
                                      }}
                                    >
                                      {getActionIcon(action)}
                                    </IconButton>
                                  </Tooltip>
                                ))
                              : row.action &&
                                Array.isArray(row.action) &&
                                row.action.map((action, actionIndex) => (
                                  <Button
                                    sx={{ width: "250px" }}
                                    variant="contained"
                                    color="secondary"
                                    key={actionIndex}
                                    onClick={() => {
                                      handleActionClick(action);
                                      setBuyerGuideName(
                                        row["Buyer Guide Name"]
                                      );
                                      localStorage.setItem(
                                        "buyerGuideName",
                                        row["Buyer Guide Name"]
                                      );
                                    }}
                                  >
                                    {action}
                                  </Button>
                                ))
                                }
                          </div>
                        ) : (
                          row[column]
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EnhancedTable;
