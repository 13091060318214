import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import theme from "../../theme/theme.js";

import DataGridTable from "../../components/DataGridTable.jsx";
import NumberInput from "../../components/AvailableUnitsForAdminAllocation/NumberInput.jsx";
import CustomizeRadio from "../../components/AvailableUnitsForAdminAllocation/CustomizeRadio.jsx";

import { UserProfileContext } from "../../context/UserContext";

const AvailableUnitsForAdminAllocation = () => {
  const { userProfile, availableUnitsForAllocation } =
    useContext(UserProfileContext);

  // DATA
  // const availableUnitsForAllocation = [
  //   {
  //     id: 88993,
  //     purchased_package: 'Solo Starter',
  //     user_units: 12,
  //     end_user_organization_units: 0,
  //     buyer_guide_units: 1,
  //     total_units: 1,
  //     end_user_organization_units_entered: 0,
  //     buyer_guide_units_entered: 0,
  //     user_units_entered: 0,
  //     total_units_entered: 0,
  //   },
  //   {
  //     id: 24872,
  //     purchased_package: 'Duel Dynamics',
  //     user_units: 14,
  //     end_user_organization_units: 4,
  //     buyer_guide_units: 4,
  //     total_units: 4,
  //     end_user_organization_units_entered: 0,
  //     buyer_guide_units_entered: 0,
  //     user_units_entered: 0,
  //     total_units_entered: 0,
  //   },
  //   {
  //     id: 2435,
  //     purchased_package: 'Focus Five',
  //     user_units: 20,
  //     end_user_organization_units: 5,
  //     buyer_guide_units: 2,
  //     total_units: 7,
  //     end_user_organization_units_entered: 0,
  //     buyer_guide_units_entered: 0,
  //     user_units_entered: 0,
  //     total_units_entered: 0,
  //   },
  //   {
  //     id: 98745,
  //     purchased_package: 'All Available',
  //     user_units: 3,
  //     end_user_organization_units: 3,
  //     buyer_guide_units: 3,
  //     total_units: 27,
  //     end_user_organization_units_entered: 0,
  //     buyer_guide_units_entered: 0,
  //     user_units_entered: 0,
  //     total_units_entered: 0,
  //   },
  // ];

  //
  // console.log(' available Units for Allocation ', availableUnitsForAllocation);

  // Stores the data for Radio buttons and selected values
  const [selectedRadioValue, setSelectedRadioValue] = useState([
    { page: "user_units", value: "self", prescription: false },
    {
      page: "end_user_organization_units",
      value: "user_defined",
      prescription: false,
    },
    {
      page: "buyer_guide_units",
      value: "user_defined",
      prescription: false,
    },
    {
      page: "unlocked_vendors",
      value: "user_defined",
      prescription: false,
    },
  ]);

  //Radio Buttons All the data is stored here
  const radioData = [
    {
      heading: "Users Units",
      option1: "self",
      text1: "Self (max 1 unit)",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
    {
      heading: "End User Organizations Units",
      option1: "user_defined",
      text1: "User Defined",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
    {
      heading: "Buyer Guide Units",
      option1: "user_defined",
      text1: "User Defined",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
    {
      heading: "Vendor to be Unlocked",
      option1: "user_defined",
      text1: "User Defined",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
  ]?.filter((idx) => {
    if (idx === 1 && userProfile.profileType !== "consultant") return false;
    return true;
  });

  const [loading, setLoading] = useState(true); // Add loading state

  // All the data
  const [sectionData, setSectionData] = useState([]);

  // Selection of Guides in "Checkbox"
  const [packageCheck, setPackageCheck] = useState([]);
  const [allPackageCheck, setAllPackageCheck] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // checks if there is "availableUnitsForAllocation" in session if yes then tries to store the earlier changed values to current availableUnitsForAllocation
    const availableUnitsForAllocationInSession = JSON.parse(
      sessionStorage.getItem("availableUnitsForAllocation")
    );

    const selectedRadioValueFromSession = JSON.parse(
      sessionStorage.getItem("radioData")
    );

    if (
      availableUnitsForAllocationInSession?.length > 0 &&
      availableUnitsForAllocation?.length > 0
    ) {
      const availableUnitsForAllocationInCorrectedForm =
        availableUnitsForAllocation?.map((packag) => ({
          id: Math.floor(Math.random() * 10000), // max limit of id is 1000
          purchased_package: packag.purchasedPackageName,
          user_units: packag.userUnits,
          end_user_organization_units: packag.endUserOrganizationUnits,
          buyer_guide_units: packag.buyerGuideUnits,
          total_units: packag.totalUnits,
          end_user_organization_units_entered: 0,
          buyer_guide_units_entered: 0,
          user_units_entered: 0,
          total_units_entered: 0,
        }));

      const mergedAvailableUnitsForAllocation =
        availableUnitsForAllocationInCorrectedForm?.map((row1) => {
          const updatedAvailable = availableUnitsForAllocationInSession?.find(
            (row2) => row1.purchased_package === row2.purchased_package
          );
          if (updatedAvailable) return updatedAvailable;
          return row1;
        });

      if (selectedRadioValueFromSession?.length > 0) {
        setSelectedRadioValue(selectedRadioValueFromSession);
      }
      setSectionData(mergedAvailableUnitsForAllocation);
      setLoading(false); // Set loading to false once data is loaded
    } else if (availableUnitsForAllocation?.length > 0) {
      setSectionData(
        availableUnitsForAllocation?.map((packag) => ({
          id: Math.floor(Math.random() * 10000), // max limit of id is 1000
          purchased_package: packag.purchasedPackageName,
          user_units: packag.userUnits,
          end_user_organization_units: packag.endUserOrganizationUnits,
          buyer_guide_units: packag.buyerGuideUnits,
          total_units: packag.totalUnits,
          end_user_organization_units_entered: 0,
          buyer_guide_units_entered: 0,
          user_units_entered: 0,
          total_units_entered: 0,
        }))
      );
      // setSelectedRadioValue([
      //   { page: "user_units", value: "self", prescription: false },
      //   {
      //     page: "end_user_organization_units",
      //     value: "user_defined",
      //     prescription: false,
      //   },
      //   {
      //     page: "buyer_guide_units",
      //     value: "user_defined",
      //     prescription: false,
      //   },
      //   {
      //     page: "unlocked_vendors",
      //     value: "user_defined",
      //     prescription: false,
      //   },
      // ]);
      setLoading(false); // Set loading to false once data is loaded
    }

    // return () => {
    //   sessionStorage.removeItem('idx');
    //   sessionStorage.removeItem('radioData');
    //   sessionStorage.removeItem('latestRadioData');
    //   sessionStorage.removeItem('availableUnitsForAllocation');
    // };
  }, [availableUnitsForAllocation]); // when uncomment remove the below line as well
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const packagToBeChecked = sectionData?.filter(
      (el) =>
        el.user_units_entered +
          el.end_user_organization_units_entered +
          el.buyer_guide_units_entered >
        0
    );

    setPackageCheck(packagToBeChecked?.map((el) => el.id));

    let sumOfUserUnits = 0;
    sectionData?.forEach((el) => (sumOfUserUnits += el.user_units_entered));

    if (sumOfUserUnits)
      setSelectedRadioValue(
        selectedRadioValue?.map((el) => {
          if (el.page === "user_units")
            return { ...el, value: sumOfUserUnits > 1 ? "prescibe" : "self" };
          return el;
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionData]);

  useEffect(() => {
    const allChecked =
      sectionData?.length > 0 &&
      sectionData.every((packag) => packageCheck?.includes(packag.id));

    setAllPackageCheck(allChecked);
  }, [packageCheck, sectionData]);

  const handleAllocateQuantitiesAndContinue = () => {
    const sectionDataChecked = sectionData?.filter((el) =>
      packageCheck?.includes(el.id)
    );

    sessionStorage.removeItem("idx");
    sessionStorage.removeItem("latestRadioData");

    if (sectionDataChecked?.length > 0) {
      sessionStorage.setItem("radioData", JSON.stringify(selectedRadioValue));
      sessionStorage.setItem(
        "availableUnitsForAllocation",
        JSON.stringify(sectionDataChecked)
      );

      navigate("/admin-allocation");
    }
  };
  const updatedColumn = columnData(
    sectionData,
    setSectionData,
    allPackageCheck,
    setAllPackageCheck,
    packageCheck,
    setPackageCheck,
    userProfile
  );

  if (loading) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 4,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,
            border: "0.5px solid grey",
            borderBottom: "none", // Remove the bottom border
            height: "fit-content",
          },
        }}
      >
        <DataGridTable
          rows={sectionData}
          columns={updatedColumn}
          page={"allocation"}
        />
      </Box>
      <Typography
        sx={{ marginTop: "20px", fontFamily: theme.typography.body1 }}
      >
        I want to allocate
      </Typography>

      {radioData?.map((data, index) => (
        <CustomizeRadio
          key={index}
          data={data}
          index={index}
          selectedRadioValue={selectedRadioValue}
          setSelectedRadioValue={setSelectedRadioValue}
        />
      ))}
      <Button
        disableRipple
        variant="contained"
        color="primary"
        sx={{ marginTop: "16px", width: "255px" }}
        onClick={handleAllocateQuantitiesAndContinue}
      >
        Allocate Quantities & Continue
      </Button>
    </Box>
  );
};

export default AvailableUnitsForAdminAllocation;

const columnData = (
  sectionData,
  setSectionData,
  allPackageCheck,
  setAllPackageCheck,
  packageCheck,
  setPackageCheck,
  userProfile
) => {
  const handleCheckboxChange = (id) => {
    setPackageCheck((prevState) =>
      prevState?.includes(id)
        ? prevState?.filter((guideId) => guideId !== id)
        : [...prevState, id]
    );
  };

  const handleSelectAllChange = () => {
    if (allPackageCheck) {
      setPackageCheck([]);
    } else {
      setPackageCheck(sectionData?.map((packag) => packag.id));
    }
    setAllPackageCheck(!allPackageCheck);
  };

  const NumberInputRenderCell = ({ params, inputsLabel }) => {
    return (
      <NumberInput
        max={params.value}
        rowData={params.row}
        inputsLabel={inputsLabel}
        page={"avalable_for_allocation"}
        onSet={() => {
          //Conditions for checking wether any of the entries is non Zero
          if (
            params.row["user_units_entered"] > 0 &&
            params.row["end_user_organization_units_entered"] === 0 &&
            params.row["buyer_guide_units_entered"] === 0
          ) {
            params.row["end_user_organization_units_entered"] = 1;
            params.row["buyer_guide_units_entered"] = 1;
          } else if (
            params.row["end_user_organization_units_entered"] > 0 &&
            params.row["buyer_guide_units_entered"] === 0 &&
            params.row["user_units_entered"] === 0
          ) {
            params.row["buyer_guide_units_entered"] = 1;
            params.row["user_units_entered"] = 1;
          } else if (
            params.row["buyer_guide_units_entered"] > 0 &&
            params.row["end_user_organization_units_entered"] === 0 &&
            params.row["user_units_entered"] === 0
          ) {
            params.row["end_user_organization_units_entered"] = 1;
            params.row["user_units_entered"] = 1;
          }

          //conditions for Checking if Any of the entries is zero
          if (params.row["user_units_entered"] === 0) {
            params.row["end_user_organization_units_entered"] = 0;
            params.row["buyer_guide_units_entered"] = 0;
          } else if (params.row["end_user_organization_units_entered"] === 0) {
            params.row["buyer_guide_units_entered"] = 0;
            params.row["user_units_entered"] = 0;
          } else if (params.row["buyer_guide_units_entered"] === 0) {
            params.row["end_user_organization_units_entered"] = 0;
            params.row["user_units_entered"] = 0;
          }

          params.row["total_units_entered"] =
            params.row["user_units_entered"] *
            params.row["end_user_organization_units_entered"] *
            params.row["buyer_guide_units_entered"];

          setSectionData(
            sectionData?.map((packag) => {
              if (packag.id === params.row.id) return params.row;
              else return packag;
            })
          );
        }}
      />
    );
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 90,
      sortable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",

      flex: 0.5,
      renderHeader: () => (
        <Checkbox
          checked={allPackageCheck}
          onChange={handleSelectAllChange}
          color="secondary"
          sx={{ color: "white" }}
        />
      ),

      renderCell: (params) => {
        return (
          <Checkbox
            checked={packageCheck?.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params.row.id)}
            color="secondary"
          />
        );
      },
    },

    {
      field: "purchased_package",
      headerName: "Purchased Packages",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
    },

    {
      field: "user_units",
      headerName: "User Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <NumberInputRenderCell
          params={params}
          inputsLabel={"user_units_entered"}
        />
      ),
    },

    {
      field: "end_user_organization_units",
      headerName: "End-User Organization Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <NumberInputRenderCell
          params={params}
          inputsLabel={"end_user_organization_units_entered"}
        />
      ),
    },

    {
      field: "buyer_guide_units",
      headerName: "Buyer Guide Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,

      renderCell: (params) => (
        <NumberInputRenderCell
          params={params}
          inputsLabel={"buyer_guide_units_entered"}
        />
      ),
    },

    {
      field: "total_units",
      headerName: "Total Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.total_units_entered} /{params.value}
          </span>
        );
      },
    },
  ];

  const updatedColumn = columns?.filter((el, idx) => {
    if (
      el.field === "end_user_organization_units" &&
      userProfile.profileType !== "consultant"
    )
      return false;
    return true;
  });

  return updatedColumn;
};
