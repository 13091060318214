import React, {
  useContext,
  useState,
  useCallback,
  memo,
  Suspense,
  useEffect,
  lazy,
  useMemo,
  useRef,
} from "react";
import {
  Typography,
  Box,
  Collapse,
  IconButton,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
  ThemeProvider,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { toPng } from "html-to-image";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UserProfileContext } from "../context/UserContext";
import OfferSummery from "./Widget/ViewReport/OfferSummery";
import QuadrantSummary from "./Widget/ViewReport/QuadrantSummary";
import theme from "../theme/theme";
import { BlobServiceClient } from "@azure/storage-blob";
import axios from "axios";

const ChartComponent = lazy(() =>
  import("./Widget/ComparativeAnalysisWidget/ChartComponent")
);
const MandatoryUseCaseTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/MandatoryUseCaseTable")
);
const OverallUseCasesTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/OverallUseCasesTable")
);
const SupportedUseCaseOverallTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/SupportedUseCaseOverallTable")
);
const UnusedGenericTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnusedGenericTable")
);
const UnusedNotRequiredTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnusedNotRequiredTable")
);
const UnsupportedUseCaseMandatoryTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnsupportedUseCaseMandatoryTable")
);
const UnsupportedUseCaseOverallTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnsupportedUseCaseOverallTable")
);

const sections = [
  "ExecutiveSummary",
  "SmartValyou",
  "PremiumValyou",
  "BudgetValyou",
  "LowValyou",
];
const fields = [
  "offerMetrics",
  "supportedUseCases",
  "unsupportedUseCases",
  "unusedFeatures",
];

const fieldsHeading = {
  offerMetrics: "OfferMetrics",
  supportedUseCases: "SupportedUseCases",
  unsupportedUseCases: "UnsupportedUseCases",
  unusedFeatures: "UnusedFeatures",
};

const ViewReport = () => {
  const date = new Date().toLocaleString();
  const {
    userProfile,
    primaryMetric,
    smartData,
    lowData,
    premiumData,
    budgetData,
    initialData,
    isLoading,
    budgetLoading,
    premiumLoading,
    lowLoading,
    currentOrganization,
    scenarioName,
  } = useContext(UserProfileContext);
  const companyName = userProfile?.companyName;
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [reportName, setReportName] = useState("");
  const [isReportLoading,setReportLoading] = useState(false);
  const initialAccordionState = useMemo(
    () =>
      sections.reduce(
        (acc, section) => ({
          ...acc,
          [section]: section === "ExecutiveSummary",
        }),
        {}
      ),
    []
  );
  const onChangeReportName = (event) => {
    setReportName(event.target.value);
  };

  const commentaryTemplate = useMemo(
    () => ({
      commentary: [
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem nihil tenetur, culpa delectus accusantium rerum dicta cum enim assumenda nostrum praesentium sed fuga veniam accusamus quia aperiam itaque quam at cupiditate blanditiis quaerat?",
      ],
      prompt: "",
      aiCommentary: "",
      showBox: false,
    }),
    []
  );

  const handleExport = async () => {
    setAccordionState((prevState) => ({
      ...prevState,
      ExecutiveSummary: true,
      SmartValyou: true,
      PremiumValyou: true,
      BudgetValyou: true,
      LowValyou: true,
    }));
    const styleSheets = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("\n");
        } catch (e) {
          
          if (e.name !== "SecurityError") {
            console.error("Failed to access stylesheet:", e.message);
          }
          return "";
        }
      })
      .join("\n");

    const dataUrl = await toPng(document.getElementById("blob"));
    var img = new Image();
    img.src = dataUrl;
    img.alt = "Exported content";
    const htmlFile = document.getElementById("htmlContent");
    const newHTMLFile = htmlFile.cloneNode(true);
    const targetElement = newHTMLFile.querySelector("#blob");
    if (targetElement) {
      setReportLoading(true);
      targetElement.innerHTML = "";
      targetElement.appendChild(img);

      const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>${styleSheets}</style>
        </head>
        <body>
            <div>${newHTMLFile.innerHTML}</div>
        </body>
        </html>
    `;

      try {
        /*const blob = new Blob([htmlContent], {
          type: "text/html;charset=utf-8",
        });*/
        //saveAs(blob, "exported-content.html");
        const writeResponse = await axios.get(
          "http://127.0.0.1:4000/generateWriteSasToken"
        );
        const blobServiceClient = BlobServiceClient.fromConnectionString(
          `BlobEndpoint=https://newtestfuncpython.blob.core.windows.net/;QueueEndpoint=https://newtestfuncpython.queue.core.windows.net/;FileEndpoint=https://newtestfuncpython.file.core.windows.net/;TableEndpoint=https://newtestfuncpython.table.core.windows.net/;SharedAccessSignature=${writeResponse.data.sasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient("staticreport");

        const blockBlobClient = containerClient.getBlockBlobClient(
          `${currentOrganization}-${scenarioName}-${reportName}.html`
        );
        const options = {
          blobHTTPHeaders: {
            blobContentType: "text/html", // Set Content-Type as text/html
            blobContentDisposition: "inline", // Set Content-Disposition as inline
          },
        };
        await blockBlobClient.upload(
          htmlContent,
          htmlContent.length,
          options
        );

        const readResponse = await axios.get(
          "http://127.0.0.1:4000/generateReadSasToken"
        );
        const staticReport = localStorage.getItem("staticReport") || {};
        localStorage.setItem(
          `StaticReport-${currentOrganization}-${scenarioName}`,
          JSON.stringify({
            ...staticReport,
            [currentOrganization]: {
              ...staticReport?.[currentOrganization],
              [scenarioName]: {
                ...staticReport?.[currentOrganization]?.[staticReport],
                url: `https://newtestfuncpython.blob.core.windows.net/staticreport/${currentOrganization}-${scenarioName}-${reportName}.html?${readResponse.data.sasToken}`,
                reportName: reportName,
              },
            },
          })
        );

        setOpen(false);
        setReportLoading(false);
        setReportName("");
      } catch (error) {
        console.error("Error uploading HTML file:", error.message);
      }
    }
  };
  const convertToHTML = (text) => {
    // Split by newline and map to <p> or <br/> tags
    return text.split("\r\n").map((item, index) => {
      if (item.trim() === "") return <br key={index} />;
      return (
        <p key={index} style={{ margin: 0 }}>
          {item}
        </p>
      );
    });
  };
  const offerTemplate = useMemo(
    () => ({
      offerSummary: {
        showBox: false,
        isLoading: true,
        aiCommentary: " ",
        commentary: "ankush",
        prompt: " ",
        all_widget_summaries: {},
        widget_jsons_input: {},
      },
      offerMetrics: {
        tables: {
          mandatory: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Offer Metrics - Mandatory Use Cases",
          },
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Offer Metrics - Overall Use Cases",
          },
        },
      },
      supportedUseCases: {
        tables: {
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Supported Use Cases (Overall)",
          },
        },
      },
      unsupportedUseCases: {
        tables: {
          mandatory: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unsupported Use Cases (Mandatory)",
          },
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unsupported Use Cases (Overall)",
          },
        },
      },
      unusedFeatures: {
        tables: {
          generic: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unusued Features (Generic)",
          },
          notRequired: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unused Features ('Not Required' Use Cases)",
          },
        },
      },
    }),
    []
  );

  const [accordionState, setAccordionState] = useState(initialAccordionState);
  const [commentary, setCommentary] = useState({});
  const [promptsState, setPromptsState] = useState({});
  const [promptVisible, setPromptVisible] = useState(false);
  const [hoveredBoxes, setHoveredBoxes] = useState({});

  useEffect(() => {
    let initialCommentary = {
      ExecutiveSummary: commentaryTemplate,
      SmartValyou:
        smartData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: " ",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
      PremiumValyou:
        premiumData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
      BudgetValyou:
        budgetData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
      LowValyou:
        lowData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
    };
    setCommentary(initialCommentary);
    setPromptsState(initialCommentary);
  }, [
    smartData,
    premiumData,
    budgetData,
    lowData,
    commentaryTemplate,
    offerTemplate,
  ]);
  const toggleAccordion = useCallback((section) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  }, []);

  const handlePromptChange = useCallback(
    (section, offer, field, table, value) => {
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            [field]: {
              ...prevState[section][offer][field],
              tables: {
                ...prevState[section][offer][field].tables,
                [table]: {
                  ...prevState[section][offer][field].tables[table],
                  prompt: value,
                },
              },
            },
          },
        },
      }));
    },
    []
  );
  const handleOfferPromptChange = useCallback((section, offer, value) => {
    setPromptsState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          offerSummary: {
            ...prevState[section][offer].offerSummary,
            prompt: value,
          },
        },
      },
    }));
  }, []);
  const handleSectionPromptChange = useCallback((section, value) => {
    setPromptsState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        quadrantSummary: {
          ...prevState[section].quadrantSummary,
          prompt: value,
        },
      },
    }));
  }, []);
  const generateExecutiveCommentary = useCallback(
    (section) => {
      const newComment = promptsState[section].prompt;
      const updateLorem = `${commentary[section].commentary[0]} Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque commodi minus asperiores illum id esse obcaecati neque quam magnam nam reprehenderit dolorum dolore recusandae officia, reiciendis totam voluptatum pariatur illo soluta accusamus! Eius dolorum sed quaerat amet facilis impedit aspernatur ratione voluptas fugiat cupiditate consequuntur magnam necessitatibus dolorem eaque veniam iste, enim natus explicabo nostrum odio corporis totam. Numquam, debitis!`;

      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          commentary: [updateLorem],
          prompt: "",
          aiCommentary: newComment,
        },
      }));

      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          prompt: "",
        },
      }));
    },
    [commentary, promptsState]
  );

  const generateCommentary = useCallback(
    (section, offer, field, table) => {
      const currentComment =
        commentary[section][offer][field]?.tables[table].commentary;
      const updatedCommentary = `${currentComment} Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque commodi minus asperiores illum id esse obcaecati neque quam magnam nam reprehenderit dolorum dolore recusandae officia, reiciendis totam voluptatum pariatur illo soluta accusamus!`;

      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            [field]: {
              ...prevState[section][offer][field],
              tables: {
                ...prevState[section][offer][field].tables,
                [table]: {
                  ...prevState[section][offer][field].tables[table],
                  commentary: updatedCommentary,
                  prompt: "",
                },
              },
            },
          },
        },
      }));

      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            [field]: {
              ...prevState[section][offer][field],
              tables: {
                ...prevState[section][offer][field].tables,
                [table]: {
                  ...prevState[section][offer][field].tables[table],
                  prompt: "",
                },
              },
            },
          },
        },
      }));
    },
    [commentary]
  );
  const generateSectionCommentary = useCallback(
    (section) => {
      const currentComment = commentary[section].quadrantSummary?.commentary;
      const updatedCommentary = `${currentComment} Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque commodi minus asperiores illum id esse obcaecati neque quam magnam nam reprehenderit dolorum dolore recusandae officia, reiciendis totam voluptatum pariatur illo soluta accusamus!`;
      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          quadrantSummary: {
            ...prevState[section].quadrantSummary,
            commentary: updatedCommentary,
            prompt: "", // Clear the prompt after generating commentary
          },
        },
      }));
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          quadrantSummary: {
            ...prevState[section].quadrantSummary,
            commentary: updatedCommentary,
            prompt: "", // Clear the prompt after generating commentary
          },
        },
      }));
    },
    [commentary]
  );

  const generateOfferCommentary = useCallback(
    (section, offer) => {
      const currentComment =
        commentary[section][offer].offerSummary?.commentary;
      const updatedCommentary = `${currentComment} Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque commodi minus asperiores illum id esse obcaecati neque quam magnam nam reprehenderit dolorum dolore recusandae officia, reiciendis totam voluptatum pariatur illo soluta accusamus!`;
      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            offerSummary: {
              ...prevState[section][offer]?.offerSummary,
              commentary: updatedCommentary,
              prompt: "",
            },
          },
        },
      }));
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            offerSummary: {
              ...prevState[section][offer]?.offerSummary,
              prompt: "",
            },
          },
        },
      }));
    },
    [commentary]
  );
  const toggleShowBox = useCallback((section, offer, field, table) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          [field]: {
            ...prevState[section][offer][field],
            tables: {
              ...prevState[section][offer][field].tables,
              [table]: {
                ...prevState[section][offer][field].tables[table],
                showBox:
                  !prevState[section][offer][field].tables[table].showBox,
              },
            },
          },
        },
      },
    }));
  }, []);
  const toggleOfferSummary = useCallback((section, offer) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          offerSummary: {
            ...prevState[section][offer].offerSummary,
            showBox: !prevState[section][offer].offerSummary.showBox,
          },
        },
      },
    }));
  }, []);
  const toggleSectionSummary = useCallback((section) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        quadrantSummary: {
          ...prevState[section].quadrantSummary,
          showBox: !prevState[section].quadrantSummary.showBox,
        },
      },
    }));
  }, []);
  const handleMouseEnter = useCallback((key) => {
    setHoveredBoxes((prev) => ({ ...prev, [key]: true }));
  }, []);

  const handleMouseLeave = useCallback((key) => {
    setHoveredBoxes((prev) => ({ ...prev, [key]: false }));
  }, []);

  const renderTable = useCallback(
    (field, section, offer, table) => {
      //console.log(table)
      //console.log(commentary[section][offer][field].tables[table].widgetName);
      const tableComponents = {
        offerMetrics: {
          mandatory: (
            <MandatoryUseCaseTable
              key={`${section}-${offer}-${field}-mandatory`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
          overall: (
            <OverallUseCasesTable
              key={`${section}-${offer}-${field}-overall`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
        supportedUseCases: {
          overall: (
            <SupportedUseCaseOverallTable
              key={`${section}-${offer}-${field}-supported`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
        unsupportedUseCases: {
          mandatory: (
            <UnsupportedUseCaseMandatoryTable
              key={`${section}-${offer}-${field}-unsupported-mandatory`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
          overall: (
            <UnsupportedUseCaseOverallTable
              key={`${section}-${offer}-${field}-unsupported-overall`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
        unusedFeatures: {
          generic: (
            <UnusedGenericTable
              key={`${section}-${offer}-${field}-unused-generic`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
          notRequired: (
            <UnusedNotRequiredTable
              key={`${section}-${offer}-${field}-unused-notrequired`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
      };
      return (
        <Suspense fallback={<div>Loading...</div>}>
          {tableComponents[field][table]}
        </Suspense>
      );
    },
    [commentary]
  );
  const renderOfferDetails = useCallback(
    (section, data, loading) => {
      return loading ? (
        <Typography variant="h6">{section} data is loading...</Typography>
      ) : data.length === 0 ? (
        <Typography variant="h6">No data available.</Typography>
      ) : (
        <Box>
          {commentary && commentary[section] && (
            <Box marginTop={3}>
              <Typography variant="h3">Quadrant Summary</Typography>
              {commentary[section]?.quadrantSummary && (
                <Box
                  sx={{ ...commentaryBoxStyles, marginTop: "10px" }}
                  onMouseEnter={() =>
                    handleMouseEnter(`${section}-quadrantSummary`)
                  }
                  onMouseLeave={() =>
                    handleMouseLeave(`${section}-quadrantSummary`)
                  }
                >
                  <Box>
                    <QuadrantSummary
                      commentary={commentary}
                      section={section}
                      setCommentary={setCommentary}
                      data={data}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "flex-start",
                      display: hoveredBoxes[`${section}-quadrantSummary`]
                        ? "flex"
                        : "none",
                      transition: "opacity 0.3s",
                    }}
                  >
                    <Tooltip title="Copy" disableInteractive arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            commentary[section]?.quadrantSummary?.commentary
                          );
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Regenerate" disableInteractive arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          toggleSectionSummary(section);
                        }}
                      >
                        <ChangeCircleIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <Collapse in={commentary[section].quadrantSummary.showBox}>
                <Box sx={{ alignItems: "center", marginTop: "10px" }}>
                  <TextField
                    label="Quadrant Prompt"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={promptsState[section].quadrantSummary?.prompt}
                    onChange={(e) =>
                      handleSectionPromptChange(section, e.target.value)
                    }
                    key={`${section}`}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => generateSectionCommentary(section)}
                    sx={{ marginTop: "10px" }}
                  >
                    Generate Commentary
                  </Button>
                </Box>
              </Collapse>
            </Box>
          )}
          {commentary &&
            commentary[section] &&
            Object.keys(commentary[section])
              .filter(
                (offer) =>
                  offer !== "Unlock Vendor" && offer !== "quadrantSummary"
              )
              .map((offer) => {
                return (
                  <Box key={`${section}-${offer}`} marginTop={3}>
                    <Typography variant="h4" marginTop={3}>
                      {offer}
                    </Typography>
                    <Box>
                      <Typography variant="subtitle2" marginTop={3}>
                        Offer Summary
                      </Typography>
                      {commentary[section][offer]?.offerSummary?.commentary && (
                        <Box
                          sx={{ ...commentaryBoxStyles, marginTop: "10px" }}
                          onMouseEnter={() =>
                            handleMouseEnter(`${section}-${offer}-offerSummer`)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(`${section}-${offer}-offerSummer`)
                          }
                        >
                          <Typography>
                            <OfferSummery
                              section={section}
                              offer={offer}
                              commentary={commentary}
                              setCommentary={setCommentary}
                            />
                          </Typography>
                          <Box
                            sx={{
                              alignItems: "flex-start",
                              display: hoveredBoxes[
                                `${section}-${offer}-offerSummer`
                              ]
                                ? "flex"
                                : "none",
                              transition: "opacity 0.3s",
                            }}
                          >
                            <Tooltip title="Copy" disableInteractive arrow>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    commentary[section][offer].offerSummary
                                      .commentary
                                  );
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Regenerate"
                              disableInteractive
                              arrow
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  toggleOfferSummary(section, offer);
                                }}
                              >
                                <ChangeCircleIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                      <Collapse
                        in={commentary[section][offer].offerSummary.showBox}
                      >
                        <Box sx={{ alignItems: "center", marginTop: "10px" }}>
                          <TextField
                            label="Field Prompt"
                            fullWidth
                            multiline
                            maxRows={4}
                            value={
                              promptsState[section][offer].offerSummary?.prompt
                            }
                            onChange={(e) =>
                              handleOfferPromptChange(
                                section,
                                offer,
                                e.target.value
                              )
                            }
                            key={`${section}-${offer}`}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              generateOfferCommentary(section, offer)
                            }
                            sx={{ marginTop: "10px" }}
                          >
                            Generate Commentary
                          </Button>
                        </Box>
                      </Collapse>
                    </Box>
                    {fields.map((field) => (
                      <Box key={`${section}-${field}`} marginTop={3}>
                        <Typography variant="subtitle2">
                          {fieldsHeading[field].split(/(?=[A-Z])/).join(" ")}
                        </Typography>

                        {Object.keys(
                          commentary[section][offer][field].tables
                        ).map((table, tableIndex) => {
                          return (
                            <Box
                              key={`${section}-${offer}-${field}-table-${tableIndex}`}
                              marginTop={3}
                              onMouseEnter={() =>
                                handleMouseEnter(
                                  `${section}-${offer}-${field}-${table}`
                                )
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(
                                  `${section}-${offer}-${field}-${table}`
                                )
                              }
                            >
                              {commentary[section][offer][field].tables[table]
                                ?.widgetName &&
                                renderTable(field, section, offer, table)}
                              {commentary[section][offer][field].tables[table]
                                ?.commentary && (
                                <Box
                                  sx={{
                                    ...commentaryBoxStyles,
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography>
                                    {commentary[section][offer][field].tables[
                                      table
                                    ].isLoading ? (
                                      <Box mt={4} textAlign="center">
                                        <CircularProgress />
                                      </Box>
                                    ) : (
                                      convertToHTML(
                                        commentary[section][offer][field]
                                          .tables[table]?.commentary
                                      )
                                    )}{" "}
                                  </Typography>
                                  <Box
                                    sx={{
                                      alignItems: "flex-start",
                                      display: hoveredBoxes[
                                        `${section}-${offer}-${field}-${table}`
                                      ]
                                        ? "flex"
                                        : "none",
                                      transition: "opacity 0.3s",
                                    }}
                                  >
                                    <Tooltip
                                      title="Copy"
                                      disableInteractive
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            commentary[section][offer][field]
                                              .tables[table]?.commentary
                                          );
                                        }}
                                      >
                                        <ContentCopyIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Regenerate"
                                      disableInteractive
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          toggleShowBox(
                                            section,
                                            offer,
                                            field,
                                            table
                                          )
                                        }
                                      >
                                        <ChangeCircleIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              )}

                              <Collapse
                                in={
                                  commentary[section][offer][field].tables[
                                    table
                                  ].showBox
                                }
                              >
                                <Box sx={{ marginTop: "10px" }}>
                                  <TextField
                                    label="Table Prompt"
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    value={
                                      promptsState[section][offer][field]
                                        ?.tables[table].prompt
                                    }
                                    onChange={(e) =>
                                      handlePromptChange(
                                        section,
                                        offer,
                                        field,
                                        table,
                                        e.target.value
                                      )
                                    }
                                    key={`${section}-${offer}-${field}-table-${tableIndex}-prompt`}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      generateCommentary(
                                        section,
                                        offer,
                                        field,
                                        table
                                      )
                                    }
                                    sx={{ marginTop: "10px" }}
                                  >
                                    Generate Commentary
                                  </Button>
                                </Box>
                              </Collapse>
                            </Box>
                          );
                        })}
                      </Box>
                    ))}
                  </Box>
                );
              })}
        </Box>
      );
    },
    [
      commentary,
      hoveredBoxes,
      promptsState,
      handleMouseEnter,
      handleMouseLeave,
      toggleShowBox,
      handlePromptChange,
      generateCommentary,
      renderTable,
      generateOfferCommentary,
      handleOfferPromptChange,
      toggleOfferSummary,
      handleSectionPromptChange,
      toggleSectionSummary,
      generateSectionCommentary,
    ]
  );

  if (isLoading) {
    return (
      <Box mt={4} textAlign="center">
        <Typography variant="h5">Loading data, please wait...</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div ref={contentRef} id="htmlContent">
        <Button onClick={handleExport}>Export as Html</Button>
        <Box sx={{ width: "100%", minWidth: "1080px" }}>
          <Box marginBottom={15}>
            <Typography
              variant="h3"
              sx={{ textAlign: "center", marginBottom: 5 }}
            >
              Buyer Guide Report
            </Typography>
            <Box sx={{ width: "100%", alignItems: "center" }}>
              <Box
                sx={{ flexDirection: "column", width: "70%", float: "left" }}
              >
                <Typography>Company Name: {companyName}</Typography>
                <Typography>Date: {date}</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ float: "right", width: "250px" }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                Finalize and Save Report
              </Button>
            </Box>
          </Box>
          <Box>
            {sections.map((section) => (
              <Box
                key={section}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h1">
                    {section.split(/(?=[A-Z])/).join(" ")}
                  </Typography>
                  <IconButton
                    size="large"
                    sx={{ cursor: "pointer" }}
                    onClick={() => toggleAccordion(section)}
                  >
                    {accordionState[section] ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRight />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={accordionState[section]}>
                  <Box>
                    {section === "ExecutiveSummary" ? (
                      <>
                        <Suspense fallback={<div>Loading chart...</div>}>
                          <Box id="blob" sx={{ flex: 1 }}>
                            {primaryMetric === "offerScore" ? (
                              <ChartComponent
                                chartType="Bubble Chart"
                                initialData={initialData}
                              />
                            ) : (
                              <>
                                <Typography variant="subtitle1">
                                  The Valyou Matrix (Scatter Chart)
                                </Typography>
                                <ChartComponent
                                  chartType="Scatter Chart"
                                  initialData={initialData}
                                />
                              </>
                            )}
                          </Box>
                        </Suspense>
                        <Box sx={{ display: "flex", alignItems: "self-start" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ cursor: "pointer" }}>
                              {commentary?.ExecutiveSummary?.commentary?.map(
                                (comment, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      ...commentaryBoxStyles,
                                      marginTop: "10px",
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter(
                                        `ExecutiveSummary-${index}`
                                      )
                                    }
                                    onMouseLeave={() =>
                                      handleMouseLeave(
                                        `ExecutiveSummary-${index}`
                                      )
                                    }
                                  >
                                    <Typography>{comment}</Typography>
                                    <Box
                                      sx={{
                                        alignItems: "flex-start",
                                        display: hoveredBoxes[
                                          `ExecutiveSummary-${index}`
                                        ]
                                          ? "flex"
                                          : "none",
                                        transition: "opacity 0.3s",
                                      }}
                                    >
                                      <Tooltip
                                        title="Copy"
                                        disableInteractive
                                        arrow
                                      >
                                        <IconButton size="small">
                                          <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="Regenerate"
                                        disableInteractive
                                        arrow
                                      >
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            setPromptVisible(!promptVisible)
                                          }
                                        >
                                          <ChangeCircleIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                )
                              )}
                            </Box>
                            <Collapse
                              in={promptVisible}
                              style={{
                                transitionDelay: promptVisible
                                  ? "200ms"
                                  : "0ms",
                              }}
                            >
                              <Box sx={{ width: "100%", marginTop: "20px" }}>
                                <TextField
                                  label="Prompt"
                                  fullWidth
                                  multiline
                                  maxRows={4}
                                  value={promptsState?.ExecutiveSummary?.prompt}
                                  onChange={(e) =>
                                    setPromptsState({
                                      ...promptsState,
                                      ExecutiveSummary: {
                                        ...promptsState.ExecutiveSummary,
                                        prompt: e.target.value,
                                      },
                                    })
                                  }
                                  key={`ExecutiveSummary-prompt`}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    generateExecutiveCommentary(
                                      "ExecutiveSummary"
                                    )
                                  }
                                  sx={{ width: "250px", marginTop: "10px" }}
                                >
                                  Generate Commentary
                                </Button>
                              </Box>
                            </Collapse>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      renderOfferDetails(
                        section,
                        section === "SmartValyou"
                          ? smartData.items
                          : section === "PremiumValyou"
                          ? premiumData.items
                          : section === "BudgetValyou"
                          ? budgetData.items
                          : section === "LowValyou"
                          ? lowData.items
                          : [],
                        section === "SmartValyou"
                          ? isLoading
                          : section === "PremiumValyou"
                          ? premiumLoading
                          : section === "BudgetValyou"
                          ? budgetLoading
                          : section === "LowValyou"
                          ? lowLoading
                          : false
                      )
                    )}
                  </Box>
                </Collapse>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ backgroundColor: "white" }}>
          Enter-ReportName
        </DialogTitle>
        <DialogContent sx={{ color: "white", backgroundColor: "white" }}>
          <TextField
            data-test-id="Report-Name"
            margin="dense"
            label="Report Name"
            type="search"
            fullWidth
            value={reportName}
            onChange={onChangeReportName}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "white" }}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={isReportLoading?null:() => {
              handleExport();
            }}
            color="primary"
          >
            {isReportLoading ? <CircularProgress size={24} /> : "Add-Report"}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

const commentaryBoxStyles = {
  borderRadius: "5px",
  padding: "10px",
  backgroundColor: "transparent",
  transition: "border-color 0.3s, background-color 0.3s", // Add transition for smooth effect
  "&:hover": {
    border: "1px solid #ccc inset",
    borderColor: "#ccc", // Change border color on hover
    backgroundColor: "#f0f0f0", // Change background color on hover
  },
};

export default memo(ViewReport);
