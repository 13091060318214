import React, { useState, useContext } from "react";
import {
  Radio,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { UserProfileContext } from "../context/UserContext";

const PurchaseBulk = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  // Example packages array, replace with actual data
  const packages = ["Package 1", "Package 2", "Package 3"];
  
  const [value, setValue] = useState("allocate");
  const [selectedPackage, setSelectedPackage] = useState(packages[0]);
  const [noNestedValue, setNoNestedValue] = useState("resultsPage");

  const handleNavigate = () => {
    if (value === "allocate") {
      navigate('/purchase-bulk-single');
    } else if (value === "no") {
      if (noNestedValue === "resultsPage") {
        navigate('/results/comparative-analysis');
      } else if (noNestedValue === "subscriptionsPage") {
        navigate('/my-subscriptions');
      }
    }
  };

  return (
    <Box>
      <Typography>Dear {userProfile?.displayName},</Typography>
      <Typography>Your purchase of multiple subscriptions was successful!</Typography>
      <Typography>Congratulations! Let's allocate the subscriptions. Which Subscription would you like to allocate?</Typography>
      <Box>
        <Typography component="legend">Select an option</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              checked={value === "allocate"}
              onChange={() => setValue("allocate")}
              value="allocate"
            />
            <Typography>Yes, Let's allocate</Typography>
          </Box>
          {value === "allocate" && (
            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 4 }}>
              {packages.map((pkg, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Radio
                    checked={selectedPackage === pkg}
                    onChange={() => setSelectedPackage(pkg)}
                    value={pkg}
                  />
                  <Typography>{pkg}</Typography>
                </Box>
              ))}
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              checked={value === "no"}
              onChange={() => setValue("no")}
              value="no"
            />
            <Typography>No</Typography>
          </Box>
          {value === "no" && (
            <Box sx={{ display: 'flex', flexDirection: 'column', pl: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  checked={noNestedValue === "resultsPage"}
                  onChange={() => setNoNestedValue("resultsPage")}
                  value="resultsPage"
                />
                <Typography>(Default) Take me to 'Results' Page</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  checked={noNestedValue === "subscriptionsPage"}
                  onChange={() => setNoNestedValue("subscriptionsPage")}
                  value="subscriptionsPage"
                />
                <Typography>Take me to 'My Subscriptions' page</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        {value === "allocate" && (
          <Button variant="contained" color="primary" onClick={handleNavigate} sx={{width:'250px'}}>
            Allocate
          </Button>
        )}
        {value === "no" && (
          <Button variant="contained" color="secondary" onClick={handleNavigate} sx={{width:'250px'}}>
            Exit to selected page
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default PurchaseBulk;
