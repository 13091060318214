const initialBuyerGuides = [
    {
      id: 1,
      name: "Buyer Guide 1",
      description: "Detailed description for Buyer Guide 1.",
      checked: false,
      status: "To Do",
      action: "Start Analysis",
    },
    {
      id: 2,
      name: "Buyer Guide 2",
      description: "Detailed description for Buyer Guide 2.",
      checked: false,
      status: "In Progress",
      action: "Continue Analysis",
    },
    {
      id: 3,
      name: "Buyer Guide 3",
      description: "Detailed description for Buyer Guide 3.",
      checked: false,
      status: "Completed",
      action: "Review Reports",
    },
    {
      id: 4,
      name: "Buyer Guide 4",
      description: "Detailed description for Buyer Guide 4.",
      checked: false,
      status: "To Do",
      action: "Start Analysis",
    },
  ];


export default initialBuyerGuides;