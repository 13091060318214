const dummyScenarios = [
    {
      scenario: "Default Scenario",
      status: "Completed",
      action: ["View Result", "Duplicate"],
    },
    {
      scenario: "Scenario 1",
      status: "In Progress",
      action: ["Edit", "Duplicate", "Delete"],
    },
    {
      scenario: "Scenario 2",
      status: "Completed",
      action: ["View Result", "Duplicate", "Delete"],
    },
    {
      scenario: "Scenario 3",
      status: "In Progress",
      action: ["Edit", "Duplicate", "Delete"],
    },
    {
      scenario: "Scenario 4",
      status: "Completed",
      action: ["View Result", "Duplicate", "Delete"],
    },
    // Add more scenarios as needed
  ];


export default dummyScenarios;