import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
 MsalProvider,
 AuthenticatedTemplate,
 useMsal,
 UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import Users from "./pages/Users";
import Activity from "./pages/Activity";
import ShareReport from "./pages/ShareReport";
import ReviewComplete from "./pages/ReviewComplete";
import Wipusecase from "./pages/Wipusecase";
import SelectBuyerGuide from "./pages/TopNavPages/SelectBuyerGuide";
import UpdateUseCase from "./pages/TopNavPages/UpdateUseCase";
import SavedReports from "./pages/SavedReports.jsx";
import ViewReport from "./pages/ViewReports.jsx";
import ReportSettings from "./pages/ReportSettings.jsx";
import CollaborateUseCase from "./pages/TopNavPages/CollaborateUseCase";
import ComparativeAnalysis from "./pages/TopNavPages/DashBoard/ComparativeAnalysis";
import VendorDetails from "./pages/TopNavPages/DashBoard/VendorDetails";
import OfferDetails from "./pages/TopNavPages/DashBoard/OfferDetails";
import StartNewUseCase from "./pages/StartNewUseCase";
import VendorOptions from "./pages/FineTuneTopNav/VendorOptions";
import FineTune from "./pages/FineTuneTopNav/FineTune";
import UpdateUseCasesFineTune from "./pages/FineTuneTopNav/UpdateUseCasesFineTune";
import { loginRequest } from "./auth-config";
import Login from "./pages/Login";
import Layout from "./layout/Layout";
import EndUserPackages from "./pages/EndUserPackages";
import SelectEndUser from "./pages/SelectEndUser"; // Updated import name
import SelectBuyer from "./pages/SelectBuyer";
import InterestedBuyer from "./pages/InterestedBuyer";
import SelectScenario from "./pages/SelectScenario";
import PurchasePackSection from "./pages/PurchangePackSection";
import SubscriptionDetails from "./pages/SubscriptionDetails";
import PrivateRoute from "./routes/PrivateRoute";
import initialBuyerGuides from "./pages/Utils/initialBuyerGuides.js";
import { UserProfileProvider } from "./context/UserContext.jsx";
import BundleSelection from "./pages/FineTuneTopNav/BundleSelection.jsx";
import ThresholdOption from "./pages/FineTuneTopNav/ThresholdOption.jsx";
import MandatoryDisplayOption from "./pages/FineTuneTopNav/MandatoryDisplayOption.jsx";
import ReportOptions from "./pages/FineTuneTopNav/ReportOptions.jsx";
import UsePurchasedPackage from "./pages/UsePurchasedPackage.jsx";
import PurchaseBulk from "./pages/PurchaseSuccessfulBulkMulti.jsx";
import PurchaseBulkSingle from "./pages/PurchaseSuccessfulBulkSingle.jsx";
import WelcomePage from "./pages/Welcome.jsx";
import AvailableUnitsForAdminAllocation from "./pages/Allocation/AvailableUnitsForAdminAllocation.jsx";
import AdminAllocation from "./pages/Allocation/AdminAllocation.jsx";
// import { apiService } from "./utils/utils.js";
import MainComponent from "../src/Analysis-Tool/MainComponent.js";
import AnalystWelcome from "./Analysis-Tool/AnalystWelcome.jsx";
import { CreateNewBuyerGuide } from "./Analysis-Tool/CreateNewBuyerGuide.jsx";
import BuyerGuideMenu from "./Analysis-Tool/BuyerGuideMenu.js";
import ReviewResultPage from "./Analysis-Tool/ReviewResultPage.js";


const Pages = () => {
 const { instance } = useMsal();
 const activeAccount = instance.getActiveAccount();
 const handleSignInRedirect = async () => {
   instance.loginRedirect({ ...loginRequest });
 };
 const handleSignUpRedirect = async () => {
   instance.loginRedirect({ ...loginRequest, prompt: "create" });
 };

 const handleSignInRedirectForEmployee = async () => {
   instance.loginRedirect({
     ...loginRequest,
     authority:
       "https://login.microsoftonline.com/smartdecisionai.onmicrosoft.com",
   });
   sessionStorage.setItem("userType", "employee");
 };

 const [buyerGuides, setBuyerGuides] = useState(initialBuyerGuides);
 const [interestedGuides, setInterestedGuides] = useState([]);

 const moveToInterested = (selectedGuides) => {
   const newInterestedGuides = buyerGuides.filter((guide) =>
     selectedGuides.includes(guide.id)
   );
   const remainingBuyerGuides = buyerGuides.filter(
     (guide) => !selectedGuides.includes(guide.id)
   );

   setInterestedGuides([...interestedGuides, ...newInterestedGuides]);
   setBuyerGuides(remainingBuyerGuides);
 };

 const moveToSelectBuyer = (selectedGuides) => {
   const returnedToSelect = interestedGuides.filter((guide) =>
     selectedGuides.includes(guide.id)
   );
   const remainingInterestedGuides = interestedGuides.filter(
     (guide) => !selectedGuides.includes(guide.id)
   );

   setBuyerGuides([...buyerGuides, ...returnedToSelect]);
   setInterestedGuides(remainingInterestedGuides);
 };

 return (
   <>
     <AuthenticatedTemplate>
       {activeAccount ? (
         <Layout>
           <Routes>
             {/* This  is for LHS Navigation */}
             <Route path="/users" element={<Users />} />
             <Route
               path="/"
               element={
                 <PrivateRoute>
                   {/* <SelectEndUser /> */}
                   <WelcomePage />
                 </PrivateRoute>
               }
             />
             <Route path="/start-new-use-case" element={<StartNewUseCase />} />
             <Route
               path="/activity"
               element={
                 <PrivateRoute>
                   <Activity />
                 </PrivateRoute>
               }
             />
             <Route
               path="/sharereport"
               element={
                 <PrivateRoute>
                   <ShareReport />
                 </PrivateRoute>
               }
             />
             <Route
               path="/activity/reviewcomplete"
               element={
                 <PrivateRoute>
                   <ReviewComplete />
                 </PrivateRoute>
               }
             />
             <Route
               path="/activity/wipusecase"
               element={
                 <PrivateRoute>
                   <Wipusecase />
                 </PrivateRoute>
               }
             />
             {/* This is for Top Navigation Bar */}

             <Route
               path="/select-end-user-org"
               element={
                 <PrivateRoute>
                   <SelectEndUser />
                 </PrivateRoute>
               }
             />
             <Route
               path="/select-buyer-guide"
               element={
                 <PrivateRoute>
                   <SelectBuyerGuide />
                 </PrivateRoute>
               }
             />
             <Route
               path="/add-change-use-cases"
               element={
                 <PrivateRoute>
                   <UpdateUseCase />
                 </PrivateRoute>
               }
             />
             <Route
               path="/collaborate-on-use-cases"
               element={
                 <PrivateRoute>
                   <CollaborateUseCase />
                 </PrivateRoute>
               }
             />
             <Route
               path="/results/comparative-analysis"
               element={
                 <PrivateRoute>
                   <ComparativeAnalysis />
                 </PrivateRoute>
               }
             />
             <Route
               path="/results/vendor-details"
               element={
                 <PrivateRoute>
                   <VendorDetails />
                 </PrivateRoute>
               }
             />
             <Route
               path="/results/offer-details"
               element={
                 <PrivateRoute>
                   <OfferDetails />
                 </PrivateRoute>
               }
             />
             <Route
               path="/select-scenario"
               element={
                 <PrivateRoute>
                   <SelectScenario />
                 </PrivateRoute>
               }
             />
             <Route
               path="/edit-scenario/vendor-options"
               element={
                 <PrivateRoute>
                   <VendorOptions />
                 </PrivateRoute>
               }
             />
             <Route
               path="/select-buyer-guide/available-buyer-guides"
               element={
                 <PrivateRoute>
                   <SelectBuyer
                     buyerGuides={buyerGuides}
                     moveToInterested={moveToInterested}
                   />
                 </PrivateRoute>
               }
             />
             <Route
               path="/select-buyer-guide/buyer-guide-of-interest"
               element={
                 <PrivateRoute>
                   <InterestedBuyer
                     interestedGuides={interestedGuides}
                     moveToSelectBuyer={moveToSelectBuyer}
                   />
                 </PrivateRoute>
               }
             />
             <Route
               path="/edit-scenario/scenario-settings/bundle-option"
               element={
                 <PrivateRoute>
                   <BundleSelection />
                 </PrivateRoute>
               }
             />
             <Route
               path="/use-purchased-package"
               element={
                 <PrivateRoute>
                   <UsePurchasedPackage />
                 </PrivateRoute>
               }
             />
             <Route
               path="/welcome"
               element={
                 <PrivateRoute>
                   <WelcomePage />
                 </PrivateRoute>
               }
             />
             <Route
               path="/purchase-bulk-multiple"
               element={
                 <PrivateRoute>
                   <PurchaseBulk />
                 </PrivateRoute>
               }
             />
             <Route
               path="/purchase-bulk-single"
               element={
                 <PrivateRoute>
                   <PurchaseBulkSingle />
                 </PrivateRoute>
               }
             />
             <Route
               path="/edit-scenario/scenario-settings/threshold-option"
               element={
                 <PrivateRoute>
                   <ThresholdOption />
                 </PrivateRoute>
               }
             />
             <Route
               path="/edit-scenario/scenario-settings/display-option"
               element={
                 <PrivateRoute>
                   <MandatoryDisplayOption />
                 </PrivateRoute>
               }
             />
             <Route
               path="/edit-scenario/scenario-settings/report-settings"
               element={
                 <PrivateRoute>
                   <ReportOptions />
                 </PrivateRoute>
               }
             />
             <Route
               path="/edit-scenario/update-use-cases"
               element={
                 <PrivateRoute>
                   <UpdateUseCasesFineTune />
                 </PrivateRoute>
               }
             />
             <Route
               path="/finetune"
               element={
                 <PrivateRoute>
                   <FineTune />
                 </PrivateRoute>
               }
             />
             <Route
               path="/packages"
               element={
                 <PrivateRoute>
                   <EndUserPackages />
                 </PrivateRoute>
               }
             />
             <Route
               path="/available-units-for-allocation"
               element={
                 <PrivateRoute>
                   <AvailableUnitsForAdminAllocation />
                 </PrivateRoute>
               }
             />

             <Route
               path="/admin-allocation/*"
               element={
                 <PrivateRoute>
                   <AdminAllocation />
                 </PrivateRoute>
               }
             />

             <Route
               path="/purchase-section"
               element={
                 <PrivateRoute>
                   <PurchasePackSection />
                 </PrivateRoute>
               }
             />
             <Route
               path="/my-subscriptions"
               element={
                 <PrivateRoute>
                   <SubscriptionDetails />
                 </PrivateRoute>
               }
             />
             <Route path="*" element={<> not found</>} />
             <Route
               path="/view-reports"
               element={
                 <PrivateRoute>
                   <ViewReport />
                 </PrivateRoute>
               }
             />
             <Route
               path="/saved-reports"
               element={
                 <PrivateRoute>
                   <SavedReports />
                 </PrivateRoute>
               }
             />
             <Route
               path="/analyst-welcome"
               element={
                 <PrivateRoute>
                   <AnalystWelcome />
                 </PrivateRoute>
               }
             />

             <Route
               path="/create-new-buyer-guide"
               element={
                 <PrivateRoute>
                   <CreateNewBuyerGuide />
                 </PrivateRoute>
               }
             />
             <Route
               path="/analysis"
               element={
                 <PrivateRoute>
                   <MainComponent />
                 </PrivateRoute>
               }
             />

           <Route
               path="/buyer-guide-menu"
               element={
                 <PrivateRoute>
                   <BuyerGuideMenu />
                 </PrivateRoute>
               }
             />

<Route
               path="/sub-use-cases"
               element={
                 <PrivateRoute>
                   <ReviewResultPage />
                 </PrivateRoute>
               }
             />
       



             <Route
               path="/report-settings"
               element={
                 <PrivateRoute>
                   <ReportSettings />
                 </PrivateRoute>
               }
             />
           </Routes>
         </Layout>
       ) : null}
     </AuthenticatedTemplate>
     <UnauthenticatedTemplate>
       <Login
         handleSignInRedirect={handleSignInRedirect}
         handleSignUpRedirect={handleSignUpRedirect}
         handleSignInRedirectForEmployee={handleSignInRedirectForEmployee}
       />
     </UnauthenticatedTemplate>
   </>
 );
};

function App({ msalInstance }) {
 return (
   <Router>
     <ThemeProvider theme={theme}>
       <MsalProvider instance={msalInstance}>
         <UserProfileProvider>
           <Pages />
         </UserProfileProvider>
       </MsalProvider>
     </ThemeProvider>
   </Router>
 );
}

export default App;


