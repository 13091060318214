import React, { useEffect, useState, useContext } from "react";
import { Box, CircularProgress, Typography, IconButton } from "@mui/material";
import { apiService } from "../../../utils/utils"; // Adjust the path as necessary
import ListToggleWidget from "../VendorRelevantOffer"; // Adjust the path as necessary
import { UserProfileContext } from "../../../context/UserContext";
import {
  FileCopyOutlined,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
const RelevantOffers = ({
  isTableVisible,
  widgetLabels,
  widgetKey,
  toggleTableVisibility,
  handleCopyWidget,
  handleRemoveWidget,
  setLayout,
}) => {
  const [offers, setOffers] = useState([]);
  const [offerCount, setOfferCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { userProfile, currentOrganization, buyerGuideName, scenarioName } =
    useContext(UserProfileContext);
  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/relevantOfferCountAndNames?", // Replace with your actual API endpoint
          {
            method: "POST",

            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: buyerGuideName,
              currentScenario: scenarioName,
              displayUseCasesOptions: "Both",
            },
          }
        );
        if (response) {
          setOffers(response.offerNames || []);
          setOfferCount(response.offerCount || 0);
          setLayout((prev) => {
            const newLayout = prev.map((item) => {
              if (item.i !== widgetKey) {
                return item;
              } else {
                const newItem = {
                  ...item,
                  h: 3 + (response.offerCount||0),
                  minH: 3 + (response.offerCount||0),
                  maxH: 3 + (response.offerCount||0),
                };
                return newItem;
              }
            });
            return newLayout;
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch offers:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchOffers();
  }, [
    userProfile,
    currentOrg,
    buyerGuideName,
    scenarioName,
    setLayout,
    widgetKey,
  ]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Typography color="error">
        Error loading data: {error.message || "Unknown error"}
      </Typography>
    );
  }

  return (
    <Box data-test-id="relevantoffer">
      <div
        className="widget-header-container"
        style={{
          flexShrink: 0,
          display: "flex",
          height: 70,
        }}
      >
        {widgetLabels && widgetKey && (
          <Typography
            variant="h6"
            sx={{ marginTop: "15px", textAlign: "center" }}
            className="widget-header"
          >
            {widgetLabels[widgetKey.split("_")[0]]}
          </Typography>
        )}

        <span className="widget-icon">
          {widgetKey === "d" && (
            <IconButton
              data-test-id={`eye-${widgetLabels[widgetKey.split("_")[0]]}`}
              onClick={toggleTableVisibility}
              aria-label="toggle table visibility"
            >
              {isTableVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
          <FileCopyOutlined
            data-test-id={`copy-${widgetLabels[widgetKey.split("_")[0]]}`}
            onClick={() => handleCopyWidget(widgetKey)}
          />
          <IconButton
            data-test-id={`close-${widgetLabels[widgetKey.split("_")[0]]}`}
            size="small"
            onClick={() => handleRemoveWidget(widgetKey)}
          >
            <Close />
          </IconButton>
        </span>
      </div>
      <ListToggleWidget
        name="relevantoffer"
        items={offers}
        count={offerCount}
      />
    </Box>
  );
};

export default RelevantOffers;
