import { Typography } from "@mui/material";

import handleSelfChange from "../../../utils/Allocation/handleSelfChange.jsx";
import updateColumnsForOtherUnits from "../../../utils/Allocation/updateColumnsForOtherUnits.jsx";

import CustomCheckbox from "../CustomCheckbox.jsx";
import { useMemo } from "react";

const CheckboxRenderCell = ({
  params,
  unitName,
  totalLabel,
  inputsLabel,
  sectionData,
  setSectionData,
  setModalData,
}) => {
  const { row: rowData } = params;

  const isDisabled = useMemo(() => {
    return (
      (rowData[unitName] === "" || rowData[unitName]?.selected === "None") &&
      rowData[inputsLabel] >= 0
    );
  }, [rowData, unitName, inputsLabel]);

  const handleCheckboxChange = () => {
    if (unitName === "user") {
      handleSelfChange(
        sectionData,
        setSectionData,
        unitName,
        rowData,
        inputsLabel,
        totalLabel,
        setModalData
      );
    } else {
      const isValidValueEntered =
        rowData[inputsLabel] > 0 ||
        [
          "solo_starter_entered",
          "duel_dynamics_entered",
          "focus_five_entered",
          "all_available_entered",
        ]
          ?.filter((label) => label !== inputsLabel)
          ?.some((label) => rowData[label] > 0);

      if (isValidValueEntered) {
        updateColumnsForOtherUnits(
          sectionData,
          setSectionData,
          rowData,
          totalLabel,
          inputsLabel
        );
      }
      //Whenever any field becomes zero manually
      else {
        setModalData({
          rowData: rowData,
          totalLabel: totalLabel,
          inputsLabel: inputsLabel,
          unitName: unitName,
          isopen: true,
          name:
            unitName === "organisation_name"
              ? rowData[unitName]
              : rowData[unitName]?.selected?.name,
        });
      }
    }
  };

  if (rowData[unitName] === "Total" || rowData[unitName] === "User Defined")
    return <Typography>{rowData[totalLabel]}</Typography>;

  return (
    <CustomCheckbox
      isDisabled={isDisabled}
      max={params.value}
      rowData={rowData}
      inputsLabel={inputsLabel}
      onSet={handleCheckboxChange}
    />
  );
};

export default CheckboxRenderCell;
