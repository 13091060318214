import React, { useState, useEffect } from "react";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./GridComponent.css";

import { TextField, Autocomplete, Box, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import DataGridComponent from "../Widget/ComparativeAnalysisWidget/DetailMetrics";
import BestOfBest from "../Widget/ComparativeAnalysisWidget/BestOfBest";
import UnmetUseCases from "../Widget/ComparativeAnalysisWidget/UnmetUseCases";
import CoverageHeatmap from "../Widget/ComparativeAnalysisWidget/CoverageHeatmap";
import Smart from "../Widget/ComparativeAnalysisWidget/Smart";
import Premium from "../Widget/ComparativeAnalysisWidget/Premium";
import Low from "../Widget/ComparativeAnalysisWidget/Low";
import Budget from "../Widget/ComparativeAnalysisWidget/Budget";
import { ValYouMatrixData } from "../Widget/SampleQuerryData";
import RelevantVendors from "../Widget/ComparativeAnalysisWidget/RelevantVendor";
import RelevantOffers from "../Widget/ComparativeAnalysisWidget/RelevantOffer";

const initialData = ValYouMatrixData;

const widgetComponents = {
  d: DataGridComponent,
  mt: BestOfBest,
  tc: UnmetUseCases,
  ch: CoverageHeatmap,
  q1: Smart,
  q2: Premium,
  q3: Low,
  q4: Budget,
  rv: RelevantVendors,
  ro: RelevantOffers,
};

const widgetLabels = {
  d: "The Valyou Matrix",
  mt: "The Best of the Best",
  tc: "Top Unmet Use Cases across all Vendors/ Offers",
  ch: "Coverage Heatmap",
  q1: "Smart",
  q2: "Premium",
  q3: "Low",
  q4: "Budget",
  rv: "Relevant Vendors",
  ro: "Relevant Offers",
};

const widgetOptions = [
  { label: "The Valyou Matrix", key: "d" },
  { label: "The Best of the Best", key: "mt" },
  { label: "Top Unmet Use Cases across all Vendors/ Offers", key: "tc" },
  { label: "Coverage Heatmap", key: "ch" },
  { label: "Smart", key: "q1" },
  { label: "Premium", key: "q2" },
  { label: "Low", key: "q3" },
  { label: "Budget", key: "q4" },
  { label: "Relevant Vendors", key: "rv" },
  { label: "Relevant Offers", key: "ro" },
];

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-listbox": {
    backgroundColor: "white",
  },
});

const GridComponent = () => {
  const initialLayout = {
    d: {
      i: "d",
      x: 0,
      y: 0,
      w: 9,
      h: 8,
      minW: 9,
      minH: 8,
      maxW: 9,
      maxH: 8,
    },
    mt: {
      i: "mt",
      x: 0,
      y: 43,
      w: 4,
      h: 4,
      minW: 4,
      minH: 4,
      maxW: 4,
      maxH: 4,
    },
    tc: {
      i: "tc",
      x: 0,
      y: 23,
      w: 9,
      h: 6,
      maxW: 9,
      maxH: 6,
      minW: 9,
      minH: 6,
    },
    ch: {
      i: "ch",
      x: 0,
      y: 33,
      w: 9,
      h: 1,
      maxW: 9,
      maxH: 1,
      minW: 9,
      minH: 1,
    },
    q1: {
      i: "q1",
      x: 9,
      y: 0,
      w: 3,
      h: 3,
      maxW: 3,
      maxH: 3,
      minW: 3,
      minH: 3,
    },
    q2: {
      i: "q2",
      x: 9,
      y: 8,
      w: 3,
      h: 3,
      maxW: 3,
      maxH: 3,
      minW: 3,
      minH: 3,
    },
    q3: {
      i: "q3",
      x: 9,
      y: 20,
      w: 3,
      h: 3,
      maxW: 3,
      maxH: 3,
      minW: 3,
      minH: 3,
    },
    q4: {
      i: "q4",
      x: 9,
      y: 13,
      w: 3,
      h: 3,
      maxW: 3,
      maxH: 3,
      minW: 3,
      minH: 3,
    },
    rv: {
      i: "rv",
      x: 9,
      y: 33,
      w: 3,
      h: 3,
      maxW: 3,
      maxH: 3,
      minW: 3,
      minH: 3,
    },
    ro: {
      i: "ro",
      x: 9,
      y: 25,
      w: 3,
      h: 3,
      maxW: 3,
      maxH: 3,
      minW: 3,
      minH: 3,
    },
  };
  const [layout, setLayout] = useState(
     Object.values(initialLayout)
  );
  console.log(layout);
  const [widgets, setWidgets] = useState(
    JSON.parse(localStorage.getItem("widgets")) ||
      widgetOptions.reduce((acc, option) => {
        acc[option.key] = true;
        return acc;
      }, {})
  );
  
  const [widgetCounter, setWidgetCounter] = useState(
    JSON.parse(localStorage.getItem("widgetCounter")) || {
      d: 0,
      mt: 0,
      tc: 0,
      ch: 0,
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
      rv: 0,
      ro: 0,
    }
  );

  const [isTableVisible, setIsTableVisible] = useState(
    JSON.parse(localStorage.getItem("isTableVisible")) || true
  ); 

  useEffect(() => {
    localStorage.setItem("widgets", JSON.stringify(widgets));
  }, [widgets]);

  useEffect(() => {
    localStorage.setItem("widgetCounter", JSON.stringify(widgetCounter));
  }, [widgetCounter]);

  useEffect(() => {
    localStorage.setItem("isTableVisible", JSON.stringify(isTableVisible));
  }, [isTableVisible]);

  const findNextAvailablePosition = (w, h) => {
    const occupied = new Set();
    layout.forEach((item) => {
      for (let dx = 0; dx < item.w; dx++) {
        for (let dy = 0; dy < item.h; dy++) {
          occupied.add(`${item.x + dx},${item.y + dy}`);
        }
      }
    });

    for (let y = 0; ; y++) {
      for (let x = 0; x <= 12 - w; x++) {
        let fits = true;
        for (let dx = 0; dx < w; dx++) {
          for (let dy = 0; dy < h; dy++) {
            if (occupied.has(`${x + dx},${y + dy}`)) {
              fits = false;
              break;
            }
          }
          if (!fits) break;
        }
        if (fits) {
          return { x, y };
        }
      }
    }
  };

  const toggleWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const updatedWidgets = {
        ...prevWidgets,
        [widgetKey]: !prevWidgets[widgetKey],
      };
      let newLayout;
      if (updatedWidgets[widgetKey]) {
        const widget = initialLayout[widgetKey];
        const position = findNextAvailablePosition(widget.w, widget.h);
        const newWidgetLayout = { ...widget, x: position.x, y: position.y };
        newLayout = [...layout, newWidgetLayout];
      } else {
        newLayout = layout.filter((item) => item.i !== widgetKey);
      }
      setLayout(newLayout);
      return updatedWidgets;
    });
  };

  const handleCopyWidget = (widgetKey) => {
    const widget = layout.find((item) => item.i === widgetKey);
    if (!widget) return;

    const newWidgetKey = `${widgetKey}_Copy_${widgetCounter[widgetKey] + 1}`;
    const position = findNextAvailablePosition(widget.w, widget.h);

    const newWidgetLayout = {
      ...widget,
      i: newWidgetKey,
      x: position.x,
      y: position.y,
    };

    setLayout((prevLayout) => [...prevLayout, newWidgetLayout]);
    setWidgets((prevWidgets) => ({ ...prevWidgets, [newWidgetKey]: true }));
    setWidgetCounter((prevCounter) => ({
      ...prevCounter,
      [widgetKey]: prevCounter[widgetKey] + 1,
    }));
  };

  const handleRemoveWidget = (widgetKey) => {
    setWidgets((prevWidgets) => {
      const updatedWidgets = { ...prevWidgets };
      delete updatedWidgets[widgetKey];
      return updatedWidgets;
    });
    setLayout((prevLayout) =>
      prevLayout.filter((item) => item.i !== widgetKey)
    );
  };

  //  const handleResizeStop = (newLayout) => {
  //    setLayout(newLayout);
  //  };

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const toggleTableVisibility = () => {
    setIsTableVisible((prev) => !prev);
  };

  const renderWidgetContent = (widgetKey) => {
    const baseKey = widgetKey.split("_")[0];
    const WidgetComponent = widgetComponents[baseKey];
    return WidgetComponent ? (
      <WidgetComponent
        {...(baseKey === "vro"
          ? null
          : {
              data: initialData,
              isTableVisible,
              widgetLabels,
              widgetKey,
              toggleTableVisibility,
              handleCopyWidget,
              handleRemoveWidget,
              setLayout
            })}
      />
    ) : null;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ flexShrink: 0, marginBottom: 35 }}>
        <StyledAutocomplete
          multiple
          options={widgetOptions}
          getOptionLabel={(option) => option.label}
          value={widgetOptions.filter((option) => widgets[option.key])}
          onChange={(event, newValue) => {
            const newWidgets = {};
            newValue.forEach((option) => {
              newWidgets[option.key] = true;
              if (!widgets[option.key]) toggleWidget(option.key);
            });
            Object.keys(widgets).forEach((key) => {
              if (!newWidgets[key] && widgets[key]) toggleWidget(key);
            });
          }}
          renderInput={(params) => (
            <TextField
              data-test-id="text-field"
              {...params}
              variant="outlined"
              placeholder="Add/Remove Widgets"
              style={{ minWidth: "200px", height: "40px" }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li data-test-id={`${option.label}`} {...props}>
              <Checkbox
                data-test-id={`checkbox-${option.label}`}
                checked={selected}
                style={{ marginRight: 8 }}
              />
              {option.label}
            </li>
          )}
          sx={{
            marginLeft: "10px",
            flexGrow: 1,
            marginBottom: "50px",
          }}
        />
      </div>

      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <GridLayout
          className="layout"
          layout={layout}
          cols={12}
          rowHeight={70}
          width={1400}
          autoSize={true}
          draggableHandle=".widget-header"
          //onResizeStop={handleResizeStop}

          onLayoutChange={handleLayoutChange}
        >
          {layout.map((item) =>
            widgets[item.i] ? (
              <div
                key={item.i}
                className="widget"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div className="widget-content" style={{ flexGrow: 1 }}>
                  {renderWidgetContent(item.i)}
                </div>
              </div>
            ) : null
          )}
        </GridLayout>
      </div>
    </Box>
  );
};

export default GridComponent;
