import React from 'react';
import { Box, Typography, Grid, Divider, Button } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import CustomizedMenus from './CustomizedMenus';

const HeaderNaming = ({ data }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  console.log(data);

  const isMatchingPath = [
    '/results/comparative-analysis',
    '/results/vendor-details',
    '/results/offer-details',
  ].includes(location.pathname);

  return (
    <>
      <Box sx={{ width: '100%', marginBottom: '30px' }}>
        <Grid container spacing={2} alignItems="center">
          {Object.entries(data).map(([label, value]) => (
            <React.Fragment key={label}>
              <Grid item xs={12} sm={3}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: 'bold', textAlign: 'left' }}
                  data-test-id={`label-${label}`}
                >
                  {isMobile ? label + ' : ' : label}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                  <Typography 
                  data-test-id={`label-${value}`}
                  variant="body1" sx={{ textAlign: 'left' }}      >
                  {isMobile ? value : ' : ' + value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        {isMatchingPath && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 3,
            }}
          >
            <Box
              sx={{
                width: '250px',
                display: 'flex',
                boxShadow: '0 4px 2px -2px rgba(0, 0, 0, 0.2)',
              }}
            >
              <Button
                sx={{
                  borderRadius: '6px 0px 0px 6px',
                  boxShadow: 'none',
                  flex: '2 2 85%',
                }}
                color="primary"
                variant="contained"
                disableRipple
                onClick={() => navigate('/report-settings')}
              >
                <Typography color="white">Finalise Scenario</Typography>
              </Button>
              <CustomizedMenus />
            </Box>
          </Box>
        )}
      </Box>
      <Divider sx={{ marginBottom: 3 }} />
    </>
  );
};

export default HeaderNaming;
