import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const PricingCard = ({
  title,
  price,
  description,
  features,
  isActive,
  onClick,
  isCurrentPlan,
}) => {
  const [showDescription, setShowDescription] = useState(true);

  const toggleDescription = (event) => {
    event.stopPropagation(); // Prevent the card click event
    setShowDescription(!showDescription);
  };

  const textColor = isActive ? "#fff" : "rgba(0,0,0,0.7)";
  const secondaryTextColor = isActive
    ? "rgba(255,255,255,1)"
    : "rgba(0,0,0,0.6)";
  const iconColor = isActive ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";
  const backgroundColor = isActive ? "#4F40BA" : "#fff";
  const buttonColor = isActive ? "#fff" : "#5f30e2";

  const shouldShowCloseIcon = (feature) => {
    if (title === "Solo Starter" || title === "Duel Dynamics") {
      return feature === "Generate Report" || feature === "Share Report";
    }
    if (title === "Focus Five") {
      return feature === "Share Report";
    }
    return false;
  };

  return (
    <Card
      sx={{
        maxWidth: 400,
        height: showDescription ? 550 : 300,
        m: 2,
        p: 2,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor,
        color: textColor,
        transition: "transform 0.3s",
        "&:hover": {
          transform: "scale(1.1)",
        },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3" sx={{ mb: 2, color: textColor }}>
              {title}
            </Typography>
            {isCurrentPlan && (
              <Chip
                label="Current Plan"
                sx={{
                  mb: 2,
                  backgroundColor: "secondary.main",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              />
            )}
          </Box>
          <Typography variant="h2" sx={{ mb: 2, color: textColor }}>
            {title === "Solo Starter" ? "Free" : `$${price / 100}`}
            {title !== "Solo Starter" && (
              <Typography
                component="span"
                variant="caption"
                sx={{ color: textColor }}
              >
                {" Per Volume Unit/Month"}
              </Typography>
            )}
          </Typography>
          {showDescription && (
            <Typography
              variant="body1"
              sx={{ mb: 2, color: secondaryTextColor }}
            >
              {description}
            </Typography>
          )}
          {showDescription && (
            <List>
              {features?.map((feature, index) => (
                <ListItem
                  key={index}
                  sx={{
                    color: isActive
                      ? "rgba(255,255,255,0.9)"
                      : "rgba(0,0,0,0.9)",
                  }}
                >
                  <ListItemIcon>
                    {shouldShowCloseIcon(feature) ? (
                      <CloseIcon sx={{ color: iconColor }} />
                    ) : (
                      <CheckIcon sx={{ color: iconColor }} />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: secondaryTextColor }}
                    >
                      {feature}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <Box>
          <Button
            variant="text"
            sx={{ mt: 2, color: buttonColor }}
            onClick={toggleDescription}
          >
            {showDescription ? "Hide Description" : "Show Description"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number,
  description: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  isCurrentPlan: PropTypes.bool,
};

PricingCard.defaultProps = {
  price: 0,
  description: "",
  features: [],
  isActive: false,
  onClick: () => {},
  isCurrentPlan: false,
};

export default PricingCard;
