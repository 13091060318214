import React from 'react';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-xcode";

import './JsonInput.css';

const JsonInput = ({
 inputData,
 onPasteData,
 handleRun,
 handleGenerate,
 handleReview,
 handlePublish,
 description,
 onDescriptionChange
}) => {
 const handleJsonChange = (newValue) => {
   onPasteData({ target: { value: newValue } });
 };

 return (
   <div className="json-input-container">
     <AceEditor
       mode="json"
       theme="xcode"
       name="input-json"
       onChange={handleJsonChange}
       fontSize={16}
       showPrintMargin={true}
       showGutter={true}
       highlightActiveLine={true}
       value={inputData}
       setOptions={{
         enableBasicAutocompletion: false,
         enableLiveAutocompletion: false,
         enableSnippets: false,
         showLineNumbers: true,
         tabSize: 2,
       }}
       style={{ height: '70vh', width: '100%' }}
     />
     <textarea
       className="description-textarea"
       value={description}
       onChange={onDescriptionChange}
       placeholder="Enter description for use case generation"
     />
     <button onClick={handleRun}>RUN</button>
     <button onClick={handleGenerate}>Generate Use Cases</button>
     <button className="review-button" onClick={handleReview}>Review Use Cases</button>
     <button onClick={handlePublish}>Publish Buyer Guide</button>
   </div>
 );
};

export default JsonInput;



