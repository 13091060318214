import React, { useContext, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { apiService } from "../../utils/utils.js";
import { UserProfileContext } from "../../context/UserContext.jsx";

const BundleSelection = () => {
  const { userProfile, currentOrganization, buyerGuideName, scenarioName } =
    useContext(UserProfileContext);
  const [limitedBundlesChecked, setLimitedBundlesChecked] = useState(false);
  const [addOnBundlesChecked, setAddOnBundlesChecked] = useState(false);
  const navigate = useNavigate();

  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser"
        ? userProfile?.companyName
        : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
  };

  const currentOrg =
    userProfile && userProfile.profileType === "endUser"
      ? userProfile.companyName
      : currentOrganization;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/retriveBundleOption?",
          {
            method: "POST",
            data: {
              email: userProfile?.email,
              currentOrganisation: currentOrg,
              currentBuyerGuide: buyerGuideName,
              currentScenario: scenarioName,
            },
          }
        );
        setAddOnBundlesChecked(response.includes("Add-on"));
        setLimitedBundlesChecked(response.includes("Yes (Limited)"));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userProfile, currentOrg, buyerGuideName, scenarioName]);

  const handleLimitedBundlesChange = (event) => {
    setLimitedBundlesChecked(event.target.checked);
  };

  const handleAddOnBundlesChange = (event) => {
    setAddOnBundlesChecked(event.target.checked);
  };

  const saveAndContinue = async () => {
    try {
      await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/updateBundleOption?",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            currentOrganisation: currentOrg,
            currentBuyerGuide: buyerGuideName,
            currentScenario: scenarioName,
            include_add_on: addOnBundlesChecked,
            include_limited_scope: limitedBundlesChecked,
          },
        }
      );
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const button = event.target.querySelector('button[type="submit"]');
    button.textContent = "Saved";

    await saveAndContinue();

    setTimeout(() => {
      button.textContent = "Save";
    }, 2000);
  };

  return (
    <>
      <HeaderNaming data={data} />
      <form onSubmit={handleSubmit}>
        <Typography data-test-id="bundle-option" variant="h3" gutterBottom marginTop={-1.7}>
          Bundle Options for Offer Evaluation
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControlLabel 
                   data-test-id="name-Include Features that are Add-On’s in Offers"
              control={
                <Checkbox
                   data-test-id="checkbox-Include Features that are Add-On’s in Offers"
                  checked={addOnBundlesChecked}
                  onChange={handleAddOnBundlesChange}
                  color="secondary"
                />
              }
              label="Include Features that are Add-On’s in Offers"
            />
          </Grid>
          <Grid item>
            <FormControlLabel 
                data-test-id="name-Include Features that have a limited scope or constraints in Offers"
             
              control={
                <Checkbox
                data-test-id="checkbox-Include Features that have a limited scope or constraints in Offers"
                  checked={limitedBundlesChecked}
                  onChange={handleLimitedBundlesChange}
                  color="secondary"
                />
              }
              label="Include Features that have a limited scope or constraints in Offers"
            />
          </Grid>
        </Grid>
      </form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mt: 2,
          ml: 2,
        }}
      >
        <Button
          style={{ width: "250px", marginLeft: "7px" }}
          color="primary"
          variant="contained"
          disableRipple
          onClick={async () => {
            //await saveAndContinue();
            navigate("/edit-scenario/scenario-settings/threshold-option");
          }}
        >
          <Typography data-test-id="save&continue" color="white">Save & Continue</Typography>
        </Button>

        <Button
          style={{ width: "250px", marginLeft: "7px" }}
          color="secondary"
          variant="contained"
          disableRipple
          onClick={async () => {
            //await saveAndContinue();
            navigate("/results/comparative-analysis", {
              state: {
                limitedBundlesChecked,
                addOnBundlesChecked,
              },
            });
          }}
        >
          <Typography data-test-id="dashboard" color="white">Save & Exit to Dashboard</Typography>
        </Button>
      </Box>
    </>
  );
};

export default BundleSelection;
