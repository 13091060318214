import React from "react";

const ChangeUseCase = () => {
  return (
    <>
      <div>{/* <h2>Select Buyer Guide</h2> */}</div>
    </>
  );
};

export default ChangeUseCase;
