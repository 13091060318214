import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { IconButton, Radio } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import { useTheme } from '@emotion/react';
import { pastReportsData } from './Utils/dummyPastReportData';
import DataGridTable from '../components/DataGridTable';

const SavedReports = () => {
  const theme = useTheme();
  const [editedData, setEditedData] = useState(
    pastReportsData.map((item, index) => ({
      id: index,
      select: false,
      reportName: item['Report Name'],
      scenarioName: item['Scenario Name'],
      buyerGuideName: item['Buyer Guide Name'],
      endUserOrganisation: item['End-user Organisation'],
      reportDateUpdated: item['Report Date (Last updated on)'],
      reportDateCreated: item['Report Date (Created)'],
      preparedBy: item['Prepared by'],
    }))
  );

  const handleRadioChange = (id) => {
    setEditedData((prevData) =>
      prevData.map((row) => ({
        ...row,
        select: row.id === id,
      }))
    );
  };

  const handleProcessRowUpdate = (newRow) => {
    setEditedData((prevData) =>
      prevData.map((row) => (row.id === newRow.id ? { ...newRow } : row))
    );
    return newRow;
  };

  const columns = [
    {
      field: 'select',
      headerName: '',
      headerClassName: 'super-app-theme--header',
      // renderHeader: () => (
      //   <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
      //     Select
      //   </div>
      // ),
      renderCell: (params) => (
        <Radio
          checked={params.row.select}
          onChange={() => handleRadioChange(params.id)}
          value={params.id}
        />
      ),
    },
    {
      field: 'reportName',
      headerName: 'Report Name',
      editable: true,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Report Name
        </div>
      ),
    },
    {
      field: 'scenarioName',
      headerName: 'Scenario Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Scenario Name
        </div>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: 'buyerGuideName',
      headerName: 'Buyer Guide Name',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Buyer Guide Name
        </div>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: 'endUserOrganisation',
      headerName: 'End-user Organisation',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          End-user Organisation
        </div>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: 'reportDateUpdated',
      headerName: 'Last updated on',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Last updated on
        </div>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: 'reportDateCreated',
      headerName: 'Created on',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Created on
        </div>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: 'preparedBy',
      headerName: 'Prepared by',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Prepared by
        </div>
      ),
      renderCell: (params) => <Box>{params.value}</Box>,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Action
        </div>
      ),
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton>
            <VisibilityIcon />
          </IconButton>
          <IconButton>
            <ShareIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box
      flexGrow={1}
      sx={{
        '& .super-app-theme--header': {
          backgroundColor: theme.palette.primary.main,
          border: '0.5px solid grey',
          height: 'fit-content',
        },
      }}
    >
      <DataGridTable
        rows={editedData}
        columns={columns}
        processRowUpdate={handleProcessRowUpdate}
        hideFooter
        autoHeight
        sx={{
          minWidth: '1080px',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
          '& .MuiDataGrid-cell': {
            border: '0.5px solid grey',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          },
          '& .MuiDataGrid-cell--editing': {
            backgroundColor: 'transparent !important',
            border: '3px solid #000', // Thicker border when in edit mode
          },
          '& .MuiDataGrid-cell:hover': {
            border: '0.5px solid #000',
          },
          '& .MuiDataGrid-cell--editable:hover': {
            border: '2px solid #000', // Thicker border when editable cell is hovered
          },
        }}
      />
    </Box>
  );
};

export default SavedReports;
