import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Grid, Button, Checkbox, Box, ThemeProvider, Stack, useTheme, Dialog, DialogContent, DialogActions, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { UserProfileContext } from "../context/UserContext";
import { useNavigate } from 'react-router-dom';
import { Snackbar } from '@mui/material';

const ReviewResultPage = () => {
const [tableData, setTableData] = useState([]);
const [allCasesActedUpon, setAllCasesActedUpon] = useState(false);
const [dialogOpen, setDialogOpen] = useState(false);
const [name, setName] = useState('');
const [description, setNewdescription] = useState('');
const theme = useTheme();
const location = useLocation();
const apiData = location.state?.data;
const { userProfile } = useContext(UserProfileContext);
const [selectAllAccepted, setSelectAllAccepted] = useState(false);
const [selectAllRejected, setSelectAllRejected] = useState(false);
const [successMessage, setSuccessMessage] = useState('');
const navigate = useNavigate();

const buyerGuideElementIde = localStorage.getItem('buyerGuideElementId');
// console.log(buyerGuideElementIde);

useEffect(() => {
setSelectAllAccepted(tableData.every(row => row.accepted_by !== 'N/A'));
setSelectAllRejected(tableData.every(row => row.rejected_by !== 'N/A'));
}, [tableData]);

const toggleAcceptAll = () => {
const newStatus = !selectAllAccepted;
const updatedData = tableData.map(row => ({
  ...row,
  accepted_by: newStatus ? userProfile.email : 'N/A',
//  accepted_by: newStatus ? "nitin.acharekar@gmail.com" : 'N/A',
  rejected_by: newStatus ? 'N/A' : row.rejected_by
}));
setTableData(updatedData);
setSelectAllAccepted(newStatus);
};

const toggleRejectAll = () => {
 const newStatus = !selectAllRejected;
 const updatedData = tableData.map(row => ({
   ...row,
   rejected_by: newStatus ? userProfile.email : 'N/A', // Update rejected_by
   accepted_by: newStatus ? 'N/A' : row.accepted_by // Ensure accepted_by is cleared if rejected
 }));
 setTableData(updatedData);
 setSelectAllRejected(newStatus);
};


useEffect(() => {
 if (apiData && apiData.TableData) {
   setTableData(apiData.TableData.map((data, index) => ({
     id: index + 1,
     ...data,
     description: data?.description || 'N/A',
     source: data?.source || 'N/A',
     accepted_by: data?.accepted_by?.length > 0 ? data?.accepted_by?.join(', ') : 'N/A', // Handle empty array for accepted_by
     rejected_by: data?.rejected_by?.length > 0 ? data?.rejected_by?.join(', ') : 'N/A', // Handle empty array for rejected_by
     hasSubUseCase: data?.hasSubUseCase ? 'Yes' : 'No',
   })));
 }
}, [apiData]);


useEffect(() => {
const allActedUpon = tableData.every(row => row.accepted_by !== 'N/A' || row.rejected_by !== 'N/A');
setAllCasesActedUpon(allActedUpon);
}, [tableData]);



const handleAccept = id => {
const updatedData = tableData.map(row =>
  row.id === id ? { ...row, accepted_by: row.accepted_by === 'N/A' ? userProfile.email : 'N/A', rejected_by: 'N/A' } : row
);
setTableData(updatedData);
checkSelectAllStatus(updatedData, 'accept');
};



const handleReject = id => {
const updatedData = tableData.map(row =>
  row.id === id ? { ...row, rejected_by: row.rejected_by === 'N/A' ? userProfile.email : 'N/A', accepted_by: 'N/A' } : row
);
setTableData(updatedData);
checkSelectAllStatus(updatedData, 'reject');
};

const checkSelectAllStatus = (data, type) => {
if (type === 'accept') {
  const allAccepted = data.every(row => row.accepted_by !== 'N/A');
  const anyAccepted = data.some(row => row.accepted_by !== 'N/A');
  setSelectAllAccepted(allAccepted);
  // Adjusting to only set indeterminate if not all are selected, and at least one is
  document.getElementById('selectAllAccepted').indeterminate = !allAccepted && anyAccepted;
} else if (type === 'reject') {
  const allRejected = data.every(row => row.rejected_by !== 'N/A');
  const anyRejected = data.some(row => row.rejected_by !== 'N/A');
  setSelectAllRejected(allRejected);
  // Adjusting to only set indeterminate if not all are selected, and at least one is
  document.getElementById('selectAllRejected').indeterminate = !allRejected && anyRejected;
}
};






const handleDialogOpen = () => {
setDialogOpen(true);
};

const handleDialogClose = () => {
setDialogOpen(false);
};

const handleSaveNewUseCase = () => {
const newId = tableData.length + 1;
const newUseCase = {
  id: newId,
  element_id : null,
  name: name,
  description: description || 'N/A',
  source: `Added by ${userProfile.email}`,
//  source: `Added by ${"nitin.acharekar@gmail.com"}`,
  accepted_by: userProfile.email,
//  accepted_by: "nitin.acharekar@gmail.com",
  rejected_by: 'N/A',
  hasSubUseCase: 'No',
};
setTableData([...tableData, newUseCase]);
handleDialogClose();
};


const handleSaveAndContinue = async () => {
const savePayload = {
 selectedUseCaseId: apiData?.Header.selectedelement_id,
 useCases: tableData.map(useCase => ({
   element_id: useCase.element_id,
   name: useCase.name,
   description: useCase.description === 'N/A' ? null : useCase.description,
   source: useCase.source === 'N/A' ? null : useCase.source,
   accepted_by: useCase.accepted_by === 'N/A' ? null : useCase.accepted_by,
   rejected_by: useCase.rejected_by === 'N/A' ? null : useCase.rejected_by,
   hasSubUseCase: useCase.hasSubUseCase === 'Yes'
 }))
};

try {
 const saveResponse = await fetch('https://newtestfuncpython.azurewebsites.net/api/saveAndContinueForAnalyst', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(savePayload)
 });

 if (!saveResponse.ok) throw new Error('Failed to save data');

 const postData = {
//    userEmail: "nitin.acharekar@gmail.com",
userEmail : userProfile.email,
   // buyerGuideElementId: "4:525b76d3-e272-4bc1-b9a4-573178e548ed:13"
   buyerGuideElementId : buyerGuideElementIde
 };

 const fetchResponse = await fetch('https://newtestfuncpython.azurewebsites.net/api/getExistingBuyerGuideForAnalyst', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(postData)
 });

 if (!fetchResponse.ok) throw new Error('Failed to fetch buyer guide data');

 const fetchResult = await fetchResponse.json();
//  console.log('Fetched Data:', fetchResult);
 setSuccessMessage('Data saved successfully!');

 setTimeout(() => {
   navigate('/analysis', { state: { buyerGuideData: fetchResult } });
 }, 2000);  // Redirect after 2 seconds to show the message and carry over the fetched data

} catch (error) {
 console.error('API Call Failed:', error);
 setSuccessMessage('Error saving data. Please try again.');
}
};



const columns = [
{
  field: 'accept',
  headerName: 'Accept',
  flex: 0.5,
  minWidth: 150,
  renderHeader: (params) => (
    <Box display="flex" alignItems="center">
      <Checkbox
        checked={selectAllAccepted}
        indeterminate={tableData.some(row => row.accepted_by !== 'N/A') && !selectAllAccepted}
        onChange={toggleAcceptAll}
        color="secondary"
        sx={{ color: 'white' }}
      />
      <Typography variant="body2" style={{ marginLeft: 8, color: 'white' }}>{params.colDef.headerName}</Typography>
    </Box>
  ),
  renderCell: (params) => (
    <Checkbox
      checked={params.row.accepted_by !== 'N/A'}
      onChange={() => handleAccept(params.id)}
      color="secondary"
    />
  )
},
{
 field: 'reject',
 headerName: 'Reject',
 flex: 0.5,
 minWidth: 150,
 renderHeader: (params) => (
   <Box display="flex" alignItems="center">
     <Checkbox
       checked={selectAllRejected}
       indeterminate={tableData.some(row => row.rejected_by !== 'N/A') && !selectAllRejected}
       onChange={toggleRejectAll}
       color="secondary"
       sx={{ color: 'white' }}
     />
     <Typography variant="body2" style={{ marginLeft: 8, color: 'white' }}>{params.colDef.headerName}</Typography>
   </Box>
 ),
 renderCell: (params) => (
   <Checkbox
     checked={params.row.rejected_by !== 'N/A'}
     onChange={() => handleReject(params.id)}
     color="secondary"
   />
 )
},
{ field: 'name', headerName: 'Use Cases', flex: 2, minWidth: 200 },
{ field: 'description', headerName: 'Description (Optional)', flex: 2, minWidth: 200 },
{ field: 'source', headerName: 'Source', flex: 1, minWidth: 130 },
{ field: 'hasSubUseCase', headerName: 'Has Sub Use Cases?', flex: 1, minWidth: 180 },
{ field: 'accepted_by', headerName: 'Accepted By', flex: 1, minWidth: 180 },
{ field: 'rejected_by', headerName: 'Rejected By', flex: 1, minWidth: 180 },
];

return (
<ThemeProvider theme={theme}>

<Snackbar
open={!!successMessage}
autoHideDuration={6000}
onClose={() => setSuccessMessage('')}
message={successMessage}
/>
  <Grid container spacing={2} style={{ marginBottom: '25px' }}>
 <Grid container spacing={2} style={{ marginBottom: '25px' }}>
<Grid item xs={12}>
<Typography variant="h3" component="div" style={{ display: 'grid', gridTemplateColumns: '350px 16px auto', alignItems: 'flex-start' }}>
  <Box component="span">Selected Use Case</Box>
  <Box component="span" style={{ textAlign: 'center' }}>:</Box>
  <Box component="span" style={{ fontWeight: 'normal' }}>{apiData?.Header.selectedname}</Box>
</Typography>
<Typography variant="h3" component="div" style={{ display: 'grid', gridTemplateColumns: '350px 16px auto', alignItems: 'flex-start' }}>
  <Box component="span">Path to Selected Use Case</Box>
  <Box component="span" style={{ textAlign: 'center' }}>:</Box>
  <Box component="span" style={{ fontWeight: 'normal' }}>{apiData?.Header.pathNodes?apiData?.Header.pathNodes.join(' --> '): apiData?.Header.selectedname} </Box>
  
</Typography>
<Typography variant="h3" component="div" style={{ display: 'grid', gridTemplateColumns: '350px 16px auto', alignItems: 'flex-start' }}>
  <Box component="span">Siblings of Selected Use Case</Box>
  <Box component="span" style={{ textAlign: 'center' }}>:</Box>
  <Box component="span" style={{ fontWeight: 'normal' }}>{apiData?.Header.siblingNames.join(', ')}</Box>
</Typography>
</Grid>
</Grid>

  </Grid>
  <div>
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={5}
        hideFooter
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableRowSelectionOnClick
        sx={{
                        // width: '100%',
                        // height: '100%',
                        '& .super-app-theme--header': {
                          backgroundColor: theme.palette.primary.main,
                          color: 'white',
                          fontSize: '14px',
                          '.MuiSvgIcon-root': {
                            color: 'white',
                          }
                        },
                        '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders': {
                          backgroundColor: theme.palette.primary.main,
                          color: 'white',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontWeight: 'normal',
                      
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                          outline: 'none !important',
                        }
                      }}
      />
      <Stack sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        {allCasesActedUpon && (
          <Button variant="contained" color="primary" sx={{ width: '250px', marginTop: "20px" }} onClick={handleSaveAndContinue}>Save & Continue</Button>
        )}
        <Button variant="contained" color="secondary" sx={{ width: '250px', marginTop: "20px" }} onClick={handleDialogOpen}>Add Use Cases</Button>
      </Stack>
    </Box>
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Use Case Name"
          type="text"
          fullWidth
          variant="outlined"
         //  value={newname}
         value  =  {name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
         //  value={newdescription}
         value={description}
          onChange={e => setNewdescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
       <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>

        <Button variant="outlined" onClick={handleDialogClose} sx={{ mr: 2 }}>Cancel</Button>
        <Button variant="contained" onClick={handleSaveNewUseCase} color="primary" >Create Use Case</Button>
       </Box>
      </DialogActions>
    </Dialog>
  </div>
</ThemeProvider>
);
};

export default ReviewResultPage;









