const generateData = (userProfile, page, availableUnitsForAllocation) => {
  //
  const getPackageData = (packageName, field) =>
    availableUnitsForAllocation?.find(
      (packag) => packag.purchased_package === packageName
    )?.[field];

  // Extracted User Units
  const solo_starter_user_units = getPackageData(
    "Solo Starter",
    "user_units_entered"
  );
  const duel_dynamics_user_units = getPackageData(
    "Duel Dynamics",
    "user_units_entered"
  );
  const focus_five_user_units = getPackageData(
    "Focus Five",
    "user_units_entered"
  );
  const all_available_user_units = getPackageData(
    "All Available",
    "user_units_entered"
  );

  // Extracted End User Organization Units
  const solo_starter_org_units = getPackageData(
    "Solo Starter",
    "end_user_organization_units_entered"
  );
  const duel_dynamics_org_units = getPackageData(
    "Duel Dynamics",
    "end_user_organization_units_entered"
  );
  const focus_five_org_units = getPackageData(
    "Focus Five",
    "end_user_organization_units_entered"
  );
  const all_available_org_units = getPackageData(
    "All Available",
    "end_user_organization_units_entered"
  );

  // Extracted Buyer Guide Units
  const solo_starter_buyer_guide = getPackageData(
    "Solo Starter",
    "buyer_guide_units_entered"
  );
  const duel_dynamics_buyer_guide = getPackageData(
    "Duel Dynamics",
    "buyer_guide_units_entered"
  );
  const focus_five_buyer_guide = getPackageData(
    "Focus Five",
    "buyer_guide_units_entered"
  );
  const all_available_buyer_guide = getPackageData(
    "All Available",
    "buyer_guide_units_entered"
  );

  // Updated User Units
  const user_units = [
    {
      id: 123,
      user: "Self",
      name: userProfile.displayName + userProfile.surname,
      email: userProfile.email,

      solo_starter_entered: 0,
      duel_dynamics_entered: 0,
      focus_five_entered: 0,
      all_available_entered: 0,

      solo_starter_total: Math.min(solo_starter_user_units || 0, 1),
      duel_dynamics_total: Math.min(duel_dynamics_user_units || 0, 1),
      focus_five_total: Math.min(focus_five_user_units || 0, 1),
      all_available_total: Math.min(all_available_user_units || 0, 1),
    },
    {
      id: 89,
      user: "Other",

      solo_starter_total: solo_starter_user_units,
      duel_dynamics_total: duel_dynamics_user_units,
      focus_five_total: focus_five_user_units,
      all_available_total: all_available_user_units,
    },
    {
      id: "other1",
      name: "",
      email: "",

      solo_starter_entered: solo_starter_user_units,
      duel_dynamics_entered: duel_dynamics_user_units,
      focus_five_entered: focus_five_user_units,
      all_available_entered: all_available_user_units,

      solo_starter_total: solo_starter_user_units,
      duel_dynamics_total: duel_dynamics_user_units,
      focus_five_total: focus_five_user_units,
      all_available_total: all_available_user_units,
    },
    {
      id: 83,
      user: "Total",
      solo_starter_total: solo_starter_user_units,
      duel_dynamics_total: duel_dynamics_user_units,
      focus_five_total: focus_five_user_units,
      all_available_total: all_available_user_units,
    },
  ];

  //
  // Updated End User Organization Units
  const end_user_organization_units = [
    {
      id: "eu1",
      organisation_name: "",

      solo_starter_entered: 0,
      duel_dynamics_entered: 0,
      focus_five_entered: 0,
      all_available_entered: 0,

      solo_starter_total: Math.min(solo_starter_org_units || 0, 1),
      duel_dynamics_total: Math.min(duel_dynamics_org_units || 0, 1),
      focus_five_total: Math.min(focus_five_org_units || 0, 1),
      all_available_total: Math.min(all_available_org_units || 0, 1),
    },
    {
      id: 23432,
      organisation_name: "User Defined",

      solo_starter_total: solo_starter_org_units,
      duel_dynamics_total: duel_dynamics_org_units,
      focus_five_total: focus_five_org_units,
      all_available_total: all_available_org_units,
    },
    {
      id: 93,
      organisation_name: "Total",
      solo_starter_total: solo_starter_org_units,
      duel_dynamics_total: duel_dynamics_org_units,
      focus_five_total: focus_five_org_units,
      all_available_total: all_available_org_units,
    },
  ];

  // Updated Buyer Guide Units
  const buyer_guide_units = [
    {
      id: "bu1",
      buyer_guide_name: {
        id: 768,
        selected: "None",
        options: [
          { name: "Buyer Guide 1" },
          { name: "Buyer Guide 2" },
          { name: "Buyer Guide 3" },
          { name: "Buyer Guide 4" },
        ],
      },

      solo_starter_entered: 0,
      duel_dynamics_entered: 0,
      focus_five_entered: 0,
      all_available_entered: 0,

      solo_starter_total: Math.min(solo_starter_buyer_guide || 0, 1),
      duel_dynamics_total: Math.min(duel_dynamics_buyer_guide || 0, 1),
      focus_five_total: Math.min(focus_five_buyer_guide || 0, 1),
      all_available_total: Math.min(all_available_buyer_guide || 0, 1),
    },
    {
      id: 93,
      buyer_guide_name: "User Defined",

      solo_starter_total: solo_starter_buyer_guide,
      duel_dynamics_total: duel_dynamics_buyer_guide,
      focus_five_total: focus_five_buyer_guide,
      all_available_total: all_available_buyer_guide,
    },
    {
      id: 88993,
      buyer_guide_name: "Total",

      solo_starter_total: solo_starter_buyer_guide,
      duel_dynamics_total: duel_dynamics_buyer_guide,
      focus_five_total: focus_five_buyer_guide,
      all_available_total: all_available_buyer_guide,
    },
  ];
  //

  // Updated Unlocked Vendors
  const unlocked_vendors = [
    {
      id: "vu1",
      vendor_name: {
        id: 768,
        selected: "None",
        options: [
          { name: "Vendor 1" },
          { name: "Vendor 2" },
          { name: "Vendor 3" },
          { name: "Vendor 4" },
        ],
      },
      solo_starter_entered: 0,
      duel_dynamics_entered: 0,
      focus_five_entered: 0,
      all_available_entered: 0,

      solo_starter_total: 1,
      duel_dynamics_total: 1,
      focus_five_total: 1,
      all_available_total: 1,
    },
    {
      id: 13,
      vendor_name: "User Defined",

      solo_starter_total: 1,
      duel_dynamics_total: 2,
      focus_five_total: 5,
      all_available_total: "Unlimited",
    },
    {
      id: 88993,
      vendor_name: "Total",
      listOfVendors: [
        { name: "Vendor 1" },
        { name: "Vendor 2" },
        { name: "Vendor 3" },
        { name: "Vendor 4" },
      ],
      solo_starter_total: 1,
      duel_dynamics_total: 2,
      focus_five_total: 5,
      all_available_total: "Unlimited",
    },
  ];

  const latestRadioData = JSON.parse(sessionStorage.getItem("latestRadioData"));

  // It maps the data from previous page with the data from session storage of allocated pages
  const dataMapping = {
    user_units: latestRadioData?.find((el) => el.page === "user_units")
      ?.prescription
      ? JSON.parse(sessionStorage.getItem("user_units"))
      : user_units,

    end_user_organization_units: latestRadioData?.find(
      (el) => el.page === "end_user_organization_units"
    )?.prescription
      ? JSON.parse(sessionStorage.getItem("end_user_organization_units"))
      : end_user_organization_units,

    buyer_guide_units: latestRadioData?.find(
      (el) => el.page === "buyer_guide_units"
    )?.prescription
      ? JSON.parse(sessionStorage.getItem("buyer_guide_units"))
      : buyer_guide_units,

    unlocked_vendors: latestRadioData?.find(
      (el) => el.page === "unlocked_vendors"
    )?.prescription
      ? JSON.parse(sessionStorage.getItem("unlocked_vendors"))
      : unlocked_vendors,
  };

  return dataMapping[page];
};

export default generateData;
