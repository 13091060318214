import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, buyerGuide, theme) {
  return {
    fontWeight:
      buyerGuide?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DropdownSelect({ unitsData, onSet }) {
  const [selectedGuide, setSelectedGuide] = useState([]);
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedGuide(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    const inputValue = unitsData?.selected?.name;
    if (inputValue) setSelectedGuide([inputValue]);
    else setSelectedGuide([]);
  }, [unitsData]);

  useEffect(() => {
    const updatedSelect =
      selectedGuide?.length > 0
        ? unitsData.options?.find((option) => option.name === selectedGuide[0])
        : "None";

    if (
      unitsData &&
      unitsData.selected !== updatedSelect &&
      updatedSelect !== "None"
    ) {
      unitsData.selected = updatedSelect;
      onSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuide]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FormControl
        sx={{
          width: "80%",
        }}
      >
        <Select
          displayEmpty
          value={selectedGuide}
          onChange={handleChange}
          input={<OutlinedInput />}
          sx={{
            color: "gray",
            height: 40,
            minHeight: 0,
          }}
          renderValue={(selected) => {
            if (selected?.length === 0) return <span>None </span>;

            return selected.join(", ");
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="" sx={{ justifyContent: "center" }}>
            <span>None</span>
          </MenuItem>
          {unitsData?.options?.map((el) => (
            <MenuItem
              sx={{ justifyContent: "center" }}
              key={el.name}
              value={el.name}
              style={getStyles(el.name, selectedGuide, theme)}
            >
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
