import React, { useEffect, useState } from "react";
import { Box , IconButton, Typography} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import DataGridTable from "../../../components/DataGridTable";
import theme from "../../../theme/theme";
import { apiService } from "../../../utils/utils";
import {
  FileCopyOutlined,
  Close,
  Visibility,
  VisibilityOff,
 
} from '@mui/icons-material';
const columns = [
  { field: "useCase", headerName: "Use Case (path)", flex: 1, headerClassName: "super-app-theme--header" },
  {
    field: "importanceScore",
    headerName: "Importance Score",
    flex: 1,
    type: "number",
    headerClassName: "super-app-theme--header",
  },
  { field: "mandatory", headerName: "Mandatory (Yes/No)", flex: 1, headerClassName: "super-app-theme--header" },
];

const payload = {
  email: "nitin.acharekar@gmail.com",
  currentOrganisation: "BOI",
  currentBuyerGuide: "ZTNA Solution (Test)",
  currentScenario: "Nitin Acharekar - BOI - Scenario 1"
};

const UnmetUseCases = ({ isTableVisible, widgetLabels, widgetKey, toggleTableVisibility, handleCopyWidget, handleRemoveWidget, setLayout}) => {
  const [unmetUseCasesData, setUnmetUseCasesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUnmetUseCases = async () => {
      try {
        const data = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/top5UnmetUseCasesAcrossAllVendors?", // Replace with your actual API endpoint
          {
            method: "POST",
            data: payload,
          },
          setLoading,
          null, // No need to set data directly in apiService call
          setError
        );

        // Transform the data to match the expected structure and include an 'id'
        setLayout((prev) => {
          const newLayout = prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              const newItem = {
                ...item,
                h:
                  1 + data.length,
                minH:
                  1 + data.length,
                maxH:
                  1 + data.length,
              };
              return newItem;
            }
          });
          return newLayout
        })
        const transformedData = data.map((item, index) => ({
          id: index,
          useCase: item.PathNames.join(" > "),
          importanceScore: item.Importance,
          mandatory: item.MandatoryFlag
        }));
        setUnmetUseCasesData(transformedData);
        setLoading(false); // Set loading to false after data is set
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchUnmetUseCases();
  }, [setLayout,widgetKey]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
      className="widget-header-container"
      style={{ flexShrink: 0, display: "flex",  height:20}}
    >
      {widgetLabels && widgetKey &&  <Typography
            variant="h6"
            sx={{ marginTop: "15px" ,textAlign:"center"}}
            className="widget-header"
          >
            {widgetLabels[widgetKey.split("_")[0]]}
          </Typography>}
      
        <Box className="widget-icon">
                    {widgetKey === 'd' && (
                      <IconButton
                      data-test-id={`eye-${widgetLabels[widgetKey.split('_')[0]]}`}
                        onClick={toggleTableVisibility}
                        aria-label="toggle table visibility"
                      >
                        {isTableVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    )}
                    <FileCopyOutlined
                      data-test-id={`copy-${widgetLabels[widgetKey.split('_')[0]]}`}
                      onClick={() => handleCopyWidget(widgetKey)}
                    />
                    <IconButton
                      data-test-id={`close-${widgetLabels[widgetKey.split('_')[0]]}`}
                      size="small"
                      onClick={() => handleRemoveWidget(widgetKey)}
                    >
                      <Close />
                    </IconButton>
        </Box>
       </Box>
      <Box data-test-id="unmetusecases" style={{marginTop:"42px"}}>
        <DataGridTable
        name="unmetusecases"
          rows={unmetUseCasesData}
          columns={columns}
          disableSelectionOnClick
          hideFooter
        />
      </Box>
    </ThemeProvider>
  );
};

export default UnmetUseCases;
