import React from 'react';
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import './Calander.css';

const groupMap = {
  'Solo Starter': 1,
  'Dual Dynamics': 2,
  'Focus Five': 3,
  'All Available': 4,
};

function SubscriptionTimeLine({ fetchedData, section }) {
  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  console.log(fetchedData[section]);
  const now = new Date();
  const year = now.getFullYear();
  
  const firstDate = new Date(year, 0, 1); 
  const lastDate = new Date(year, 11, 31);
  useEffect(() => {
    setGroups(() => {
      let groupCollection = [];
      fetchedData[section].forEach((data) => {
        const packageName = data.package;
        groupCollection.push({
          id: groupMap[packageName],
          title: packageName,
        });
      });
      return groupCollection;
    });

    setItems(() => {
      let itemId = 1;
      let itemsCollection = [];
      fetchedData[section].forEach((data) => {
        const subscriptions = data.subscriptions;
        const packageName = data.package;
        subscriptions.forEach((subscription) => {
          itemsCollection.push({
            id: itemId,
            group: groupMap[packageName],
            title: `Total Units ${subscription.total_units} & Cost ${subscription.cost}`,
            start_time: moment(subscription.start_date),
            end_time: moment(subscription.end_date),
            canMove: false,
            canResize: false,
            itemProps: {
              style: {
                background: '#319792',
              },
            },
          });
          itemId++;
        });
      });
      return itemsCollection;
    });
  }, [fetchedData, section]);
 
  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      <Box sx={{ width: '100%' }}>
        <Timeline
          groups={groups}
          items={items}
          defaultTimeStart={moment(firstDate)}
          defaultTimeEnd={moment(lastDate)}
          showCurrentTime
          stackItems
          lineHeight={50}
        />
      </Box>
    </Box>
  );
}

export default SubscriptionTimeLine;
