import React from "react";

const UpdateUseCase = () => {
  return (
    <>
      <div>
        <h2>Update Use Case Page</h2>
      </div>
    </>
  );
};

export default UpdateUseCase;
