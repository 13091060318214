import React from "react";
import { Button, Typography } from "@mui/material";

const CustomButton = ({ style, color, variant, onClick, text }) => (
  <Button style={style} color={color} variant={variant} onClick={onClick} disableRipple sx={{width:'250px'}}>
    <Typography color="white">{text}</Typography>
  </Button>
);

export default CustomButton;
